import React, { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip, Table, Card, Row, Spinner } from "react-bootstrap";
import { IGallerySubmission } from "../../../interfaces/fairs";
import { getSurveyTitle } from "../_helper/submission_helper";
import moment from "moment";
import StatusButton from "../status-button.component";
import DataTable from "react-data-table-component";
import instance from "../../../api/api";
import { toast } from "react-toastify";
import { FormHelper } from "../../../_helper/formHelper";
import { Icon } from "@ailibs/feather-react-ts";
import RenderSurvey from "../../display-survey.component";
import { ERoles } from "../../../interfaces/authentication";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import StatusBadge from "../status-badge.component";
import { isAdminAccess } from "../_helper/module_helper";
import { getUniqueSubmissions } from "../../../_helper/nominationSubmissionsHelper";

interface IProps {
    show:boolean
    onHide:Function
    data:any
    enableNominationModal:Function
    updateSubmissionByAdministrator: Function
    getGallerySubmissions:Function
    resultData:any[]
    fairId:string
    moduleId:string
}

export default function DetailedModal(props:IProps) {

    const [ showHistoryModal, setShowHistoryModal ] = useState<boolean>(false);
    const [ historyModalKey, setHistoryModalKey ] = useState<number>(-1);

    const [ showEditModal, setShowEditModal ] = useState<boolean>(false);
    const [ showEditModalKey, setShowEditModalKey ] = useState<number>(-1);

    const [ editSubmissionData, setEditSubmissionData ] = useState<IGallerySubmission | null>(null);

    const [ modalDetails, setModalDetails ] = useState<{fairName: string, moduleName: string} | null>(null);

    //submissions
    const [ submissions, setSubmissions ] = useState<IGallerySubmission[]>([])

    //past submissions
    const [ allSubmissions, setAllSubmissions ] = useState<IGallerySubmission[]>([]);

    //loader
    const [ loading, setLoading ] = useState(false);


    //redux user
    const { user, selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)


    useEffect(() => {
        if(props.show && props.data.gallery_id) {
            getAllGallerySubmissions();
        }
    }, [props.data, props.resultData,props.show])

    useEffect(() => {
        const fair = fairs.find(item => item._id === props.fairId);
        if (fair) {
            const module = fair.moduls.find(item => item._id === props.moduleId);
            if (module) {
                setModalDetails({fairName: fair.name, moduleName: module.title});
            }
        }
    }, [props.fairId, props.moduleId])

    async function getAllGallerySubmissions() {
        try {
            setLoading(true);

            const response = await instance.get(`/gallery-submission`, {
                params: {
                    data: {
                        gallery_id:props.data.gallery_id,
                        fair:props.data.fair,
                        modul_id:props.data.module_id,

                    },
                }
            });
            setAllSubmissions(response.data)

            const unique = getUniqueSubmissions(response.data);
            setSubmissions(unique);

            setLoading(false);
        }
        catch(error) {
            toast.error("Failed to load Submissions");
            console.log('error', error)
        }
    }

    function createDynamicResultList(submission:IGallerySubmission, showActions:boolean = false) {
        const resultObj = submission.survey_result;
        const formInfo = submission.survey_definition;

        const formElements = formInfo.elements?.map((element:any)=>{
            if(element.type === "panel"){
                return element.elements ? element.elements : [];
            }else{
                return element;
            }
        }).flat();

        let cols: any[] = [];

        formElements.forEach((question:any) => {
            if(resultObj[question.name]){
                cols.push({
                    name:question.title ? question.title : question.name,
                    selector: (row:any) => getSelectorValue(question,resultObj[question.name], submission._id),
                    minWidth: question.type === "paneldynamic" ? "150px" : "100px",
                    style: question.type === "paneldynamic" ? {width: '100%'} : null,
                    id: question.type === "paneldynamic" ? "paneldynamic" : null,
                })
            }else{
                cols.push({
                    name:question.title ? question.title : question.name,
                    selector: (row:any) => "-",
                    minWidth: question.type === "paneldynamic" ? "150px" : "100px",
                    style: question.type === "paneldynamic" ? {width: '100%'} : null,
                    id: question.type === "paneldynamic" ? "paneldynamic" : null,
                })
            }
        });

        if(showActions){
            const resultColumns = [...cols.filter(col => col.name)];
            const columnsWithAction = [ ...resultColumns ];
            return columnsWithAction
        }
        return cols;
    }

    function _updateSubmissionByAdministrator(data:any) {
        setShowEditModal(false);
        props.updateSubmissionByAdministrator(data)
    }

    const handleSubmissionStatusChange = async (submission:IGallerySubmission, newStatus:string) => {
        try {
            let data:any = {submission_status:newStatus};

            const response = await instance.patch(`/gallery-submission/${submission._id}`, data);
            delete response.data.survey_result;
            _updateSubmissionByAdministrator(response.data);
            toast.success("Submission status updated!");

        } catch (error) {
            console.log(error);
            toast.error("Submission status couldn't be updated!");
        }
    }

    function dataURLtoFile(dataurl:any, filename:any) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    function getSelectorValue(field:any,value:any, subId?:string){
        switch (field.type) {
            case "signaturepad":
                const sFile = dataURLtoFile(value,"signature");
                const sUrl = URL.createObjectURL(sFile);
                return (<a href={sUrl} target="_blank" rel="noreferrer" download>Signature</a>);
            case "file":
                const file = dataURLtoFile(value[0].content, value[0].name);
                const url = URL.createObjectURL(file);
                return (<a href={url} target="_blank" rel="noreferrer" download>{value[0].name}</a>);
            case "dropdown":
            case "radiogroup":
                if (field.choices) {
                    let choice = field.choices.find((c:any)=>c.value && c.value === value);
                    if(choice){
                        return choice.text ? choice.text : (choice.value ? choice.value : choice);
                    }
                }
                return value;
            case "checkbox":
                let labels:string[] = [];
                value.forEach((v:any)=>{
                    if (field.choices) {
                        let choice = field.choices.find((c:any)=>c.value && c.value === v);
                        if (choice) {
                            labels.push(choice.text ? choice.text : (choice.value ? choice.value : choice));
                        } else {
                            labels.push(v);
                        }
                    }
                });
                return labels.join(", ");
            case "paneldynamic":
                let panelHeader = field.templateElements.map((elem:any,index:number)=>{
                    return <th key={index}><b>{elem.title || elem.name}</b></th>
                });
                if(field.usage && field.usage === FormHelper.usage.PASS_ALLOCATION){
                    panelHeader.push(<th>Actions</th>);
                }

                if (field.type === "paneldynamic" && field.usage && field.usage === FormHelper.usage.PASS_ALLOCATION) {
                    value = value.map((item:any, i:number)=>({...item, index: i}));
                    value = value.sort((a:any, b:any) => a.status < b.status ? 1 : -1);
                }
                let panelData = value.map((answer: any, i: number) => {
                    let panelTableData = field.templateElements.map((question:any,index:number)=>{
                        if(field.usage && field.usage === FormHelper.usage.PASS_ALLOCATION && question.name === "status"){
                            return <td><StatusBadge currentStatus={getSelectorValue(question,answer[question.name])}
                                statuses={[
                                    {name:"Not Yet Submitted", value:"Submitted", variant:"warning"},
                                    {name:"Under Review", value:"Under Review", variant:"info"},
                                    {name:"Approved", value:"Approved",variant:"success"},
                                    {name:"Completed", value:"Completed",variant:"success"},
                                    {name:"Already Nominated", value:"Already Nominated",variant:"danger"},
                                ]}
                            /></td>
                        }
                        if(answer[question.name]){
                            return (<td key={i}>{getSelectorValue(question,answer[question.name])}</td>);
                        }    
                    });
     
                    return (
                        <tr className="paneldynamic-row" key={i}>
                                {panelTableData}
                                {field.usage && field.usage === FormHelper.usage.PASS_ALLOCATION && <td className="pass_action">
                                    <Icon
                                        className={"action-icon"}
                                        name="check-square"
                                        onClick={() => {
                                            props.enableNominationModal(value, i, answer.index, subId || "")
                                        }}
                                        size="30px"
                                    />
                                    <Icon
                                        className={"action-icon"}
                                        name="trash-2"
                                        onClick={() => {
                                            handlePanelDynamicRowDelete(subId || "", i)
                                        }}
                                        size="30px"
                                    />
                                </td>}
                        </tr>
                    )
                });
                return (<Table className="paneldynamic-cell">
                            <thead>{panelHeader}</thead>
                            <tbody>{panelData}</tbody>
                        </Table>)
            default:
                return value;
        }
    }

    const handlePanelDynamicRowDelete = async (submissionId: string, index: number) => {
        try {
            const submission = allSubmissions.find(s =>s._id === submissionId);
            if (submission) {
                const survey = submission.survey;

                let data:any = {};
                if(survey.survey_form.usage === "Pass Allocation"){
                    let result = submission.survey_result;
                    Object.keys(result).forEach(key=>{
                        if(Array.isArray(result[key])){
                            result[key].splice(index, 1);
                        }
                    });
                    data["survey_result"] = result;
                }

                const response = await instance.patch(`/gallery-submission/${submission._id}`, data);
                _updateSubmissionByAdministrator(response.data);
                toast.success("Deleted successfully!");
            }
        } catch (error) {
            console.log(error);
            toast.error("Error occured whlie deleting row");
        }
    }

    function closeModal() {
        setSubmissions([]);
        props.onHide()
    }

    return(
        <Modal dialogClassName="submissions-detailed-modal" show={props.show} onHide={()=>{closeModal()}}>
            <Modal.Header closeButton>
            <Modal.Title>
                {props.data ? props.data.gallery_name : ""} - {modalDetails?.fairName || ""} - {modalDetails?.moduleName || ""}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.data && props.data.submissions && submissions ? <div className="expanded">
                    {submissions.map((submission:IGallerySubmission, index:number) => {
                        let submissionHistory = allSubmissions.filter(sub => sub.survey._id === submission.survey._id && sub.gallery_id === submission.gallery_id);
                        let usage = submission.survey_definition.usage;

                        submissionHistory = submissionHistory.sort(function(subA,subB) {
                            if (subA.survey._id === subB.survey._id) {
                                return (subA.createdAt < subB.createdAt) ? 1 : ((subB.createdAt > subA.createdAt) ? -1 : 0)
                            }
                            return subA.survey._id > subB.survey._id ? 1 : -1;
                        });

                        return(
                            <div className="sub">
                            <div className="submissions" key={submission._id}>
                                <div className="submissions-header">
                                    <div className="submission-name-container">
                                        <b>{getSurveyTitle(submission.survey)} -  </b>
                                        <div className="submission-date-container">
                                            <div className="date">
                                                <>{moment(submission.createdAt).format("YYYY/MM/DD (HH:mm:ss)")}</>
                                            </div>
                                            {submission.modified_by && <div>{"(Modified by: "}{submission.modified_by.firstname} {submission.modified_by.lastname}{")"}</div>}
                                        </div>
                                    </div>

                                    <div className="submission-data-container">
                                        <div className="status">
                                            <StatusButton passAllocation={usage && usage === FormHelper.usage.PASS_ALLOCATION} onChange={(status:string)=>{handleSubmissionStatusChange(submission,status)}} currentStatus={submission.submission_status}
                                            statuses={[
                                                {name:"Pending", value:"pending",variant:"warning"},
                                                {name: "In Progress", value:"inprogress", variant:"warning"},
                                                {name: "Under Review", value:"under_review", variant:"info"},
                                                {name:"Approved", value:"approved",variant:"success"},
                                                {name:"Rejected", value:"rejected",variant:"danger"},
                                                {name:"Open", value:"open",variant:"info"},
                                                {name:"Not Submitted", value:"incomplete",variant:"danger"},
                                                {name:"In Progress", value:"inprogress", variant:"warning" },
                                                {name:"Completed", value:"completed",variant:"success"}
                                            ]}
                                            />
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Show submission history</Tooltip>}>
                                                {({ ref, ...triggerHandler }) => (
                                                    <div className="btn-container" onClick={() => {setShowHistoryModal(!showHistoryModal); setHistoryModalKey(index)}} >
                                                        <Button className={showHistoryModal && historyModalKey === index ? "small active": "small "} {...triggerHandler} ref={ref}>
                                                            <Icon size={18} name="eye" />
                                                        </Button>
                                                    </div>
                                                )}
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Edit submission</Tooltip>} >
                                                {({ ref, ...triggerHandler }) => (
                                                    <div className="btn-container" onClick={() => { setShowEditModal(!showEditModal); setEditSubmissionData(submission); setShowEditModalKey(index) }}>
                                                        <Button {...triggerHandler} className={showEditModal && showEditModalKey === index  ? "small active" : "small"} ref={ref}>
                                                            <Icon size={18} name="edit" />
                                                        </Button>
                                                    </div>
                                                )}
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>

                                {showHistoryModal && historyModalKey === index ? 
                                <div className="show-history-container">
                                    <div className="title-container">
                                            <p>Submission History</p>
                                            <div onClick={() => setShowHistoryModal(false)}>
                                                <Icon size={26} name="x" />
                                            </div>
                                        </div>
                                    <Row>
                                        {submissionHistory.map((sub, index) => {
                                            return(
                                                <Card key={index}>
                                                    <Card.Header>
                                                        <p><b>{moment(sub.createdAt).format("YYYY/MM/DD (HH:mm:ss)")}</b></p>
                                                        {sub.modified_by && <p><b>Modified by: {sub.modified_by.firstname} {sub.modified_by.lastname}</b></p>}
                                                    </Card.Header>
                                                    <Card.Body>

                                                        <DataTable
                                                        className="expanded-table"
                                                        columns={createDynamicResultList(sub)}
                                                        data={[sub.survey_result]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            )})}
                                    </Row>
                                </div> : null}

                                {showEditModal && showEditModalKey === index ?
                                    <div className="show-edit-container">
                                        <div className="title-container">
                                            <p>Edit Submission </p>
                                            <div onClick={() => setShowEditModal(false)}>
                                                <Icon size={26} name="x" />
                                            </div>
                                        </div>
                                        {editSubmissionData !== null ? <RenderSurvey isAdminUpdate={true} gallery_name={editSubmissionData.gallery_name} gallery_id={editSubmissionData.gallery_id} survey={editSubmissionData.survey} updateSubmissions={(data:any) => _updateSubmissionByAdministrator(data)} template={false} readonly={!isAdminAccess(user, selectedGallery)} submissions={allSubmissions} getSubmissions={props.getGallerySubmissions}/> : null}
                                    </div>
                                :null}

                                <div className="table-container" >
                                    <DataTable className="expanded-table"
                                        columns={createDynamicResultList(submission,true)}
                                        data={[submission.survey_result]}
                                        responsive={true}
                                        striped
                                    />
                                </div>
                            </div>
                            
                        </div>)
                    })}
                </div> : <p>Submission data not avalible</p>}
                {loading ? <Spinner className="login-loader" animation="border" size="sm" /> : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{closeModal()}}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}