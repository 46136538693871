import React, { useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import instance from "../../api/api";
import { ERoles } from "../../interfaces/authentication";
import { Button } from "reactstrap"
import { toast } from "react-toastify";
import {
    EOrderCategory,
    EPaymentStatus,
    EOrderStatus,
    IGallerySubmission,
    IMainPage,
    IModule,
    IOrder,
    IOrderItem as FairOrderItem,
    IPayment,
    ISubPage,
    IDynamicObject,
    IFair,
    EPaymentType,
} from "../../interfaces/fairs";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { useHistory, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { getOpportunityId, isSubmissionStatusOpen } from "../../_helper/_custom";
import StatusBadge from "../fair/status-badge.component";
import moment from "moment";
import { sortBy, head, round } from "lodash";
import fileDownload from "js-file-download";
import { Icon } from "@ailibs/feather-react-ts";
import CurrencyList from "currency-list";
import { isAdminAccess } from "../fair/_helper/module_helper";

interface IPorps {
    fairId:string
    module:IModule
    categoryNames?: string[]
    typeNames?: string[]
    settings: IDynamicObject
    paymentMethod?: string
}

interface IOrderItem {
    crm_id:string
    type_id:string
    name:string
    price:string
    price_ex_vat: string
    calculated_tax_c: string
    tax_rate_c: string
    currency:string
    currency_name:string
}

interface IOrderValue extends IOrderItem {
    value:number
}

interface IOrderData {
    fair:string
    module:string
    page: string
    gallery_id:string
    gallery_name:string
    order_items:any[]
    status:EOrderStatus
    payment:IPayment
    orderCategory:EOrderCategory
    legal_entity: string
    opportunity_id: string
}

type GenericObject = { [key: string]: IOrderValue };


export default function OrderForm(props:IPorps) {

    const { id } = useParams<{ id: string }>();

    const history = useHistory();
    const cookies = new Cookies();

    const [ orderItems, setOrderItems ] = useState<IOrderItem[]>([]);
    const [ previousOrders, setPreviousOrders ] = useState<IOrder[]>([])
    const [ formSubmissions, setFormSubmissions ] = useState<IGallerySubmission[]>([])
    const [ loading, setLoading ] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState(false)
    const [isFormOpen, setIsFormOpen] = useState(false);

    const [paymentMethod,setPaymentMethod] = useState<string>("Stripe");
    const [orderCategory,setOrderCategory] = useState<string>(EOrderCategory.STAND_ORDER);
    const [successMessage,setSuccessMessage] = useState<string>('');

    const [hasDownloadButton,setHasDownloadButton] = useState<boolean>(true);

    const [fair,setFair] = useState<IFair|undefined>();

    //gallrey submissions
    const [ galleryOrderSubmission, setGalleryOrderSubmission ] = useState<GenericObject>({})

    const { user } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    //redux data
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)

    const { mainpageid, subpageid } = useParams<{ mainpageid?: string; subpageid?: string }>()

    useEffect(() => {
        setLoading(true)
        getOrderItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fairs, user])

    useEffect(() => {
        if(orderItems.length && !isAdminAccess(user, selectedGallery) && selectedGallery.gallery_id) {
            loadPreviousOrders();
        }
        loadSubmissions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderItems, selectedGallery, user.role])

    useEffect(()=>{
        setIsFormOpen(isOrderOpen());
    },[previousOrders, formSubmissions]);

    async function getOrderItems() {
        try {
            const settings = props.settings;
            
            const _fair = fairs.find((fair) => props.fairId === fair._id)
            setFair(_fair);

            if(settings.hasOwnProperty("paymentMethod")){
                setPaymentMethod(settings.paymentMethod);
            }
            if(settings.hasOwnProperty("orderCategory")){
                setOrderCategory(settings.orderCategory);
            }
            if(settings.hasOwnProperty("hasDownloadButton")){
                setHasDownloadButton(settings.hasDownloadButton !== '0');
            }
            if(settings.hasOwnProperty("successMessage")){
                setSuccessMessage(settings.successMessage);
            }
            if (settings.hasOwnProperty("categoryNames") && settings.hasOwnProperty("typeNames") && _fair) {

                const query = new URLSearchParams({
                    active_status: "Active",
                    category_names: settings.categoryNames,
                    typeNames: settings.typeNames,
                    eve_events_producttemplates_1eve_events_ida: _fair?.crm_id,
                })

                const response = await instance.get(`/order/items?${query.toString()}`)
                if (!isAdminAccess(user, selectedGallery)) {
                    const temp: any = {}
                    response.data.forEach((item: any) => {
                        
                        temp[item.crm_id] = {
                            value: 0,
                            ...item,
                        }
                    })
                    setGalleryOrderSubmission(temp)
                }
                response.data = sortBy(response.data, 'name')
                if(settings.hasOwnProperty("paymentMethod") && settings.paymentMethod === "Stripe"){
                    setOrderItems(response.data.filter((item:any)=>item.price && parseFloat(item.price)!==0));
                }else{
                    setOrderItems(response.data);
                }

            }
            setLoading(false)
        } catch (error) {
            toast.error("Failed to load order items.")
            setLoading(false)
        }
    }

    async function loadPreviousOrders() {
        try {
            const response = await instance.get("/order",{params:{
                fair:props.fairId,
                module:props.module._id,
                gallery_id:selectedGallery.gallery_id,
                page:subpageid || mainpageid || ""
            }});
            if(response.data && response.data.length) {
                // TODO: move dateSort out with param ASC DESC
                if(orderCategory === EOrderCategory.STAND_ORDER){
                    const lastOrder = head(response.data.filter((order:any) => (
                        order.fair === props.fairId
                        && order.module === props.module._id
                        && order.gallery_id === selectedGallery.gallery_id
                        && order.page === (subpageid || mainpageid || "")
                    )).sort(function compare(a:any, b:any) {
                        let dateA:any = new Date(a.createdAt);
                        let dateB:any = new Date(b.createdAt);
                        return dateB - dateA;
                    }));
                    if(lastOrder){
                        setPreviousOrders([lastOrder as IOrder] )
                    }
                }else{
                    setPreviousOrders(response.data)
                }
            }
            else {
                return;
            }
        }
        catch(error) {
            toast.error("Failed to load previously submitted order.")
        }
    }

    async function createInvoiceOrder() {
        try {
            setIsDisabled(true)

            const orderData : IOrderData = {
                fair:props.fairId,
                module:props.module._id,
                gallery_id:selectedGallery.gallery_id,
                gallery_name:selectedGallery.gallery_name,
                page: subpageid || mainpageid || "",
                order_items:Object.values(galleryOrderSubmission).filter(x => (x.value > 0)).map(e=>{return isNaN(parseFloat(e.price)) ? {...e,...{price:"0"}} : e}),
                status:EOrderStatus.PENDING,
                payment:{
                    data:"",
                    status:EPaymentStatus.UNPAID,
                    type:EPaymentType.INVOICE,
                    currency: fair?.currency || "GBP",
                },
                orderCategory: orderCategory as EOrderCategory,
                legal_entity: fair?.legalEntity || "",
                opportunity_id: getOpportunityId(selectedGallery, props.module.contract_category_id),
            }
            await instance.post("/order", {data : orderData});
            history.push("/checkout?success=true&successMessage="+encodeURIComponent(successMessage))
        }
        catch(error) {
            setIsDisabled(false)
            toast.error("Failed to place order.");
        }
    }

    const handleSubmitClick = ()=>{
        switch (paymentMethod) {
            case "Stripe":
                const order_items = Object.values(galleryOrderSubmission).filter(x => (x.value > 0));

                const cart = {
                    items: order_items,
                    fairId:props.fairId,
                    moduleId:props.module._id,
                    moduleContractCategoryId:props.module.contract_category_id,
                    pageId: subpageid || mainpageid || "",
                    orderCategory:orderCategory,
                    currency_name: CurrencyList.get(fair?.currency || "GBP").code,
                    currency_symbol:CurrencyList.get(fair?.currency || "GBP").symbol,
                };
                cookies.set("cart", JSON.stringify(cart), { path: "/" })
                history.push("/checkout?successMessage="+encodeURIComponent(successMessage));
                break;
            case "Invoice":
                createInvoiceOrder();
                break;
            default:
                break;
        }

    }

    function checkHasItemWithUnit() {
        const hasNotZeroUnit = Object.values(galleryOrderSubmission).filter((x) => x.value > 0).length > 0
        return hasNotZeroUnit
    }

    function getPage(){

        let pageId = subpageid || mainpageid || false;
        if(!pageId) return;

        // we check all the main pages first
        let page: IMainPage|ISubPage|undefined = props.module.main_pages.find(mainPage => mainPage._id === pageId);

        if(!page){
            // then we check all the subpages if not found yet
            props.module.main_pages.forEach( mainPage => {
                if(mainPage.sub_pages.find(subPage => subPage._id === pageId)){
                    page = mainPage.sub_pages.find(subPage => subPage._id === pageId);
                }
            })
        }

        if(!page){
            return
        }
        return page;
    }

    function getSurvey(){
        let page = getPage();

        if(!page) return;
        if(!page.surveys.length) return;
        return page.surveys[0];
    }

    async function loadSubmissions(){
        try {

            let survey = getSurvey();

            if(!survey) return;
            if(!selectedGallery.gallery_id) return;
            const response = await instance.get(`/gallery-submission`, {
                params: {
                    data: {
                        survey:survey._id,
                        gallery_id:selectedGallery.gallery_id
                    }
                }
            });

            setFormSubmissions(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    function isOrderOpen(){
        try {
            // TODO: REMOVE THIS AS THIS NEEDS TO BE DONE BY API
            let submissions = formSubmissions;
            if(submissions) submissions = submissions.filter((submission:IGallerySubmission)=>!submission.deleted);


            // if form was not submitted Open depends on if they had placed orders before (only for stand orders)
            // TODO: move order category string to a constant eg ORDER_CATEGORY.STAND_ORDER
            if(!submissions || !submissions.length) return (previousOrders.length === 0 || orderCategory != EOrderCategory.STAND_ORDER);

            // TODO: REMOVE THIS AS THIS NEEDS TO BE DONE BY API
            submissions = submissions.sort(function(subA:IGallerySubmission,subB:IGallerySubmission) {
                if (subA.survey._id === subB.survey._id) {
                    return (subA.createdAt < subB.createdAt) ? 1 : ((subB.createdAt > subA.createdAt) ? -1 : 0)
                }
                return subA.survey._id > subB.survey._id ? 1 : -1;
            });

            let submission = submissions[submissions.length-1];
            // otherwise either the form submission needs to be open or they have placed no orders yet
            return (orderCategory != EOrderCategory.STAND_ORDER || isSubmissionStatusOpen(submission.submission_status) || previousOrders.length === 0);
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const AdminColumns = [
        {
            name: 'Name',
            cell: (row: any) => (<div style={{whiteSpace:"break-spaces"}}>{row.name}</div>),
            sortable: true,
        },
        {
            name: 'Price (Excluding VAT)',
            selector: (row: any) => (CurrencyList.get(fair?.currency || "GBP").symbol) + round(parseFloat(row.price_ex_vat),2) ,
            sortable: true,
            omit: (!fair?.showPrices && paymentMethod!=="Stripe")
        },
        {
            name: 'Price (Including VAT)',
            selector: (row: any) => (CurrencyList.get(fair?.currency || "GBP").symbol) + round(parseFloat(row.price),2) ,
            sortable: true,
            omit: (!fair?.showPrices && paymentMethod!=="Stripe")
        }
    ];

    const galleryColumns = [
        {
            name: "Name",
            cell: (row: any) => (<div style={{whiteSpace:"break-spaces"}}>{row.name}</div>) ,
        },
        {
            name: "Unit",
            cell: (row: any) => {
                let max = (row.maximum_order_quantity!==null && row.maximum_order_quantity !== 0) ? row.maximum_order_quantity : undefined;
                return (
                    <div>
                        {galleryOrderSubmission[row.crm_id] ? <input
                            style={{ backgroundColor: galleryOrderSubmission[row.crm_id].value > 0 ? "#c5ffc5" : "" }}
                            min="0"
                            disabled={!isFormOpen}
                            type="number"
                            value={galleryOrderSubmission[row.crm_id].value}
                            onChange={(e) => {
                                let value = parseInt(e.target.value);
                                if(max && value>max) value = max;
                                setGalleryOrderSubmission((prev) => {
                                    return { ...prev, [row.crm_id]: { ...prev[row.crm_id], value: value } }
                                })
                            }}></input> : <></>}
                            {(galleryOrderSubmission[row.crm_id]?.value && (paymentMethod === "Stripe" || fair?.showPrices))   ? <span className="product-price-sum">{`${CurrencyList.get(fair?.currency || "GBP").symbol}${round(galleryOrderSubmission[row.crm_id]?.value * round(parseFloat(galleryOrderSubmission[row.crm_id]?.price),2), 2)}`}</span> : <></>}
                    </div>
                )
            },
        },
        {
            name: "Unit price (Excluding VAT)",
            selector: (row: any) => (CurrencyList.get(fair?.currency || "GBP").symbol) + round(parseFloat(row.price_ex_vat), 2),
            omit: (!fair?.showPrices && paymentMethod!=="Stripe")
        },
        {
            name: "Unit price (Including VAT)",
            selector: (row: any) => (CurrencyList.get(fair?.currency || "GBP").symbol) + round(parseFloat(row.price), 2),
            omit: (!fair?.showPrices && paymentMethod!=="Stripe")
        }
    ]

    function getFinalPrice() {
        const addedPrices = Object.values(galleryOrderSubmission).filter(x => x.value > 0).map(x => round( round(parseFloat(x.price),2) * x.value, 2));
        const sum = addedPrices.reduce((partialSum, a) => partialSum + a, 0);
        return  sum.toLocaleString(); //TODO: fix currency
    }

    async function downloadPDF(order: IOrder) {
        try {
            const response = await instance.get(`/order/${order._id}?pdf=true`, {
                responseType: 'blob' //Force to receive data in a Blob Format
            })
            fileDownload(response.data, `${order._id}.pdf`);

        } catch (error) {
            toast.error("Failed to download PDF")
        }
    }

    async function downloadZIP() {
        try {
            if (!isAdminAccess(user, selectedGallery)) {
                const url = `/order/stand-form/${id}?gallery=${selectedGallery.gallery_id}&gallery_name=${selectedGallery.gallery_name}`
                const response = await instance.get(url, {
                responseType: "blob", //Force to receive data in a Blob Format
                })
                fileDownload(response.data, `Stand_Plan_and_Order_Form.zip`)
            }
            
        } catch (error) {
            toast.error("Failed to download PDF")
        }
    }

    const orderColumns = [
        {
            name: "Status",
            minWidth:"150px",
            cell: (row: any) => {
                return (
                    <>
                        <StatusBadge
                            currentStatus={row.status}
                            statuses={[
                                {
                                    name: EOrderStatus.APPROVED,
                                    value: EOrderStatus.APPROVED,
                                    variant: "success",
                                },
                                {
                                    name: EOrderStatus.PENDING,
                                    value: EOrderStatus.PENDING,
                                    variant: "warning",
                                },
                                {
                                    name: EOrderStatus.UNDER_REVIEW,
                                    value: EOrderStatus.UNDER_REVIEW,
                                    variant: "info",
                                },
                                {
                                    name: EOrderStatus.CANCELED,
                                    value: EOrderStatus.CANCELED,
                                    variant: "info",
                                }
                            ]}
                        />
                    </>
                )
            },
        },
        {
            name: "Payment Method",
            selector: (row: any) => row.payment?.type,
        },
        {
            name: "Created At",
            selector: (row: any) => moment(row.createdAt).format("YYYY MMM DD HH:mm:ss"),
            minWidth:"200px"
        },
        {
            name: "Action",
            omit: orderCategory === EOrderCategory.STAND_ORDER,
            cell: (row: any) => {
                return (
                    <Button
                        onClick={() => {
                            downloadPDF(row)
                        }}
                        className="download-btn">
                        {" "}
                        <Icon size={16} className="download-icon" name="download" />
                    </Button>
                )
            },
            allowOverflow: true,
        },
    ]
    const orderItemColumns = [
        {
            name: 'Name',
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name:'Quantity',
            selector: (row: any) => row.value,
            sortable: true,
        },
        {
            name: 'Price (Excluding VAT)',
            selector: (row: any) => `${CurrencyList.get(row.currency || "GBP").symbol}${round(parseFloat(row.price_ex_vat || row.price),2)}`,
            omit: !fair?.showPrices
        },
        {
            name: 'VAT Rate',
            selector: (row: any) => `${row.tax_rate_c ? round(parseFloat(row.tax_rate_c)*100,2) : 0}%`,
            omit: !fair?.showPrices
        },
        {
            name: 'Price (Including VAT)',
            selector: (row: any) => `${CurrencyList.get(row.currency || "GBP").symbol}${round(parseFloat(row.price),2)}`,
            omit: !fair?.showPrices
        },
    ];

    const ExpandedRowComponent: React.FC<ExpanderComponentProps<any>> = ({ data }) => {
        return (
            <div className="expanded">
                <DataTable className="expanded-table" columns={orderItemColumns} data={data.order_items.map((item: any) => { return {...item, orderId: data._id}})} />
            </div>
        )
    }



    // function getLastOrder(orders: IOrder[]) {
    //     console.log("orderItems", orders);
        
    //     const sortedOrders = orders.sort(function (orderA, orderB) {
    //         return orderA.createdAt > orderB.createdAt ? 1 : orderB.createdAt < orderA.createdAt ? -1 : 0
    //     })
    //     console.log(sortedOrders[0]);
        
    //     return orders
    // }

    return(
        <div className="order-form-container">
            {isAdminAccess(user, selectedGallery) ?
                <div className="admin-view">
                    <div className="table-container">
                        <DataTable columns={AdminColumns} data={orderItems} progressPending={loading} paginationRowsPerPageOptions={[100]} defaultSortFieldId={1}/>
                    </div>
                </div>
                :
                <div className="gallery-view">
                    <div className="table-container">
                        {(orderCategory === EOrderCategory.STAND_ORDER && hasDownloadButton) && <div className="d-flex justify-content-end align-items-center">
                            <p className="mx-2 mb-0">Download Stand Plan and Order Form</p>
                            <Button
                                onClick={() => {
                                        downloadZIP()
                                }}
                                className="download-btn p-2">
                                {" "}
                                <Icon size={16} className="download-icon" name="download" />
                            </Button>
                        </div>}
                        <div>

                        </div>
                        
                        <div>

                                <div className="status-info">
                                    <p><b>Your Orders:</b></p>
                                    {previousOrders.length ?
                                    <DataTable
                                        columns={orderColumns}
                                        data={previousOrders}
                                        expandableRows
                                        expandableRowsComponent={ExpandedRowComponent}
                                        persistTableHead={true}
                                    />: <p className="mx-2  mb-0">No Additional Orders submitted</p>}
                                </div>
                        </div>
                        <DataTable columns={galleryColumns} data={orderItems} progressPending={loading} paginationRowsPerPageOptions={[100]} defaultSortFieldId={1}/>
                        <div className="order-summary">
                            {(paymentMethod === "Stripe" || fair?.showPrices) && <p className="price">Total: {CurrencyList.get(fair?.currency || "GBP").symbol}{getFinalPrice()}</p>}
                        </div>
                        <div>
                            <Button disabled={isDisabled || !checkHasItemWithUnit() || !isFormOpen} className="btn btn-primary" onClick={()=> { handleSubmitClick() } }>Submit</Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
