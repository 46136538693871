import React, { useEffect, useState } from "react"
import { Form, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import instance from "../../../api/api"
import { IEmailTemplate } from "../../../interfaces/email"
import { IModule, IFair, IModuleEmailTemplate, EEmailTemplateTypes } from "../../../interfaces/fairs"

interface IProps {
    module: IModule
    fairId: string
    crmId: string
    setFair: Function
    setSelectedModule: Function
    emailTemplates: IEmailTemplate[]
}

interface IEmailTemplateOption {
    key:EEmailTemplateTypes
    name:string
    modules: string[]
}

const avalible_templates:IEmailTemplateOption[] = [
    {
        key:EEmailTemplateTypes.SUBMISSION,
        name:"Submission e-mail template",
        modules: ["accreditation", "application", "manual", "listing", "manual_sponsor", "manual_caterer"]
    },
    {
        key:EEmailTemplateTypes.REVIEW,
        name:"Review e-mail template",
        modules: ["accreditation"]
    },
    {
        key:EEmailTemplateTypes.ACCREDITATION,
        name:"Accreditation e-mail template",
        modules:["accreditation"]
    }
]

function FairEmailSettings(props: IProps) {


    const [emailTemplates, setEmailTemplates] = useState<IModuleEmailTemplate[]>([])

    const [name,setName] = useState<string>(props.module.emailSettings?.name || "");
    const [email,setEmail] = useState<string>(props.module.emailSettings?.email || "");
    const [isEnabled,setIsEnabled] = useState<boolean>(props.module.emailSettings?.isEnabled || false);
    const [isAcceptEmailEnabled,setIsAcceptEmailEnabled] = useState<boolean>(props.module.emailSettings?.isAcceptEmailEnabled || false);

    useEffect(() => {
        const templates = props.module.emailTemplates;
        if (props.module.emailTemplates)
            setEmailTemplates(templates);
    }, [])

    async function updateModule(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const updateData = {
               emailTemplates: emailTemplates,
                emailSettings:{
                    name:name,
                    email:email,
                    isEnabled:isEnabled,
                    isAcceptEmailEnabled:isAcceptEmailEnabled,
                }
            }
            if (Object.keys(updateData).length) {
                const response = await instance.patch(`/fair/${props.fairId}/modul/${props.module._id}`, updateData)
                props.setFair(response.data)
                props.setSelectedModule((response.data as IFair).moduls.find((module) => module._id === props.module._id))
                toast.success("The module is updated")
            }
        } catch (error) {
            console.log("Failed to update module", error)
            toast.error("Failed to update module")
        }
    }

    return (
        <div>
            <Form onSubmit={(e:any) => updateModule(e)}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <div>
                        <Form.Label className={isEnabled !== props.module.emailSettings?.isEnabled ? "changed-data" : ""}>Send email on submission</Form.Label>
                        <div className="form-check form-switch mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={isEnabled}
                                onChange={(e) => {
                                    setIsEnabled(e.target.checked)
                                }}
                            />
                            <label className={`form-check-label ${isEnabled !== props.module.emailSettings?.isEnabled ? "changed-data" : ""}`} htmlFor="customSwitch1">
                                {isEnabled ? "Enabled" : "Disabled"}
                            </label>
                        </div>
                        {props.module.modultype.name.includes("accreditation") && <>
                            <Form.Label className={isAcceptEmailEnabled !== props.module.emailSettings?.isAcceptEmailEnabled ? "changed-data" : ""}>Send email to Nominees</Form.Label>
                            <div className="form-check form-switch mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isAcceptEmailEnabled}
                                    onChange={(e) => {
                                        setIsAcceptEmailEnabled(e.target.checked)
                                    }}
                                />
                                <label className={`form-check-label ${isEnabled !== props.module.emailSettings?.isAcceptEmailEnabled ? "changed-data" : ""}`} htmlFor="customSwitch1">
                                    {isAcceptEmailEnabled ? "Enabled" : "Disabled"}
                                </label>
                            </div></>
                        }
                        <Form.Label>Sender Name</Form.Label>
                        <Form.Control required className="mb-3" value={name} onChange={(e:any)=>{setName(e.target.value)}}></Form.Control>
                        <Form.Label>Sender Email Address</Form.Label>
                        <Form.Control required type="email" className="mb-3" value={email} onChange={(e:any)=>{setEmail(e.target.value)}}></Form.Control>

                        {avalible_templates.map((template:IEmailTemplateOption) => {
                            if(template.modules.includes(props.module.modultype.name)) {
                                const savedTemplate = emailTemplates ? emailTemplates.find((t:IModuleEmailTemplate) => t.action === template.key) : null
                                return(
                                    <Form.Group style={{marginTop:"20px", marginBottom:"5px"}}>
                                            <Form.Label>{template.name}</Form.Label>
                                            <Form.Select
                                                value={savedTemplate ? savedTemplate.template?._id : undefined}
                                                onChange={(e:any) => {

                                                    const t = props.emailTemplates.find(x => x._id === e.target.value);

                                                    if(t) {
                                                        const newTemplate = {action:template.key, template:t};
                                                        if(savedTemplate) {
                                                            let temp = emailTemplates.filter(x => x.action !== savedTemplate.action);
                                                            setEmailTemplates([...temp, newTemplate]);
                                                        }
                                                        else {
                                                            setEmailTemplates(prev => [...prev, newTemplate])
                                                        }
                                                    }
                                                }}>
                                            <option>Select a template</option>
    
                                            {props.emailTemplates.map((emailTemplate: any) => (
                                                <option key={emailTemplate._id} value={emailTemplate._id}>
                                                    {emailTemplate.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                )
                            }
                        })}
                    </div>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Update
                </Button>
            </Form>
        </div>
    )
}

export default FairEmailSettings
