import React, { useEffect } from "react"
import { setPageData } from "../../store/pageSlice"
import { useDispatch, useSelector } from "react-redux"
import { IFair } from "../../interfaces/fairs"
import FairCard from "../../components/fair/fair-card.component"
import { Row, Col } from "react-bootstrap"
import { RootState } from "../../store/store"

function ReportFairSelector() {
    //fairs from redux
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            setPageData({
                name: "Reports",
                firstBreadCrumbName: "",
                secondBreadCrumbName: "",
                thirdBreadCrumbName: "",
            })
        )
    }, [dispatch])
    return (
        <div className="fairs-cards">
            <Row>
                {fairs.map((fair: IFair) => {
                    return (
                        <Col key={fair._id}>
                            <FairCard fair={fair} redirect={`/reports/${fair._id}`} />
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default ReportFairSelector
