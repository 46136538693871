import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import instance from "../../api/api"
import { Spinner } from "reactstrap"
interface IProps {
    dashboardNumber: number
    fairId?: string
    moduleId?: string
}

function MetabaseDashboardDisplayer(props: IProps) {
    const [metabaseUrl, setMetabaseUrl] = useState<string>("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getMetabaseUrl()
    }, [props.dashboardNumber, props.fairId, props.moduleId])

    async function getMetabaseUrl() {
        setLoading(true)
        try {
            const params = {
                dashboard_number: props.dashboardNumber,
                ...(props.fairId && { fair: props.fairId }),
                ...(props.moduleId && { module: props.moduleId }),
            }
            const response = await instance.get(`/report`, { params })
            setMetabaseUrl(response.data.metabaseUrl)
        } catch (error) {
            toast.error("Failed to get the Metabase url")
        }
        setLoading(false)
    }

    return (
        <>
            {loading ? (
                <Spinner className="loader" animation="border" size="lg" />
            ) : (
                <>
                    <iframe src={metabaseUrl} title="Metabase" frameBorder="0" width="100%" height="100%" allowTransparency></iframe>
                </>
            )}
        </>
    )
}

export default MetabaseDashboardDisplayer
