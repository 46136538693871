import { IUserDetails } from "./authentication"
import { IEmailTemplate } from "./email";

export interface IDynamicObject {
    [key: string]: any
}

export enum EEmailTemplateTypes {
    SUBMISSION="SUBMISSION",
    REVIEW="REVIEW",
    ACCREDITATION="ACCREDITATION"
}

export interface IModuleEmailTemplate{
    action:EEmailTemplateTypes 
    template:IEmailTemplate
}

export interface IFormHelper{
    usage:IDynamicObject
    status:IDynamicObject
}

export interface IFairItem {
    id: number
    name: string
    image?: string
    description: string
}

export interface ISubPage {
    _id?: string
    title: string
    isEnabled: boolean
    isOpen: boolean
    isMandatory: boolean
    deadline: Date | null
    markdown?: string
    surveys: IPageSurvey[]
    files: IUploadedFile[]
}

export interface IMainPage {
    _id?: string
    title: string
    isEnabled: boolean
    isOpen: boolean
    isMandatory: boolean
    markdown?: string
    surveys: IPageSurvey[]
    files: IUploadedFile[]
    deadline: Date | null
    sub_pages: ISubPage[]
}
export interface IEmailSettings {
    name:string
    email:string
    isEnabled:boolean
    isAcceptEmailEnabled:boolean
}
export interface IModule {
    _id: string
    title: string
    isEnabled: boolean
    isOpen: boolean
    description?: string
    main_pages: IMainPage[]
    deadline_extensions?: IDeadlineExtension[]
    modultype: IModuleType
    fairId?: string
    fairName?: string
    emailSettings:IEmailSettings
    emailTemplates:IModuleEmailTemplate[]
    contract_category_id?:string
    metabase_dashboards: IMetabaseDashboard[]
    permission:EPermissionSettings
    defaultStatusFilter:string
}

export interface IMetabaseDashboard {
    dashboard_name: string
    dashboard_number: number
    _id: string
}
export enum EPermissionSettings{
    REVENUELINEITEM="revenuelineitem",
    PASS_ALLOCATION="passallocation"
}
export interface IExtension {
    extensionDate: Date,
    pageId?:string
}

export interface IDeadlineExtension {
    _id: string
    gallery_id: string
    gallery_name: string
    for_all_pages: IExtension | null
    pages: IExtension[]
    createdAt?: Date
    updatedAt?: Date
}

export interface IModuleType {
    name:string
    order_position:number
}

export interface IProspectList {
    id:string
    name:string
    fairId:string
}

export interface IFair {
    _id: string
    crm_id: string
    name: string
    moduls: IModule[]
    description: string
    descriptionInPDF: string
    image: IUploadedFile
    logo?: IUploadedFile
    standOrderPDFlogo?: IUploadedFile
    subtitleInStandPDF?: string
    bottomTextStandPDF?: string
    descriptionInStandPDF?: string
    deadline?: Date
    user_nomination?: IUserNomination
    prospectLists: IProspectList[]
    showPrices:boolean
    legalEntity: string
    currency: string
    checkoutEmailSettings:ICheckoutEmailSettings
}

export interface ICheckoutEmailSettings {
    email:string,
    name:string,
    enabled:boolean,
    template:IEmailTemplate
}
export interface ISurvey {
    _id: string
    survey_form: any
    successMessage: string
    approvalNeeded:boolean
    multipleSubmissions:boolean
    submitBtnText: string
    createdAt: Date
    updatedAt: Date
    deleted:boolean
}

export interface IPageSurvey extends ISurvey {
    fairId: string
    module_type: IModuleType
    pageId: string
    type: "PageSurvey"
}

export interface ITemplateSurvey extends ISurvey {
    default: boolean
    type: "TemplateSurvey"
}

export interface IGallerySubmission {
    _id: string
    gallery_name:string
    gallery_id: string
    fair: string | IFair
    modul_id: string | string
    modified_by: IUserDetails
    survey: IPageSurvey
    survey_result: any
    survey_definition: any
    submission_status: "pending" | "approved" | "rejected" | "open" | "completed" | "incomplete" | "inprogress" | "under_review"
    createdAt: Date
    updatedAt: Date
    approvalNeeded: boolean
    deleted:boolean
    additional_order?:boolean
}

export interface IModuleType {
    _id: string
    name: string
    order_position: number
    createdAt: Date
    updatedAt: Date
}

export interface IUploadedFile {
    _id: string
    name: string
    url: string
    type: "image" | "pdf" | "unknown"
    uploader: IUserDetails
    category?: string
    extra_info?: IDynamicObject
    fair_id?: string
    module_id?: string
    createdAt: string
}

export interface INominationItem {
    first_name: string
    last_name: string
    email: string
    [key: string]: string
}

export enum submission_types {
    ACCREDITATION="accreditation",
    MANUAL="manual",
    APPLICATION="application",
    LISTING="listing",
    NONE="None"
}
export interface IUserNomination
{
    content: string,
    form: string,
    adminEmail: string,
    emailTemplate: IEmailTemplate,
    url:string
}

export interface ISignage {
    _id?: string
    gallery_name: string
    gallery_id: string
    gallery_cities: string
    artist_name: string
    submitted_by: string
}

export interface IOrder {
    _id:string
    fair:string
    gallery_id:string
    gallery_name:string
    module:string
    order_items:any[]
    status:EOrderStatus
    updatedAt: string
    payment:IPayment
    createdAt:Date
    orderCategory:EOrderCategory
    legal_entity:string
}

export interface IPayment{
    type: EPaymentType,
    status: EPaymentStatus,
    data: string,
    invoiceId?: string,
    currency: string,
}

export enum EPaymentType{
    STRIPE="Stripe",
    INVOICE="Invoice"
}

export enum EOrderStatus {
    PENDING="Pending",
    UNDER_REVIEW="Under Review",
    APPROVED="Approved",
    CANCELED="Canceled"
}

export enum EOrderCategory {
    STAND_ORDER="Stand Order",
    ADDITIONAL_ORDER="Additional Order"
}

export interface IOrderItem {
    _id:string
    crm_id: string
    name: string
    price: string
    price_ex_vat: string
    calculated_tax_c: string
    tax_rate_c: string
    currency: string
    currency_name:string
    value: number
    status: EOrderItemStatus
}

export interface IGlobalSettings {
    invitationEmail: IEmailTemplate
    forgotPasswordEmail: IEmailTemplate
    welcomeMessage: string
    termsAndConditions: string
    privacyPolicy: string
    cookiePolicy: string
}

export enum EOrderItemStatus {
    PENDING="Pending",
    APPROVED="Approved",
    REFUNDED="Refunded",
    CANCELED="Canceled"
}

export enum EPaymentStatus{
    PAID="Paid",
    UNPAID="Unpaid",
}