import React, { useState, useEffect } from "react"
import { Button, ListGroup, Modal } from "react-bootstrap"
import instance from "../../api/api"
import { toast } from "react-toastify"
import DataTable from "react-data-table-component"
import { IFair, IGallerySubmission } from "../../interfaces/fairs"
import AllocatePassModal from "./allocate-pass-modal.component"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { Icon } from "@ailibs/feather-react-ts"
import { capitalize } from "lodash";
import { countries } from "../../_helper/countries";

interface IProps {
    show: boolean
    setShow: Function
    queryData: any
    submissionId?: string
    rowIndex:number
    dataIndex:number
    getSubmissions:Function
    updateSubmissionByAdministrator:Function
    galleries:any
    fair_id:string
    module_id:string
}

export default function CheckNominationModal(props: IProps) {
    const params:any = useParams();

    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedRow, setSelectedRow] = useState<any>();
    const [allocatePassModalShow, setAllocatePassModalShow] = useState<boolean>(false);
    const [allocationLoading, setAllocationLoading] = useState<boolean>(false);
    const [existingSelected, setExistingSelected] = useState<boolean>(false);

    const [otherPassesModalShow, setOtherPassesModalShow] = useState<boolean>(false);

    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    useEffect(() => {
        if (props.show === true) {
            fetchContacts()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    async function fetchContacts() {
        setLoading(true)
        try {
            const params = new URLSearchParams(props.queryData)

            const response = await instance.get(`/crm/get-contacts?${params.toString()}`)
            if (response.data.records){
                //filter duplicates 
                const ids = response.data.records.map((record:any)=>record.id);
                const filtered = response.data.records.filter((record:any, index:number) => !ids.includes(record.id, index + 1))
                setContacts(filtered);
            }
                
        } catch (error) {
            console.log(error)
            toast.error("Failed to fetch contacts by query")
        }
        setLoading(false)
    }

    function closeModal() {
        props.setShow(false)
    }

    async function collectPassData(contactId:string, galleryId:string, email:string, emailSettingsEnabled: boolean|undefined) {
        try {
            const id = params.id;
            const response = await instance.get("/fair/" + id);
            const fair: IFair = response.data;
            let pt_pass_types_id = props.queryData.pt_pass_types_id || false;

            if(!pt_pass_types_id){
                const allocation = await instance.get("/crm/pass-allocation/" + props.queryData.allocation_id);
                pt_pass_types_id = allocation.data ? allocation.data.pt_pass_types_pa_pass_allocations_1pt_pass_types_ida : '';
            }

            const result = {
                name: props.queryData.pass,
                contacts_p_passes_1contacts_ida: contactId,
                pass_status_c: emailSettingsEnabled ? "Invited" : "Allocated",
                pass_email_c: email,
                nomination_reason_c: "External Nomination",
                eve_events_p_passes_1eve_events_ida: fair.crm_id,
                pt_pass_types_p_passes_1pt_pass_types_ida: pt_pass_types_id,
                accounts_p_passes_1accounts_ida: galleryId,
            }
            console.log(result)
            return result;
        } catch (error) {
            console.log(error);
        }
    }


    async function allocatePass(existing:boolean, data:any) {
        setAllocationLoading(true);
        try {
            const fair = fairs.find((fair:IFair)=>fair._id === props.fair_id);
            const module = fair?.moduls.find(m=>m._id === props.module_id);
            const response = await instance.get("/gallery-submission/"+props.submissionId);
            let submission:IGallerySubmission = response.data;


            let contact;
            if (!existing) {
                const contactResponse = await instance.post("/crm/add-new-contact", {
                    salutation: data.salutation,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    contact_type_c: capitalize(data.contact_type),
                    primary_address_country: countries.getAlpha3Code(data.country, "en")
                })
                contact = contactResponse.data;
            } else if (existing && data) {
                const contactResponse = await instance.put(`/crm/update-contact/${selectedRow.id}`, {
                    first_name: data.first_name,
                    last_name: data.last_name
                })
                contact = contactResponse.data;
            }

            const passData = await collectPassData(contact.id, submission.gallery_id, data.email, module?.emailSettings?.isAcceptEmailEnabled);
            const passResponse = await instance.post("/crm/allocate-pass", passData);

            const eventId = fair?.crm_id;
            const P_Passes:string[] = selectedRow ? selectedRow._extra.P_Passes.filter((pass:any)=>pass.eve_events_id === eventId && pass.pass_status_c!=="Booked").map((pass:any)=>pass.id) : [];
            
            for (const passId of P_Passes) {
                await instance.put(`crm/update-pass/${passId}`,{
                    pass_status_c:"Cancelled"
                });
            }

            let resultObj = submission.survey_result;
            let isAllReviewed = true;
            for (let key of Object.keys(resultObj)) {
                if (key !== "usage" && Array.isArray(resultObj[key])) {
                    resultObj[key][props.dataIndex].status = "Approved";
                    resultObj[key][props.dataIndex].contact_id = contact.id
                    resultObj[key][props.dataIndex].email = data.email;
                    resultObj[key][props.dataIndex].first_name = data.first_name;
                    resultObj[key][props.dataIndex].last_name = data.last_name;
                    resultObj[key].forEach((row:any) => {
                        if (row.status !== "Accepted" && row.status !== "Approved" && row.status !== "Rejected" && row.status !== "Already Nominated") {
                            isAllReviewed = false;
                        }
                    })
                }
            }
            let galleryDetails;
            if (isAllReviewed) {
                submission.submission_status = "completed";
                let user = props.galleries.find((u:any)=>u.id === submission.gallery_id);
                let email = user?.email1 && user.email1.length ? user.email1 : "";

                if (user && email) {
                    galleryDetails = {
                        email: email,
                        galleryName: user.name
                    }
                }
            }
            submission.survey_result = resultObj;
            const updatedResponse = await instance.patch("/gallery-submission/" + props.submissionId, submission, {
                params: {
                    invitationAccepted: true,
                    invitedEmail: props.queryData.email,
                    invitedFirstName: props.queryData.first_name,
                    invitedLastName: props.queryData.last_name,
                    allReviewed: isAllReviewed,
                    galleryDetails: galleryDetails,
                }
            });
            props.setShow(false);
            setAllocatePassModalShow(false);
            delete updatedResponse.data.survey_result;
            props.updateSubmissionByAdministrator(updatedResponse.data);
            toast.success("Invitation accepted!");
            setAllocationLoading(false);
        } catch (error) {
            console.log(error);
            setAllocationLoading(false);
        }
    }

    async function rejectInvitation() {
        try {
            const response = await instance.get("/gallery-submission/"+props.submissionId);
            let submission:IGallerySubmission = response.data;
            let resultObj = submission.survey_result;
            let isAllReviewed = true;
            for (let key of Object.keys(resultObj)) {
                if (key !== "usage" && Array.isArray(resultObj[key])) {
                    resultObj[key][props.dataIndex].status = "Already Nominated";
                    resultObj[key].forEach((row:any) => {
                        if (row.status !== "Accepted" && row.status !== "Approved" && row.status !== "Rejected" && row.status !== "Already Nominated") {
                            isAllReviewed = false;
                        }
                    })
                }
            }
            let galleryDetails;
            if (isAllReviewed) {
                submission.submission_status = "completed";
                let user = props.galleries.find((u:any)=>u.id === submission.gallery_id);
                let email = user?.email1 && user.email1.length ? user.email1 : "";

                if (user && email) {
                    galleryDetails = {
                        email: email,
                        galleryName: user.name
                    }
                }
            }
            submission.survey_result = resultObj;
            const updatedResponse = await instance.patch("/gallery-submission/" + props.submissionId, submission, {
                params: {
                    allReviewed: isAllReviewed,
                    galleryDetails: galleryDetails,
                }
            });
            props.setShow(false);
            delete updatedResponse.data.survey_result;
            props.updateSubmissionByAdministrator(updatedResponse.data);
            toast.warning("Invitation rejected!");
        } catch (error) {
            console.log(error);
        }
    }

    const editNominationData = async(updateKeyArray:string[], value:any) => {
        try {
            const response = await instance.get("/gallery-submission/"+props.submissionId);
            let submission:IGallerySubmission = response.data;
            let resultObj = submission.survey_result;
            for (let key of Object.keys(resultObj)) {
                if (key !== "usage" && Array.isArray(resultObj[key])) {
                    for (let updateKey of updateKeyArray) {
                        if (resultObj[key][props.dataIndex][updateKey]) {
                            resultObj[key][props.dataIndex][updateKey] = value[updateKey];
                        }
                    }
                }
            }
            submission.survey_result = resultObj;
            const updatedResponse = await instance.patch("/gallery-submission/" + props.submissionId, submission);
            delete updatedResponse.data.survey_result;
            props.updateSubmissionByAdministrator(updatedResponse.data);
            toast.success("Nomination data updated");
        } catch (error) {
            console.log(error);
            toast.error("Error occured while updating nomination data");
        }
    }

    const getExistingPasses = (passArray:any[]) => {
        const fair = fairs.find(item => item._id === props.fair_id);
        passArray = passArray.filter(item => item.eve_events_id === fair?.crm_id);
        if (passArray.length) {
            return(
                <ListGroup variant="flush">
                    {passArray.map(pass => {
                        if (pass.name && !pass.name.toLowerCase().includes("+1") && pass.pass_status_c !== 'Cancelled') {
                            return <ListGroup.Item>{pass.name || "Unnamed Pass"} ({pass.pass_status_c})</ListGroup.Item>
                        }
                    })}
                </ListGroup>
            );
        }
        return "-"
    }

    const getOtherPasses = () => {
        const fair = fairs.find(item => item._id === props.fair_id);
        const passArray = selectedRow._extra.P_Passes.filter((item:any) => item.eve_events_id !== fair?.crm_id);
        if (passArray.length) {
            return(
                <ListGroup variant="flush">
                    {passArray.map((pass:any) => {
                        const otherFair = fairs.find(item => item.crm_id === pass.eve_events_id);
                        return <ListGroup.Item>
                            <p><b>{otherFair?.name || "Unknown Event"}</b></p>
                            <p>{pass.name || "Unnamed Pass"}</p>
                            </ListGroup.Item>
                    })}
                </ListGroup>
            );
        }
        return "This Contact has no Passes for other Events"
    }

    const isPassStatusBooked = (passArray:any[]) =>  {
        const fair = fairs.find(item => item._id === props.fair_id);
        passArray = passArray.filter(item => item.eve_events_id === fair?.crm_id);
        if (passArray.length) {
            for (let pass of passArray) {
                if ((pass.name && !pass.name.toLowerCase().includes("+1")) && pass.pass_status_c.toLowerCase() === "booked") {
                    return true;
                }
            }
        }
        return false;
    }

    const columns = [
        {
            name: "Name",
            selector: (row: any, index: any) => row.first_name + " " + row.last_name,
            sortable: true,
            wrap:true,
            minWidth: "15%",
        },
        {
            name: "Email",
            cell: (row: any, index: any) => (<div>
                {row.personal_email_address_c && <span><b>P: </b>{row.personal_email_address_c} <br/> </span>}
                {row.business_email_address_c && <span><b>B: </b>{row.business_email_address_c}</span>}
                </div>),
            sortable: true,
            minWidth: "30%",
            wrap: true,
        },
        {
            name: "Contact Type",
            selector: (row: any, index: any) => row.contact_type_c || "-",
            sortable: true,
            wrap: true,
            minWidth: "10%",
        },
        {
            name: "Passes for this Event",
            cell: (row: any, index: any) => getExistingPasses(row._extra.P_Passes),
            sortable: false,
            minWidth: "30%",
            wrap: true,
        },
        {
            name: "Sugar CRM Link",
            cell: (row: any) =>  <a target="_blank" rel="noreferrer noopener" href={`${process.env.REACT_APP_CRM_BASE_URL}/#Contacts/${row.id}`}><Icon className="action-icon" name="external-link" /></a>,
            sortable: false,
            minWidth: "5%",
        },
        {
            name: "Other Passes",
            cell: (row: any, index: any) => <Icon className="action-icon" name="archive" onClick={() => {
                setSelectedRow(row);
                setOtherPassesModalShow(true);
            }}/>,
            sortable: false,
            minWidth: "10%",
            center: true,
        },
        {
            name: "Actions",
            cell: (row:any) => {
                return <Button onClick={() => {
                    setSelectedRow(row);
                    setExistingSelected(true);
                    setAllocatePassModalShow(true);
                }}>Select</Button>
            },
            right: true,
            minWidth: "10%",
        }
    ]

    return (
        <>
        <Modal backdrop="static" dialogClassName="check-nomination-modal" show={props.show}>
            <Modal.Header>
                <Modal.Title>Similar Contacts</Modal.Title><span>{props.queryData.first_name} {props.queryData.last_name} | {props.queryData.email} | {props.queryData.pass}</span>
            </Modal.Header>
            <Modal.Body>
                <DataTable columns={columns} data={contacts} progressPending={loading} />
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-container">
                    <Button variant="danger" onClick={closeModal}>
                        Cancel
                    </Button>

                    <div className="invitation_actions">
                        {!loading && <Button variant="secondary" className="btn btn-secondary" onClick={() => rejectInvitation()}>Reject Invitation</Button>}
                        {!loading &&
                        <Button className="btn btn-primary" onClick={() => {setExistingSelected(false); setSelectedRow(null); setAllocatePassModalShow(true)}}>
                            Add New Contact
                        </Button>}
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
        
        <AllocatePassModal
            show={allocatePassModalShow}
            setShow={setAllocatePassModalShow}
            allocatePass={allocatePass}
            queryData={props.queryData}
            pass={props.queryData.pass}
            existing={existingSelected}
            selectedRow={selectedRow ? selectedRow : false}
            loading={allocationLoading}
            galleries={props.galleries}
            editNominationData={editNominationData}
            getSubmissions={props.getSubmissions}
            submissionId={props.submissionId || ""} fairId={props.fair_id}/>
        
        <Modal centered dialogClassName="other-passes-modal" show={otherPassesModalShow} onHide={() => {setOtherPassesModalShow(false); setSelectedRow(null)}}>
            <Modal.Header closeButton>
                <Modal.Title>Passes for other Events</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedRow && getOtherPasses()}
            </Modal.Body>
        </Modal>
        </>
    )
}
