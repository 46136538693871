import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import instance from '../../api/api';
import { IGallerySubmission, IMainPage, ISubPage, ISurvey } from '../../interfaces/fairs';
import { RootState } from '../../store/store';
import { countryCodes } from '../../_helper/phones';
import { getSelectorValue } from './_helper/result-table-helper';
import { getUniqueSubmissions } from "../../_helper/nominationSubmissionsHelper";

interface IProps {
    page: IMainPage | ISubPage | null
    submissions: IGallerySubmission[] | null
    getSubmissions: Function | null
    fairId: string | null
}

function SubmissionResultTable(props: IProps) {
    const [surveys, setSurveys] = useState<ISurvey[]>([]);

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [surveyId, setSurveyId] = useState<string>("");

    const { user } = useSelector((state) => (state as RootState).userReducer)
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [countries, setCountries] = useState<string[]>([]);

    useEffect(() => {
        getSurveys();
    }, [props.page, props.submissions, props.fairId])

    useEffect(() => {
        getCountries();
    }, [])

    const getSurveys = () => {
        if (props.page && props.page.surveys && props.page.surveys.length) {
            setSurveys(props.page.surveys);
        }
    }

    const setUpColumns = () => {
        const columns = [
            {
                name: <b>Form</b>,
                selector: (row: any) => row.title || "-",
                sortable: true,
            }
        ]
        return columns;
    }

    const getCountries = async() => {
        try {
            const response = await instance.get("/user/countries");
            const countriesData = response.data;
            setCountries(countriesData);
        } catch (error) {
            console.log(error);
        }
    }

    const setUpColumnData = () => {
        const result = [];
        for (let survey of surveys) {
            let surveyForm = survey.survey_form;
            surveyForm.id = survey._id;
            result.push({...surveyForm, defaultExpanded:true});
        }
        return result;
    }

    const setUpResultColumns = (data: any) => {
        let results = props.submissions ? props.submissions.filter(
            (sub: IGallerySubmission) => sub.survey._id && sub.survey._id === data.id
        ) : []
        let uniqueResults = getUniqueSubmissions(results);
        uniqueResults = uniqueResults.sort((a: IGallerySubmission, b: IGallerySubmission) => (new Date(a.createdAt) < new Date(b.createdAt)) ? 1 : ((new Date(b.createdAt) < new Date(a.createdAt)) ? -1 : 0));
        const resultObj = uniqueResults.length ? uniqueResults[0].survey_result : null;
        const formInfo = uniqueResults.length ? uniqueResults[0].survey_definition : null;

        const cols: any[] = [];
        if (resultObj && formInfo) {
            const formElements = formInfo.elements.map((element: any) => {
                if (element.type === "panel") {
                    return element.elements ? element.elements : [];
                } else {
                    return element;
                }
            }).flat();

            formElements.forEach((question: any) => {
                if (resultObj[question.name]) {
                    cols.push({
                        name: question.title ? question.title : question.name,
                        selector: (row: any) => getSelectorValue(question, resultObj[question.name], setEditInteraction, setDeleteInteraction, data.id),
                        minWidth: question.type === "paneldynamic" ? "350px" : "100px",
                        style: question.type === "paneldynamic" ? {width: '100%'} : null,
                        id: question.type === "paneldynamic" ? "paneldynamic" : null,
                    })
                } else {
                    cols.push({
                        name: question.title ? question.title : question.name,
                        selector: (row: any) => "-",
                        minWidth: question.type === "paneldynamic" ? "150px" : "100px",
                        style: question.type === "paneldynamic" ? {width: '100%'} : null,
                        id: question.type === "paneldynamic" ? "paneldynamic" : null,
                    })
                }
            });
        }
        return cols;
    }

    const getResultData = (data: any) => {
        let results = props.submissions ? props.submissions.filter(
            (sub: IGallerySubmission) => sub.survey._id && sub.survey._id === data.id
        ) : []
        let uniqueResults = getUniqueSubmissions(results);
        uniqueResults = uniqueResults.sort((a: IGallerySubmission, b: IGallerySubmission) => (new Date(a.createdAt) < new Date(b.createdAt)) ? 1 : ((new Date(b.createdAt) < new Date(a.createdAt)) ? -1 : 0));
        const surveyResult = uniqueResults.length ? uniqueResults[0].survey_result : null;

        if (surveyResult) {
            return [surveyResult]
        }
        return [];
    }

    const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({ data }) => {
        const columns = setUpResultColumns(data);
        const result = getResultData(data);

        return (
            <DataTable noTableHead={true} columns={columns} data={result} responsive />
        )
    }

    function getPassChoices() {
        let choices = [];
        let results = props.submissions ? props.submissions.filter(
            (sub: IGallerySubmission) => sub.survey._id && sub.survey._id === surveyId
        ) : []
        results = results.sort((a: IGallerySubmission, b: IGallerySubmission) => (new Date(a.createdAt) < new Date(b.createdAt)) ? 1 : ((new Date(b.createdAt) < new Date(a.createdAt)) ? -1 : 0));
        const surveyResult = results.length ? results[0].survey_result : null;
        if (user.gallery_access.length) {
            for (let pass of selectedGallery.pass_allocation) {
                let passLimit = pass.quantity;
                if (surveyResult) {
                    for (let answer of surveyResult[Object.keys(surveyResult)[0]]) {
                        let answerKeys = Object.keys(answer);
                        for (let key of answerKeys) {
                            if (pass.pass_name === answer[key] && answer.status !== "Rejected" && answer.status !== "Already Nominated") {
                                passLimit--;
                            }
                        }
                    }
                }
                const fair = fairs.find(item => item._id === props.fairId)
                if (fair && pass.fair_id === fair.crm_id && passLimit > 0)
                    choices.push(pass.pass_name);
            }
        }
        return choices;
    }

    async function getSubmission(survId?: string) {
        try {
            if (user.gallery_access.length) {
                const response = await instance.get(`/gallery-submission`, {
                    params: {
                        data: {
                            survey:survId || surveyId,
                            gallery_id:selectedGallery.gallery_id
                        }
                    }
                })
                if (response.data.length) {
                    let submissions = response.data;
                    submissions = submissions.sort((a: IGallerySubmission, b: IGallerySubmission) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
                    return submissions[0];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            console.log("Failed to get previous submission", error)
            return null;
        }
    }

    async function editRow() {
        try {
            let submission: IGallerySubmission = await getSubmission();
            let resultObj = submission.survey_result;
            for (let key of Object.keys(resultObj)) {
                if (key !== "usage" && Array.isArray(resultObj[key])) {
                    resultObj[key][selectedRowIndex] = selectedRow;
                }
            }
            submission.survey_result = resultObj;

            if (user.gallery_access.length && props.getSubmissions) {
                await instance.patch(`/gallery-submission/${submission._id}`, submission);
                props.getSubmissions();
            }
            setEditModalOpen(false);
            toast.success("Invitation edited!");
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteRow(rowIndex: number, survId: string) {
        try {
            let submission: IGallerySubmission = await getSubmission(survId);
            let resultObj = submission.survey_result;
            for (let key of Object.keys(resultObj)) {
                if (key !== "usage" && Array.isArray(resultObj[key])) {
                    resultObj[key].splice(rowIndex, 1);
                }
            }
            submission.survey_result = resultObj;
            if (user.gallery_access.length && props.getSubmissions) {
                await instance.patch(`/gallery-submission/${submission._id}`, submission);
                props.getSubmissions();
            }
            setDeleteModalOpen(false);
            toast.warning("Invitation deleted!");
        } catch (error) {
            console.log(error);
        }
    }

    function getBase64(file: any, key: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setSelectedRow({ ...selectedRow, [key]: [{ name: file.name, type: file.type, content: reader.result }] })
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function setEditInteraction(value:any, index:number, survey_id?:string) {
        setSelectedRow(value);
        setSelectedRowIndex(index);
        setSurveyId(survey_id ? survey_id : "");
        setEditModalOpen(true);
    }

    function setDeleteInteraction(value:any, index:number, survey_id?:string) {
        setSelectedRow(value);
        setSelectedRowIndex(index);
        setSurveyId(survey_id ? survey_id : "");
        setDeleteModalOpen(true);
    }

    const renderEditForm = ()=>{
        let results = props.submissions ? props.submissions.filter(
            (sub: IGallerySubmission) => sub.survey._id && sub.survey._id === surveyId
        ) : []
        let uniqueResults = getUniqueSubmissions(results);
        uniqueResults = uniqueResults.sort((a: IGallerySubmission, b: IGallerySubmission) => (new Date(a.createdAt) < new Date(b.createdAt)) ? 1 : ((new Date(b.createdAt) < new Date(a.createdAt)) ? -1 : 0));
        const form = uniqueResults.length ? uniqueResults[0].survey_definition : null;
        const elements = form.elements.map((element:any)=>element.type === "paneldynamic" ? element.templateElements : element).flat();

        return elements.map((element:any)=>{
            const title = element.title || "";
            const type = element.type;
            const key = element.name;
            let checkedArray: any[] = [];
            return (
            <>{key !== "status" && !key.includes("pass") && !key.includes("country") && key !== "allocation_id" && key !== "pt_pass_types_id" && key !== "contact_id" &&
                <Form.Group className="submission-field">
                    {type === "text" && <>
                        <Form.Label>{title !== "" ? title : key}</Form.Label>
                        <Form.Control onChange={(e:any) => setSelectedRow({ ...selectedRow, [key]: e.currentTarget.value })} type="text" value={selectedRow[key] || ""} /></>}
                    {type === "file" && <>
                        <Form.Label>{title !== "" ? title : key}</Form.Label>
                        <Form.Control onChange={(e: any) => getBase64(e.target.files[0], key)} type="file" value={selectedRow[key]?.name} /></>}
                    {type === "checkbox" && <>
                        <Form.Label>{title !== "" ? title : key}</Form.Label>
                        {element.choices.map((choice: any) => {
                            if (selectedRow[key]?.find((item: any) => choice.text ? (item === choice.text || item === choice.value) : item === choice)) {
                                checkedArray.push(choice.text ? choice.text : choice)
                            }
                            return <Form.Check type="checkbox" checked={checkedArray.find((item: any) => choice.text ? (item === choice.text || item === choice.value) : item === choice)} label={choice.text ? choice.text : choice}
                                onChange={(e:any) => {
                                    if (e.target.checked) {
                                        checkedArray.push(choice.text ? choice.text : choice)
                                        setSelectedRow({ ...selectedRow, [key]: checkedArray })
                                    } else {
                                        const index = checkedArray.indexOf(choice.text ? choice.text : choice);
                                        if (index > -1) {
                                            checkedArray.splice(index, 1);
                                        }
                                        setSelectedRow({ ...selectedRow, [key]: checkedArray })
                                    }
                                }} />
                        })}
                    </>}
                    {type === "comment" && <>
                        <Form.Label>{title !== "" ? title : key}</Form.Label>
                        <Form.Control as="textarea" rows={3} onChange={(e) => setSelectedRow({ ...selectedRow, [key]: e.currentTarget.value })} type="text" value={selectedRow[key] || ""} /></>}
                    {type === "radiogroup" && <>
                        <Form.Label>{title !== "" ? title : key}</Form.Label>
                        {element.choices.map((choice: any) => {
                            return <Form.Check type="radio" checked={selectedRow[key] && (choice.text ? (selectedRow[key] === choice.text || selectedRow[key] === choice.value) : selectedRow[key] === choice)} name={key} onChange={(e:any) => { if (e.target.checked) setSelectedRow({ ...selectedRow, [key]: choice.text ? choice.text : choice }) }} label={choice.text ? choice.text : choice} />
                        })}
                    </>}
                    {type === "dropdown" && <>
                        <Form.Label>{title !== "" ? title : key}</Form.Label>
                        <Form.Select value={selectedRow[key] || ""} onChange={(e:any) => setSelectedRow({ ...selectedRow, [key]: e.currentTarget.value })}>
                            {element.choices.map((choice: any) => {
                                return <option value={choice.value ? choice.value : choice}>{choice.text ? choice.text : choice}</option>
                            })}
                        </Form.Select>
                    </>}
                    {type === "telephone" && <>
                        <Form.Label>{title !== "" ? title : key}</Form.Label>
                        <div className="d-flex justify-content-between">
                            <Form.Select value={selectedRow[key]?.split("-")[0]} className="form-select form-select-sm" style={{width: "35%"}} onChange={
                                (e:any) => setSelectedRow({...selectedRow, [key]: e.currentTarget.value + "-" + selectedRow[key]?.split("-")[1]})
                            }>
                                {countryCodes.map((country) => {
                                    return (<option value={country.code}>{country.name} {country.code}</option>)
                                })}
                            </Form.Select>
                            <input className="form-control" value={selectedRow[key]?.split("-")[1]} onChange={(e) => setSelectedRow({...selectedRow, [key]: selectedRow[key]?.split("-")[0] + "-" + e.currentTarget.value})} type="tel" minLength={9} maxLength={10} style={{width: "60%"}} />
                        </div>
                    </>}
                </Form.Group>}

                {key.includes("pass") && type === "dropdown" &&
                    <Form.Group className="submission-field">
                        <Form.Label>{title !== "" ? title : key}</Form.Label>
                        <Form.Select value={selectedRow[key] || ""} onChange={(e:any) => setSelectedRow({ ...selectedRow, [key]: e.currentTarget.value })}>
                            {getPassChoices().map(choice => {
                                return (<option>{choice}</option>)
                            })}
                        </Form.Select>
                    </Form.Group>}

                {key.includes("country") && type === "dropdown" &&
                <Form.Group className="submission-field">
                    <Form.Label>{title !== "" ? title : key}</Form.Label>
                    <Form.Select value={selectedRow[key] || ""} onChange={(e:any) => setSelectedRow({ ...selectedRow, [key]: e.currentTarget.value })}>
                        {countries.map(choice => {
                            return (<option>{choice}</option>)
                        })}
                    </Form.Select>
                </Form.Group>} 
            </>
        )});
    }

    return (
        <>
            <div className="submission-result-table-wrapper">
                <DataTable columns={setUpColumns()} data={setUpColumnData()} noTableHead={true} expandableRowsHideExpander={true} expandableRows expandableRowsComponent={ExpandedComponent} expandableRowExpanded={(row) => row.defaultExpanded} responsive />
                <div className='submit-nominations-container'></div>
            </div>
            <Modal show={editModalOpen} className="edit-invitation-modal" backdrop="static">
                <Modal.Header><h4>Edit Invitation</h4></Modal.Header>
                <Modal.Body>
                     <Form>
                        {selectedRow && renderEditForm()}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='btn-container'>
                        <Button variant="danger" onClick={() => setEditModalOpen(false)}>
                            Cancel
                        </Button>
                        <Button onClick={editRow}>Accept</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteModalOpen} className="delete-invitation-modal">
                <Modal.Header>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this invitation?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-secondary" onClick={() => setDeleteModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button className="btn-warning" onClick={() => { deleteRow(selectedRowIndex, surveyId) }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SubmissionResultTable;
