import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Col, Card, Form, CloseButton } from "react-bootstrap";
import { Icon } from "@ailibs/feather-react-ts/dist/Icon";
import AlertDismissible from "../alert/alert-dismissible.component";
import instance from "../../api/api";
import { IEmailTemplate } from "../../interfaces/email";
import RichTextEditor, { EditorValue } from "react-rte";
import { IUploadedFile } from "../../interfaces/fairs";

interface IProps {
    data: emailEditData
    handleEdit: Function
}

interface emailEditData {
    _id: string
    name: string
    subject:string
    body:string
    attachments:IUploadedFile[]
}

function EmailEditForm(props: IProps) {

    const { _id, name, subject, body, attachments } = props.data;

    const [subjectValue, setSubjectValue] = useState<string>(subject);
    const [bodyValue, setBodyValue] = useState<EditorValue>(RichTextEditor.createValueFromString(body,"html"));

    const [markedForDelete, setMarkedForDelete] = useState<IUploadedFile[]>([]);
    const [oldAttachments, setOldAttachments] = useState<IUploadedFile[]>(attachments);
    const [newAttachments, setNewAttachments] = useState<File[]>([]);

    async function handleSaveTemplate (e: React.FormEvent) {

        if(name === "" || subjectValue === "" || bodyValue.toString("markdown") === ""){
            return;
        } 
        e.preventDefault();

        try {
            //upload attachments
            const formData = new FormData();
            for (let file of newAttachments) {
                formData.append("files", file);
            }
            formData.append("category", "attachment");
            //delete file if marked
            await instance.post("file-upload/delete-multiple",{
                idArray:markedForDelete.map(file=>file._id)
            });

            const attachmentResponse = await instance.post(`file-upload/multiple`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            
            
            const reqData: IEmailTemplate = {
                name: name,
                subject: subjectValue,
                body: bodyValue.toString("html"),
                attachments: [...oldAttachments.map((file:IUploadedFile)=>file._id), ...attachmentResponse.data.map((file:IUploadedFile)=>file._id)],
            };
            await instance.patch(`/email/${_id}`, reqData);
            ReactDOM.render(<AlertDismissible variant={"success"} message={`"${name}" template was updated.`} />, document.getElementById("email-alert"));
        } catch (error) {
            console.log("Failed to update email template", error);
            ReactDOM.render(<AlertDismissible variant={"error"} message={`Failed to update "${name}" template.`} />, document.getElementById("email-alert"));
        }

        props.handleEdit();
        ReactDOM.render(<></>, document.getElementById("email-modaledit"));
    };

    const handleCancelEditing = (e: React.FormEvent) => {
        e.preventDefault();
        ReactDOM.render(<></>, document.getElementById("email-modaledit"));
    };

    const handleSubjectChange = (elem: React.ChangeEvent<HTMLInputElement>) => {
        setSubjectValue(elem.target.value);
    }

    const handleAddAttachments = (elem: React.ChangeEvent<HTMLInputElement>) => 
    {
        if (elem.target.files && elem.target.files.length>0)
        {
            setNewAttachments([...newAttachments,...Object.values(elem.target.files)]);
        } 
    }
    
    const handleDeleteOldAttachment = (i:number) => {
        setMarkedForDelete([...markedForDelete,oldAttachments[i]]);
        setOldAttachments([...oldAttachments.filter((file:IUploadedFile,j:number)=>i!==j)]);
    }

    const handleDeleteNewAttachment = (i:number) => {
        setNewAttachments([...newAttachments.filter((file:File,j:number)=>i!==j)]);
    }

    const renderOldAttachments = ()=>{
        return oldAttachments.map( (attachment:IUploadedFile,i:number) => (
            <div key={i} className="text-secondary mb-1 d-flex align-items-center">
                {attachment.name} 
                <CloseButton onClick={()=>handleDeleteOldAttachment(i)}></CloseButton> 
            </div>
        ));
    }

    const renderNewAttachments = ()=>{
        return newAttachments.map( (attachment:File,i:number) => (
            <div key={i} className="text-success mb-1 d-flex align-items-center">
                {attachment.name} 
                <CloseButton onClick={()=>handleDeleteNewAttachment(i)}></CloseButton> 
            </div>
        ));
    }

    return (
        <React.Fragment>
        <Col xl={12} style={{ zIndex: 1001, width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
            <Card>
            <Card.Header className="d-flex justify-content-between align-items-center bg-light py-1 pe-0">
                <h4 className="h4 mb-0">{`Edit "${name}"`}</h4>
                <button className="btn" onClick={handleCancelEditing}>
                <Icon name="x" size={24} />
                </button>
            </Card.Header>
            <Card.Body>
                <Form>
                <div className="mb-3">
                    <label htmlFor="#name">Email template name</label>
                    <input required={true} type="text" id="name" className="form-control" readOnly value={name} />
                </div>
                <div className="mb-3">
                    <label htmlFor="#subject">Email subject</label>
                    <input required={true} type="text" id="subject" className="form-control" value={subjectValue} onChange={handleSubjectChange} />
                </div>

                <div className="mb-3">
                    <label htmlFor="#body">Email Body</label> <br></br>
                    <RichTextEditor className="rte" value={bodyValue} onChange={(value)=>setBodyValue(value)} />
                </div>

                <div className="mb-3">
                    <label htmlFor="#name">Attachments (optional)</label> <br></br>
                    {renderOldAttachments()}
                    {renderNewAttachments()}
                    <input type="file" multiple id="name" className="form-control mt-3" onChange={handleAddAttachments} />
                </div>
                <div className="button-group">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={(e) => {
                        handleSaveTemplate(e);
                    }}>Save changes</button>
                    <button
                        type="button"
                        className="btn btn-secondary-outline"
                        onClick={(e) => {
                        handleCancelEditing(e);
                    }}>Cancel</button>
                </div>
                </Form>
            </Card.Body>
            </Card>
        </Col>
        </React.Fragment>
    );
};

export default EmailEditForm;
