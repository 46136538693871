import moment from "moment"
import React, { useState, useRef, useEffect } from "react"
import { Form, Spinner } from "react-bootstrap"
import { IModule, IDeadlineExtension, IFair, IMainPage, ISubPage } from "../../../interfaces/fairs"
import { Icon } from "@ailibs/feather-react-ts"
import { toast } from "react-toastify"
import instance from "../../../api/api"
import DataTable, { ExpanderComponentProps } from "react-data-table-component"
import DatePicker from "react-datepicker"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { setFairs } from "../../../store/fairsSlice"

interface IProps {
    module: IModule
    fairId: string
    crmId: string
    setFair: Function
    setSelectedModule: Function
}

interface IAccountData {
    id: string
    name: string
    account_type: string
}

function DeadlineExtensions(props: IProps) {
    const dispatch = useDispatch();

    // accounts from the crm
    const [accounts, setAccounts] = useState<IAccountData[]>([]);
    const [ allAccounts, setAllAccounts ] = useState<number>(0);

    // loader state for the account fetch
    const [accountLoader, setAccountLoader] = useState<boolean>(false)

    // state for the user search input
    const [userSearch, setUserSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);


    const { fairs } = useSelector((state) => (state as RootState).fairsReducer);

    const wrapperRef = useRef<HTMLDivElement>(null)
    const upperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false)
        }
    }, [])

    //timeouts for search
    useEffect(() => {
        console.log('change')
        if(!loading) {
            console.log('not loading')
            const delayDebounceFn = setTimeout(() => {
                console.log('hele')
                getUsers();
            }, 1000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [ userSearch ])

    async function getUsers() {
        try {
            setLoading(true)
            let filter:any = {};

            if(userSearch.length) {
                filter["name"] = { "$contains": userSearch };
            }

            const fair = fairs.find(f=>f._id === props.fairId);

            if(!fair) {
                return
            }

            const response = await instance.get("/crm/all-accounts", {
                params: {
                    offset: 0,
                    max_num:1000,
                    sort:"name",
                    order:"ASC",
                    filter:filter,
                    category_id:props.module.contract_category_id, 
                    event_id:fair.crm_id,
                    permission: props.module.permission,
                }
            });

            console.log('hello', response.data)
            setAccounts(response.data.records)

            setLoading(false);

        } catch (error) {
            console.log(error);

        }
    }

    function handleClickOutside(event: any) {
        if (wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node) && !upperRef?.current?.contains(event.target as Node)) {
            setUserSearch("")
        }
    }

    /**
     * create new deadline extension
     * @param galleryId
     * @param galleryName
     */
    async function createExtension(galleryId: string, galleryName: string) {
        try {
            setUserSearch("")
            const newExtension = {
                gallery_id: galleryId,
                gallery_name: galleryName,
                for_all_pages: {extended: true, extensionDate:new Date()},
                pages: [] as string[],
            }

            const response = await instance.post(`/fair/${props.fairId}/modul/${props.module._id}/deadline`, newExtension)
            props.setFair(response.data)
            props.setSelectedModule((response.data as IFair).moduls.find((module) => module._id === props.module._id))
            toast.success("The deadline extension is created")
        } catch (error) {
            console.log("Failed to create extension")
            toast.error("Failed to create extension")
        }
    }

    /**
     * update deadline extension
     * @param extensionId
     * @param updateData
     */
    async function updateExtension(extensionId: string, updateData: any) {
        try {
            const response = await instance.patch(`/fair/${props.fairId}/modul/${props.module._id}/deadline-extension/${extensionId}`, updateData)
            props.setFair(response.data)
            props.setSelectedModule((response.data as IFair).moduls.find((module) => module._id === props.module._id))

            const updatedFairs = fairs.map((contextFair:IFair) => {
                return contextFair._id === response.data._id ? response.data : contextFair
            })
            dispatch(setFairs(updatedFairs))

            toast.success("The deadline extension is updated")
        } catch (error) {
            console.log("Failed to update extension")
            toast.error("Failed to update extension")
        }
    }

    /**
     * delete deadline extension
     * @param extensionId
     */
    async function deleteExtension(extensionId: string) {
        try {
            const response = await instance.delete(`/fair/${props.fairId}/modul/${props.module._id}/deadline-extension/${extensionId}`)
            props.setFair(response.data)
            props.setSelectedModule((response.data as IFair).moduls.find((module) => module._id === props.module._id))
            toast.success("The deadline extension is deleted")
        } catch (error) {
            console.log("Failed to delete extension")
            toast.error("Failed to delete extension")
        }
    }

    /**
     * columns of the current deadline extensions table
     */
    const columns = [
        {
            name: "Gallery",
            selector: (row: any) => row.gallery_name,
            sortable: true,
        },
        {
            name: "Actions",
            selector: (row: any) => {
                return (
                    <div className="row-actions">
                        <Icon className="action-icon delete-icon" onClick={() => deleteExtension(row._id)} name="trash-2" size="14" />
                    </div>
                )
            },
            sortable: false,
        },
    ]

    /**
     * merge the subpages and main pages with survey into one array
     */
    function createArrayFromPages() {
        const pages: IMainPage | ISubPage[] = []
        props.module.main_pages.forEach((mainpage) => {
            if (mainpage.surveys.length) {
                pages.push(mainpage)
            }
            mainpage.sub_pages.forEach((subpage) => {
                if (subpage.surveys.length) {
                    pages.push(subpage)
                }
            })
        })
        return pages
    }

    /**
     * display results of gallery search
     */
    function showResults() {
        if (!userSearch.length) {
            return <></>
        }
        const filteredByExisting = accounts.filter(
            (account) => !props.module.deadline_extensions?.find((extension) => extension.gallery_id === account.id) && account.name.toLowerCase().includes(userSearch.toLowerCase())
        )
        if (!filteredByExisting.length) {
            return (
                <div className="result-area" ref={wrapperRef}>
                        {loading ? <div style={{height:"100px"}}><Spinner animation="border" className="loader" size="sm" /></div> :  <div className="result-row"> <p>No results</p></div>}

                </div>
            )
        }
        return (
            <div className="result-area" ref={wrapperRef}>
                {filteredByExisting.map((account) => {
                    return (
                        <div
                            className="result-row"
                            onClick={() => {
                                createExtension(account.id, account.name)
                            }}>
                            <p>{account.name}</p>
                        </div>
                    )
                })}
            </div>
        )
    }

    /**
     * the expanded component of the rows of the current deadline extensions table
     * @param param0
     * @returns
     */
    const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({ data }: { data: IDeadlineExtension }) => {
        const getPage = (pageId:string)=>{
            return data.pages.find(p=>p.pageId === pageId);
        }

        return (
            <div className="expanded-extension">
                <div>
                    <h6 className="deadline-label">Edit rights: </h6>
                    <div className="form-check form-switch d-flex mb-3 align-items-center">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={data.for_all_pages!==null}
                            onChange={(e) => {
                                updateExtension(data._id, { for_all_pages: e.target.checked ? {extensionDate:new Date()} : null });
                            }}
                        />
                        <div className="label">For all pages and subpages</div>
                        {data.for_all_pages && <div className="datepicker-wrapper" >
                            <DatePicker forceShowMonthNavigation minDate={new Date()} onKeyDown={(e)=>{e.preventDefault()}} className="form-control form-control-sm datepicker" value={ data.for_all_pages.extensionDate? new Date(data.for_all_pages.extensionDate).toLocaleDateString() : "Invalid Date" } onChange={(e)=>{
                                updateExtension(data._id, { for_all_pages:{ extensionDate: e }}); 
                            }}/>
                        </div>}
                    </div>
                </div>
                {data.for_all_pages!==null ? (
                    <></>
                ) : (
                    createArrayFromPages().map((page) => {
                        return (
                            <div>
                                <div>
                                <div className="form-check form-switch d-flex mb-3 align-items-center">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={page._id ? getPage(page._id) !== undefined : false}
                                            onChange={(e) => {
                                                if (page._id) {
                                                    updateExtension(data._id, { pages: e.target.checked ? [...data.pages, {pageId:page._id, extensionDate:Date.now()}] : data.pages.filter((p) => p.pageId !== page._id) });
                                                }
                                            }}
                                        />
                                         <div className="label">{page.title}</div>
                                        {page._id && getPage(page._id) !== undefined && <div className="datepicker-wrapper" >
                                            <DatePicker onKeyDown={(e)=>{e.preventDefault()}} className="form-control form-control-sm datepicker" value={ new Date(getPage(page._id)?.extensionDate as Date).toLocaleDateString() } onChange={(e)=>{
                                                if (page._id && e !== null) {
                                                    let updatedPages = [...data.pages];
                                                    updatedPages = updatedPages.map(p=>{
                                                        return p.pageId === page._id ? {...p,...{extensionDate:e}} : p;
                                                    });
                                                    updateExtension(data._id, { pages: updatedPages });
                                                }
                                            }}/>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
        )
    }

    return (
        <div className="deadline-extensions">
            {!accountLoader ? (
                <div>
                    <Form.Label className="deadline">Deadline extension for galleries</Form.Label>
                    <Form.Control type="text" value={userSearch} placeholder="Type gallery name" onChange={(evt:any) => setUserSearch(evt.target.value)} />
                    {showResults()}
                </div>
            ) : (
                <div className="loader-container">
                    <Spinner animation="border" className="loader" size="sm" />
                </div>
            )}

            <div className="current-extensions-container">
                <Form.Label className="deadline">Current deadline extensions</Form.Label>
                <DataTable columns={columns} data={props.module.deadline_extensions || ([] as IDeadlineExtension[])} pagination expandableRows expandableRowsComponent={ExpandedComponent} />
            </div>
        </div>
    )
}

export default DeadlineExtensions
