import { ERoles, IUserDetails } from "../../../interfaces/authentication";
import { IFair, IModule } from "../../../interfaces/fairs";

export const PERMISSION_TYPES = {
    "revenueLineItem": {
        value: "revenuelineitem",
        display: "RevenueLine Item"
    },
    "passAllocation": {
        value: "passallocation",
        display: "Pass Allocation"
    }
}

export function checkModuleAccess(fair: IFair, module: IModule, user:IUserDetails, selectedGallery:any) {
    if (!module.permission || isAdminAccess(user, selectedGallery)) {
        return true;
    } else if (module.permission === PERMISSION_TYPES.revenueLineItem.value) {
        return revenueLineItemCheck(fair, module, user, selectedGallery);
    } else if (module.permission === PERMISSION_TYPES.passAllocation.value) {
        return passAllocationCheck(fair, selectedGallery);
    }
}

function revenueLineItemCheck(fair: IFair, module: IModule, user:IUserDetails, selectedGallery:any) {
    if (!module.contract_category_id || isAdminAccess(user, selectedGallery)) {
        return true;
    } else {
        const revenueLineItems = (selectedGallery as any)._extra.RevenueLineItems;
        for (let item of revenueLineItems) {
            if (item.sales_stage === "Closed Won" && item.eve_events_id === fair.crm_id && item.category_id === module.contract_category_id) {
                return true;
            }
        }
    }
    return false;
}

function passAllocationCheck(fair:IFair, selectedGallery:any) {
    const allocations = selectedGallery.pass_allocation.filter((allocation:any) => allocation.fair_id === fair.crm_id)
    return allocations.length > 0;
}

export const isAdminAccess = (user:IUserDetails, selectedGallery:any) => {
    if (user.role === ERoles.SUPERADMIN && !selectedGallery.gallery_id) {
        return true;
    }
    else return false;
}
