import { Icon } from "@ailibs/feather-react-ts/dist/Icon";
import { Form } from "react-bootstrap"
import DatePicker from "react-datepicker";

interface Iprops{
    label:string
    value:Date | null,
    onChange:any,
}

function DateFilter(props:Iprops) {
    return (
        <div className="datatable-filter">
        <div className="column-title">{props.label}</div>
        <div className="datepicker-wrapper">
            <DatePicker 
                onKeyDown={(e)=>{e.preventDefault();}}
                className="form-control datepicker"
                value={props.value ? props.value.toLocaleDateString() : "Select a date"}
                selected={props.value}
                onChange={(e)=>props.onChange(e)}                           
            >
            </DatePicker>
            <Icon size={18} name="x" className="date-reset-icon" onClick={() => {props.onChange(null)}}/>
        </div>
    </div>
    )
}

export default DateFilter
