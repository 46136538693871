import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import instance from "../../api/api"
import { RootState } from "../../store/store"
import { IOrder, EOrderStatus, EPaymentStatus, EOrderCategory } from "../../interfaces/fairs"
import moment from "moment"
import DataTable from "react-data-table-component"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { ERoles } from "../../interfaces/authentication"
import StatusBadge from "../fair/status-badge.component"
import TextFilter from "../DataTableFilters/text-filter.component"
import SelectFilter from "../DataTableFilters/select-filter.component"
import { Button } from "react-bootstrap"
import { Icon } from "@ailibs/feather-react-ts";
import fileDownload from "js-file-download";
import CurrencyList from 'currency-list';
import { isAdminAccess } from "../fair/_helper/module_helper"
import {round} from "lodash";

function OrderHistory() {
    // state for order data
    const [orders, setOrders] = useState<IOrder[]>([])
    const [filteredData, setFilteredData] = useState<IOrder[]>([])

    // loading state of the table
    const [loading, setLoading] = useState<boolean>(false)

    // data from redux
    const { user } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)

    // data from url params
    const { moduleId } = useParams<{ moduleId: string }>()

    //filters
    const [fairFilter, setFairFilter] = useState<string>("");
    const [moduleFilter, setModuleFilter] = useState<string>("");
    const [orderStatusFilter, setOrderStatusFilter] = useState<string>("");
    const [paymentStatusFilter, setPaymentStatusFilter] = useState<string>("");
    const [paymentMethodFilter, setPaymentMethodFilter] = useState<string>("");

    //filters
    const [ filters, setFilters ] = useState<any>({
        orderCategory:"",
        "payment.status":"",
        "payment.type":"",
        createdAt:null
    });

    //pagination
    const [ limit, setLimit ] = useState<number>(20);
    const [ pageNo, setPageNo ] = useState<number>(0);
    const [ total, setTotal ] = useState<number>(0);


    useEffect(() => {
        setLoading(true)
        // if the redux is ready (selectedGallery is ready after refresh): fetch the order history of the gallery
        selectedGallery.gallery_id && getGalleryOrderHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGallery, fairs, moduleId, pageNo, filters])

    /**
     * fetch the order history of the gallery (in the current module)
     */
    async function getGalleryOrderHistory() {
        try {
            let queryParams = {
                ...filters,
                ...{
                    gallery_id:selectedGallery.gallery_id,
                    module:moduleId
                }
            };

            if(filters.createdAt) {
                const start = new Date(queryParams.createdAt)
                const end = new Date(queryParams.createdAt)
    
                start.setHours(0,0,0,0);
                end.setHours(24,0,0,0);
    
                queryParams.createdAt = {
                    $gte: start, 
                    $lt: end
                }
            }   
            else {
                delete queryParams["createdAt"]
            }
            
            Object.keys(queryParams).forEach(key => {
                if (!queryParams[key].length && key !== "createdAt") {
                    delete queryParams[key];
                }
            });

            const response = await instance.get("/order",{params:{
                data:JSON.stringify(queryParams),
                limit:limit, 
                pageNo:pageNo
            }});

            if (response.data) {
                setOrders(response.data.result);
                setTotal(response.data.totalRecords);
            }
        } catch (error) {
            console.log("No order found")
            toast("Failed to load order history")
        }
        setLoading(false)
    }

    function getFilteredData() {
        let tempFilteredData: IOrder[] = [];
        for (let order of orders) {
            const fair = fairs.find((fair) => fair._id === order.fair)
            const module = fair?.moduls.find((module) => module._id === order.module)

            if (fair?.name.toLowerCase().includes(fairFilter.toLowerCase()) 
            || module?.title.toLowerCase().includes(moduleFilter.toLowerCase()) ||
            order.status.toLowerCase().includes(orderStatusFilter.toLowerCase()) ||
            order.payment.status.toLowerCase().includes(paymentStatusFilter.toLowerCase()) ||
            order.payment.type.toLowerCase().includes(paymentMethodFilter.toLowerCase())) {
                tempFilteredData.push(order);
            }
        }
        setFilteredData(tempFilteredData);
    }

    async function downloadPDF(order: IOrder) {
        try {
            const response = await instance.get(`/order/${order._id}?pdf=true`, {
                responseType: 'blob' //Force to receive data in a Blob Format
            })
            fileDownload(response.data, `${order._id}.pdf`);
            
        } catch (error) {
            toast.error("Failed to download PDF")
        }
    }

    // columns of the orders table
    const orderColumns = [
        {
             name: "Fair",/*(
                 <TextFilter label="Fair" value={fairFilter} onChange={(e:any) => {setFairFilter(e); getFilteredData()}}/> 
             ),*/
            selector: (row: IOrder) => {
                const fair = fairs.find((fair) => fair._id === row.fair)
                return fair?.name || ""
            },
        },
        {
            name: "Module", /*(
                <TextFilter label="Module" value={moduleFilter} onChange={(e:any) => {setModuleFilter(e); getFilteredData()}}/> 
            ),*/
            selector: (row: IOrder) => {
                const fair = fairs.find((fair) => fair._id === row.fair)
                const module = fair?.moduls.find((module) => module._id === row.module)
                return module?.title || ""
            },
        },
        {
            name: (<SelectFilter label="Status" value={filters.status} onChange={(data:string) => { setFilters((prev:any) => { return {...prev, status:data} })}} options={
                [
                    { value: '', display: 'Any'},
                    ...Object.values(EOrderStatus).map( val => ({ value: val, display: val }) )
                ]
            } />),
            cell: (row: any) =>{ return (<StatusBadge
                currentStatus={row.status}
                statuses={[
                    {
                        name: EOrderStatus.APPROVED,
                        value: EOrderStatus.APPROVED,
                        variant: "success",
                    },
                    {
                        name: EOrderStatus.PENDING,
                        value: EOrderStatus.PENDING,
                        variant: "warning",
                    },
                    {
                        name: EOrderStatus.UNDER_REVIEW,
                        value: EOrderStatus.UNDER_REVIEW,
                        variant: "info",
                    },
                ]}
            />)}
        },
        {
            name: (<SelectFilter label="Payment Status" value={filters["payment.status"]} onChange={(data:string) => {setFilters((prev:any) => { return {...prev, "payment.status":data} })}} options={
                [
                    { value: '', display: 'Any'},
                    ...Object.values(EPaymentStatus).map( val => ({ value: val, display: val }) )
                ]
            } />),
            cell: (row: any) =>{ return (<StatusBadge
                currentStatus={row.payment ? row.payment.status : EPaymentStatus.UNPAID}
                statuses={[
                    {
                        name: EPaymentStatus.PAID,
                        value: EPaymentStatus.PAID,
                        variant: "success",
                    },
                    {
                        name: EPaymentStatus.UNPAID,
                        value: EPaymentStatus.UNPAID,
                        variant: "warning",
                    },
                ]}
            />)}
        },
        {
            name: (<SelectFilter label="Payment Method" value={filters["payment.type"]} onChange={(data:string) => {setFilters((prev:any) => { return {...prev, "payment.type":data} })}} options={[
                {value:"", display:"Any"},
                {value:"Invoice", display:"Invoice"},
                {value:"Stripe", display:"Stripe"},
            ]} />),
            selector: (row: any) => row.payment?.type
        },
        {
            name: "Created At",
            selector: (row: any) => moment(row.createdAt).fromNow(),
        },
        {
            name: "Action",
            cell: (row: any) => {
                return (
                    <Button
                        onClick={() => {
                            downloadPDF(row)
                        }}
                        className="download-btn">
                        {" "}
                        <Icon size={16} className="download-icon" name="download" />
                    </Button>
                )
            },
            allowOverflow: true,
        },
    ]

    // columns for the expanded rows
    const orderItemColumns = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: "Price (Excluding VAT)",
            selector: (row: any) => `${CurrencyList.get(row.currency || "GBP").symbol}${round(parseFloat(row.price_ex_vat || row.price),2)}`,
        },
        {
            name: 'VAT Rate',
            selector: (row: any) => `${row.tax_rate_c ? round(parseFloat(row.tax_rate_c)*100,2) : 0}%`,
        },
        {
            name: "Price (Including VAT)",
            selector: (row: any) => `${CurrencyList.get(row.currency || "GBP").symbol}${round(parseFloat(row.price),2)}`,
        },
        {
            name: "Quantity",
            selector: (row: any) => row.value,
            sortable: true,
        },
    ]

    function ExpandedRowComponent(props: any) {
        return (
            <div className="expanded">
                <DataTable className="expanded-table" columns={orderItemColumns} data={props.data.order_items} />
            </div>
        )
    }

    return (
        <div className="order-history-gallery">
            {isAdminAccess(user, selectedGallery) ? (
                <div>
                    <p>Order History (unique component)</p>
                    <p>This component displays the submitted orders from the module for the galleries.</p>
                </div>
            ) : (
                <div className="order-results">
                    <DataTable 
                    progressPending={loading}
                    columns={orderColumns}
                    data={orders}
                    expandableRows
                    persistTableHead 
                    expandableRowsComponent={ExpandedRowComponent} 
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    onChangeRowsPerPage={(newPerPage, page) => { setPageNo(0); setLimit(newPerPage)}}
                    onChangePage={(page) => setPageNo(page-1)}
                    paginationRowsPerPageOptions={[ 10, 20, 30, 40, total ]}
                    paginationPerPage={limit}
                    />
                </div>
            )}
        </div>
    )
}

export default OrderHistory
