export const categories = [
    {
        display: "Stand Plan",
        value: "stand-plan"
    },
    {
        display: "Stand Plan Feedback",
        value: "stand-plan-feedback"
    }
]

export const getGalleryStandNumber = (selectedGallery: any, fairs: any, fairId: any, contractCategoryId: any) => {
    const fair = fairs.find((item:any) => item._id === fairId);
    if (fair && selectedGallery && selectedGallery._extra && selectedGallery._extra.RevenueLineItems) {
        const revenueLineItems = (selectedGallery as any)._extra.RevenueLineItems;
        for (let item of revenueLineItems) {
            if (item.sales_stage === "Closed Won" && item.eve_events_id === fair.crm_id && item.category_id === contractCategoryId) {
                return item.stand_number_c;
            }
        }
    }
    return "";
}