import React, { useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import instance from "../../api/api"
import { toast } from "react-toastify"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { Editor, RawDraftContentState } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { Icon } from "@ailibs/feather-react-ts"
import { IDynamicObject } from "../../interfaces/fairs"
import { IGlobalSettings } from "../../interfaces/fairs"
import CodeEditor from "@uiw/react-textarea-code-editor"

const options = ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link", "embedded", "emoji", "remove", "history"]

interface IProps {
    settings: IGlobalSettings
    setSettings: Function
}

function TermsAndConditionsSettings({ settings, setSettings }: IProps) {
    const [termsAndConditionsMarkdown, setTermsAndConditionsMarkdown] = useState<string>("")
    const [privacyPolicyMarkdown, setPrivacyPolicyMarkdown] = useState<string>("")
    const [cookiePolicyMarkdown, setCookiePolicyMarkdown] = useState<string>("")

    //wyswyg editor
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
    const [privacyEditorState, setPrivacyEditorState] = useState<EditorState>(EditorState.createEmpty())
    const [cookieState, setCookieEditorState] = useState<EditorState>(EditorState.createEmpty())
    const editorRef = useRef(null)
    const privacyeditorRef = useRef(null)
    const cookieEditorRef = useRef(null)
    const [rtePreview, setRtePreview] = useState<boolean>(false)
    const [privacyRtePreview, setPrivacyRtePreview] = useState<boolean>(false)
    const [cookieRtePreview, setCookieRtePreview] = useState<boolean>(false)

    const [termsAndConditionsHtmlEditor, setTermsAndConditionsHtmlEditor] = useState<boolean>(false)
    const [privacyPolicyHtmlEditor, setPrivacyPolicyHtmlEditor] = useState<boolean>(false)
    const [cookiePolicyHtmlEditor, setCookiePolicyHtmlEditor] = useState<boolean>(false)

    useEffect(() => {
        setTermsAndConditionsMarkdown(settings?.termsAndConditions || "")
        setPrivacyPolicyMarkdown(settings?.privacyPolicy || "")
        setCookiePolicyMarkdown(settings?.cookiePolicy || "")

        if (settings?.termsAndConditions) {
            try {
                const contentBlock = htmlToDraft(settings?.termsAndConditions.replaceAll(/(<\/?)figure((?:\s+.*?)?>)/g, ""))
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEditorState(editorState)
            } catch (error) {
                console.log(error)
            }
        } else {
            setEditorState(EditorState.createEmpty())
        }

        if (settings?.privacyPolicy) {
            try {
                const contentBlock = htmlToDraft(settings?.privacyPolicy.replaceAll(/(<\/?)figure((?:\s+.*?)?>)/g, ""))
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setPrivacyEditorState(editorState)
            } catch (error) {
                console.log(error)
            }
        } else {
            setPrivacyEditorState(EditorState.createEmpty())
        }

        if (settings?.cookiePolicy) {
            try {
                const contentBlock = htmlToDraft(settings?.cookiePolicy.replaceAll(/(<\/?)figure((?:\s+.*?)?>)/g, ""))
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setCookieEditorState(editorState)
            } catch (error) {
                console.log(error)
            }
        } else {
            setCookieEditorState(EditorState.createEmpty())
        }
    }, [settings])

    /*
    Input field change handling
    */
    const handleMarkdownChange = (state: EditorState, markdownField: string) => {
        let isEmpty: boolean = !state.getCurrentContent().hasText()
        switch (markdownField) {
            case "termsAndConditions":
                setEditorState(state)
                if (isEmpty) setTermsAndConditionsMarkdown("")
                else setTermsAndConditionsMarkdown(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                break
            case "privacyPolicy":
                setPrivacyEditorState(state)
                if (isEmpty) setPrivacyPolicyMarkdown("")
                else setPrivacyPolicyMarkdown(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                break
            case "cookiePolicy":
                setCookieEditorState(state)
                if (isEmpty) setCookiePolicyMarkdown("")
                else setCookiePolicyMarkdown(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                break

            default:
                break
        }
    }

    const handleContentStateChange = (state: RawDraftContentState, markdownField: string) => {
        switch (markdownField) {
            case "termsAndConditions":
                setTermsAndConditionsMarkdown(draftToHtml(state))
                break
            case "privacyPolicy":
                setPrivacyPolicyMarkdown(draftToHtml(state))
                break
            case "cookiePolicy":
                setCookiePolicyMarkdown(draftToHtml(state))
                break

            default:
                break
        }
    }

    const handleHTMLEditorChange = (html: string, markdownField: string) => {

        const contentBlock = htmlToDraft(html.replaceAll(/(<\/?)figure((?:\s+.*?)?>)/g, ""))
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)

        switch (markdownField) {
            case "termsAndConditions":
                setTermsAndConditionsMarkdown(html)
                setEditorState(editorState)
                break
            case "privacyPolicy":
                setPrivacyPolicyMarkdown(html)
                setPrivacyEditorState(editorState)
                break
            case "cookiePolicy":
                setCookiePolicyMarkdown(html)
                setCookieEditorState(editorState)
                break

            default:
                break
        }
    }

    const validateHTML = (htmlString: string) => {
        let htmlToParse = htmlString.replaceAll("<br>", "<br/>").replaceAll("&nbsp;", "")
        let parser = new DOMParser()
        let doc = parser.parseFromString("<div>" + htmlToParse + "</div>", "application/xml")
        let errorNode = doc.querySelector("parsererror")
        if (errorNode) {
            return false
        } else {
            return true
        }
    }

    async function submitTermsAndConditions(markdownField: string) {
        try {
            let data: IDynamicObject = {}
            switch (markdownField) {
                case "termsAndConditions":
                    data = {
                        ...(termsAndConditionsMarkdown && settings?.termsAndConditions !== termsAndConditionsMarkdown && { termsAndConditions: termsAndConditionsMarkdown }),
                    }
                    break
                case "privacyPolicy":
                    data = {
                        ...(privacyPolicyMarkdown && settings?.privacyPolicy !== privacyPolicyMarkdown && { privacyPolicy: privacyPolicyMarkdown }),
                    }
                    break
                case "cookiePolicy":
                    data = {
                        ...(cookiePolicyMarkdown && settings?.cookiePolicy !== cookiePolicyMarkdown && { cookiePolicy: cookiePolicyMarkdown }),
                    }
                    break

                default:
                    break
            }

            Object.entries(data).forEach(([key, value]) => {
                if(!validateHTML(data[key])) {
                    toast.error("The HTML is invalid")
                    return;
                }
            })

            await instance.put("settings", data);

            setSettings((prev:any) => { return {...prev, ...data}})

            toast.success("Settings are updated")
        } catch (error) {
            toast.error("Failed to update settings")
        }
    }

    return (
        <div className="terms-and-conditions-settions-container">
            <div className="wyswyg_editor">
                <div className="rte-wrapper">
                    <div className="rte-header">
                        <h5>Terms and Conditions</h5>
                        <div className="editor-btn-container">
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setRtePreview(false)
                                    setTermsAndConditionsHtmlEditor(!termsAndConditionsHtmlEditor)
                                }}>
                                <Icon name="code" />
                                <span>{!termsAndConditionsHtmlEditor ? "Edit HTML" : "Stop Editing HTML"}</span>
                            </Button>
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setRtePreview(!rtePreview)
                                }}>
                                <Icon name={!rtePreview ? "eye" : "eye-off"} />
                                <span>Preview</span>
                            </Button>
                        </div>
                    </div>
                    {!rtePreview && !termsAndConditionsHtmlEditor && (
                        <div className="wyswyg_editor">
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={(state) => handleMarkdownChange(state, "termsAndConditions")}
                                onChange={(state) => handleContentStateChange(state, "termsAndConditions")}
                                ref={editorRef}
                                toolbar={{
                                    options: options,
                                }}
                                handlePastedText={() => false}
                            />
                        </div>
                    )}
                    {!termsAndConditionsHtmlEditor && rtePreview && <div className="preview-rte" dangerouslySetInnerHTML={{ __html: termsAndConditionsMarkdown }}></div>}
                    {termsAndConditionsHtmlEditor && !rtePreview && (
                        <CodeEditor
                            value={termsAndConditionsMarkdown}
                            placeholder=""
                            onChange={(e) => handleHTMLEditorChange(e.target.value, "termsAndConditions")}
                            padding={15}
                            style={{
                                fontSize: 14,
                                backgroundColor: "#f2f2f2",
                                color: "#000",
                                minHeight: 250,
                            }}
                        />
                    )}
                </div>

                <div className="submit-button-container">
                    <Button className="invitations-save-button" type="submit" onClick={() => submitTermsAndConditions("termsAndConditions")}>
                        Save
                    </Button>
                </div>
            </div>

            <div className="wyswyg_editor">
                <div className="rte-wrapper">
                    <div className="rte-header">
                        <h5>Privacy Policy</h5>
                        <div className="editor-btn-container">
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setPrivacyRtePreview(false)
                                    setPrivacyPolicyHtmlEditor(!privacyPolicyHtmlEditor)
                                }}>
                                <Icon name="code" />
                                <span>{!privacyPolicyHtmlEditor ? "Edit HTML" : "Stop Editing HTML"}</span>
                            </Button>
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setPrivacyRtePreview(!privacyRtePreview)
                                }}>
                                <Icon name={!privacyRtePreview ? "eye" : "eye-off"} />
                                <span>Preview</span>
                            </Button>
                        </div>
                    </div>
                    {!privacyRtePreview && !privacyPolicyHtmlEditor && (
                        <div className="wyswyg_editor">
                            <Editor
                                editorState={privacyEditorState}
                                onEditorStateChange={(state) => handleMarkdownChange(state, "privacyPolicy")}
                                onChange={(state) => handleContentStateChange(state, "privacyPolicy")}
                                ref={privacyeditorRef}
                                toolbar={{
                                    options: options,
                                }}
                                handlePastedText={() => false}
                            />
                        </div>
                    )}
                    {!privacyPolicyHtmlEditor && privacyRtePreview && <div className="preview-rte" dangerouslySetInnerHTML={{ __html: privacyPolicyMarkdown }}></div>}
                    {privacyPolicyHtmlEditor && !privacyRtePreview && (
                        <CodeEditor
                            value={privacyPolicyMarkdown}
                            placeholder=""
                            onChange={(e) => handleHTMLEditorChange(e.target.value, "privacyPolicy")}
                            padding={15}
                            style={{
                                fontSize: 14,
                                backgroundColor: "#f2f2f2",
                                color: "#000",
                                minHeight: 250,
                            }}
                        />
                    )}
                </div>

                <div className="submit-button-container">
                    <Button className="invitations-save-button" type="submit" onClick={() => submitTermsAndConditions("privacyPolicy")}>
                        Save
                    </Button>
                </div>
            </div>

            <div className="wyswyg_editor">
                <div className="rte-wrapper">
                    <div className="rte-header">
                        <h5>Cookie Policy</h5>
                        <div className="editor-btn-container">
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setCookieRtePreview(false)
                                    setCookiePolicyHtmlEditor(!cookiePolicyHtmlEditor)
                                }}>
                                <Icon name="code" />
                                <span>{!cookiePolicyHtmlEditor ? "Edit HTML" : "Stop Editing HTML"}</span>
                            </Button>
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setCookieRtePreview(!cookieRtePreview)
                                }}>
                                <Icon name={!cookieRtePreview ? "eye" : "eye-off"} />
                                <span>Preview</span>
                            </Button>
                        </div>
                    </div>
                    {!cookieRtePreview && !cookiePolicyHtmlEditor && (
                        <div className="wyswyg_editor">
                            <Editor
                                editorState={cookieState}
                                onEditorStateChange={(state) => handleMarkdownChange(state, "cookiePolicy")}
                                onChange={(state) => handleContentStateChange(state, "cookiePolicy")}
                                ref={cookieEditorRef}
                                toolbar={{
                                    options: options,
                                }}
                                handlePastedText={() => false}
                            />
                        </div>
                    )}
                    {!cookiePolicyHtmlEditor && cookieRtePreview && <div className="preview-rte" dangerouslySetInnerHTML={{ __html: cookiePolicyMarkdown }}></div>}
                    {cookiePolicyHtmlEditor && !privacyRtePreview && (
                        <CodeEditor
                            value={cookiePolicyMarkdown}
                            placeholder=""
                            onChange={(e) => handleHTMLEditorChange(e.target.value, "cookiePolicy")}
                            padding={15}
                            style={{
                                fontSize: 14,
                                backgroundColor: "#f2f2f2",
                                color: "#000",
                                minHeight: 250,
                            }}
                        />
                    )}
                </div>

                <div className="submit-button-container">
                    <Button className="invitations-save-button" type="submit" onClick={() => submitTermsAndConditions("cookiePolicy")}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsSettings
