import React, { useEffect, useState } from "react";
import { setPageData } from "../../store/pageSlice";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import instance from "../../api/api";
import { IFair, IGallerySubmission } from "../../interfaces/fairs";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { getIconName } from "../../_helper/_custom";
import { Icon } from "@ailibs/feather-react-ts";
import { getUniqueSubmissions } from "../../_helper/nominationSubmissionsHelper";

interface IProps {
    
}

const FairSubmissionDetails: React.FunctionComponent<any> = (props:IProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [ fair, setFair ] = useState<IFair>();
    const [ subCounts, setSubCounts] = useState<{moduleId: string, count: number}[]>([])

    const dispatch = useDispatch();
    const params: any = useParams();
    const history = useHistory();

    useEffect(() => {
        getFairData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])

    useEffect(() => {
        if(fair) {
            dispatch(setPageData({
                name: "Fair Submissions",
                firstBreadCrumbName: fair?.name || "",
                secondBreadCrumbName: "" ,
                thirdBreadCrumbName: "",
            }))
            getSubmissionCounts();
        }
    }, [ dispatch, fair, params.id ])

    async function getFairData() {
        setLoading(true);
        try {
            const id = params.id;
            const response = await instance.get("/fair/" + id);
            setFair(response.data)
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function getSubmissionCounts() {
        setLoading(true);
        try {
            let tempCounts = [];
            const response = await instance.get("/gallery-submission", {
                params: {
                    data: {
                        fair:fair?._id,
                    },
                    select:"-survey_result"
                }
            });
            const submissions: IGallerySubmission[] = response.data;
            const uniqueSubmissions = getUniqueSubmissions(submissions);
            if (fair?.moduls) {
                for (let module of fair?.moduls) {
                    const moduleSubmissions = uniqueSubmissions.filter(sub => sub.modul_id === module._id);
                    const countObj = {
                        moduleId: module._id,
                        count: moduleSubmissions.length
                    }
                    tempCounts.push(countObj);
                }
            }
            setSubCounts(tempCounts);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <div className="fair-submission-details-container">
            {!loading ? <Row>
                {fair?.moduls.map((module) => {
                    return(
                        <Col lg={6} key={module._id}>
                            <Card onClick={() => history.push("/submissions/" + fair._id + "/" + module._id)}>
                                <Card.Body>
                                    <div className="module-header">
                                        <div className="module-icon">
                                            <Icon name={getIconName(module.modultype.name)} size="30px" />
                                        </div>
                                        <div className="module-title">
                                            <h5>{module.title}</h5>
                                        </div>
                                    </div>
                                    <div className="details">
                                        <p>{`There are currently ${subCounts.find(item => item.moduleId === module._id)?.count} submissions for this module.`}</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })}
            </Row> : <div style={{textAlign: "center"}}><Spinner animation="border" /></div>}
        </div>
    );
};

export default FairSubmissionDetails;