import { ISurvey } from "../../../interfaces/fairs";

export function getSurveyTitle(survey:ISurvey) {
    return survey.survey_form.title ? survey.survey_form.title : "Form has no title";
}

export function dataURLtoFile(dataurl:any, filename:string) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}