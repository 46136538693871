import React, { useEffect, useState } from "react";
import { Card, Spinner, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ERoles } from "../../interfaces/authentication";
import { setPageData } from "../../store/pageSlice";
import { Link } from "react-router-dom";
import ActivityLogs from "../../components/activity-logs.component";
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import instance from "../../api/api";
import { isAdminAccess } from "../../components/fair/_helper/module_helper";

const Dashboard: React.FunctionComponent<any> = (props) =>
{
    const dispatch = useDispatch();
    const { user, selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const [welcomeMessage, setWelcomeMessage] = useState("")

    useEffect(() =>
    {
        // set page title and breadcrumbs of the header
        dispatch(setPageData({
            name: "Summary",
            firstBreadCrumbName: "",
            secondBreadCrumbName: "",
            thirdBreadCrumbName: "",
        }))
    }, [dispatch])

    useEffect(() => {
        getContent()
    }, [])

    async function getContent() {
        try {
            const response = await instance.get("/settings")
            setWelcomeMessage(response.data.welcomeMessage || "")
        } catch (error) {
            console.log("Failed to get content")
        }
    }

    return (
        <div className="dashboard_page">
            {user._id && user._id !== "" ? <div className="content_wrapper">
                {!isAdminAccess(user, selectedGallery) &&
                <Row>
                    <Card className="">
                        <Card.Body>
                            {parse(welcomeMessage)}
                        </Card.Body>
                    </Card>
                </Row>}

                <div className="dashboard">
                    {isAdminAccess(user, selectedGallery) && 
                        <Row className="">
                            <Col sm={12} md={6}>
                                <Card className="">
                                    <Card.Body>
                                        <div className="header">
                                            <h5 className="card-title mb-4">Manage Email Templates</h5>
                                        </div>
                                        <div className="content">
                                            <Link className="btn btn-secondary" to={"/emails"}>Manage</Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} md={6}>
                                <Card className="">
                                    <Card.Body>
                                        <div className="header">
                                            <h5 className="card-title mb-4">Manage All Fairs</h5>
                                        </div>
                                        <div className="content">
                                            <Link className="btn btn-secondary" to={"/fairs"}>Manage</Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>}

                    {isAdminAccess(user, selectedGallery) && 
                        <Row>
                            <Col>
                                <Card className="">
                                    <Card.Body>
                                        <div className="header">
                                            <h5 className="card-title mb-4">Recent Activities</h5>
                                        </div>
                                        <div className="content">
                                            <ActivityLogs/>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>}
                </div>
            </div> : <Spinner animation="border" className="loader" />}
        </div>
    );
};

export default Dashboard;
