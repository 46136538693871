import React, { useEffect, useState } from "react";
import * as Survey from "survey-core";
import * as SurveyCreator from "survey-creator-react";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import { IPageSurvey, ITemplateSurvey } from "../../interfaces/fairs";
import Cookies from "universal-cookie";
import instance from "../../api/api";
import { telephoneWidget } from "../../_helper/_telephoneWidget";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { FormHelper } from "../../_helper/formHelper";

SurveyCreator.StylesManager.applyTheme("bootstrap")

interface IProps {
    show: boolean
    handleClose: () => void
    addNewSurvey: Function
    editSurvey: Function
    survey?: IPageSurvey | ITemplateSurvey | null
    surveyForm?: string
    surveyIndex: number
    templateCreator: boolean
}

function FormCreatorModal(props: IProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [creator, setCreator] = useState<SurveyCreator.SurveyCreator | null>(null);

    const [templates, setTemplates] = useState<ITemplateSurvey[]>([]);
    const [selectedTemplate, selectTemplate] = useState<string | null>(null);
    const [showTemplateSelector, setShowTemplateSelector] = useState<boolean>(false);


    useEffect(() => {
        if (props.show) {
            setUpCreator();
            getTemplates();
            document.body.style.overflow = "hidden";
            window.scrollTo(0,0);
        } else {
            document.body.style.overflow = "auto";
        }
    }, [props.show, props.survey]);

    useEffect(() => {
        let added = Survey.CustomWidgetCollection.Instance.getCustomWidgetByName("telephone")
        if (!added)
            Survey.CustomWidgetCollection.Instance.addCustomWidget(telephoneWidget, "customtype");
    },[])

    const getTemplates = async () => {
        setLoading(true);
        try {
            const response = await instance.get("/template-survey/");
            setTemplates(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const addStatusField = (surveyData:any) => {
        for (let element of surveyData.elements) {
            if (element.type === "paneldynamic") {
                let contains = element.templateElements.find((field:any) => field.name === "status");
                if (!contains) {
                    element.templateElements.push({
                        name: "status",
                        title: "Status",
                        type: "text",
                        visible: "false",
                    })
                }
            }
        }
    } 

    const saveMySurvey = (saveNo:number, callback:Function, creator: SurveyCreator.SurveyCreator) => {
        if (creator) {
            let surveyData = JSON.parse(creator.text);
            surveyData.isCRM = false;
            surveyData.usage = surveyData.usage || "Default";
            if (surveyData.usage === FormHelper.usage.PASS_ALLOCATION)
                addStatusField(surveyData);
            if (!props.survey)
                props.addNewSurvey(surveyData);
            else
                props.editSurvey(surveyData, props.survey._id, props.surveyIndex);

            if(!surveyData.title){
                callback(saveNo,false);
                return;
            }

        } else {
            console.log("no creator")
        }
    }

    const addNewTemplate = async () => {
        if (creator) {
            let surveyData = JSON.parse(creator.text);
            if (surveyData.title) {
                try {
                    const surveyToSend = {
                        survey_form: surveyData,
                        name: surveyData.title,
                    }
                    const response = await instance.post(`/template-survey`, surveyToSend)
                    getTemplates();
                    toast.success("Form Template created successfully")
                } catch (error) {
                    console.log(error)
                    const err = error as AxiosError
                    toast.error(err.response?.data)
                }
            } else {
                toast.error("Form title is required!");
            }
        }
    }

    const setUpCreator = () => {
        setLoading(true);
        const options = {
            showLogicTab: false,
            showJSONEditorTab: false,
            pageEditMode: "single",
            questionTypes: ["text", "checkbox", "radiogroup", "dropdown", "boolean", "comment", "file", "panel", "paneldynamic","signaturepad"]
        };

        let tempCreator = new SurveyCreator.SurveyCreator(options);
        let surveyJSON = null; 
        if(props.survey){
            surveyJSON = props.survey.survey_form;
        } 
        else if(props.surveyForm){
            surveyJSON = props.surveyForm;
        }
        tempCreator.JSON = surveyJSON;
        tempCreator.toolbox.forceCompact = false;
        tempCreator.saveSurveyFunc = (saveNo:number,callback:Function) => saveMySurvey(saveNo,callback,tempCreator);

        setCreator(tempCreator);
        setLoading(false);
    }


    const cookies = new Cookies();
    const token = cookies.get("token");
    Survey.ChoicesRestfull.onBeforeSendRequest = function (sender: any, options: any) {
        options.request.setRequestHeader("Authorization", "Bearer " + token);
    };

    Survey.Serializer.findProperty("survey", "logo").visible = false;

    Survey
        .Serializer
        .addProperty("question", {
            name: "usage",
            choices: ["Default", "Pass Allocation"],
            default: "Default",
            category: "general",
            visibleIndex: 0,
        });

    Survey
        .Serializer
        .addProperty("survey", {
            name: "usage",
            choices: ["Default", "Pass Allocation"],
            default: "Default",
            category: "general",
            visibleIndex: 2,
        });

    Survey
        .Serializer
        .addProperty("survey", {
            name: "id",
            category: "general",
            visibleIndex: 0,
        });

    Survey
        .Serializer
        .addProperty("survey", {
            name: "category",
            type: "dropdown",
            default: "General Form",
            category: "general",
            visibleIndex: 1,
        });
    SurveyCreator.PropertyGridEditorCollection.register({
            fit: function (prop) {
              return prop.type === "dropdown";
            },
            getJSON: function (obj, prop, options) {
              return {type: "dropdown", maxLength: 5, choices:["General Form", "Stand Form"]}
            }
          });

    let prop = Survey.Serializer.findProperty("file", "storeDataAsText");  
        prop.defaultValue = false;  
        prop.visible = false;

    SurveyCreator
        .PropertyGridEditorCollection
        .register({
            fit: function (prop:any) {
                return prop.type === "choices";
            },
            getJSON: function (obj:any, prop:any, options:any) {
                return { type: "choices", choices: ["Default", "Pass Allocation"] }
            }
        });

    const handleTemplateSelect = (template: any) => {
        if (creator) {
            let updatedCreator = creator;
            selectTemplate(template);
            if (template === "blank") {
                updatedCreator.JSON = null;
            } else {
                updatedCreator.JSON = template.survey_form;
            }
            updatedCreator.setModified();
            setCreator(updatedCreator);
        }
    }

    const getModalTitle = () => {
        if (props.templateCreator) return "Create a template";
        else if (!selectedTemplate && !props.survey) return "Select a template";
        else return "Create a form";
    }

    return (
        <div hidden={!props.show} className="form-creator-modal">
            <div className="modal-container">
                {!loading ? <><div className="btn-container">
                    {!props.templateCreator && <div className="editor-buttons">
                        <Button className="btn btn-primary" onClick={() => setShowTemplateSelector(!showTemplateSelector)}>
                            {!showTemplateSelector ? "Select template" : "Back"}
                        </Button>
                        {!showTemplateSelector && <Button className="save-template-btn btn-secondary" onClick={addNewTemplate}>Save as Template</Button>}
                     </div>}
                    <Button variant="danger" onClick={() => { props.handleClose(); selectTemplate(null) }}>Cancel</Button>
                </div>
                {showTemplateSelector && !props.templateCreator &&
                    <div className="template-container">
                        <Card className="template-card" onClick={() => {handleTemplateSelect("blank"); setShowTemplateSelector(false)}}>
                            <Card.Body>
                                <h4>Blank Form</h4>
                            </Card.Body>
                        </Card>
                        {templates.map(template => {
                            return (
                                <Card className="template-card" onClick={() => {handleTemplateSelect(template); setShowTemplateSelector(false)}}>
                                    <Card.Body>
                                        <h4>{template.survey_form.title}</h4>
                                    </Card.Body>
                                </Card>
                            )
                        })}
                    </div>
                }
                {creator && !showTemplateSelector && <SurveyCreator.SurveyCreatorComponent creator={creator} />}
                </> : <div className="d-flex justify-content-center"><Spinner animation="border"/></div>}
                </div>
        </div>
    )
}
export default FormCreatorModal;