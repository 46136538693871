import React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardImg, CardText, CardTitle } from "reactstrap";
import placeholderImg from "../../assets/images/london.jpg";
import { IFair } from "../../interfaces/fairs";
import parse from "html-react-parser"


interface IProps {
    fair: IFair
    redirect: string
}

export default function FairCard(props: IProps) {
    const history = useHistory();

    const handleClick = () => {
        history.push(props.redirect);
    }

    return (
        <Card onClick={handleClick}>
            <div className="image-wrapper">
                <CardImg
                    alt="Card image cap"
                    src={props.fair.image ? `${process.env.REACT_APP_API_URL}/${props.fair.image.url}` : placeholderImg}
                    top
                    width="100%"
                    height="130px"
                />
            </div>
            <CardBody>
                <CardTitle tag="h5">
                    {props.fair.name}
                </CardTitle>
                <CardText tag="div">
                    {props.fair.description ? parse(props.fair.description) : "No description"}
                </CardText>
            </CardBody>
        </Card>
    )
}