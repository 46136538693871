import { Icon } from "@ailibs/feather-react-ts"
import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import FairCard from "../../components/fair/fair-card.component"
import FairCreateModal from "../../components/fair/fair-create-modal.component"
import { ERoles } from "../../interfaces/authentication"
import {IFair, IModule} from "../../interfaces/fairs"
import { setPageData } from "../../store/pageSlice"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { checkModuleAccess, isAdminAccess } from "../../components/fair/_helper/module_helper"

const Fairs: React.FunctionComponent<any> = () => {
    const [show, setShow] = useState(false)

    const dispatch = useDispatch()

    const { user, selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    useEffect(() => {
        // set page title and breadcrumbs of the header
        dispatch(
            setPageData({
                name: "Fairs",
                firstBreadCrumbName: "",
                secondBreadCrumbName: "",
                thirdBreadCrumbName: "",
            })
        )
    }, [dispatch])

    return (
        <div className="fairs-content">
            <Row className="fairs-cards">
                {fairs.filter((fair:IFair)=>fair.moduls.some(module=>(((module.isEnabled && checkModuleAccess(fair,module,user,selectedGallery)) || isAdminAccess(user, selectedGallery) )))).map((fair: IFair, i) => {
                    return (
                        <Col key={fair._id} sm={12} md={6} lg={3}>
                            <FairCard key={fair._id} fair={fair} redirect={`/fairs/${fair._id}`} />
                        </Col>
                    )
                })}
                {isAdminAccess(user, selectedGallery) ? (
                    <Col sm={12} md={6} lg={3}>
                        <Card className="new-fair" onClick={() => setShow(true)}>
                            <Card.Body>
                                <Icon name="plus" size={104} />
                            </Card.Body>
                        </Card>
                    </Col>
                ) : null}
            </Row>
            <FairCreateModal show={show} setShow={setShow} />
        </div>
    )
}

export default Fairs
