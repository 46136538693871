import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { IActivityLog } from "../interfaces/authentication"
import instance from "../api/api"
import moment from "moment"
import { toast } from "react-toastify"
import { Button } from "reactstrap"
import { Icon } from "@ailibs/feather-react-ts"
import fileDownload from "js-file-download"
import { Modal } from "react-bootstrap"
import ReactJson from "react-json-view"
import { IDynamicObject } from "../interfaces/fairs"
import TextFilter from "./DataTableFilters/text-filter.component"
import { useSelector } from "react-redux"
import { RootState } from "../store/store"
import SelectFilter from "./DataTableFilters/select-filter.component"
import DateFilter from "./DataTableFilters/date-filter.component"

interface IProps {
    filters?: IDynamicObject
}

function ActivityLogs(props: IProps) {
    const [logs, setLogs] = useState<IActivityLog[]>([])

    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [userFilter, setUserFilter] = useState<string>("")
    const [resetPage, setResetPage] = useState<boolean>(false);

    const [changesModalShow, setChangesModalShow] = useState<boolean>(false);
    const [changesData, setChangesData] = useState<any>(null);

    const [filters, setFilters] = useState<IDynamicObject>(props.filters || {});
    
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const applyFilters = ()=>{
        fetchLogs(1);
        setResetPage(!resetPage);
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            applyFilters()
        }, 1000)
        return () => clearTimeout(delayDebounceFn)

    }, [filters])

    useEffect(() => {
        fetchLogs(1)
    }, [])

    async function fetchLogs(page: number) {
        setLoading(true)
        try {
            const response = await instance.get('/log', {
                params: {
                    page: page,
                    per_page: perPage,
                    filters
                }
            })
            setTotalRows(response.data.totalRecords)
            setLogs(response.data.logs)
        } catch (error) {
            console.log("Failed to fetch logs")
            toast.error("Failed to get logs from the server")
        }
        setLoading(false)
    }

    const handlePageChange = (page: number) => {
        fetchLogs(page)
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        try {
            setLoading(true)
            const response = await instance.get('/log', {
                params: {
                    filters
                }
            })
            setLogs(response.data.logs)
            setPerPage(newPerPage)
            setLoading(false)
        } catch (error) {
            toast.error("Failed to get logs from the server")
        }
    }

    const handleCSVDownload = async () => {
        try {
            const response = await instance.get('/log', {
                params: {
                    csv:true,
                    filters
                }
            })
            fileDownload(response.data, "logs.csv")
        } catch (error) {
            toast.error("Failed to download CSV")
        }
    }

    const getFairOptions = () => {
        let options:any[] = [{value: "", display: ""}]
        fairs.forEach(fair => {
            options.push({
                value: fair._id,
                display: fair.name
            })
        })
        return options;
    }

    const columns = [
        {
            name: <TextFilter disabled={loading || (props.filters && props.filters.user)} label="User" value={filters.user || ""} onChange={(e:any) => {
                setFilters({...filters, user: e})
            }} />,
            selector: (row: IActivityLog) => row.user,
            sortable: true,
            wrap: true,
            minWidth: "165px",
        },
        {
            name: <TextFilter disabled={loading || (props.filters && props.filters.userId)} label="Contact Id" value={filters.userId || ""} onChange={(e:any) => {
                setFilters({...filters, userId: e})
            }} />,
            selector: (row: IActivityLog) => row.userId || "-",
            sortable: true,
            wrap: true,
            minWidth: "165px",
        },
        {
            name: <TextFilter disabled={loading || (props.filters && props.filters.userEmail)} label="User Email" value={filters.userEmail || ""} onChange={(e:any) => {
                setFilters({...filters, userEmail: e})
            }} />,
            selector: (row: IActivityLog) => row.userEmail || "-",
            sortable: true,
            wrap: true,
            minWidth: "165px",
        },
        {
            name: <TextFilter disabled={loading || (props.filters && props.filters.galleryId)} label="Account Id" value={filters.galleryId || ""} onChange={(e:any) => {
                setFilters({...filters, galleryId: e})
            }} />,
            selector: (row: IActivityLog) => row.galleryId || "-",
            sortable: true,
            wrap: true,
            minWidth: "165px",
        },
        {
            name: <TextFilter disabled={loading || (props.filters && props.filters.galleryName)} label="Account Name" value={filters.galleryName || ""} onChange={(e:any) => {
                setFilters({...filters, galleryName: e})
            }} />,
            selector: (row: IActivityLog) => row.galleryName || "-",
            sortable: true,
            wrap: true,
            minWidth: "165px",
        },
        {
            name: <TextFilter disabled={loading} label="Action" value={filters.action || ""} onChange={(e:any) => {
                setFilters({...filters, action: e})
            }} />,
            selector: (row: IActivityLog) => row.action,
            sortable: true,
            minWidth: "165px",
        },
        {
            name: <TextFilter disabled={loading} label="Description" value={filters.action_description || ""} onChange={(e:any) => {
                setFilters({...filters, action_description: e})
            }} />,
            selector: (row: IActivityLog) => row.action_description || "-",
            sortable: true,
            wrap: true,
            minWidth: "165px",
        },
        {
            name: <SelectFilter label="Fair" value={filters.fair || ""} onChange={(e:any) => {
                setFilters({...filters, fair: e})
            }} options={getFairOptions()} />,
            selector: (row: IActivityLog) => row.fair?.name || "-",
            sortable: true,
            wrap: true,
            minWidth: "165px",
        },
        {
            name: <b>Module</b>,
            selector: (row: IActivityLog) => row.module?.name || "-",
            sortable: true,
            wrap: true,
            minWidth: "165px",
        },
        {
            name: <DateFilter label="Created At" value={filters.createdAt || null} onChange={(e:any) => {
                setFilters({...filters, createdAt: e})
            }}/>,
            selector: (row: IActivityLog) => moment(row.createdAt).format("YYYY-MM-DD (h:mm:ss A)"),
            sortable: false,
            wrap: true,
            minWidth: "195px",
        },
        {
            name: <b>Actions</b>,
            cell: (row: IActivityLog) => <>
                    {row.data ?
                    <Button className="btn btn-primary" title="Data" onClick={() => {
                        setChangesData(row.data);
                        setChangesModalShow(true);
                    }}>
                        <Icon name="book-open" size="18px"/>
                    </Button>
                    : "-"}
                </>,
            right: true,
        }
    ]

    return (
        <div className="activity-logs">
            <div className="actions">
                <div>
                    <Button onClick={handleCSVDownload}>
                        <Icon className="download-icon" name="download" />
                        CSV
                    </Button>
                </div>
            </div>
            <DataTable
                columns={(columns as any[])}
                responsive
                data={logs}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationDefaultPage={1}
                paginationResetDefaultPage={resetPage}
                persistTableHead
            />
            <Modal className="activity-data-modal" show={changesModalShow} onHide={() => {setChangesModalShow(false); setChangesData(null)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactJson src={JSON.parse(changesData)} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ActivityLogs
