import { Dropdown, DropdownButton } from "react-bootstrap";

type statusVariants = 'danger' | 'warning' | 'success' | 'primary' | 'secondary' | 'info'
interface IStatus{
    name:string
    value:string
    variant:statusVariants
}

interface IProps {
    onChange: Function
    currentStatus: string
    statuses:IStatus[]
    passAllocation?:boolean
}

export default function StatusButton(props: IProps) {

    const renderDropDownButton = ()=>{
        let openStatuses = props.statuses.filter(i=>i.value != props.currentStatus);
        return openStatuses.map((status,i)=>(
                <Dropdown.Item key={i} onClick={()=>{props.onChange(status.value)}}>{status.name}</Dropdown.Item>
        ));
    }

    const getVariant = ()=>{
        let foundStatus = props.statuses.find(status=>status.value == props.currentStatus);
        if(!foundStatus){
             return "primary";
        }
        return foundStatus.variant;
    }

    const getTitle = ()=>{
        let foundStatus = props.statuses.find(status=>status.value == props.currentStatus);
        if(!foundStatus){
             return "Invalid Status";
        }
        return foundStatus.name;
    }

    return (
        <div className="status-button">
            <DropdownButton disabled={props.passAllocation} title={getTitle()} variant={getVariant()}>
                {renderDropDownButton()}
            </DropdownButton>
        </div>
    )
}