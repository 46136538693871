import IRoute from "../interfaces/routes"
import Dashboard from "../pages/dashboard/dashboard.page"
import ModulDetails from "../pages/fairs/modul-details.page"
import Fairs from "../pages/fairs/fairs.page"
import Emails from "../pages/emails/emails.page"
import ContentCreator from "../pages/content-creator/content-creator.page"
import ModuleSelection from "../pages/fairs/module-selection.page"
import Users from "../pages/listings/users.page"
import Galleries from "../pages/listings/galleries.page"
import TemplateSurveys from "../pages/surveys/template-surveys.page"
import Profile from "../pages/profile/profile.page"
import FairEdit from "../pages/fairs/fair-edit.page"
import FairSubmissions from "../pages/fairs/fair-submissions.page"
import FairSubmissionDetails from "../pages/fairs/fair-submission-details.page"
import FairSubmissionDetailsByModule from "../pages/fairs/fair-submission-details-by-module.page"
import { ERoles } from "../interfaces/authentication"
import FairOrderHistory from "../pages/fairs/fair-order-history.page"
import FileUploadsByModule from "../pages/file-uploads/file-uploads-by-module.page"
import Checkout from "../pages/checkout/checkout.page"
import FileUploads from "../pages/file-uploads/file-uploads.page"
import FileUploadsByFair from "../pages/file-uploads/file-uploads-by-fair.page"
import SettingsPage from "../pages/settings/settings.page"

// REPORTS
import MetabaseReport from "../pages/reports/metabesa-report.page"
import ReportFairSelector from "../pages/reports/report-fair-selector.page"
import ReportModuleSelector from "../pages/reports/report-module-selector.page"

const routes: IRoute[] = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        private: true,
        exact: true,
        icon: "x",
        showInSideBar: false,
        roles:[ERoles.SUPERADMIN,ERoles.GALLERY]
    },
    {
        path: "/settings",
        name: "Settings",
        component: SettingsPage,
        private: true,
        exact: true,
        icon: "x",
        showInSideBar: false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/dashboard",
        name: "Summary",
        component: Dashboard,
        private: true,
        exact: true,
        icon: "home",
        showInSideBar:true,
        roles:[ERoles.SUPERADMIN,ERoles.GALLERY]
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        private: true,
        exact: true,
        icon:"users",
        showInSideBar:true,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/accounts",
        name: "Accounts",
        component: Galleries,
        private: true,
        exact: true,
        icon: "grid",
        showInSideBar:true,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/fairs",
        name: "Fairs",
        component: Fairs,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN,ERoles.GALLERY]
    },
    {
        path: "/fairs/:id",
        name: "ModuleSelection",
        component: ModuleSelection,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN,ERoles.GALLERY]
    },
    {
        path: "/fairs/:id/edit",
        name: "Fair Edit",
        component: FairEdit,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/fair/:id/:moduleId/:mainpageid?/:subpageid?",
        name: "ModulDetails",
        component: ModulDetails,
        private: true,
        exact: false,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN,ERoles.GALLERY]
    },
    {
        path: "/creator/:id/:moduleId/:mainPageId?/:subPageId?",
        name: "ContentCreator",
        component: ContentCreator,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/emails",
        name: "Email Templates",
        component: Emails,
        private: true,
        exact: true,
        showInSideBar:true,
        icon:"mail",
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN,ERoles.GALLERY]
    },
    {
        path: "/surveys",
        name: "Form Templates",
        component: TemplateSurveys,
        private: true,
        exact: true,
        showInSideBar:true,
        icon:"file-text",
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/submissions",
        name: "Fair Submissions",
        component: FairSubmissions,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN,ERoles.GALLERY]
    },
    {
        path: "/submissions/:id",
        name: "Fair Submission Details",
        component: FairSubmissionDetails,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/submissions/:id/:moduleId",
        name: "Fair Submission Details Module",
        component: FairSubmissionDetailsByModule,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/checkout/",
        name: "Checkout",
        component: Checkout,
        private: false,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN,ERoles.GALLERY]
    },
    {
        path: "/orderhistory",
        name: "Order History",
        component: FairOrderHistory,
        private: true,
        exact: true,
        showInSideBar:true,
        icon:"credit-card",
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/files",
        name: "File Uploads",
        component: FileUploads,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/files/:id",
        name: "File Uploads By Fair",
        component: FileUploadsByFair,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/files/:id/:moduleId",
        name: "Files",
        component: FileUploadsByModule,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },

    // REPORT PAGES
    {
        path: "/reports",
        name: "Reports, Fairs",
        component: ReportFairSelector,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/reports/:id",
        name: "Reports, Fair, Modules",
        component: ReportModuleSelector,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    },
    {
        path: "/reports/:id?/:moduleId?",
        name: "Files",
        component: MetabaseReport,
        private: true,
        exact: true,
        showInSideBar:false,
        roles:[ERoles.SUPERADMIN]
    }
]

export default routes
