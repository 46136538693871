import React, { useEffect, useState } from "react";
import { setPageData } from "../../store/pageSlice";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import instance from "../../api/api";
import { IFair, IGallerySubmission, IUploadedFile } from "../../interfaces/fairs";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { getIconName } from "../../_helper/_custom";
import { Icon } from "@ailibs/feather-react-ts";

interface IProps {
    
}

const FileUploadsByFair: React.FunctionComponent<any> = (props:IProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [ fair, setFair ] = useState<IFair>();
    const [ fileCounts, setFileCounts] = useState<{moduleId: string, count: number}[]>([])

    const dispatch = useDispatch();
    const params: any = useParams();
    const history = useHistory();

    useEffect(() => {
        getFairData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])

    useEffect(() => {
        if(fair) {
            dispatch(setPageData({
                name: "File Uploads",
                firstBreadCrumbName: fair?.name || "",
                secondBreadCrumbName: "" ,
                thirdBreadCrumbName: "",
            }))
            getFileCounts();
        }
    }, [ dispatch, fair, params.id ])

    async function getFairData() {
        setLoading(true);
        try {
            const id = params.id;
            const response = await instance.get("/fair/" + id);
            setFair(response.data)
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const getFileCounts = async() => {
        setLoading(true);
        try {
            let tempCounts = [];
            const response = await instance.get("/file-upload?fair_id=" + fair?._id);
            const uploadedFiles: IUploadedFile[] = response.data;
            if (fair?.moduls) {
                for (let module of fair?.moduls) {
                    const moduleFiles = uploadedFiles.filter(file => file.module_id === module._id);
                    const countObj = {
                        moduleId: module._id,
                        count: moduleFiles.length
                    }
                    tempCounts.push(countObj);
                    console.log(countObj);
                    
                }
            }
            setFileCounts(tempCounts);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <div className="fair-submission-details-container">
            {!loading ? <Row>
                {fair?.moduls.map((module) => {
                    return(
                        <Col lg={6} key={module._id}>
                            <Card onClick={() => history.push("/files/" + fair._id + "/" + module._id)}>
                                <Card.Body>
                                    <div className="module-header">
                                        <div className="module-icon">
                                            <Icon name={getIconName(module.modultype.name)} size="30px" />
                                        </div>
                                        <div className="module-title">
                                            <h5>{module.title}</h5>
                                        </div>
                                    </div>
                                    <div className="details">
                                        <p>{`There are currently ${fileCounts.find(item => item.moduleId === module._id)?.count} uploaded files for this module.`}</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })}
            </Row> : <div style={{textAlign: "center"}}><Spinner animation="border" /></div>}
        </div>
    );
};

export default FileUploadsByFair;