import React, { useState, useEffect } from "react"
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import instance from "../../api/api"
import { toast } from "react-toastify"
import { IOrderItem, IOrder, EOrderItemStatus } from "../../interfaces/fairs"
import { parseInt, sortBy } from "lodash"
import CurrencyList from "currency-list";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

interface IProps {
    show: boolean
    setShow: Function
    orderId: string
    orderItem: IOrderItem | null
    setOrders: Function
    currency: string
    addNewItem: Function
    eventId: string
}

export default function OrderItemModal(props: IProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const [quantity, setQuantity] = useState(0)
    const [productName, setProductName] = useState("")
    const [price, setPrice] = useState("")

    const [selectedItem, setSelectedItem] = useState<IOrderItem|null>(null);

    const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);

    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    function closeModal() {
        props.setShow(false)
    }

    useEffect(() => {
        setSelectedItem(props.orderItem);
        getOrderItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    useEffect(() => {
        if (selectedItem) {
            setQuantity(selectedItem.value);
            setPrice(parseFloat(selectedItem.price).toFixed(2));
            setProductName(selectedItem.name);
        }
    }, [selectedItem])

    async function getOrderItems() {
        setLoading(true);
        try {
            const fair = fairs.find(item => item._id === props.eventId);
            const query = new URLSearchParams({
                active_status: "Active",
                eve_events_producttemplates_1eve_events_ida: fair?.crm_id || ""
            })
            const response = await instance.get(`/order/items?${query.toString()}`)
            response.data = sortBy(response.data, 'name')
            setOrderItems(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function updateItem() {
        try {
            const updateData = {
                ...(quantity !== 0 && quantity !== props.orderItem?.value && { value: quantity }),
                ...(Number(props.orderItem?.price) !== Number(price) && { price: price }),
                ...(productName !== props.orderItem?.name && { name: productName }),
                ...(props.orderItem?.crm_id !== selectedItem?.crm_id && { crm_id: selectedItem?.crm_id})
            }

            if (Object.keys(updateData).length !== 0) {
                const response = await instance.put("/order", {
                    id: props.orderId,
                    orderItemId: props.orderItem?._id,
                    data: updateData,
                })
                props.setOrders((prev: IOrder[]) => {
                    return prev.map((order) => (order._id === props.orderId ? response.data : order))
                })
                toast.success("Order item is updated")
                closeModal()
            }
        } catch (error) {
            toast("Order item is updated")
        }
    }

    function checkDisabled() {
        const updateData = {
            ...(quantity !== 0 && quantity != props.orderItem?.value && { value: quantity }),
            ...(Number(props.orderItem?.price) !== Number(price) && { price: price }),
            ...(productName !== props.orderItem?.name && { name: productName }),
        }

        return Object.keys(updateData).length === 0
    }

    function _addNewItem() {
        if (selectedItem && quantity !== 0) {
            const newItem:any = {
                status: EOrderItemStatus.PENDING,
                value: quantity,
                crm_id: selectedItem.crm_id,
                type_id: (selectedItem as any).type_id,
                name: selectedItem.name,
                price: selectedItem.price,
                currency: selectedItem.currency,
                currency_name: selectedItem.currency_name,
            }
            props.addNewItem(newItem);
        }
    }

    return (
            <Modal backdrop="static" dialogClassName="order-item-modal" show={props.show} onHide={() => props.setShow(false)}>
                <Modal.Header>
                    <Modal.Title>Edit Order Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loading ? <>
                    <Form>
                        <Form.Group className="form-group">
                            <Form.Label>Product</Form.Label>
                            {selectedItem && <p className="label-info">{`(CRM ID: ${selectedItem.crm_id})`}</p>}
                            <Form.Select value={selectedItem?.name} onChange={(e:any) => {
                                const item = orderItems.find(item => item.name === e.target.value);
                                if (item) {
                                    setSelectedItem(item);
                                } else {
                                    setSelectedItem(null);
                                }
                            }}>
                                <option></option>
                                {orderItems.map(item => {
                                    return (
                                        <option value={item.name}>{item.name}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group  className="form-group">
                            <Form.Label>Price</Form.Label>
                            {props.orderItem?.currency ? <p className="label-info">{`(currency: ${props.orderItem.currency})`}</p> : <></>}
                            <Row>
                                <Col sm={11}><Form.Control
                                    style={{ backgroundColor: Number(props.orderItem?.price) === Number(price) ? "" : "#c5ffc5" }}
                                    onChange={(e:any) => setPrice(e.target.value)}
                                    type="number"
                                    value={price}
                                    step="0.01"
                                /></Col>
                                <Col className="currency_symbol" sm={1}><span>{CurrencyList.get(props.currency || "GBP").symbol}</span></Col>
                            </Row>
                        </Form.Group>
                        <Form.Group  className="form-group" controlId="formQuantity">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                                style={{ backgroundColor: props.orderItem?.value === quantity ? "" : "#c5ffc5" }}
                                min="0"
                                type="number"
                                name="value"
                                id=""
                                value={quantity}
                                onChange={(e:any) => setQuantity(parseInt(e.target.value))}
                            />
                        </Form.Group>
                    </Form>
                    <div className="btn-container">
                        <Button variant="danger" onClick={closeModal}>
                            Cancel
                        </Button>

                        <div className="actions">
                            {
                                <Button
                                    disabled={checkDisabled()}
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (props.orderItem) {
                                            updateItem();
                                        } else {
                                            _addNewItem();
                                        }
                                    }}>
                                    {props.orderItem ? "Update" : "Add"}
                                </Button>
                            }
                        </div>
                    </div>
                    </> : <div style={{height: "100%", textAlign: "center"}}><Spinner animation="border" /></div>}
                </Modal.Body>
            </Modal>
    )
}
