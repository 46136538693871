import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { setPageData } from "../../store/pageSlice"
import { IEmailTemplate } from "../../interfaces/email"
import { IGlobalSettings } from "../../interfaces/fairs"
import instance from "../../api/api"
import { toast } from "react-toastify"
import TermsAndConditionsSettings from "../../components/settings/terms-and-conditions-settings.component"
import SummaryPageSettings from "../../components/settings/summary-page-settings.component"

function SettingsPage() {
    const dispatch = useDispatch()

    const [settings, setSettings] = useState<IGlobalSettings>()
    const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[]>([])
    const [invitationEMailTemplate, setInvitationEMailTemplate] = useState("")
    const [forgotPasswordEmailTemplate, setForgotPasswordEmailTemplate] = useState("")

    useEffect(() => {
        // set page title and breadcrumbs of the header
        dispatch(
            setPageData({
                name: "Settings",
                firstBreadCrumbName: "",
                secondBreadCrumbName: "",
                thirdBreadCrumbName: "",
            })
        )
    }, [dispatch])

    useEffect(() => {
        getSettings()
        getEmailTemplates()
    }, [])

    useEffect(() => {
        setInvitationEMailTemplate(settings?.invitationEmail?._id || "")
        setForgotPasswordEmailTemplate(settings?.forgotPasswordEmail?._id || "")
    }, [settings])

    const getSettings = async () => {
        try {
            const response = await instance.get("settings")
            setSettings(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getEmailTemplates = async () => {
        try {
            const response = await instance.get("email")
            setEmailTemplates(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    async function handleInvitationsSubmit() {
        try {
            const data = {
                ...(invitationEMailTemplate && settings?.invitationEmail?._id !== invitationEMailTemplate && { invitationEmail: invitationEMailTemplate }),
                ...(forgotPasswordEmailTemplate && settings?.forgotPasswordEmail?._id !== forgotPasswordEmailTemplate && { forgotPasswordEmail: forgotPasswordEmailTemplate }),
            }
            const response = await instance.put("settings", data)
            setSettings(response.data)
            toast.success("Settings are updated")
        } catch (error) {
            toast.error("Failed to update settings")
        }
    }

    function checkInvitationsDisabledButton() {
        return (
            (!invitationEMailTemplate || settings?.invitationEmail?._id === invitationEMailTemplate) &&
            (!forgotPasswordEmailTemplate || settings?.forgotPasswordEmail?._id === forgotPasswordEmailTemplate)
        )
    }

    const renderEmailTemplateOptions = emailTemplates.map((emailTemplate: any) => (
        <option key={emailTemplate._id} value={emailTemplate._id}>
            {emailTemplate.name}
        </option>
    ))

    return (
        <div className="settings-page">
            <Tabs defaultActiveKey="emails" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="emails" title="Emails">
                    {settings ? (
                        <div>
                            <Row>
                                <Col sm={12} md={6}>
                                    <h5>Invitation Email</h5>

                                    <Form.Select
                                        className={`invitation-email-selector ${settings?.invitationEmail?._id === invitationEMailTemplate ? "" : "active"}`}
                                        value={invitationEMailTemplate}
                                        onChange={(e) => {
                                            setInvitationEMailTemplate(e.target.value)
                                        }}>
                                        <option value="">Select an email template</option>
                                        {emailTemplates.length > 0 && renderEmailTemplateOptions}
                                    </Form.Select>
                                </Col>
                                <Col sm={12} md={6}>
                                    <h5>Forgot Password Email</h5>

                                    <Form.Select
                                        className={`invitation-email-selector ${!forgotPasswordEmailTemplate || settings?.forgotPasswordEmail?._id === forgotPasswordEmailTemplate ? "" : "active"}`}
                                        value={forgotPasswordEmailTemplate}
                                        onChange={(e) => {
                                            setForgotPasswordEmailTemplate(e.target.value)
                                        }}>
                                        <option value="">Select an email template</option>
                                        {emailTemplates.length > 0 && renderEmailTemplateOptions}
                                    </Form.Select>
                                </Col>
                            </Row>

                            <div className="submit-button-container">
                                <Button disabled={checkInvitationsDisabledButton()} className="invitations-save-button" type="submit" onClick={handleInvitationsSubmit}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </Tab>
                <Tab eventKey="summary-page" title="Summary Page">
                {settings ? <SummaryPageSettings settings={settings} setSettings={setSettings} /> : <></>}
                </Tab>
                <Tab eventKey="terms-and-conditions" title="Policies">
                    {settings ? <TermsAndConditionsSettings settings={settings} setSettings={setSettings} /> : <></>}
                </Tab>
            </Tabs>
        </div>
    )
}

export default SettingsPage
