import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageData } from '../../store/pageSlice';
import { FileUploader } from "react-drag-drop-files";
import { Card, Col, Form, ListGroup, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { IFair, IModule, IUploadedFile } from '../../interfaces/fairs';
import instance from '../../api/api';
import { toast } from 'react-toastify';
import JSZip from 'jszip';
import fileDownload from 'js-file-download';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { categories } from '../../_helper/_standPlans';
import moment from 'moment';
import DateFilter from '../../components/DataTableFilters/date-filter.component';

interface IUploadedStandPlan extends IUploadedFile {
    name: string,
    standCode: string,
    uploaderName: string,
}

function FileUploadsByModule() {
    const dispatch = useDispatch();
    const { id, moduleId } = useParams<{ id: string; moduleId: string; }>();

    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [fair, setFair] = useState<IFair|undefined>();
    const [module, setModule] = useState<IModule|undefined>();

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [category, setCategory] = useState<string>("stand-plan");

    const [uploadedFiles, setUploadedFiles] = useState<IUploadedStandPlan[]>([]);
    const [selectedRows, setSelectedRows] = useState<IUploadedStandPlan[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [filters, setFilters] = useState<any>({
        name: "",
        standCode: "",
        uploaderName: "",
        category: "",
        date: null,
    })

    const fileTypes = process.env.REACT_APP_FILE_TYPES ? process.env.REACT_APP_FILE_TYPES.split(",") : [];

    useEffect(() => {
        // set page title and breadcrumbs of the header
        getFairData();
        getUploadedFiles();
    }, [id, moduleId, fairs]);

    const getFairData = () => {
        const tempFair = fairs.find(item => item._id === id);
        const tempModule = tempFair?.moduls.find(item => item._id === moduleId);
        dispatch(
            setPageData({
                name: "File Uploads",
                firstBreadCrumbName: tempFair?.name || "",
                secondBreadCrumbName: tempModule?.title || "",
                thirdBreadCrumbName: "",
            })
        );
        setFair(tempFair);
        setModule(tempModule);
    }

    const getUploadedFiles = async() => {
        setLoading(true);
        try {
            const categoryValues = Object.keys(categories).map((key:any) => categories[key].value);
            const response = await instance.get('/file-upload', {
                params: {
                    category: categoryValues,
                    fair_id: id,
                    module_id: moduleId,
                }
            });
            const uploadedFilesData:IUploadedFile[] = response.data;
            let standPlans = [];
            for (let fileData of uploadedFilesData) {
                const fileNameArr = fileData.name.split("-");
                let obj: IUploadedStandPlan = {
                    ...fileData,
                    name: fileNameArr[fileNameArr.length-1],
                    standCode: fileData.extra_info && fileData.extra_info.stand_code ? fileData.extra_info.stand_code : "-",
                    uploaderName: fileData.uploader && fileData.uploader._id ? `${fileData.uploader.firstname} ${fileData.uploader.lastname}` : "-"
                }
                standPlans.push(obj);
            }
            setUploadedFiles(standPlans);
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error("Error occured while loading uploaded files");
            setLoading(false);
        }
    }

    const handleFileInputChange = (files:any) => {
        setSelectedFiles([...selectedFiles,...files]);
    }

    const handleSelectedFileDelete = (index: number) => {
        setSelectedFiles([...selectedFiles.filter((file:File,i:number)=>i!==index)]);
    }

    const handleUploadFiles = async() => {
        try {
            setUploading(true);
            
            const formData = new FormData()
            for (let file of selectedFiles) {
                formData.append("files", file);
            }
            formData.append("category", category);
            formData.append("fair_id", id);
            formData.append("module_id", moduleId);

            const response = await instance.post(`file-upload/multiple`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            setUploading(false);
            getUploadedFiles();
            setSelectedFiles([]);
            toast.success("Files uploaded successfully!");
        } catch (error) {
            console.log(error);
            toast.error("Error occured while uploading files!");
            setUploading(false);
        }
    }

    const handleSingleFileDelete = async(fileId: string, index: number) => {
        try {
            const response = await instance.delete("/file-upload/"+fileId);
            setUploadedFiles([
                ...uploadedFiles.slice(0, index),
                ...uploadedFiles.slice(index + 1)
            ]);
            toast.warning("File deleted!");
        } catch (error) {
            console.log(error);
            toast.error("Error occured while deleting file!");
        }
    }

    const handleSingleFileDownload = (fileUrl: string) => {
        const link = document.createElement("a");
        link.href = process.env.REACT_APP_API_URL + "/" + fileUrl;
        link.target = "_blank";
        link.rel = "noreferrer";
        link.download = process.env.REACT_APP_API_URL + "/" + fileUrl;
        link.click();
    }

    const handleMultipleDelete = async() => {
        try {
            await instance.post("/file-upload/delete-multiple", {
                idArray: selectedRows.map(row=>row._id)
            });
            getUploadedFiles();
            setSelectedRows([]);
            toast.warning("Files deleted!");
        } catch (error) {
            console.log(error);
            toast.error("Error occured while deleting files!")
        }
    }

    async function createFileFromURL(url:string, fileName:string){
        let response = await fetch(url);
        let data = await response.blob();
        let file = new File([data], fileName);
        return file;
      }

    const handleMultipleDownload = async() => {
        let files:any[] = [];
        for (let row of selectedRows) {
            const fileUrl = (process.env.REACT_APP_API_URL + row.url.replace(".", "")).replace("//", "/");
            const file = await createFileFromURL(fileUrl, row.name)
            files.push(file);
        }
        if(files.length === 0) return;

        const zip = new JSZip();
        files.forEach(file => {
            zip.file(file.name,file);
        });
        zip.generateAsync({type:"blob"}).then(function(content) {
            fileDownload(content, "stand_plans.zip");
        });
    }

    const columns = [
        {
            name: <div className="table-header">
                    <p><b>File Name</b></p>
                     <Form.Control type="text" value={filters.name} onClick={(e) => e.stopPropagation()} onChange={(e) => setFilters({...filters, name: e.currentTarget.value})}/>
                </div>,
            selector: (row: IUploadedStandPlan) => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: <div className="table-header">
                    <p><b>Category</b></p>
                     <Form.Select value={filters.category} onClick={(e) => e.stopPropagation()} onChange={(e) => setFilters({...filters, category: e.currentTarget.value})}>
                        <option value="">Any</option>
                        {categories.map(cat => {
                            return <option value={cat.value}>{cat.display}</option>
                        })}
                     </Form.Select>
                </div>,
            selector: (row: IUploadedStandPlan) => categories.find(cat => cat.value === row.category)?.display || "-",
            sortable: true,
            wrap: true,
        },
        {
            name: <div className="table-header">
                    <p><b>Stand Number</b></p>
                    <Form.Control type="text" value={filters.standCode} onClick={(e) => e.stopPropagation()} onChange={(e) => setFilters({...filters, standCode: e.currentTarget.value})}/>
                </div>,
            selector: (row: IUploadedStandPlan) => row.standCode,
            sortable: true,
            wrap: true,
        },
        {
            name: <div className="table-header">
                    <p><b>Uploaded by</b></p>
                    <Form.Control type="text" value={filters.uploaderName} onClick={(e) => e.stopPropagation()} onChange={(e) => setFilters({...filters, uploaderName: e.currentTarget.value})}/>
                </div>,
            selector: (row: IUploadedStandPlan) => row.uploaderName,
            sortable: true,
            filterable: true,
        },
        {
            name: <div className="table-header">
                    <p><b>Uploaded at</b></p>
                    <DateFilter label="" value={filters.date} onChange={(e:any) => setFilters({...filters, date: e})}/>
                </div>,
            selector: (row: IUploadedStandPlan) => moment(new Date(row.createdAt)).format("YYYY-MM-DD[\r\n](h:mm:ss A)"),
            sortable: true,
            wrap: true,
        },
        {
            name: "Actions",
            cell: (row: IUploadedStandPlan, index:number) => {
                return (
                    <div className="action-container">
                        <Icon name="download" onClick={() => handleSingleFileDownload(row.url)}/>
                        <Icon name="trash-2" onClick={() => handleSingleFileDelete(row._id, index)}/>
                    </div>
                )
            },
            sortable: false,
            right: true,
        }
    ]

    return (
        <div className="file-upload-page">
            <Card>
                <Card.Body>
                    <Row>
                        <Col className="file-uploader" sm={4}>
                            <FileUploader label="Upload or drop a file right here" name="file" multiple={true} types={fileTypes} handleChange={handleFileInputChange}
                            children={
                                <div className="dragzone-content">
                                    <Icon name="upload-cloud" />
                                    <p>Select or drop files here!</p>
                                </div>
                            } />
                        </Col>
                        <Col sm={4} className="file-list">
                            {selectedFiles.length ?
                            <ListGroup>
                                {selectedFiles.map((file, index) => {
                                    return (
                                        <ListGroup.Item>
                                            <Row>
                                                <Col sm={8}><b><span>{file.name}</span></b></Col>
                                                <Col className="file-size" sm={2}><span>{(file.size/1024).toFixed(0)+"KB"}</span></Col>
                                                <Col className="file-delete" sm={2}><Icon name="trash-2" size="21px" onClick={() => handleSelectedFileDelete(index)}/></Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                            : 
                            <div className='no-files'>
                                <p>No files selected</p>
                            </div>
                            }
                           
                        </Col>
                        <Col sm={2} className="category-selector">
                            <Form.Group>
                                <Form.Label>Category</Form.Label>
                                <Form.Select value={category} onChange={(e) => setCategory(e.currentTarget.value)}>
                                    {categories.map(cat => {
                                        return <option value={cat.value}>{cat.display}</option>}
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={2} className="btn-container">
                            <Button className="btn btn-primary" disabled={!selectedFiles.length} onClick={handleUploadFiles} >
                                <Icon name="upload" size="17px"/>
                                <span>Upload</span>
                            </Button>
                        </Col>
                    </Row>
                    {uploading && <Row className="upload-progress">
                        <div style={{textAlign: "center"}}><Spinner animation='border' /></div>
                    </Row> }
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <h5>Uploaded Files</h5>
                </Card.Header>
                <Card.Body>
                    {!loading ?
                    <>
                    <div className="action-btn-row">
                        {selectedRows.length > 0 && <div className="btn-wrapper">
                            <Button onClick={handleMultipleDelete} className="download-btn"> <Icon className="download-icon" name="trash-2" /> <span>{`Delete (${selectedRows.length})`}</span></Button>
                            <Button variant="secondary" onClick={handleMultipleDownload} className="download-btn"> <Icon className="download-icon" name="download" /> <span>{`Download (${selectedRows.length})`}</span></Button>
                        </div>}
                    </div>
                    <DataTable 
                        columns={columns} 
                        data={uploadedFiles.filter(
                        (file:any) => {
                                for (let key of Object.keys(filters)) {
                                    if (key === "category" && filters.category !== "" && file.category !== filters.category) {
                                        return false;
                                    }
                                    if (key === "date" && filters.date !== null && new Date(file.createdAt).setHours(0,0,0,0) !== new Date(filters.date).setHours(0,0,0,0)) {
                                        return false;
                                    }
                                    if (key !== "category" && key !== "date" && !file[key].toLowerCase().includes(filters[key].toLowerCase()))
                                        return false;
                                }
                                return true;
                            }
                        )}
                        responsive
                        pagination 
                        paginationPerPage={20} 
                        persistTableHead 
                        fixedHeader 
                        selectableRows
                        onSelectedRowsChange={(e)=>{setSelectedRows(e.selectedRows)}}
                    /></>
                    : <div style={{textAlign: "center"}}><Spinner animation='border' /></div>}
                </Card.Body>
            </Card>
        </div>
    )
}
export default FileUploadsByModule;