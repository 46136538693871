import React, { useState } from "react"
import ReactDOM from "react-dom"
import instance from "../../api/api"
import { Col, Card, Form } from "react-bootstrap"
import { Icon } from "@ailibs/feather-react-ts/dist/Icon"

import AlertDismissible from "../alert/alert-dismissible.component"
import { toast } from "react-toastify"
import { IEmailTemplate } from "../../interfaces/email"
import RichTextEditor, { EditorValue } from "react-rte"

const EmailAddForm = (props: any) => {
    async function handleSaveTemplate(e: React.FormEvent) {
        if(nameValue === "" || subjectValue===""  || bodyValue.toString("markdown") === ""){
            return;
        }
        e.preventDefault()
        if (!nameValue || !subjectValue || !bodyValue) {
            toast.error("Template name, subject and body are required")
            return
        }
        try {
            const updatedAttachments:any[] = []
            const reqData: IEmailTemplate = {
                name: nameValue,
                subject: subjectValue,
                body: bodyValue.toString('html'),
                attachments: updatedAttachments,
            }
            const response = await instance.post("/email", reqData)
            toast.success(`"${nameValue}" is created.`)
            props.handleAddTemplate()
            ReactDOM.render(<></>, document.getElementById("email-modaladd"))
        } catch (error) {
            console.log("Failed to create email template", error)
            toast.error(`Failed to create "${nameValue}".`)
        }
    }

    const handleCancelAdding = (e: React.FormEvent) => {
        e.preventDefault()
        ReactDOM.render(<></>, document.getElementById("email-modaladd"))
    }

    const [nameValue, setNameValue] = useState<string>("")
    const [subjectValue, setSubjectValue] = useState<string>("")
    const [bodyValue, setBodyValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());
    const [attachmentValue, setAttachmentValue] = useState<string>("")

    const handleNameChange = (elem: React.ChangeEvent<HTMLInputElement>) => setNameValue(elem.target.value)
    const handleSubjectChange = (elem: React.ChangeEvent<HTMLInputElement>) => setSubjectValue(elem.target.value)
    // const handleBodyChange = (elem: React.ChangeEvent<HTMLTextAreaElement>) => setBodyValue(elem.target.value)
    // const handleAttachmentChange = (elem: React.ChangeEvent<HTMLInputElement>) => setAttachmentValue(elem.target.value)

    return (
        <React.Fragment>
            <Col xl={12} style={{ zIndex: 1001, width: "100%", height: "100%", position: "absolute", top: 0, left: 0, background: "white" }}>
                <Card className="mb-3">
                    <Card.Header className="d-flex justify-content-between align-items-center bg-light py-1 pe-0">
                        <h4 className="h4 mb-0">{`Add new email template`}</h4>
                        <button className="btn" onClick={handleCancelAdding}>
                            <Icon name="x" size={24} />
                        </button>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="#templateName">Email template name</label>
                                <input required={true} type="text" id="templateName" className="form-control" value={nameValue} onChange={handleNameChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="#templateSubject">Email subject</label>
                                <input required={true} type="text" id="templateSubject" className="form-control" value={subjectValue} onChange={handleSubjectChange} />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="#templateBody">Email Body</label> <br></br>
                                <RichTextEditor className="rte" value={bodyValue} onChange={(value)=>setBodyValue(value)} />
                                {/* <textarea required={true} className="form-control" id="templateBody" rows={7} value={bodyValue} onChange={handleBodyChange}></textarea> */}
                            </div>

                            {/* TODO: attachments */}
                            {/* <div className="mb-3">
                                <label htmlFor="#templateName">Attachments (optional)</label> <br></br>
                                <input type="text" id="templateName" className="form-control" value={attachmentValue} onChange={handleAttachmentChange} />
                            </div> */}
                            <div className="button-group">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    onClick={(e) => {
                                        handleSaveTemplate(e)
                                    }}>
                                    Save changes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary-outline ms-2"
                                    onClick={(e) => {
                                        handleCancelAdding(e)
                                    }}>
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default EmailAddForm
