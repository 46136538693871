import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ERoles } from '../../interfaces/authentication';
import { IModule } from '../../interfaces/fairs';
import { RootState } from '../../store/store';
import { isAdminAccess } from '../fair/_helper/module_helper';

interface IProps {
    fairId: string | null
    module: IModule | null
}

interface IWallAllocationData {
    total_area: number
    remaining: number
    lights_allocation: number
    truss_allocation: number
}

function WallAllocationTable(props: IProps) {
    const { user, selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [wallAllocationData, setWallAllocationData] = useState<IWallAllocationData | null>(null);

    useEffect(() => {
        getWallAllocationData();
    }, [user, selectedGallery, props.fairId, props.module])

    const getWallAllocationData = () => {
        const fair = fairs.find(item => item._id === props.fairId);
        if (!isAdminAccess(user, selectedGallery) && fair) {
            const revenueItem = (selectedGallery as any)._extra.RevenueLineItems.find(
                (item: any) => item.sales_stage === "Closed Won" && item.eve_events_id === fair.crm_id && (props.module && item.category_id === props.module.contract_category_id));
            if (revenueItem) {
                const allocationDataObj: IWallAllocationData = {
                    total_area: revenueItem.quantity,
                    remaining: revenueItem.wall_allocation_c,
                    lights_allocation: revenueItem.lights_allocation_c,
                    truss_allocation: revenueItem.truss_allocation_c
                }
                setWallAllocationData(allocationDataObj);
            }
        }
    }

    return (
        <Card>
            <Card.Body>
                <Table>
                    <tbody>
                        <tr>
                            <td>Total Stand Area (rounded)</td>
                            {wallAllocationData && wallAllocationData.total_area ? 
                            <td>{`${wallAllocationData.total_area} m`}<sup>2</sup></td>
                            : <td>0</td>}
                        </tr>
                        <tr>
                            <td>Remaining Wall Allocation</td>
                            {wallAllocationData && wallAllocationData.remaining ?
                            <td>{`${wallAllocationData.remaining} m`}</td>
                            : <td>0</td>}
                        </tr>
                        {wallAllocationData && wallAllocationData.lights_allocation && <tr>
                            <td>Lights Allocation</td>
                            <td>{`${wallAllocationData.lights_allocation}`}</td>
                        </tr>}
                        {wallAllocationData && wallAllocationData.truss_allocation && <tr>
                            <td>Truss Allocation</td>
                            <td>{`${wallAllocationData.truss_allocation} m`}</td>
                        </tr>}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}
export default WallAllocationTable;