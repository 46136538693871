import React, { useState, useEffect } from "react";
import instance from "../../api/api";
import {
    IGallerySubmission,
    submission_types,
    ISurvey,
    IMainPage,
    ISubPage,
    IOrder,
    EOrderCategory,
    EPermissionSettings
} from "../../interfaces/fairs";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Icon } from "@ailibs/feather-react-ts";
import CheckNominationModal from "./check-nomination-modal.component";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import SelectFilter from "../DataTableFilters/select-filter.component";
import TextFilter from "../DataTableFilters/text-filter.component";
import StatusBadge from "./status-badge.component";
import { getSurveyTitle } from "./_helper/submission_helper";
import { head, findIndex, map } from "lodash";
import DetailedModal from "./submissions/detailed_modal.component";
import { setSelectedGallery } from "../../store/userSlice";
import { useHistory } from "react-router-dom";
import { getUniqueSubmissions } from "../../_helper/nominationSubmissionsHelper";

interface IProps {
    submission_type:submission_types
    fair_id:string
    module_id:string
    form_id?:string
    setGalleries?:Function
    setLoading?:Function
}

interface IStatusFilter{
    id:string,
    title:string,
    status:string
}

interface ISort {
    column:string
    sortDirection:string
}

function SubmissionTable(props:IProps) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [allSubmissions, setAllSubmissions] = useState<IGallerySubmission[]>([]);
    const [resultData,setResultData] = useState<Object[]>([]);

    const [users,setUsers] = useState<any[]>([]);

    //Nomination/Accreditation unique actions
    const [showNominationModal, setShowNominationModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [selectedSubId, setSelectedSubId] = useState<string>("");
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [selectedDataIndex, setSelectedDataIndex] = useState<number>(-1);

    //filters
    const [ galleryNameFilter, setGalleryNameFilter ] = useState<string>("");
    const [ statusFilters, setStatusFilters ] = useState<IStatusFilter[]>([]);
    const [ activeStatusFilters, setActiveStatusFilters] = useState<IStatusFilter[]>([]);

    //pagination
    const [ totalData, setTotalData ] = useState<number>(0);
    const [ perPage, setPerPage] = useState<number>(20);
    const [ page, setPage ] = useState<number>(1);
    const [ paginationAvalible, setPaginationAvalible ] = useState<boolean>(false);

    //detailed view
    const [ showDetailed, setShowDetailed ] = useState<boolean>(false);
    const [ detailedData, setDetailedData ] = useState<any>();

    //sort
    const [ sortOrder, setSortOrder] = useState<ISort>({column:"name", sortDirection:"ASC"});

    const [ orders, setOrders ] = useState<IOrder[]>([]);

    // const [ columns, setColumns ] = useState<any[]>([]);

    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [loading,setLoading] = useState<boolean>(true);
    const [ loadingFilesExport, setLoadingFilesExport ] = useState<boolean>(false);
    const [ loadingXLS, setLoadingResultExport ] = useState<boolean>(false);
    const [ loadingStatusExport, setLoadingStatusExport ] = useState<boolean>(false);
    const [ loadingAllResultExport, setLoadingAllResultExport ] = useState<boolean>(false);
    const [ loadingAllStatusExport, setLoadingAllStatusExport ] = useState<boolean>(false);

    const [loadingLogIn, setLoadingLogIn] = useState<boolean>(false);
    const [loadingLogInIndex, setLoadingLogInIndex] = useState<number>(-1);

    useEffect(() => {
        if(props.fair_id && props.module_id && props.submission_type) {
            setGalleryNameFilter("");
            setSelectedRow(null);
            setShowNominationModal(false);
            loadOrders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fair_id, props.module_id, props.submission_type, props.form_id])

    useEffect(() => {
        getGallerySubmissions();
    }, [perPage, page, sortOrder, activeStatusFilters, props.fair_id, props.module_id, props.submission_type, props.form_id])

    const getAllSurvey = ()=>{
        const fair = fairs.find(f=>f._id === props.fair_id);
        if(!fair) return [];
        const module = fair.moduls.find(m=>m._id === props.module_id);
        if(!module) return [];

        let surveys:ISurvey[] = [];

        module.main_pages.forEach((main_page:IMainPage) =>{
            surveys.push(...main_page.surveys);
            main_page.sub_pages.forEach((sub_page:ISubPage)=>{
                surveys.push(...sub_page.surveys);
            });
        });

        return surveys;
    }

    const loadOrders = async() => {
        try {
            const response = await instance.get("/order", { params: {
                    fair:props.fair_id,
                    module:props.module_id,
                    orderCategory:EOrderCategory.STAND_ORDER
            }});
            if(response.data) {
                setOrders(response.data)
            }
            else {
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * check if the type of the module is manual
     * @returns boolean
     */
    function isManualModuleType() {
        const fair = fairs.find((f) => f._id === props.fair_id)
        if (!fair) return false
        const module = fair.moduls.find((m) => m._id === props.module_id)
        if (!module) return false
        return module.modultype.name.toLowerCase() === "manual"
    }


    const setUpStatusFilters = ()=>{
        const surveys = props.form_id ? getAllSurvey().filter(survey=>survey._id === props.form_id) : getAllSurvey();

        setStatusFilters(
            surveys.map(survey=>{
                return {
                    id:survey._id,
                    title: getSurveyTitle(survey),
                    status:"any",
                }
            })
        );
    }

    async function getGallerySubmissions() {
        try {
            setLoading(true);

            const mongofilter:any = { $and: [] };

            //If there are active status filters build the mongo query
            if(activeStatusFilters.length) {
                activeStatusFilters.forEach(filter => {
                    if(filter.status !== "any") {
                        mongofilter.$and.push( { $or: [{survey:filter.id, submission_status:filter.status} ]})
                    }
                })
            }

            //add default filters to the object which dont change
            let filterData:any = {
                deleted:false,
                fair: props.fair_id,
                modul_id:props.module_id,
            }

            //if there were any filters apply it
            if(mongofilter.$and.length) {
                filterData = {
                    ...filterData,
                    ...mongofilter
                }
            }

            setUpStatusFilters();

            let result_table_data:any = [];

            //If there was active status filter we only use our DB
            if(mongofilter.$and.length || props.form_id) {
                const usersRes = await instance.get(`/gallery-submission`, {
                    params: {
                        data:filterData,
                        select: "-survey_result",
                        // pageNop: page,
                        // limit: perPage,
                    }
                });

                const users = new Set(usersRes.data.map((x:any) => x.gallery_id))

                const uniqueUsers = [...users as any]
                
                const fullRes = await instance.get(`/gallery-submission`, {
                    params: {
                        data:{
                            deleted:false,
                            fair: props.fair_id,
                            modul_id:props.module_id,
                            gallery_id:uniqueUsers
                        },
                        select: "-survey_result",
                    }
                });

                let submissions:IGallerySubmission[] = fullRes.data || [];
                // if submissions are listed on the form page for the given survey
                if (props.form_id) {
                    submissions = submissions.filter(sub => sub.survey._id === props.form_id)
                // else listed on admin submission grid
                }else{
                    submissions = getUniqueSubmissions(submissions);
                }

                submissions.forEach((data:IGallerySubmission) => {
                    const idx = findIndex(result_table_data, (row:any) => (row.gallery_id === data.gallery_id) );
                    if( idx !== -1) {
                        result_table_data[idx].submissions.push(data)
                    } else {
                        result_table_data.push({
                            gallery_id:data.gallery_id,
                            gallery_name:data.gallery_name,
                            createdAt:data.createdAt,
                            fair:props.fair_id,
                            module_id:props.module_id,
                            submissions:[data]
                        })
                    }
                })

                setPaginationAvalible(false);
                setTotalData(result_table_data.length);
                setResultData(result_table_data);

            //Else we get every other gallery as well to display everything
            } else {
                const galleries = await getUsers();
                setUsers(galleries);

                filterData = {
                    ...filterData,
                    gallery_id: map(galleries, 'id')
                }

                //Get submissions
                const response = await instance.get(`/gallery-submission`, {
                    params: {
                        data:filterData,
                        select: "-survey_result"
                    }
                });
                console.log('submissions', response.data);

                let submissions:IGallerySubmission[] = response.data || [];
                // if submissions are listed on the form page for the given survey
                if (props.form_id) {
                    submissions = submissions.filter(sub => sub.survey._id === props.form_id)
                // else listed on admin submission grid
                }else{
                    submissions = getUniqueSubmissions(submissions);
                }


                setAllSubmissions(submissions);

                // this is where we merge paginated user data and loaded submissions by user IDs
                galleries.forEach((gallery:any) => {
                    let gallerySubmissions = submissions.filter((data:IGallerySubmission) => data.gallery_id == gallery.id);
                    const idx = findIndex(result_table_data, (row:any) => (row.gallery_id === gallery.id) );
                    if(gallerySubmissions) {
                        if( idx !== -1) {
                            result_table_data[idx].submissions.push(...gallerySubmissions)
                        }
                        else {
                            result_table_data.push({
                                gallery_id:gallery.id,
                                gallery_name:gallery.name,
                                fair:props.fair_id,
                                module_id:props.module_id,
                                createdAt: (head(gallerySubmissions) || {}).createdAt,
                                submissions:[...gallerySubmissions]
                            })
                        }
                    }
                    else {
                        result_table_data.push({
                            gallery_id:gallery.id,
                            gallery_name:gallery.name,
                            createdAt:gallery.createdAt,
                            fair:props.fair_id,
                            module_id:props.module_id,
                            submissions:[]
                        })
                    }
                });
            }

            setPaginationAvalible(true);
            setResultData(result_table_data);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            if (props.setLoading) props.setLoading(false);
        }
    }

    const getUsers = async () => {
        if (props.setLoading) props.setLoading(true);
        try {

            let filter:any = {};

            if(galleryNameFilter.length) {
                filter["name"] = { "$contains": galleryNameFilter };
            }

            const fair = fairs.find(f=>f._id === props.fair_id);

            if(!fair) {
                return
            }

            const module = fair.moduls.find(m=>m._id === props.module_id);

            const response = await instance.get("/crm/all-accounts", {
                params: {
                    offset: (page-1) * perPage,
                    max_num:perPage,
                    sort:sortOrder.column,
                    order:sortOrder.sortDirection,
                    filter:filter,
                    category_id:module ? module.contract_category_id : "",
                    event_id:fair.crm_id,
                    permission: module ? module.permission : EPermissionSettings.REVENUELINEITEM,
                }
            });
            setTotalData(response.data.total_records);
            if (process.env.REACT_APP_CRM_TYPE && process.env.REACT_APP_CRM_TYPE === "prototype") {
                return response.data.records.map((record:any) => ({
                    id: record.id,
                    name: record.name
                }));
            } else {
                return response.data.records.map((record:any) => ({
                    id: record.account_link.id,
                    name: record.account_link.name
                }));
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    function enableNominationModal(value:any, index:any, dataIndex:any, subId?:string){
        setSelectedRow(value[index]);
        setSelectedRowIndex(index);
        setSelectedDataIndex(dataIndex);
        setSelectedSubId(subId ? subId : "");
        setShowNominationModal(true)
    }

    const getExtensionDate = (galleryId:string,surveyId:string)=>{
        const fair = fairs.find(f=>f._id === props.fair_id);
        if(!fair) return;
        const module = fair.moduls.find(m=>m._id === props.module_id);
        if(!module) return;
        let pageId = module.main_pages.find(p=>p.surveys.findIndex(s=>s._id === surveyId)!==-1)?._id;
        if(!pageId){
            module.main_pages.forEach(mp=>{
                let subpage = mp.sub_pages.find(p=>p.surveys.findIndex(s=>s._id === surveyId)!==-1);
                if(subpage){
                    pageId = subpage._id;
                }
            })
        }
        if(!pageId) return;
        const deadline_extension = module.deadline_extensions?.find(extension=>extension.gallery_id === galleryId);
        if(!deadline_extension) return;

        if(deadline_extension.for_all_pages !== null){
            return deadline_extension.for_all_pages.extensionDate;
        }else{
            const extended_page = deadline_extension.pages.find(p=>p.pageId === pageId);
            return extended_page?.extensionDate;
        }

    }

    const isStandOrderSubmitted = (galleryId:string) => {
        const submitted = orders.find(order => order.gallery_id === galleryId);
        if (submitted) return "Yes";
        return "No";
    }

    const getStatusFilter = (id:string)=>{
        const statusFilter = activeStatusFilters.find(sf => sf.id === id);
        return statusFilter ? statusFilter.status : "any";
    }

    const isAdditionalOrdersNeeded = (galleryId:string) => {
        const gallerySubs = getUniqueSubmissions(allSubmissions);
        const submission = gallerySubs.find(sub => {
            return sub.gallery_id === galleryId && sub.additional_order
        })

        if (submission) {
            return "Yes";
        }
        return "No";
    }

    const handleNameFilterKeyDown = (e:any)=>{
        //apply filter on enter press
        if(e.keyCode === 13){
            e.preventDefault(); //otherwise it will change the sorting of the column too
            getGallerySubmissions();
        }
    }

    const logInToAccount = async(galleryId:string, rowIndex:number) => {
        setLoadingLogIn(true);
        setLoadingLogInIndex(rowIndex);
        try {
            const response = await instance.get("/crm/account-by-id", {
                params: {
                    gallery_id: galleryId
                }
            });
            const galleryData = response.data;
            if (galleryData.length) {
                dispatch(setSelectedGallery(galleryData[0]));
                setLoadingLogIn(false);
                setLoadingLogInIndex(-1);
                history.push("/");
            }
        } catch (error) {
            console.log(error);
        }
    }


    const statusColumns = statusFilters.map((filter)=>{
            return {
                name:(
                     <SelectFilter label={filter.title} value={getStatusFilter(filter.id)} onChange={(e:string)=>{handleStatusFilterChange(filter.id,e)}} options={[
                         {value:"any", display:"Any"},
                         {value:"approved", display:"Approved"},
                         {value:"pending", display:"Pending"},
                         {value:"incomplete", display:"Not Submitted"},
                         {value:"inprogress", display:"In Progress"},
                         {value:"open", display:"Open"},
                         {value:"completed", display:"Completed"},
                         {value:"rejected", display:"Rejected"},
                         {value:"inprogress", display:"In Progress"},
                         {value:"under_review", display:"Under Review"},
                     ]}/>
                ),
                cell:(row:any) => {
                    let extensionDate = (getExtensionDate(row.gallery_id,filter.id));
                    let foundSubmission = row.submissions.find((submission:any)=>submission.survey._id === filter.id);
                    return(<div className="status-cell">
                    <StatusBadge
                        currentStatus={foundSubmission ? foundSubmission.submission_status : "incomplete"}
                        statuses={[
                            {name:"Pending", value:"pending", variant:"warning"},
                            {name:"Open", value:"open", variant:"info"},
                            {name:"Not Submitted", value:"incomplete", variant:"danger"},
                            {name:"In Progress", value:"inprogress", variant:"warning" },
                            {name:"Rejected", value:"rejected", variant:"danger"},
                            {name:"Completed", value:"completed", variant:"success"},
                            {name:"Approved", value:"approved", variant:"success"},
                            {name:"In Progress", value:"inprogress", variant:"warning"},
                            {name:"Under Review", value:"under_review", variant:"info"},
                        ]}
                    />
                    {extensionDate && (!foundSubmission || foundSubmission.submission_status === "incomplete") && <div className="extension-label"><b>Ext: </b>{new Date(extensionDate).toLocaleDateString()}</div>}
                    </div>
                    );
                },
                minWidth:"150px",
                style:{alignItems:"flex-start"},
                omit:(!resultData.length && !activeStatusFilters.filter(x => x.id ===filter.id).length)
            }
        })


    const columns = [
        {
            name:"",
            cell: (row: any, index: number) => {
                return (
                    <Row>
                        <Col>
                            <div className="actions-with-account">
                                    <Button title={`Log in as ${row.gallery_name}`} onClick={() => {
                                        logInToAccount(row.gallery_id, index);
                                    }}>
                                        <Icon name="log-in" />
                                    </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className={!row.submissions.length ? "actions-with-account detailed-open-modal-disabled" : "actions-with-account detailed-open-modal"} >
                                <Button onClick={() => {
                                        if(row.submissions.length) {
                                            setShowDetailed(true);
                                            setDetailedData(row);
                                        }
                                    }}  title={row.submissions.length ? "Open Submissions" : "No Submissions"}>
                                    <Icon name={"eye"} className="eye-icon"/>
                                </Button>
                            </div>
                        </Col>
                        {loadingLogIn && loadingLogInIndex === index && <Col><Spinner animation="border" size="sm"/></Col>}
                    </Row>
                )
            },
            sortable:false,
            maxWidth: loadingLogIn ? "190px" : "120px",
            minWidth: loadingLogIn ? "190px" : "120px"

        },
        {
            name: (
                <TextFilter disabled={loading} label="Gallery Name" value={galleryNameFilter} onChange={setGalleryNameFilter} onKeyDown={handleNameFilterKeyDown} />
                ),
            sort_id:"name",
            selector: (row: any, index: any) => row.gallery_name,
            sortable: true,
            minWidth:"150px",
            wrap:true
        },
        {
            name: <b>Stand Order Submitted</b>,
            selector: (row: any) => isStandOrderSubmitted(row.gallery_id),
            sortable: false,
            omit: !isManualModuleType(),
        },
        {
            name: <b>Need Stand Order</b>,
            selector: (row: any) => isAdditionalOrdersNeeded(row.gallery_id),
            sortable: false,
            omit: !isManualModuleType(),
        },
        ...statusColumns,
        ...(isManualModuleType() ? [
            {
                name: "Stand Build PDF",
                cell: (row: any) => {
                    return <Button onClick={()=>{ handleGalleryStandBuildDownload(row.gallery_id, row.gallery_name, props.fair_id);}} className="pdf-download-btn"> <Icon className="download-icon" name="download" /> <span></span></Button>
                },
                sortable: false,
                right:true,
            }
        ] : []),
        {
            name: "Created At",
            selector: (row: any) => row.createdAt ? moment(row.createdAt).fromNow() : "-",
            sort_id:"created_at",
            sortable: true,
            right:true,
            minWidth:"200px"
        },
    ]

    // Handling change of the top status row
    // We group everything that is active (not any) to an active state, update it if changed, and remove it if set to any
    const handleStatusFilterChange = (id:string,status:string)=>{

        let changedFilter = statusFilters.find(filter => filter.id === id);

        if(changedFilter) {
            let active = activeStatusFilters.find(filter => filter.id === id);

            if(active) {
                //TODO: move to enum
                if(status === "any") {
                    // remove if any
                    setActiveStatusFilters(prev => prev.filter(status => status.id !== id))
                }
                else {
                    // else update new status
                    setActiveStatusFilters(prev => prev.map(filter => filter.id === id ? {...filter, status:status} : {...filter}))
                }
            }
            else {
                // add new with correct status
                setActiveStatusFilters(prev => [...prev, {...(changedFilter as IStatusFilter), status:status}])
            }
        }
    }

    async function handleFileDownload() {
        try {
            setLoadingFilesExport(true);
            const submissionIds = selectedRows.map((row:any)=>row.submissions.map((sub:any)=>sub._id)).flat();
            const response = await instance.post("/gallery-submission/export/files",{
                submissionIds:submissionIds
            },
            { responseType: "blob"});

            if(response.status === 204){
                toast.error("Couldn't find any file for export");
            }else{
                fileDownload(response.data, `exportedFiles.zip`)
            }   
            setLoadingFilesExport(false);
        } catch (error) {
            console.log(error);
            setLoadingFilesExport(false);
        }
    }

    async function handleGalleryStandBuildDownload(galleryId: string, galleryName: string, fairId: string) {
        try {
            const response = await instance.get(`/order/stand-form/${fairId}?gallery=${galleryId}&gallery_name=${galleryName}`, {
                responseType: "blob", //Force to receive data in a Blob Format
            })

            fileDownload(response.data, `${galleryId}.zip`)
        } catch (error) {
            toast.error("Failed to download pdf")
        }
    }

    async function handlePdfZipDownload() {
        try {
            const galleries: { galleryId: string; galleryName: string }[] = selectedRows.map((row) => {
                return { galleryId: row.gallery_id, galleryName: row.gallery_name }
            })
            const response = await instance.post(
                `/order/stand-form-pdf/${props.fair_id}`,
                {
                    galleries,
                },
                {
                    responseType: "blob",
                }
            )

            fileDownload(response.data, `Stand_Plan_and_Order_Form.zip`)
        } catch (error) {
            toast.error("Failed to download zip")
        }
    }

    
  
    async function handleResultExport(){
        try{
            setLoadingResultExport(true);
            const response = await instance.post("/gallery-submission/export/xlsx/results",{
                galleryIds:selectedRows.map((row:any)=>row.gallery_id),
                fair:props.fair_id,
                modul_id:props.module_id,
                surveyIds: getAllSurvey().map((survey:ISurvey)=>survey._id)
            },
            {
                responseType: 'blob'
            });
            fileDownload(response.data, "submission-result-export.xlsx");
            setLoadingResultExport(false);
        }
        catch (error){
            console.log(error);
            setLoadingResultExport(false);
        }       
    }

    const handleStatusExport = async ()=>{
        try{
            setLoadingStatusExport(true);
            const response = await instance.post("/gallery-submission/export/xlsx/statuses",{
                galleryIds:selectedRows.map((row:any)=>row.gallery_id),
                fair:props.fair_id,
                modul_id:props.module_id,
                surveyIds: getAllSurvey().map((survey:ISurvey)=>survey._id)
            },
            {
                responseType: 'blob'
            });
            
            fileDownload(response.data, "submission-status-export.xlsx");
            setLoadingStatusExport(false);
        }
        catch (error){
            console.log(error);
            setLoadingStatusExport(false);
        }
    }

    const handleAllResultExport = async ()=>{
        try{
            setLoadingAllResultExport(true);
            const response = await instance.post("/gallery-submission/export/xlsx/results/all",{
                fair_id:props.fair_id,
                modul_id:props.module_id,
                surveyIds: getAllSurvey().map((survey:ISurvey)=>survey._id)
            },
            {
                responseType: 'blob'
            });
            fileDownload(response.data, "submission-all-result-export.xlsx");
            setLoadingAllResultExport(false);
        }
        catch (error){
            console.log(error);
            setLoadingAllResultExport(false);
        }
    }
    const handleAllStatusExport = async ()=>{
        try{
            setLoadingAllStatusExport(true);
            const response = await instance.post("/gallery-submission/export/xlsx/statuses/all",{
                fair_id:props.fair_id,
                modul_id:props.module_id,
                surveyIds: getAllSurvey().map((survey:ISurvey)=>survey._id)
            },
            {
                responseType: 'blob'
            });
            fileDownload(response.data, "submission-all-status-export.xlsx");
            setLoadingAllStatusExport(false);
        }
        catch (error){
            console.log(error);
            setLoadingAllStatusExport(false);
        }
    }

    function updateSubmissionByAdministrator(data:any) {
        setAllSubmissions(prev => {
            let updatedSubmission = prev.find(sub => sub._id === data._id);
            if (updatedSubmission) {
                updatedSubmission = data;
            }
            let result_table_data:any = [];
            users.forEach((gallery:any) => {
                let gallerySubmissions = prev.filter((data:IGallerySubmission) => data.gallery_id === gallery.id);
                const idx = findIndex(result_table_data, (row:any) => (row.gallery_id === gallery.id) );
                if(gallerySubmissions) {
                    if( idx !== -1) {
                        result_table_data[idx].submissions.push(...gallerySubmissions)
                    }
                    else {
                        result_table_data.push({
                            gallery_id:gallery.id,
                            gallery_name:gallery.name,
                            fair:props.fair_id,
                            module_id:props.module_id,
                            createdAt: (head(gallerySubmissions) || {}).createdAt,
                            submissions:[...gallerySubmissions]
                        })
                    }
                }
                else {
                    result_table_data.push({
                        gallery_id:gallery.id,
                        gallery_name:gallery.name,
                        fair:props.fair_id,
                        module_id:props.module_id,
                        createdAt:gallery.createdAt,
                        submissions:[]
                    })
                }
            });
            setResultData(result_table_data);
            return prev;
        });
    }

    function handleSort(column:any, sortDirection:any) {
        setSortOrder({column:column.sort_id, sortDirection:sortDirection});
    };

    return (
        <div className="user-submission-container">
            <div>
                <Row>
                    <Col>
                        <Button variant="info" onClick={()=>{ handleAllResultExport();}} className="download-btn export-btn"> {loadingAllResultExport ? <Spinner className="login-loader" animation="border" size="sm" /> : <span><Icon className="download-icon" name="download" /> <span>All Result</span></span>}</Button>
                        <Button variant="warning" onClick={()=>{ handleAllStatusExport();}} className="download-btn export-btn"> {loadingAllStatusExport ? <Spinner className="login-loader" animation="border" size="sm" /> : <span><Icon className="download-icon" name="download" /> <span>All Status</span></span>}</Button>
                    </Col>
                </Row>
                <Row>
                {selectedRows.length > 0 && <Col>
                        {isManualModuleType() ? <Button onClick={()=>{ handlePdfZipDownload();}} className="download-btn pdf-zip-btn"> <Icon className="download-icon" name="download" /> <span>Stand Build</span></Button> : <></>}
                        <Button onClick={()=>{ handleResultExport();}} className="download-btn export-btn"> {loadingXLS ? <Spinner className="login-loader" animation="border" size="sm" /> : <span><Icon className="download-icon" name="download" /> <span>Export Results</span></span>}</Button>
                        <Button onClick={()=>{ handleStatusExport();}} className="download-btn export-btn"> {loadingStatusExport ? <Spinner className="login-loader" animation="border" size="sm" /> : <span><Icon className="download-icon" name="download" /> <span>Export Statuses</span></span>}</Button>
                        <Button variant="secondary" onClick={()=>{ handleFileDownload(); }} className="download-btn">  {loadingFilesExport ? <Spinner className="login-loader" animation="border" size="sm" /> : <span><Icon className="download-icon" name="save" /> <span>Files</span></span>}</Button>
                    </Col>}
                </Row>

            <DataTable
                selectableRows
                onSelectedRowsChange={(e)=>{ setSelectedRows(e.selectedRows) }}
                columns={columns}
                data={resultData}

                // pagination
                pagination={paginationAvalible}
                paginationServer
                paginationTotalRows={totalData}
                onChangeRowsPerPage={(newPerPage:number, page) => {setPerPage(newPerPage); setPage(1); console.log('newperpage', newPerPage)} }
                onChangePage={(page:number) => setPage(page)}
                paginationRowsPerPageOptions={[ 10, 20, 30, 40, totalData ]}
                paginationPerPage={perPage}

                //progress
                progressComponent={<div className="submission-progress"><Spinner animation="border" color="primary" className="loader"/></div>}
                progressPending={loading}

                //sort
                onSort={handleSort}
                sortServer

                responsive={true}
                persistTableHead={true}
                />

            <CheckNominationModal
                show={showNominationModal}
                setShow={setShowNominationModal}
                queryData={{ salutation: selectedRow?.salutation, contact_type: selectedRow?.question1, country: selectedRow?.country, first_name: selectedRow?.first_name, last_name: selectedRow?.last_name, email: selectedRow?.email, pass: selectedRow?.pass_type, allocation_id: selectedRow?.allocation_id, pt_pass_types_id: selectedRow?.pt_pass_types_id }}
                submissionId={selectedSubId}
                galleries={users}
                rowIndex={selectedRowIndex}
                dataIndex={selectedDataIndex}
                getSubmissions={getGallerySubmissions}
                updateSubmissionByAdministrator={updateSubmissionByAdministrator}
                fair_id={props.fair_id}
                module_id={props.module_id}
            />
            <DetailedModal getGallerySubmissions={getGallerySubmissions} updateSubmissionByAdministrator={updateSubmissionByAdministrator} enableNominationModal={enableNominationModal} show={showDetailed} resultData={resultData} onHide={() => setShowDetailed(false)} data={detailedData} fairId={props.fair_id} moduleId={props.module_id}/>
            </div>
        </div>
    )
}

export default SubmissionTable
