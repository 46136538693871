import { Icon } from '@ailibs/feather-react-ts';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Spinner, ListGroup, FormGroup, FormControl, Form  } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import instance from '../../api/api';
import { RootState } from '../../store/store';

interface IProps {
    queryData?: any
    allocatePass: Function
    show: boolean
    setShow: Function
    pass: string
    existing: boolean
    selectedRow: any
    loading:boolean
    galleries:any
    editNominationData:Function
    getSubmissions:Function
    submissionId:string
    fairId:string
}

function AllocatePassModal(props: IProps) {

    const [nominationData, setNominationData] = useState<any>(null)

    const [editingName, setEditingName] = useState<boolean>(false);
    const [editingEmail, setEditingEmail] = useState<boolean>(false);
    const [editingPass, setEditingPass] = useState<boolean>(false);
    const [passes, setPasses] = useState<string[]>([]);

    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    useEffect(() => {
        if(props.submissionId.length && props.queryData) {
            console.log(props.queryData,props.selectedRow)
            getNominationData(props.selectedRow);
            //getPassChoices();
        }
    }, [props.queryData, props.submissionId, props.selectedRow])

    const getNominationData = (selectedRow: any) => {
        const nominationDataObj = {
            first_name: selectedRow?.first_name || props.queryData.first_name,
            last_name: selectedRow?.last_name || props.queryData.last_name,
            email: props.queryData.email,
            pass_type: props.pass,
            salutation: props.queryData.salutation,
            contact_type: props.queryData.contact_type,
            country: props.queryData.country
        }
        setNominationData(nominationDataObj);
    }

    async function getPassChoices() {

        const fair = fairs.find(item => item._id === props.fairId);
        let choices = [];
        const subResponse = await instance.get("/gallery-submission/" + props.submissionId);
        const submission = subResponse.data;
        const surveyResult = submission ? submission.survey_result : null
        
        const response = await instance.get("/crm/account-by-id", {
            params: {
                gallery_id: submission.gallery_id
            }
        });
        if (response.data.length) {
            for (let pass of response.data[0].pass_allocation) {
                let passLimit = pass.quantity;
                if (surveyResult) {
                    for (let answer of surveyResult[Object.keys(surveyResult)[0]]) {
                        let answerKeys = Object.keys(answer);
                        for (let key of answerKeys) {
                            if (pass.pass_name === answer[key] && answer.status !== "Rejected" && answer.status !== "Already Nominated") {
                                passLimit--;
                            }
                        }
                    }
                }
                if (pass.fair_id === fair?.crm_id && passLimit > 0)
                    choices.push(pass.pass_name);
            }
        }
        setPasses(choices);
    }

    return (
        <>
            {nominationData ?
                <Modal backdrop="static" dialogClassName="allocate-pass-modal" centered show={props.show} onHide={() => props.setShow(false)}>
                    <Modal.Header>
                        <Modal.Title>{props.existing ? "Allocate to Existing Contact" : "Add New Contact"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup className="contact-details" variant="flush">
                            <ListGroup.Item>
                                <Row>
                                    <Col sm={2}>
                                        <span><b>Name:</b></span>
                                    </Col>
                                    <Col sm={9}>
                                        {!editingName ? 
                                        <span>
                                            {nominationData.first_name + " " + nominationData.last_name}
                                        </span> :
                                        <FormGroup>
                                            <Row>
                                                <Col sm={4}><FormControl type='text' value={nominationData.first_name} onChange={(e:any) => {
                                                    setNominationData({...nominationData, first_name: e.currentTarget.value})
                                                }} /></Col>
                                                <Col sm={4}><FormControl type='text' value={nominationData.last_name} onChange={(e:any) => {
                                                    setNominationData({...nominationData, last_name: e.currentTarget.value})
                                                }} /></Col>
                                                <Col sm={2}><Button onClick={() => {
                                                    setEditingName(false);
                                                    }}>Save</Button></Col>
                                                <Col sm={2}><Button onClick={() => {setNominationData({...nominationData, last_name: props.queryData.last_name, first_name: props.queryData.first_name}); setEditingName(false)}}>Cancel</Button></Col>
                                            </Row>
                                        </FormGroup>
                                        }
                                    </Col>
                                    <Col sm={1}>
                                        <Icon name="edit-2" onClick={() => setEditingName(true)} />
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col sm={2}>
                                        <span><b>Email:</b></span>
                                    </Col>
                                    <Col sm={9}>
                                        {!editingEmail ? 
                                        <span>{nominationData.email}</span> :
                                        <FormGroup>
                                            <Row>
                                                <Col sm={8}><FormControl type='text' value={nominationData.email} onChange={(e:any) => {
                                                    setNominationData({...nominationData, email: e.currentTarget.value})
                                                }} /></Col>
                                                <Col sm={2}><Button onClick={() => {
                                                    setEditingEmail(false); 
                                                    }}>Save</Button></Col>
                                                <Col sm={2}><Button onClick={() => {setNominationData({...nominationData, email: props.queryData.email}); setEditingEmail(false)}}>Cancel</Button></Col>
                                            </Row>
                                        </FormGroup>
                                        }
                                    </Col>
                                    <Col sm={1}>
                                        <Icon name="edit-2" onClick={() => setEditingEmail(true)} />
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col sm={2}>
                                        <span><b>Pass Type:</b></span>
                                    </Col>
                                    <Col sm={9}>
                                        {!editingPass ? 
                                        <span>{nominationData.pass_type}</span> : null
                                        // <Row>
                                        // <Col sm={8}>
                                        //     <Form.Select value={nominationData.pass_type} onChange={(e) => setNominationData({...nominationData, pass_type: e.target.value})}>
                                        //         {passes.map(pass => {
                                        //             return <option value={pass}>{pass}</option>
                                        //         })}
                                        //     </Form.Select>
                                        // </Col>
                                        // <Col sm={1}><Button onClick={() => { props.editNominationData(["pass_type"], nominationData); setEditingPass(false);}}>Save</Button></Col>
                                        // <Col sm={1}><Button onClick={() => { setNominationData({...nominationData, pass_type: props.pass}); setEditingPass(false)}}>Cancel</Button></Col>
                                        // </Row>
                                        }
                                    </Col>
                                    {/* {!props.existing && <Col sm={1}>
                                        <Icon name="edit-2" onClick={() => setEditingPass(true)} />
                                    </Col>} */}
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                    <div className="btn-container">
                            <Button variant="danger" onClick={() => {props.setShow(false)}}>
                                Cancel
                            </Button>
                            <Button onClick={() => props.allocatePass(props.existing, nominationData)}>
                                Accept
                                {props.loading && <Spinner className="login-loader" animation="border" size="sm" />}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal> : null}</>)
}
export default AllocatePassModal;