import React, { useEffect, useState } from "react";
import { Accordion, Button, Spinner, Form, Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import instance from "../../api/api";
import placeholderImg from "../../assets/images/london.jpg";
import { IFair } from "../../interfaces/fairs";
import { setPageData } from "../../store/pageSlice";
import RichTextEditor, { EditorValue } from "react-rte";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import FormCreatorModal from "../../components/content-creator/form-creator-modal.component";
import { IEmailTemplate } from "../../interfaces/email";
import { Icon } from "@ailibs/feather-react-ts";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { setFairs } from "../../store/fairsSlice";
import CurrencyList from 'currency-list';
import { head } from "lodash";

const currencies = [CurrencyList.get("USD"),CurrencyList.get("GBP"),CurrencyList.get("EUR")];

enum OrderPDFTypes {
    ORDER_PDF_LOGO= "sOrderPDFlogo",
    STAND_ORDER_PDF_LOGO= "standOrderPDFlogo"
}

interface IUploadFileData
{
    file: File
    name: string
}
interface IProps
{

}
const FairEdit: React.FunctionComponent<any> = (props:IProps) =>
{
    const [loading, setLoading] = useState<boolean>(true);

    const dispatch = useDispatch()
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)


    const [fair, setFair] = useState<IFair>();
    const [emailTemplates,setEmailTemplates] = useState<IEmailTemplate[]>([]);

    const params: any = useParams();

    const [name, setName] = useState<string>("");
    const [rteValue, setRteValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());
    const [crmId, setCrmId] = useState<string>("");
    const [showPrices, setShowPrices] = useState<boolean>(true);
    const [image, setImage] = useState<IUploadFileData | null>(null);
    const [deadline, setDeadline] = useState<Date|null>(null);
    const [legalEntity, setLegalEntity] = useState<string>("");
    const [currency,setCurrency] = useState<string>("");
    const [checkoutEmailEnabled,setCheckoutEmailEnabled] = useState<boolean>(false);
    const [checkoutEmailSenderName,setCheckoutEmailSenderName] = useState<string>("");
    const [checkoutEmailSenderAddress,setCheckoutEmailSenderAddress] = useState<string>("");
    const [checkoutEmailTemplate,setCheckoutEmailTemplate] = useState<string>("");

    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

    const [nominationContent,setNominationContent] = useState<EditorValue>(RichTextEditor.createEmptyValue());
    const [nominationForm, setNominationForm] = useState<any>(null);
    const [nominationAdminEmail, setNominationAdminEmail] = useState<string>("");
    const [nominationEmailTemplate,setNominationEmailTemplate] = useState<string>("");
    const [nominationUrl,setNominationUrl] = useState<string>("");
    const [formCreatorOpen,setFormCreatorOpen] = useState(false);

    const [pdfDescription, setPdfDescription] = useState("")
    const [descriptionInStandPDF, setDescriptionInStandPDF] = useState("")
    const [subtitleInStandPDF, setSubtitleInStandPDF] = useState("")
    const [bottomTextStandPDF, setBottomTextStandPDF] = useState("")
    const [logo, setLogo] = useState<IUploadFileData | null>(null);
    const [standOrderPDFLogo, setStandOrderPDFLogo] = useState<IUploadFileData | null>(null);

    const parseForm = (form:string) => {
        if (!form) {
            return null;
        }
        try {
            const parsed = JSON.parse(form);
            return parsed;
        } catch (e) {
            return null;
        }
    }

    const getImgUrl = ()=>{
        if(fair?.image){
            return `${process.env.REACT_APP_API_URL}/${fair.image.url}`;
        }
        return placeholderImg;
    }

    //check if usernomination form has email field
    const formHasEmailField = ()=>{
        return nominationForm.elements.find((element:any)=>element.name == "email") !== undefined;
    }

    const getFairData = async () =>
    {
        setLoading(true);
        try
        {
            const id = params.id;
            const response = await instance.get("/fair/" + id);
            const fairData: IFair = response.data;

            const emailTemplates = await getEmailTemplates();
            const firstEmailTemplate = head(emailTemplates) as IEmailTemplate;
            setEmailTemplates(emailTemplates);
            
            setFair(fairData);
            setName(fairData.name);
            setRteValue(RichTextEditor.createValueFromString(fairData.description || "", "html"));
            setCrmId(fairData.crm_id);
            setShowPrices(fairData.showPrices);
            setDeadline(fairData.deadline ? new Date(fairData.deadline) : null);
            setNominationContent(RichTextEditor.createValueFromString((fairData.user_nomination?.content || ""),"html"));
            setNominationForm(fairData.user_nomination ? parseForm(fairData.user_nomination.form) : null);
            setNominationAdminEmail(fairData.user_nomination?.adminEmail || "");
            setNominationUrl(fairData.user_nomination?.url || "");

            setNominationEmailTemplate(fairData.user_nomination?.emailTemplate?._id || "");

            setPdfDescription(fairData.descriptionInPDF || "")
            setDescriptionInStandPDF(fairData.descriptionInStandPDF || "")
            setSubtitleInStandPDF(fairData.subtitleInStandPDF || "")
            setBottomTextStandPDF(fairData.bottomTextStandPDF || "")
            setLegalEntity(fairData.legalEntity || "");
            setCurrency(fairData.currency || "GBP");
            setCheckoutEmailEnabled(fairData.checkoutEmailSettings?.enabled || false);
            setCheckoutEmailSenderName(fairData.checkoutEmailSettings?.name || "");
            setCheckoutEmailSenderAddress(fairData.checkoutEmailSettings?.email || "");
            setCheckoutEmailTemplate(fairData.checkoutEmailSettings?.template?._id || firstEmailTemplate?._id || "");
            setLoading(false);

        } catch (error)
        {
            toast.error("There was an error loading this fair");
            console.log(error);
            setLoading(false);
        }
    }
    const getEmailTemplates =  async () =>
    {
        try {
            const response = await instance.get("email");
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    const handleFileChange = (files: any) =>
    {
        if (files.length > 0)
        {
            setImage({ file: files[0], name: files[0].name });
        }
    }

    const handleLogoChange = (files: any, type: OrderPDFTypes) => {
        if (files.length > 0) {
            switch (type) {
                case OrderPDFTypes.ORDER_PDF_LOGO:
                    setLogo({ file: files[0], name: files[0].name })
                    break
                case OrderPDFTypes.STAND_ORDER_PDF_LOGO:
                    setStandOrderPDFLogo({ file: files[0], name: files[0].name })
                    break
            }
        }
    }

    const upladImage = async () =>
    {
        if(image === null){
            return;
        }
        try {
            //delete previous image if exist
            if(fair?.image){
                await instance.delete(`file-upload/${fair.image._id}`);
            }
            //upload new image
            const formData = new FormData();
            formData.append("name", image.name);
            formData.append("category", "page");
            formData.append("file", image.file);
            const response = await instance.post("file-upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            updateFair({image:response.data._id});
            setImage(null);
        } catch (error) {
            toast.error("Photo could not be uploaded.");
            console.log(error);
        }
    }
    const updateFair = async (data: any) =>
    {
        const newFair = { ...fair, ...data };
        try
        {
            const response = await instance.patch("/fair/" + fair?._id, data);
            setFair(response.data);
            setLoading(false);
            toast.success("Fair updated successfully!");
            const updatedFairs = fairs.map(contextFair => {
                return contextFair._id === newFair._id ? response.data : contextFair
            })
            dispatch(setFairs(updatedFairs))
        } catch (error)
        {
            toast.error("Fair could not be updated.");
            console.log(error);
        }
    }

    async function updatePDFData() {
        try {
            const updateData = {
                ...(pdfDescription !== fair?.descriptionInPDF && { descriptionInPDF: pdfDescription }),
                ...(descriptionInStandPDF !== fair?.descriptionInStandPDF && { descriptionInStandPDF: descriptionInStandPDF }),
                ...(subtitleInStandPDF !== fair?.subtitleInStandPDF && { subtitleInStandPDF: subtitleInStandPDF }),
                ...(bottomTextStandPDF !== fair?.bottomTextStandPDF && { bottomTextStandPDF: bottomTextStandPDF }),
            }
            if (Object.keys(updateData).length) {
                const response = await instance.patch("/fair/" + fair?._id, updateData)
                setFair(response.data);
                setLoading(false);
                toast.success("Fair updated successfully!");
                const updatedFairs = fairs.map((contextFair: IFair) => {
                    return contextFair._id === response.data._id ? response.data : contextFair
                })
                dispatch(setFairs(updatedFairs))
            }
        } catch (error) {
            toast.error("Failed to update fair")
        }
    }

    const uploadLogo = async (type: OrderPDFTypes) => {

        try {
            if (type === OrderPDFTypes.ORDER_PDF_LOGO) {
                if (logo === null) {
                    return
                }
                //delete previous logo if exist
                if(fair?.logo){
                    await instance.delete(`file-upload/${fair.logo._id}`);
                }
                const formData = new FormData()
                formData.append("name", logo.name)
                formData.append("category", "page")
                formData.append("file", logo.file)
                const response = await instance.post("file-upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                updateFair({ logo: response.data._id })
                setLogo(null)
            } else if (type === OrderPDFTypes.STAND_ORDER_PDF_LOGO) {
                if (standOrderPDFLogo === null) {
                    return
                }
                //delete previous standorder logo if exist
                if(fair?.standOrderPDFlogo){
                    await instance.delete(`file-upload/${fair.standOrderPDFlogo._id}`);
                }
                const formData = new FormData()
                formData.append("name", standOrderPDFLogo.name)
                formData.append("category", "page")
                formData.append("file", standOrderPDFLogo.file)
                const response = await instance.post("file-upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                updateFair({ standOrderPDFlogo: response.data._id })
                setLogo(null)
            }
        } catch (error) {
            toast.error("Photo could not be uploaded.")
            console.log(error)
        }
    }

    useEffect(() =>
    {
        getFairData();
    }, [params.id]);

    const renderEmailTemplateOptions = emailTemplates.map((emailTemplate:any) => (<option key={emailTemplate._id} value={emailTemplate._id}>{emailTemplate.name}</option>));

    useEffect(() =>
    {
        dispatch(setPageData({
            name: "Fair Edit",
            firstBreadCrumbName: fair?.name || "",
            secondBreadCrumbName: "",
            thirdBreadCrumbName: "" 
        }))
    }, [dispatch, fair])

    return (
        <div>
            {!loading && fair ? <>
                <div className="fair-edit_page">
                    <div className="title-wrapper">
                        <h1>Edit {fair.name} Fair</h1>
                        <small>{fair._id}</small>
                    </div>
                    <Accordion>
                        <Accordion.Item eventKey={"image"}>
                            <Accordion.Header>
                                <h5>Cover Image</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <img alt="cover" src={getImgUrl()} />
                                <input type="file" onChange={(e) => { handleFileChange(e.target.files) }} className="form-control small-input" accept="image/*" />
                                <div className="button-container">
                                    <Button className="submit-button" disabled={image === null} onClick={upladImage}><span>Upload</span></Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={"details"}>
                            <Accordion.Header>
                                <h5>Details</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={(e)=>{
                                    e.preventDefault();
                                    updateFair({ name: name, description: rteValue.toString("html"), deadline: deadline });
                                }}>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <h5>Name</h5>
                                            <input required type="text" className="form-control small-input" value={name} onChange={(e) => { setName(e.target.value) }} />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <h5>Deadline</h5>
                                            <div className="datepicker-wrapper">
                                                <ReactDatePicker className="form-control small-input" readOnly open={datePickerOpen} selected={deadline} minDate={new Date()} value={deadline ? deadline.toLocaleDateString() : undefined} onChange={(date) => {setDeadline(date); setDatePickerOpen(false)}} />
                                                <Icon name="calendar" onClick={() => setDatePickerOpen(!datePickerOpen)}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <h5>Description</h5>
                                    <RichTextEditor className="rte" value={rteValue} onChange={(value) => setRteValue(value)} />
                                    
                                    
                                    <div className="button-container-rigth">
                                        <Button className="submit-button" type="submit" disabled={name === fair.name && rteValue.toString("html").trim() === fair.description?.trim() && (fair.deadline && deadline?.getTime() === new Date(fair.deadline).getTime())}><span>Save</span></Button>
                                    </div>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={"crm"}>
                            <Accordion.Header>
                                <h5>Event ID</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={(e)=>{
                                    e.preventDefault();
                                    updateFair({crm_id:crmId});
                                }}>
                                        <Row>
                                            <Col sm={10} md={6} xl={4}><input required type="text" className="form-control small-input" value={crmId} onChange={(e) => { setCrmId(e.target.value) }} /></Col>
                                            <Col sm={1} md={6} xl={8} className="event_id">
                                            <OverlayTrigger placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">The Sugar Event ID for this event</Tooltip>}>
                                                    {({ ref, ...triggerHandler }) => (
                                                        <div ref={ref}>
                                                            <Icon {...triggerHandler} name="help-circle" />
                                                        </div>
                                                    )}
                                            </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    <Button className="submit-button" type="submit" disabled={crmId === fair.crm_id}><span>Save</span></Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                
                        <Accordion.Item eventKey={"pricing"}>
                            <Accordion.Header>
                                <h5>Pricing Configuration</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={(e)=>{
                                    e.preventDefault();
                                    let data:any = {showPrices:showPrices, legalEntity:legalEntity, currency:currency};
                                    let checkoutEmailSettings:any = {enabled:checkoutEmailEnabled, email:checkoutEmailSenderAddress, name:checkoutEmailSenderName};
                                    if(checkoutEmailTemplate!== "") checkoutEmailSettings = {...checkoutEmailSettings,template:checkoutEmailTemplate};
                                    data = {...data,checkoutEmailSettings:checkoutEmailSettings};
                                    updateFair(data);
                                }}>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <h5>Show Prices</h5>
                                            <div className="d-flex">
                                                <div className="form-check form-switch me-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={showPrices}
                                                        onChange={(e) => {
                                                            setShowPrices(e.target.checked)
                                                        }}
                                                    />
                                                    <label className={`form-check-label`} htmlFor="customSwitch1">
                                                        {showPrices ? "Show" : "Hide"}
                                                    </label>
                                                </div>
                                            
                                                <OverlayTrigger placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">Indicate prices on invoice type order forms</Tooltip>}>
                                                        {({ ref, ...triggerHandler }) => (
                                                            <div ref={ref}>
                                                                <Icon {...triggerHandler} name="help-circle" />
                                                            </div>
                                                        )}
                                                </OverlayTrigger>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <h5>Legal Entity</h5>
                                            <Form.Select value={legalEntity} onChange={(e) => setLegalEntity(e.currentTarget.value)}>
                                                <option value="Frieze Events Ltd">Frieze Events Ltd</option>
                                                <option value="Frieze Events Inc">Frieze Events Inc</option>
                                            </Form.Select>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <h5>Currency</h5>
                                            <Form.Select value={currency} onChange={(e)=>setCurrency(e.currentTarget.value)}>
                                                {currencies.map((c:any,i:number)=>(
                                                    <option key={i} value={c.code}>{c.name} {c.symbol}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <h5>Send Receipt/Invoice in email</h5>
                                         <div className="d-flex">
                                            <div className="form-check form-switch me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={checkoutEmailEnabled}
                                                    onChange={(e) => {
                                                        setCheckoutEmailEnabled(e.target.checked)
                                                    }}
                                                />
                                            <label className={`form-check-label`}>
                                                {checkoutEmailEnabled ? "Enable" : "Disable"}
                                            </label>
                                            </div>
                                        </div>
                                    </Row>
                                    {checkoutEmailEnabled && <>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <h5>Sender Email</h5>
                                            <Form.Control type="email" value={checkoutEmailSenderAddress} onChange={(e)=>setCheckoutEmailSenderAddress(e.currentTarget.value)}/>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <h5>Sender Name</h5>
                                            <Form.Control value={checkoutEmailSenderName} onChange={(e)=>setCheckoutEmailSenderName(e.currentTarget.value)}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <h5>Email Template</h5>
                                            <Form.Select value={checkoutEmailTemplate} onChange={(e)=>{setCheckoutEmailTemplate(e.target.value)}}>
                                                {emailTemplates.length && renderEmailTemplateOptions}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    </>
                                    }
                                    <div className="button-container-rigth">
                                        <Button className="submit-button" type="submit" disabled={
                                            showPrices === fair.showPrices && 
                                            legalEntity === fair.legalEntity && 
                                            currency === fair.currency &&
                                            checkoutEmailEnabled === fair.checkoutEmailSettings?.enabled && 
                                            checkoutEmailSenderAddress === fair.checkoutEmailSettings?.email && 
                                            checkoutEmailSenderName === fair.checkoutEmailSettings?.name && 
                                            checkoutEmailTemplate === fair.checkoutEmailSettings?.template?._id
                                            }><span>Save</span></Button>
                                    </div>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={"user_individual_nomination"}>
                            <Accordion.Header>
                                <h5>User Individual Nomination</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={(e)=>{
                                    e.preventDefault(); 
                                    updateFair({ user_nomination: {content: nominationContent.toString("markdown"), form:nominationForm? JSON.stringify(nominationForm) : "", adminEmail:nominationAdminEmail, emailTemplate:nominationEmailTemplate === "" ? null : nominationEmailTemplate, url:nominationUrl} });
                                    }}>
                                    <h5>URL</h5>
                                    {nominationUrl && <p className="text-muted">{process.env.REACT_APP_FRONTEND_URL}/usernomination/{nominationUrl}</p>}
                                    <Form.Control value={nominationUrl} onChange={(e) => setNominationUrl(e.target.value.replace(/\s+/g, '-').toLowerCase())} />
                                    <h5>Content</h5>
                                    <RichTextEditor className="rte" value={nominationContent} onChange={(value) => setNominationContent(value)} />
                                    <h5>Admin Email</h5>
                                    <input type="email" className="form-control" value={nominationAdminEmail} onChange={(e) => setNominationAdminEmail(e.target.value)} />
                                    <h5>Email Template</h5>
                                    <Form.Select value={nominationEmailTemplate} onChange={(e)=>{setNominationEmailTemplate(e.target.value)}}>
                                        <option>Select an email template</option>
                                        {emailTemplates.length>0 && renderEmailTemplateOptions}
                                    </Form.Select>
                                    <h5>Form</h5>
                                    {nominationForm === null ? <p><Button className="btn btn-primary add_survey_btn" onClick={() => setFormCreatorOpen(true)}><Icon name="plus" /><span>Create Form</span></Button></p>:
                                    <div className="form_container">
                                        <div className="form_title">{nominationForm.title? nominationForm.title : "Survey Title"}</div>
                                        <div className="form_btn_container">
                                            <Button onClick={() => { console.log(nominationForm); setFormCreatorOpen(true); }} className="btn btn-primary"><Icon name="edit-2" /></Button>
                                            <Button onClick={() => { setNominationForm(null); }} className="btn btn-primary"><Icon name="trash-2" /></Button>
                                        </div>
                                    </div>}
                                    {(nominationForm !== null && !formHasEmailField()) && <p className="text-danger">Warning: The form should have a field with the name: 'email'</p>}
                                    <FormCreatorModal surveyForm={JSON.stringify(nominationForm)} surveyIndex={0} show={formCreatorOpen} addNewSurvey={(surveyData: any) => setNominationForm(surveyData)} editSurvey={() => { }} handleClose={() => { setFormCreatorOpen(false); }} templateCreator={false} />
                                    <div className="button-container-rigth">
                                        <Button className="submit-button" type="submit"><span>Save</span> </Button>
                                    </div>
                                    
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={"pdf"}>
                            <Accordion.Header>
                                <h5>PDF Settings</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col sm={12}>
                                        <Form onSubmit={(e)=>{ e.preventDefault();}}>
                                            <h5>PDF Description</h5>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control value={pdfDescription} as="textarea" rows={10} placeholder="Description with email address and phone number" onChange={(e) => setPdfDescription(e.target.value)} />
                                                </Form.Group>
                                            <h5>Description in Stand Order PDF</h5>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control value={descriptionInStandPDF} as="textarea" rows={10} placeholder="Description with email address and phone number" onChange={(e) => setDescriptionInStandPDF(e.target.value)} />
                                                </Form.Group>
                                            <h5>Subtitle in Stand Order PDF</h5>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control value={subtitleInStandPDF} as="textarea" rows={2} placeholder="Description under the logo" onChange={(e) => setSubtitleInStandPDF(e.target.value)} />
                                                </Form.Group>
                                            <h5>Bottom text in Stand Order PDF</h5>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control value={bottomTextStandPDF} as="textarea" rows={5} placeholder="Description at the bottom of the stand order PDF" onChange={(e) => setBottomTextStandPDF(e.target.value)} />
                                                </Form.Group>
                                            <div className="button-container-rigth">
                                                <Button className="submit-button" onClick={updatePDFData} type="submit"><span>Save</span></Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                <Col sm={12} md={6}>
                                        <div className="logo-uploader">
                                            <h5>Logo in Order PDF</h5>
                                            <input type="file" onChange={(e) => { handleLogoChange(e.target.files, OrderPDFTypes.ORDER_PDF_LOGO) }} className="form-control small-input" accept="image/*" />
                                            <div className="button-container-rigth">
                                                <Button  className="submit-button" disabled={logo === null} onClick={() => uploadLogo(OrderPDFTypes.ORDER_PDF_LOGO)}><span>Upload</span></Button>
                                            </div>
                                            <div className="logo-container">
                                                {fair.logo ? <img alt="logo" className="uploaded-logo" src={`${process.env.REACT_APP_API_URL}/${fair.logo.url}`} /> : <></>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <div className="logo-uploader">
                                            <h5>Logo in Stand Order PDF</h5>
                                            <input type="file" onChange={(e) => { handleLogoChange(e.target.files, OrderPDFTypes.STAND_ORDER_PDF_LOGO) }} className="form-control small-input" accept="image/*" />
                                            <div className="button-container-rigth">
                                                <Button  className="submit-button" disabled={standOrderPDFLogo === null} onClick={() => uploadLogo(OrderPDFTypes.STAND_ORDER_PDF_LOGO)}><span>Upload</span></Button>
                                            </div>
                                            <div className="logo-container">
                                                {fair.standOrderPDFlogo ? <img alt="logo" className="uploaded-logo" src={`${process.env.REACT_APP_API_URL}/${fair.standOrderPDFlogo.url}`} /> : <></>}
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                                
                                
                                
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </> : <Spinner animation="border" className="loader" />}
        </div>
    );
};

export default FairEdit;
