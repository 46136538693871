import {IFormHelper} from "../interfaces/fairs";

// TODO: create proper interfaces for statuses!
export const FormHelper:IFormHelper = {
    usage : {
        PASS_ALLOCATION: "Pass Allocation"
    },
    status: {
        pending: {name:"Pending", variant:"warning", code: "pending", color: "orange"},
        inprogress: {name:"In Progress", variant:"warning", code: "inprogress", color: "orange"},
        open: {name:"Open", variant:"info", code: "open", color: "blue"},
        incomplete: {name:"Not Submitted", variant:"danger", code: "incomplete", color: "red"},
        rejected: {name:"Rejected", variant:"danger", code: "rejected", color: "red"},
        completed: {name:"Completed", variant:"success", code: "completed", color: "green"},
        approved: {name:"Approved", variant:"success", code: "approved", color: "green"},
        under_review: {name:"Under Review", variant:"warning", code: "under_review", color: "orange"}
    }
}

export const isDataParsable = (data: string) => {
    try {
        JSON.parse(data)
    } catch (e) {
        return false
    }
    return true
}

export const filterAvailablePasses = (passes: any, modultypeName: string|undefined) => {
    return passes.filter((pass:any) => (modultypeName === 'accreditation' ? pass.pass_name.includes('VIP') : !pass.pass_name.includes('VIP')));
}