import React from "react"
import { closeSidebarMobile } from "../../../_helper/_setSidebar"
import SidebarContent from "./sidebar-content.component"

const Sidebar = (props: any) => {
    return (
        <React.Fragment>
            <div className="menu-container">
                <div className="menu-out" onClick={() => closeSidebarMobile()}></div>
                <div className="vertical-menu">
                    <div data-simplebar className="h-100">
                        <SidebarContent userData={props.userData} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sidebar
