import React from 'react';
import { Col, Form, FormControl, Row } from 'react-bootstrap';

interface IProps {
    defaultTableSorting: string,
    sortingOptions: string[],
    groupByPages: string,
    setDefaultTableSorting: Function,
    setGroupByPages: Function
}

function ProgressTableSettings(props: IProps) {

    return (
        <div>
            <Row className="filter-selector">
                <Col>
                    <p className="label">Default Sorting</p>
                    <Form.Select value={props.defaultTableSorting} onChange={(e) => props.setDefaultTableSorting(e.target.value)}>
                        {props.sortingOptions.map((option, i) => {
                            return (
                                <option value={option} key={i}>
                                    <span>{option}</span>
                                </option>
                            )
                        })}
                    </Form.Select>
                </Col>
                {/* <Col>
                    <p className="label">Group By Pages</p>
                    <Form.Select value={props.groupByPages} onChange={(e) => props.setGroupByPages(e.target.value)}>
                        <option value={'0'} key={0}>
                            <span>{'No'}</span>
                        </option>
                        <option value={'1'} key={1}>
                            <span>{'Yes'}</span>
                        </option>
                    </Form.Select>
                </Col> */}
            </Row>
        </div>
    )
}
export default ProgressTableSettings;