import "survey-react/modern.min.css"
import { Survey } from "survey-react"
import { IPageSurvey, IGallerySubmission, IFair } from "../interfaces/fairs"
import { useParams } from "react-router-dom"
import instance from "../api/api"
import React, { useState, useEffect } from "react"
import * as SurveyCore from "survey-core"
import Cookies from "universal-cookie"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { RootState } from "../store/store"
import { ERoles, IPassAllocation } from "../interfaces/authentication"
import { isSubmissionStatusOpen } from "../_helper/_custom"
import { telephoneWidget } from "../_helper/_telephoneWidget"
import { get } from "lodash";
import {filterAvailablePasses, FormHelper, isDataParsable} from "../_helper/formHelper"
import { Button, Spinner } from "react-bootstrap"
import {getGalleryStandNumber} from "../_helper/_standPlans";
import { isAdminAccess } from "./fair/_helper/module_helper"

SurveyCore.StylesManager.applyTheme("bootstrapmaterial")

interface IProps {
    survey: IPageSurvey
    updateSubmissions: Function
    readonly?: boolean
    submissions: IGallerySubmission[]
    getSubmissions:Function
    template:boolean
    contractCategoryId?:string
    gallery_id?:string // for superadmin update
    gallery_name?:string // for superadmin update
    isAdminUpdate?: boolean
    isGalleryUpdate?: boolean
}

function RenderSurvey(props: IProps) {

    const { id, moduleId } = useParams<{ id: string; moduleId: string; mainpageid: string; subpageid: string }>();

    //Logged in user
    const { user } = useSelector((state) => (state as RootState).userReducer)
    const [ survey, setSurvey ] = useState<SurveyCore.SurveyModel>();
    const [ fair, setFair ] = useState<IFair|undefined>();

    //redux data
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [ resultStatus, setResultStatus ] = useState<string>(FormHelper.status.incomplete.code);
    const [loading, setLoading] = useState<boolean>(false);

    const cookies = new Cookies();
    const token = cookies.get("token");

    SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender: any, options: any) {
        options.request.setRequestHeader("Authorization", "Bearer " + token);
    };


    useEffect(() => {
        if (fairs.length) {
            const tempFair = fairs.find(fair => fair._id === id);
            setFair(tempFair);
            if (tempFair)
                setUpSurvey(tempFair);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.survey._id, user, selectedGallery, fairs])

    useEffect(() => {
        let added = SurveyCore.CustomWidgetCollection.Instance.getCustomWidgetByName("telephone")
        if (!added)
            SurveyCore.CustomWidgetCollection.Instance.addCustomWidget(telephoneWidget, "customtype");
    },[])

    useEffect(() => {
        addSubmitButton();
    }, [])

    async function setUpSurvey(fair:IFair|undefined) {
        setLoading(true);
        const previousSubmission: IGallerySubmission = await getPreviousSubmission();

        let tempForm = props.survey.survey_form;

        if (!props.isAdminUpdate && (props.readonly || previousSubmission && (!isSubmissionStatusOpen(previousSubmission.submission_status)) && !props.survey.multipleSubmissions)) {
            //make form readonly if modul is inactive
            tempForm.elements = tempForm.elements.map((e: any) => {
                return { ...e, ...{ readOnly: true } };
            });
            tempForm.showNavigationButtons = false;
        }

        if (tempForm.elements) {
            for (let element of tempForm.elements) {
                if (element.type === "paneldynamic" && element.usage && element.usage === FormHelper.usage.PASS_ALLOCATION) {
                    for (let question of element.templateElements) {
                        if (question.type === "dropdown" && question.usage === FormHelper.usage.PASS_ALLOCATION) {
                            const choices = await getPassChoices(fair, previousSubmission)
                            question.choices = choices;
                            if(!choices.length){
                                question.optionsCaption = 'No Available Passes - All passes have been allocated '
                            }
                        }
                    }
                    if (props.isAdminUpdate && element.usage === FormHelper.usage.PASS_ALLOCATION) {
                        element.allowAddPanel = true;
                        element.allowRemovePanel = true;
                    }
                }
                if (element.defaultValueExpression && new RegExp('{{(.*?)}}').test(element.defaultValueExpression)) {
                    const key = element.defaultValueExpression.replace("\"", "").replace("\"","").replace("{{","").replace("}}", "");
                    let value:any = getDefaultValueData(key);
                    if (Array.isArray(value)) {
                        value = "";
                        getDefaultValueData(key).forEach((item:any, index:number) => {
                            // TODO: szétröhögöm magam
                            value += (index ? ", " : "") + (item.contacts_or_opp_relationships_1_name || item.city_c)
                        })
                    }
                    element.defaultValueExpression = '"'+ value +'"';
                }
                if (element.choicesByUrl && element.choicesByUrl.url && new RegExp('{{(.*?)}}').test(element.choicesByUrl.url)) {
                    const key = element.choicesByUrl.url.replace("{{","").replace("}}", "");
                    element.choicesByUrl = {};
                    const choices = getDefaultValueData(key);
                    element.choices = (choices && Array.isArray(choices)) ? choices.map((item:any) => item.contacts_or_opp_relationships_1_name || item.city_c) : [];
                }
            }
        }
        let tempSurvey = new SurveyCore.Model(tempForm)
        tempSurvey.focusFirstQuestionAutomatic = false
        tempSurvey.onComplete.add(submit);
        tempSurvey.onUploadFiles.add(uploadFiles);
        tempSurvey.showCompletedPage = false;

        if (props.isAdminUpdate || props.isGalleryUpdate) {
            tempSurvey.completeText = "Update"
        }

        if(props.template) {
            tempSurvey.showNavigationButtons = false;
        }

        if (previousSubmission && ((!props.template && tempForm.usage !== FormHelper.usage.PASS_ALLOCATION) || props.isAdminUpdate || props.isGalleryUpdate)) {
            tempSurvey.data = previousSubmission.survey_result
        }
        if (previousSubmission && !props.template) {
            setResultStatus(previousSubmission.submission_status);
        }

        setSurvey(tempSurvey)
        setLoading(false);
    }

    function getDefaultValueData(key: string):any {
        if(get(selectedGallery, key)){
            return get(selectedGallery, key);
        }
        if(get(user, key)){
            return get(user, key);
        }
        if(key === 'stand_number'){
            return getGalleryStandNumber(selectedGallery, fairs, fair?._id, props.contractCategoryId)
        }

        return "";
    }

    async function getPassChoices(fair:IFair|undefined, submission:IGallerySubmission) {
        setLoading(true);
        let choices = [];
        const fairId = fair?.crm_id;
        const surveyResult = submission ? submission.survey_result : null
        if (user.gallery_access.length || (user.role === ERoles.SUPERADMIN && !isAdminAccess(user,selectedGallery))) {
            for (let pass of selectedGallery.pass_allocation) {
                let passLimit = pass.quantity;
                if (surveyResult) {
                    for (let answer of surveyResult[Object.keys(surveyResult)[0]]) {
                        let answerKeys = Object.keys(answer);
                        for (let key of answerKeys) {
                            if (pass.pass_name === answer[key] && answer.status !== "Rejected" && answer.status !== "Already Nominated") {
                                passLimit--;
                            }
                        }
                    }
                }
                if (pass.fair_id === fairId && passLimit > 0)
                    choices.push(pass);
            }
        } else if (props.isAdminUpdate && props.gallery_id) {
            const response = await instance.get("/crm/account-by-id", {
                params: {
                    gallery_id: props.gallery_id
                }
            });
            if (response.data.length) {
                for (let pass of response.data[0].pass_allocation) {
                    let passLimit = pass.quantity;
                    if (surveyResult) {
                        for (let answer of surveyResult[Object.keys(surveyResult)[0]]) {
                            let answerKeys = Object.keys(answer);
                            for (let key of answerKeys) {
                                if (pass.pass_name === answer[key] && answer.status !== "Rejected" && answer.status !== "Already Nominated") {
                                    passLimit--;
                                }
                            }
                        }
                    }
                    if (pass.fair_id === fairId && passLimit > 0)
                        choices.push(pass);
                }
            }
        }
        setLoading(false);

        const module = fair?.moduls.find(modul => modul._id === moduleId);
        choices = filterAvailablePasses(choices, module?.modultype.name)
        return choices.map((choice:any) => choice.pass_name);
    }

    function getAllocationId(field:any, passes:IPassAllocation[]) {
        for (let key of Object.keys(field)) {
            if (key.includes("pass")) {
                const pass = passes.find((item:IPassAllocation) => item.pass_name === field[key]);
                return pass ? pass.id : "";
            }
        }
        return "";
    }

    function getPassTypeId(field:any, passes:IPassAllocation[]) {
        for (let key of Object.keys(field)) {
            if (key.includes("pass")) {
                const pass = passes.find((item:IPassAllocation) => item.pass_name === field[key]);
                return pass ? pass.pt_pass_types_id : "";
            }
        }
        return "";
    }

    async function addSubmittedStatus(data:any, previousResult?:any) {
        setLoading(true);
        let passes = [];
        if (props.isAdminUpdate && props.gallery_id) {
            const response = await instance.get("/crm/account-by-id", {
                params: {
                    gallery_id: props.gallery_id
                }
            });
            if (response.data.length) {
                passes = response.data[0].pass_allocation;
            }
        } else if (user.gallery_access.length) {
            passes = selectedGallery.pass_allocation;
        }
        let keys = Object.keys(data);
        for (let key of keys) {
            if (key !== "usage" && Array.isArray(data[key])) {
                if (previousResult && previousResult.hasOwnProperty(key)) {
                    let index = 0;
                    for (let field of data[key]) {
                        field.status = previousResult[key][index] && previousResult[key][index].status ? previousResult[key][index].status : "Submitted";
                        field.allocation_id = getAllocationId(field, passes);
                        field.pt_pass_types_id = getPassTypeId(field, passes);
                        index++;
                    }
                }
                else {
                    for (let field of data[key]) {
                        field.status = "Submitted";
                        field.allocation_id = getAllocationId(field, passes);
                        field.pt_pass_types_id = getPassTypeId(field, passes);
                    }
                }
            }
        }
        setLoading(false);
    }

    function mergeSubmissionData(incoming:any, previous:IGallerySubmission) {
        const previousResult = previous.survey_result;
        let keys = Object.keys(incoming);
        for (let key of keys) {
            if (key !== "usage" && Array.isArray(incoming[key])) {
                for (let field of previousResult[key]) {
                    incoming[key].unshift(field);
                }
            }
        }
    }

    const getStatusForSubmissionData = () => {
        if (props.survey.multipleSubmissions)
            return "inprogress";
        if (props.survey.approvalNeeded)
            return "pending";
        else return "completed";
    }

    async function submit(sender: any) {
        // TODO: what about the approved submissions?
        setLoading(true);
        try {
            const previousSubmission: IGallerySubmission = await getPreviousSubmission();
            let data = sender.data;
            const surveyForm = props.survey.survey_form;
            data.usage = surveyForm.usage ? surveyForm.usage : "Default";
            if (data.usage === FormHelper.usage.PASS_ALLOCATION)
                await addSubmittedStatus(data, props.isAdminUpdate ? previousSubmission.survey_result : undefined);
            if (data.usage === FormHelper.usage.PASS_ALLOCATION && previousSubmission && !props.isAdminUpdate) {
                mergeSubmissionData(data, previousSubmission);
            }

            const submitData = {
                fair: id,
                gallery_name:props.gallery_name || selectedGallery.gallery_name,
                gallery_id: props.gallery_id || selectedGallery.gallery_id,
                modul_id: moduleId,
                survey: props.survey._id,
                survey_result: data,
                modified_by: user.role === ERoles.SUPERADMIN ? user._id : null,
                submission_status: !props.isAdminUpdate ? getStatusForSubmissionData() : previousSubmission.submission_status
            }
            const response = await instance.post("/gallery-submission", submitData);
            props.getSubmissions();

            props.updateSubmissions(response.data);

            toast.success(props.survey.successMessage ? props.survey.successMessage : "Thank you for your submission!");
            setUpSurvey(fair);
            setLoading(false);
        } catch (error) {
            toast.error("Error occured while submitting!");
            console.log(error)
            setUpSurvey(fair);
        }
    }

    async function getPreviousSubmission() {
        try {
            if (props.gallery_id || selectedGallery.gallery_id) {
                const response = await instance.get(`/gallery-submission`, {
                    params: {
                        data: {
                            survey:props.survey._id,
                            gallery_id:props.gallery_id || selectedGallery.gallery_id
                        }
                    }
                })
                if (response.data.length) {
                    let submissions = response.data.filter((submission:IGallerySubmission)=>!submission.deleted);
                    submissions = submissions.sort((a: IGallerySubmission, b: IGallerySubmission) => (new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1));
                    return submissions[0];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            console.log("Failed to get previous submission", error)
            return null;
        }
    }

    async function uploadFiles(survey: any, options: any) {
        try {
            const files = options.files;
            for (let file of files) {
                const formData = new FormData();
                formData.append("name", file.name);
                formData.append("category", "page");
                formData.append("file", file);
                const response = await instance.post(`file-upload`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                options.callback("success", files.map((file: any) => {
                    return { file: file, content: `${process.env.REACT_APP_API_URL}/${response.data.url}` }
                }))
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addSubmitButton = async() => {
        const surveyActionBar = await waitForElm(".submit-nominations-container");
        const submitBtn = document.createElement("button");
        let previousSubmission: IGallerySubmission = await getPreviousSubmission();
        if (props.survey.multipleSubmissions && (previousSubmission) && props.survey.submitBtnText) {
            submitBtn.classList.add("btn-primary");
            submitBtn.classList.add("submit-btn");
            submitBtn.innerText = props.survey.submitBtnText;
            submitBtn.disabled = previousSubmission.submission_status !== FormHelper.status.inprogress.code
            submitBtn.onclick = async function() {
                try {
                    previousSubmission.submission_status = props.survey.approvalNeeded ? FormHelper.status.under_review.code : FormHelper.status.completed.code;
                    let resultObj = previousSubmission.survey_result;
                    for (let key of Object.keys(resultObj)) {
                        if (key !== "usage" && Array.isArray(resultObj[key])) {
                            resultObj[key].map((row:any) => {
                                if (row.status && (row.status !== 'Accepted' && row.status !== "Approved" && row.status !== FormHelper.status.rejected.name && row.status !== "Already Nominated")) {
                                    row.status = props.survey.approvalNeeded ? FormHelper.status.under_review.name : FormHelper.status.completed.name;
                                }
                            })
                        }
                    }
                    previousSubmission.survey_result = resultObj;
                    await instance.patch(`/gallery-submission/${previousSubmission._id}`, previousSubmission);
                    props.getSubmissions();
                    toast.success("Pass Allocations submitted successfully!")
                } catch (error) {
                    console.log(error);
                    toast.success("Error occured while submitting Pass Allocations");
                }
            }
            surveyActionBar.appendChild(submitBtn);
        }
    }

    const waitForElm = (selector:string):Promise<Element> => {
        return new Promise(resolve => {
            const element = document.querySelector(selector)
            if (element) {
                return resolve(element);
            }
    
            const observer = new MutationObserver(mutations => {
                const element = document.querySelector(selector)
                if (element) {
                    resolve(element);
                    observer.disconnect();
                }
            });
    
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    async function deleteFiles(survey: any, options: any) {
        //TODO: Create proper file deletion in API
    }
    const currentStatus = FormHelper.status[resultStatus] || {};
    return (
        <>
        {!loading ? <div className="display-survey">
            {props.gallery_id && <div className={'result-status'} style={{color: currentStatus.color}}><span>Status: </span>{currentStatus.name}</div>}
            {survey && <Survey model={survey} />}
        </div>
        : <div style={{textAlign: "center"}}><Spinner animation="border"></Spinner></div>}
        </>
    )
}

export default RenderSurvey
