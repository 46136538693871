import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Icon } from "@ailibs/feather-react-ts/dist/Icon";

export interface IAlertDismissible {
  variant: string;
  message: string;
}

const AlertDismissible = (props: IAlertDismissible) => {
    const [show, setShow] = useState(true);

    return (
        <Alert show={show} variant={props.variant} onClose={() => setShow(false)} dismissible>
            <div>
            <span className="d-inline-block pe-1">
                <Icon name="check" size={14} />
            </span>
            {props.message}
            </div>
        </Alert>
    );
};

export default AlertDismissible;
