import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { setPageData } from "../../store/pageSlice"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { IModule, IFair, IMetabaseDashboard } from "../../interfaces/fairs"
import { Tabs, Tab } from "react-bootstrap"
import MetabaseDashboardDisplayer from "./metabase-dashboard-displayer"

function MetabaseReport() {
    const dispatch = useDispatch()

    const [metabaseDashboards, setMetabaseDashboards] = useState<IMetabaseDashboard[]>([])

    const { id, moduleId } = useParams<{ id: string; moduleId: string }>()

    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    useEffect(() => {
        getModule()
    }, [])

    useEffect(() => {
        getModule()
    }, [dispatch, id, moduleId])

    function getModule() {
        const fair: IFair | undefined = fairs.find((fair) => fair._id === id)
        const module: IModule | undefined = fair?.moduls.find((module) => module._id === moduleId)
        dispatch(
            setPageData({
                name: "Reports",
                firstBreadCrumbName: fair?.name || "",
                secondBreadCrumbName: module?.title || "",
                thirdBreadCrumbName: "",
            })
        )
        if (module?.metabase_dashboards) {
            setMetabaseDashboards(module?.metabase_dashboards)
        } else {
            setMetabaseDashboards([])
        }
    }

    return (
        <div className="metabase-report-page">
            {id && moduleId && metabaseDashboards && metabaseDashboards.length ? (
                <div>
                    <Tabs defaultActiveKey={"0"} id="fill-tab-example" className="mb-3">
                        {metabaseDashboards.map((dashboard, index) => {
                            return (
                                <Tab eventKey={index} title={dashboard.dashboard_name}>
                                    <MetabaseDashboardDisplayer dashboardNumber={dashboard.dashboard_number} fairId={id} moduleId={moduleId} />
                                </Tab>
                            )
                        })}
                    </Tabs>
                </div>
            ) : (
                <>
                    <p className="no-reports-message">This module has not reports</p>
                </>
            )}
        </div>
    )
}

export default MetabaseReport
