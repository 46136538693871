import React, { useEffect, useState } from "react";
import { Badge, ListGroup } from "react-bootstrap"
import { ERoles, IPassAllocation } from "../../interfaces/authentication";
import {IGallerySubmission, IDynamicObject, IModule} from "../../interfaces/fairs";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { FormHelper, filterAvailablePasses } from "../../_helper/formHelper";
import { isAdminAccess } from "../fair/_helper/module_helper";

interface IProps {
    submissions:IGallerySubmission[]
    fairId: string | null
    module: IModule | null
    settings: IDynamicObject
}

export default function AccreditationPassTypes(props:IProps) {

    //redux data
    const { user } = useSelector((state) => (state as RootState).userReducer)
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [passes, setPasses] = useState<IPassAllocation[]>([]);

    //Check if all data provided to be able to load module
    const [ available, setAvailable ] = useState<boolean>(false);

    const settings = props.settings;
    const title = settings.hasOwnProperty("title") ? settings.title : 'Remaining Passes';
    const setUpPasses = (submissions: IGallerySubmission[], fair:any) => {
        // if admin show a placeholder
        if(isAdminAccess(user, selectedGallery)) {
            setPasses([{
                id: "1",
                pass_name: "Pass Placeholder",
                quantity: 0,
                fair_id: "0",
                pt_pass_types_id: ""
            }])
            return;
        }

        let accountPasses:IPassAllocation[] = []
        if ((user.role === ERoles.GALLERY && user.gallery_access.length) || (user.role === ERoles.SUPERADMIN && !isAdminAccess(user,selectedGallery))) {
            accountPasses = JSON.parse(JSON.stringify(selectedGallery.pass_allocation));
            accountPasses = accountPasses.filter(pass => pass.fair_id === fair?.crm_id);
            // filter for name includes VIP or not
            accountPasses = filterAvailablePasses(accountPasses, props.module?.modultype.name);

            setPasses(accountPasses);

            // if gallery has submissions we start checking usage
            if(submissions && submissions.length){
                //get only accreditation submissions

                let accreditations = submissions.filter(sub => sub.survey_definition && sub.survey_definition.usage === FormHelper.usage.PASS_ALLOCATION);

                // find last submission
                accreditations = accreditations.sort((a: IGallerySubmission, b: IGallerySubmission) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0));
                
                const submission = accreditations[0];
                const surveyResult = submission ? submission.survey_result : null

                // we found submissions result with accreditation usage
                if (surveyResult && surveyResult.usage === FormHelper.usage.PASS_ALLOCATION) {
                    for (let pass of accountPasses) {
                        for (let answer of surveyResult[Object.keys(surveyResult)[0]]) {
                            let answerKeys = Object.keys(answer);
                            let counted = false;
                            for (let key of answerKeys) {
                                if (answer[key] && typeof answer[key] === 'string' && answer[key].trim().includes(pass.pass_name.trim()) && !counted && answer.status !== "Rejected" && answer.status !== "Already Nominated") {
                                    pass.quantity--;
                                    //counted = true;
                                }
                            }
                        }
                    }
                    setPasses(accountPasses);
                }
            }
        }
    }

    useEffect(() => {
        if(props.submissions !== null) {
            setAvailable(true);
        }
    }, [])

    useEffect(() => {
        if(available && fairs.length) {
            const myFair = fairs.find(fair => fair._id === props.fairId);
            setUpPasses(props.submissions, myFair);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.submissions, available, fairs])

    return(
        <div className="pass-type-container">
            {available ? <>
                <h5>{title}</h5>
                {passes.length ? <ListGroup>
                    {passes.map((pass: any) => {
                        const maxQuantity = selectedGallery.pass_allocation.find((item:IPassAllocation) => item.id === pass.id)?.quantity;
                        return (
                            <ListGroup.Item>
                                <p>{pass.pass_name}</p><Badge>{pass.quantity} / {maxQuantity}</Badge>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup> : <p style={{textAlign: 'center'}}>You have no passes available for this event.</p>}
            </> : <p>Component not available with the current page data.</p>}
        </div>
    );
}
