import { Button, Modal } from "react-bootstrap"

interface IProps {
    show:boolean
    onConfirm:Function
    onCancel:Function
    title:string,
    body:string
}

export default function ConfirmModal(props: IProps) {
    
    return (
        <Modal show={props.show} onHide={()=>{props.onCancel()}}>
            <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.body}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={()=>{props.onCancel()}}>
                Close
            </Button>
            <Button variant="warning" onClick={()=>{props.onConfirm()}}>
                Confirm
            </Button>
            </Modal.Footer>
        </Modal>
    )
}
