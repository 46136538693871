import React, { useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import instance from "../../api/api"
import { toast } from "react-toastify"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { Editor, RawDraftContentState } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { Icon } from "@ailibs/feather-react-ts"
import { IGlobalSettings } from "../../interfaces/fairs"
import CodeEditor from "@uiw/react-textarea-code-editor"

const options = ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link", "embedded", "emoji", "remove", "history"]

interface IProps {
    settings: IGlobalSettings
    setSettings: Function
}

function SummaryPageSettings({ settings, setSettings }: IProps) {
    const [welcomeMessageMarkdown, setWelcomeMessageMarkdown] = useState<string>("")

    //wyswyg editor
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
    const [htmlEditor, setHtmlEditor] = useState<boolean>(false)
    const editorRef = useRef(null)

    const [rtePreview, setRtePreview] = useState<boolean>(false)

    useEffect(() => {
        setWelcomeMessageMarkdown(settings?.welcomeMessage || "")
        if (settings?.welcomeMessage) {
            try {
                const contentBlock = htmlToDraft(settings?.welcomeMessage.replaceAll(/(<\/?)figure((?:\s+.*?)?>)/g, ""))
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEditorState(editorState)
            } catch (error) {
                console.log(error)
            }
        } else {
            setEditorState(EditorState.createEmpty())
        }
    }, [settings])

    /*
    Input field change handling
    */
    const handleMarkdownChange = (state: EditorState) => {
        let isEmpty: boolean = !state.getCurrentContent().hasText()
        setEditorState(state)
        if (isEmpty) setWelcomeMessageMarkdown("")
        else setWelcomeMessageMarkdown(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    const handleContentStateChange = (state: RawDraftContentState) => {
        setWelcomeMessageMarkdown(draftToHtml(state))
    }

    const handleHTMLEditorChange = (html: string) => {
        setWelcomeMessageMarkdown(html)
        const contentBlock = htmlToDraft(html.replaceAll(/(<\/?)figure((?:\s+.*?)?>)/g, ""))
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)
        setEditorState(editorState)
    }

    const validateHTML = (htmlString: string) => {
        let htmlToParse = htmlString.replaceAll("<br>", "<br/>").replaceAll("&nbsp;", "")
        let parser = new DOMParser()
        let doc = parser.parseFromString("<div>" + htmlToParse + "</div>", "application/xml")
        let errorNode = doc.querySelector("parsererror")
        if (errorNode) {
            return false
        } else {
            return true
        }
    }

    async function submitWelcomeMessage() {
        try {
            let data = {
                ...(welcomeMessageMarkdown && settings?.welcomeMessage !== welcomeMessageMarkdown && { welcomeMessage: welcomeMessageMarkdown }),
            }

            if (data.welcomeMessage && !validateHTML(data.welcomeMessage)) {
                toast.error("The welcome message HTML is invalid")
                return
            }

            const response = await instance.put("settings", data)
            setSettings(response.data)
            toast.success("Settings are updated")
        } catch (error) {
            toast.error("Failed to update settings")
        }
    }

    return (
        <div>
            <div className="wyswyg_editor">
                <div className="rte-wrapper">
                    <div className="rte-header">
                        <h5>Welcome Message</h5>
                        <div className="editor-btn-container">
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setRtePreview(false)
                                    setHtmlEditor(!htmlEditor)
                                }}>
                                <Icon name="code" />
                                <span>{!htmlEditor ? "Edit HTML" : "Stop Editing HTML"}</span>
                            </Button>
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setRtePreview(!rtePreview)
                                }}>
                                <Icon name={!rtePreview ? "eye" : "eye-off"} />
                                <span>Preview</span>
                            </Button>
                        </div>
                    </div>
                    {!rtePreview && !htmlEditor && (
                        <div className="wyswyg_editor">
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={handleMarkdownChange}
                                onChange={handleContentStateChange}
                                ref={editorRef}
                                toolbar={{
                                    options: options,
                                }}
                                handlePastedText={() => false}
                            />
                        </div>
                    )}
                    {!htmlEditor && rtePreview && <div className="preview-rte" dangerouslySetInnerHTML={{ __html: welcomeMessageMarkdown }}></div>}
                    {htmlEditor && !rtePreview && (
                        <CodeEditor
                            value={welcomeMessageMarkdown}
                            placeholder=""
                            onChange={(e) => handleHTMLEditorChange(e.target.value)}
                            padding={15}
                            style={{
                                fontSize: 14,
                                backgroundColor: "#f2f2f2",
                                color: "#000",
                                minHeight: 250,
                            }}
                        />
                    )}
                </div>

                <div className="submit-button-container">
                    <Button className="invitations-save-button" type="submit" onClick={submitWelcomeMessage}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SummaryPageSettings
