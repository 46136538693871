import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import routes from "../../../config/routes";
import { ERoles } from "../../../interfaces/authentication";
import { closeSidebarMobile } from "../../../_helper/_setSidebar";

interface Props {
  user: any,
  selectFairItem: any
}

export const SidebarLinks: React.FC<Props> = ({ user, selectFairItem }) => {
    const history = useHistory();

    function renderRoutes() {
        return routes.map((route, i) => (
            route.showInSideBar && route.roles.includes(ERoles.GALLERY) ? <li key={i}>
                <div className={history.location.pathname === route.path ? "menu-item-wrapper active" : "menu-item-wrapper"} onClick={() => { selectFairItem(""); closeSidebarMobile() }}>
                    <Link className="main-item" to={route.path}>
                        {route.icon && <Icon name={route.icon} />}
                        <span>{route.name}</span>
                    </Link>
                </div>
            </li> : null
        ));
    }
    return (
        <>
            {renderRoutes()}
        </>
    );
};

export const SidebarAdminLinks: React.FC<Props> = ({ user, selectFairItem }) => {
    const history = useHistory();

    function renderRoutes() {
        return routes.map((route, i) => (
            route.showInSideBar && route.roles.includes(user.role as ERoles) && !route.roles.includes(ERoles.GALLERY) && route.roles.includes(ERoles.SUPERADMIN) ? <li key={i}>
                <div className={history.location.pathname === route.path ? "menu-item-wrapper active" : "menu-item-wrapper"} onClick={() => { selectFairItem(""); closeSidebarMobile() }}>
                    <Link className="main-item" to={route.path}>
                        {route.icon && <Icon name={route.icon} />}
                        <span>{route.name}</span>
                    </Link>
                </div>
            </li> : null
        ));
    }
    return (
        <>
            {renderRoutes()}
        </>
    );
};
