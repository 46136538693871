import React, { useState } from "react"
import Header from "./header.component"
import Sidebar from "./sidebar/sidebar.component"
import { Redirect, Route, Switch } from "react-router"
import routes from "../../config/routes"
import IRoute from "../../interfaces/routes"
import { ERoles, IGalleryAccess } from "../../interfaces/authentication"
import { useEffect } from "react"
import instance from "../../api/api"
import { defaultUser, setIsAuthenticated, setSelectedGallery, setUser } from "../../store/userSlice"
import { useDispatch, useSelector } from "react-redux"
import logout from "../../_helper/_logout"
import { RootState } from "../../store/store"
import { setFairs } from "../../store/fairsSlice";
import { Spinner } from "react-bootstrap";
import { IDynamicObject } from "../../interfaces/fairs"
export const CONTRACT_WON = "Closed Won";
export const SECTION_TYPES: IDynamicObject = {
    '48ca186e-ea34-11ec-b94c-2a6df1683594': 'Themed',
    '48ca16bf-ea34-11ec-b94c-2a6df1683594': 'Focus',
    '48ca175a-ea34-11ec-b94c-2a6df1683594': 'Spotlight',
    '48ca15fb-ea34-11ec-b94c-2a6df1683594': 'Main',
    '707759cc-ef05-11ec-8621-0a5eb05ce262': 'Masters',
    '48ca1a8e-ea34-11ec-b94c-2a6df1683594': 'Gallery'
};

const Layout: React.FunctionComponent<any> = () => {
    const dispatch = useDispatch()

    //Logged in user
    const { user } = useSelector((state) => (state as RootState).userReducer)
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(()=>{
        getUserData();
        // getFairData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    async function getUserData() {
        try {
            const userResponse = await instance.get(`/auth/user`)
            dispatch(setUser(userResponse.data))

            //Set gallery access to the first if present
            if(userResponse.data.gallery_access.length) {
                dispatch(setSelectedGallery(userResponse.data.gallery_access[0]))

            }

            //Setup fairs that the user have access to
            dispatch(setFairs(userResponse.data.fairs))

            dispatch(setIsAuthenticated(true))
            setIsLoading(false);

            //in the background update user data
            if(userResponse.data.gallery_access.length) {
                updateUserData(userResponse.data.gallery_access[0])
            }

        } catch (error) {
            dispatch(setUser(defaultUser))
            dispatch(setIsAuthenticated(false))
            logout()
        }
    }

    async function updateUserData(selectedGallery:any) {
        // we find the section type in the exhibitor contract
        const sections:IDynamicObject = {};
        const artists:IDynamicObject = {};

        // set sections and artists
        for (const item of selectedGallery._extra.RevenueLineItems) {
            if (SECTION_TYPES[item.type_id] && item.sales_stage === CONTRACT_WON) {
                sections[item.eve_events_id + '-' + item.category_id] = SECTION_TYPES[item.type_id] || '';
                const artistResponse = await instance.get("/crm/artists", {
                    params: {
                        revenueLineId: item.id
                    }
                });
                artists[item.eve_events_id + '-' + item.category_id] = artistResponse ? artistResponse.data.records : []
            }
        }

        const locationsResponse = await instance.get("/crm/locations", {
            params: {
                gallery_id: selectedGallery.gallery_id
            }
        })
        const locations = locationsResponse ? locationsResponse.data.records : [];

        selectedGallery = {
            ...selectedGallery,
            section: sections,
            artists: artists,
            locations: locations,
        }

        dispatch(setSelectedGallery(selectedGallery))
    }

    function renderRoutes() {
        return (
            <Switch>
                <Route exact path="/">
                    <Redirect to="/dashboard" />
                </Route>
                {routes.filter((route:IRoute)=>{
                    return route.roles.includes(user?.role as ERoles);
                }).map((route: IRoute, key: number) => {
                    return (
                        <Route
                            key={key}
                            path={route.path}
                            exact={route.exact}
                            render={() => {
                                return <route.component />
                            }}
                        />
                    )
                })}
            </Switch>
        )
    }

    return (  
            <React.Fragment>
                <div id="layout-wrapper">
                    <Header />
                    <Sidebar />
                    {!isLoading ? <div className="main-content">{renderRoutes()}</div> : <Spinner animation="border" className="loader" />}
                </div>
                <footer>
                    <div>
                        <a href="/terms-and-policies/privacy-policy" target="_blank">Privacy Policy</a>
                        <a href="/terms-and-policies/terms-and-conditions" target="_blank">Terms And Conditions</a>
                        {/* <a href="/terms-and-policies/cookie-policy" target="_blank">Cookie Policy</a> */}
                        <p className="ot-sdk-show-settings" id="ot-sdk-btn">Manage My Cookies</p>
                        <a href="https://artnav.drb.services/" rel="noreferrer" target="_blank">© ARTNAV 2022</a>
                    </div>
                </footer>
            </React.Fragment>
    )
}
export default Layout
