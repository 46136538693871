import Cookies from 'universal-cookie';

/**
 * Default logout function.
 */
export default async function logout() {
    //Clear jwt cookie
    const cookies = new Cookies();
    cookies.remove('token',{path:'/'});
    window.location.replace('/login');
};