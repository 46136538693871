import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import RichTextEditor, { EditorValue } from "react-rte";
import { IModuleType } from "../../interfaces/fairs";
import placeholderImg from "../../assets/images/london.jpg";
import instance from "../../api/api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { Icon } from "@ailibs/feather-react-ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store"
import { setFairs } from "../../store/fairsSlice";

interface INewFairData {
    crm_id: string,
    name: string,
    description: string,
    deadline?: Date|null,
    image:{
        file:any
        name:string
        blob:string
    }
}

interface IProps {
    show:boolean
    setShow:Function
}

const default_fair =  {
    crm_id: '',
    name: '',
    moduls: [],
    description: '',
    image: {
        file:null,
        name:'',
        blob:''
    }
};

type GenericObject = { [key: string]: boolean };


export default function FairCreateModal(props:IProps) {
    const dispatch = useDispatch()
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [rteValue, setRteValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());
    const [fair, setFair] = useState<INewFairData>(default_fair);
    const [ modulesAvalible, setModulesAvalible ] = useState<GenericObject>({});
    const [ moduleTypes, setModuleTypes ] = useState<any[]>([])
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        getModuleTypes()
    }, [])

    async function getModuleTypes() {
        try {
            const response = await instance.get("/modultype");
            setModuleTypes(response.data);
            const default_modules:GenericObject = {};
            
            response.data.forEach((type:IModuleType) => {
                default_modules[type.name] = true;
            });

            setModulesAvalible(default_modules)
        }
        catch(error) {
            console.log('error', error)
        }
    }

    async function createFair(evt:any) {
        evt.preventDefault();

        const data = {
            ...fair,
            description:rteValue.toString("html"),
            image: await upladImage(),
            moduls: Object.entries(modulesAvalible).filter(([key, value]) => value).map(([key, value]) => {
                const type = moduleTypes.find(x => x.name === key);
                if(type) {
                    return(
                        {
                            modultype:type._id,
                            title:key,
                            description:"default description."
                        }
                    )
                }
            })
        };

        try {
            const response = await instance.post(`/fair`, data);
            toast.success("New fair created !");
            dispatch(setFairs([...fairs, response.data]))
           
            history.push("/fairs/" + response.data._id)
        }
        catch(err) {
            toast.error("Failed to create new Fair.");

        }
    }

    async function upladImage() {
        try {
            const formData = new FormData();
            formData.append("name", fair.image.name);
            formData.append("category", "page");
            formData.append("file", fair.image.file);
            const response = await  instance.post("file-upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            return response.data._id
        } catch (error) {
            toast.error("Photo could not be uploaded.");
        }
    }


    function handleFileChange(files: any) {
        if (files.length > 0) {

            setFair(prev => { return {...prev, image:{ file: files[0], name: files[0].name, blob: URL.createObjectURL(files[0])}} })
        }
    }

    function closeModal() {
        setRteValue(RichTextEditor.createEmptyValue());
        setFair(default_fair);
        props.setShow(false)
    }

    return(
        <Modal backdrop="static" dialogClassName="create-fair-modal" show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header>
                <Modal.Title>New Fair</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="form-body" onSubmit={createFair}>
                    <div className="image">
                        <h5>Cover image</h5>
                        <img alt="fair-cover" src={fair.image.blob.length ? fair.image.blob : placeholderImg} />
                        <input type="file" onChange={(e) => { handleFileChange(e.target.files) }} className="form-control small-input" accept="image/*" />
                    </div>
                    <div className="text" >
                        <h5>Name</h5>
                        <input required type="text" className="form-control small-input" value={fair.name} onChange={(e) => { setFair(prev => { return {...prev, name:e.target.value }}) }} />
                        <h5>Description</h5>
                        <RichTextEditor className="rte" value={rteValue} onChange={(value) => { setRteValue(value) } } />
                        <h5>Event ID</h5>
                        <input required type="text" className="form-control small-input" value={fair.crm_id} onChange={(e) => { setFair(prev => { return {...prev, crm_id:e.target.value }}) }} />
                        <h5>Deadline</h5>
                        <div className="datepicker-wrapper">
                            <ReactDatePicker className="form-control small-input" readOnly open={datePickerOpen} selected={fair.deadline} minDate={new Date()} value={fair.deadline ? fair.deadline.toLocaleDateString() : undefined} onChange={(date) => {setFair(prev => { return {...prev, deadline:date }}); setDatePickerOpen(false)}} />
                            <Icon name="calendar" onClick={() => setDatePickerOpen(!datePickerOpen)}/>
                        </div>
                    </div>
                    <div className="modules">
                        <h5>Modules</h5>
                        {Object.entries(modulesAvalible).map(([key, value],i) => {
                            return(
                                <Row key={i} keys={key}>
                                    <Col>{key}</Col>
                                    <Col><input type="checkbox" checked={value} onChange={(checked) => setModulesAvalible((prev:any) => {return {...prev, [key]: !prev[key]}})}/></Col>
                                </Row>
                            );
                        })}
                    </div>
                    <Button type="submit">Create</Button>
                    <Button variant="danger" onClick={closeModal}>Cancel</Button>
                </form>
            </Modal.Body>
        </Modal>
    );
}