import React, {useState} from "react";
import {Button, Col, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import {toast} from "react-toastify";
import instance from "../../api/api";
import {EPermissionSettings, IFair, IModule} from "../../interfaces/fairs";
import DeadlineExtensions from "./module-edit-tabs/deadline-extensions.component";
import {IEmailTemplate} from "../../interfaces/email";
import FairEmailSettings from "./module-edit-tabs/fair-email-settings.component";
import MetabaseDashboards from "./module-edit-tabs/metabase-dashboards.component";
import {PERMISSION_TYPES} from "./_helper/module_helper";
import { FormHelper } from "../../_helper/formHelper";


interface IProps {
    show:boolean
    onHide:Function
    module:IModule
    fairId:string
    crmId: string
    setFair: Function
    setSelectedModule: Function
    emailTemplates:IEmailTemplate[]
}

export default function FairModuleEdit(props:IProps) {

    const [title, setTitle] = useState(props.module.title);
    const [description, setDescription] = useState(props.module.description || "");
    const [isEnabled, setIsEnabled] = useState<boolean>(props.module.isEnabled || false);
    const [isOpen, setIsOpen] = useState<boolean>(props.module.isOpen || false);
    const [contractCategoryId, setContractCategoryId] = useState<string>(props.module.contract_category_id || "");
    const [permission, setPermission] = useState<string>(props.module.permission || EPermissionSettings.REVENUELINEITEM);
    const [defaultStatusFilter, setDefaultStatusFilter] = useState<string>(props.module.defaultStatusFilter || "");

    async function updateModule(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const updateData = {
                ...(title && title !== props.module.title && { title }),
                ...(description !== props.module.description && { description }),
                ...(isEnabled !== props.module.isEnabled && { isEnabled }),
                ...(isOpen !== props.module.isOpen && { isOpen }),
                ...(contractCategoryId !== props.module.contract_category_id && { contract_category_id: contractCategoryId }),
                ...(permission !== props.module.permission && { permission: permission }),
                ...(defaultStatusFilter !== props.module.defaultStatusFilter && { defaultStatusFilter: defaultStatusFilter }),
            }
            if (Object.keys(updateData).length) {
                const response = await instance.patch(`/fair/${props.fairId}/modul/${props.module._id}`, updateData)
                props.setFair(response.data)
                props.setSelectedModule((response.data as IFair).moduls.find((module) => module._id === props.module._id))
                toast.success("The module is updated")
            }
        } catch (error) {
            console.log("Failed to update module",error)
            toast.error("Failed to update module")
        }
    }

    return(
        <Modal size="lg" centered backdrop="static" dialogClassName="fair-module-edit-modal" show={props.show} onHide={() => props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>{props.module.title} Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="basic" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="basic" title="Basic Settings">
                            <Form onSubmit={(e:any) => updateModule(e)}>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label className={title && title !== props.module.title ? "changed-data" : ""}>Title</Form.Label>
                                    <Form.Control
                                        className={title && title !== props.module.title ? "changed-data-input" : ""}
                                        placeholder={props.module.title}
                                        onChange={(e:any) => setTitle(e.target.value)}
                                        value={title}
                                        required={true}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" as={Col} controlId="formGridEmail">
                                    <Form.Label className={isEnabled !== props.module.isEnabled ? "changed-data" : ""}>Status</Form.Label>
                                    <div className="form-check form-switch mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isEnabled}
                                            onChange={(e) => {
                                                setIsEnabled(e.target.checked)
                                            }}
                                        />
                                        <label className={`form-check-label ${isEnabled !== props.module.isEnabled ? "changed-data" : ""}`} htmlFor="customSwitch1">
                                            {isEnabled ? "Enabled" : "Disabled"}
                                        </label>
                                    </div>
                                    <div className="form-check form-switch mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isOpen}
                                            onChange={(e) => {
                                                setIsOpen(e.target.checked)
                                            }}
                                        />
                                        <label className={`form-check-label ${isOpen !== props.module.isOpen ? "changed-data" : ""}`} htmlFor="customSwitch1">
                                            {isOpen ? "Opened" : "Closed"}
                                        </label>
                                    </div>
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className={description && description !== props.module.description ? "changed-data" : ""}>Description</Form.Label>
                                <Form.Control
                                    className={description && description !== props.module.description ? "changed-data" : ""}
                                    as="textarea"
                                    rows={3}
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={description}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Contract Category ID</Form.Label>
                                <Form.Control
                                    className={contractCategoryId && contractCategoryId !== props.module.contract_category_id ? "changed-data" : ""}
                                    type="text"
                                    onChange={(e:any) => setContractCategoryId(e.target.value)}
                                    value={contractCategoryId}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Tab>
                    <Tab eventKey="deadline" title="Deadline Extensions">
                        <DeadlineExtensions module={props.module} fairId={props.fairId} crmId={props.crmId} setFair={props.setFair} setSelectedModule={props.setSelectedModule} />
                    </Tab>
                    <Tab eventKey="emails" title="Emails">
                        <FairEmailSettings module={props.module} fairId={props.fairId} crmId={props.crmId} setFair={props.setFair} setSelectedModule={props.setSelectedModule} emailTemplates={props.emailTemplates} />
                    </Tab>

                    <Tab eventKey="metabase" title="Reports">
                        <MetabaseDashboards moduleId={props.module._id} metabaseDashboards={props.module.metabase_dashboards} fairId={props.fairId} crmId={props.crmId} setFair={props.setFair} setSelectedModule={props.setSelectedModule} />
                    </Tab>

                    <Tab eventKey="permissions" title="Permissions">
                        <div className="module-permission-settings">
                            <Form onSubmit={(e:any) => updateModule(e)}>
                                <Form.Label>Permission Checking Type</Form.Label>
                                <Form.Select className="mb-3" value={permission} onChange={(e:any) => setPermission(e.currentTarget.value)}>
                                    <option value="">None</option>
                                    {Object.keys(PERMISSION_TYPES).map((key, index) => {
                                        const item:any = (PERMISSION_TYPES as any)[key];
                                        return <option value={item.value}>{item.display}</option>
                                    })}
                                </Form.Select>
                                <Button variant="primary" type="submit">
                                    Update
                                </Button>
                            </Form>
                        </div>
                    </Tab>
                    <Tab eventKey="submissions" title="Submissions">
                        <div className="module-permission-settings">
                            <Form onSubmit={(e:any) => updateModule(e)}>
                                <Form.Label>Default Submission Status Filter</Form.Label>
                                <Form.Select className="mb-3" value={defaultStatusFilter} onChange={(e:any) => setDefaultStatusFilter(e.currentTarget.value)}>
                                    <option value="">Any</option>
                                    {Object.keys(FormHelper.status).map((key, index) => {
                                        const status = FormHelper.status[key];
                                        return <option value={status.code}>{status.name}</option>
                                    })}
                                </Form.Select>
                                <Button variant="primary" type="submit">
                                    Update
                                </Button>
                            </Form>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    )
}