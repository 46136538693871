import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import fl22Bg from "../../assets/images/artnav.png";
import fm22Bg from "../../assets/images/artnav.png";
import fs22Bg from "../../assets/images/artnav.png";

const items = [
    {
        id: 1,
        src: fl22Bg,
    },
    {
        id: 2,
        src: fm22Bg,
    },
    {
        id: 3,
        src: fs22Bg,
    },
];

const LoginCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map(item => {
        return (
        <Carousel.Item
            key={item.id}
        >
            <div className="carousel-item active auth-bg d-flex" style={{
                backgroundImage: `url(${item.src})`
            }}>
            </div>
        </Carousel.Item>
        );
    });

    return (
        <div className="col-xxl-9 col-lg-8 col-md-7">
            <div className="row justify-content-center align-items-center">
                <div className="col p-0">
                    <div className="p-0">
                        <div
                            id="reviewcarouselIndicators"
                            className="carousel slide"
                            data-bs-ride="carousel"
                        >
                        <Carousel fade interval={8000} controls={false} indicators={false} pause={false}>
                            {slides}
                        </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginCarousel;
