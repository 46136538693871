import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface IProps{
  onSuccess:any
}

export default function CheckoutForm(props:IProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string|null|undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useSelector((state) => (state as RootState).userReducer);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
      setIsLoading(false);
    });
  }, [stripe]);

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    await stripe.confirmPayment({
      elements,
      redirect:"if_required",
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_API_URL}/checkout?success=true`,
        receipt_email: user.email,
        payment_method_data:{
            billing_details:{
                name: user.firstname+" "+user.lastname,
                // TODO: fix this later (redux userdata needs the email address of fop)
                email: user.email,
            }
        }
      },
    }).then((res)=>{
      if(!res.error){
        console.log("successfull payment response",res);
        if(res.paymentIntent.status === "succeeded"){
          props.onSuccess(res.paymentIntent.id);
        }
      }else{
        let error = res.error;
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);

      }
    });

    
  };

  return (
    <div className="checkout-form">
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    </div>
  );
}
