import React from "react"
import { useSelector } from "react-redux"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import Layout from "../components/layout/layout.component"
import Login from "./authentication/login.page"
import Registration from "./authentication/registration.page"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import UserNomination from "./user-nomination/user-nomination.page"
import { RootState } from "../store/store"
import TermsAndConditions from "./authentication/terms-and-conditions.page"

const Application: React.FunctionComponent<{}> = (props) => {
    
    const { isAuthenticated } = useSelector((state) => (state as RootState).userReducer)


    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path="/login">{isAuthenticated ? <Redirect to="/" /> : <Login />}</Route>
                    <Route path="/registration">{isAuthenticated ? <Redirect to="/" /> : <Registration />}</Route>
                    <Route path="/forgot-password">{isAuthenticated ? <Redirect to="/" /> : <Registration />}</Route>
                    <Route path="/terms-and-policies/:type">{ <TermsAndConditions />}</Route>
                    <Route path="/usernomination/:fairUrl">  <UserNomination /> </Route>
                    <Route path="/">{isAuthenticated ? <Layout /> : <Redirect to="/login" />}</Route>
                    <Redirect to={isAuthenticated ? "/" : "/login"} />
                </Switch>
            </BrowserRouter>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick pauseOnHover />
        </div>
    )
}

export default Application
