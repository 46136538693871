import React, { useState, useEffect } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import instance from "../../api/api"
import { toast } from "react-toastify"
import DataTable from "react-data-table-component"
import moment from "moment"
import { Link } from "react-router-dom"

interface IProps {
    show: boolean
    setShow: Function
    galleryId: string
    galleryName: string
}

interface IContact {
    id: string
    name: string
    first_name: string
    last_name: string
    personal_email_address_c: string
    business_email_address_c: string
    isInFOP: boolean
    selectedEmail?: string
    last_login?: Date
}

export default function ContactsModal(props: IProps) {
    const [contacts, setContacts] = useState<IContact[]>([])
    const [dataLoading, setDataLoading] = useState(false)

    const [invitationEmailExists, setInvitationEmailExists] = useState(false)

    useEffect(() => {
        checkInvitationEmail()
    }, [])
    

    useEffect(() => {
        props.show && props.galleryId && getContacts()
    }, [props.galleryId, props.show])

    async function getContacts() {
        setDataLoading(true)
        try {
            const response = await instance.get(`/crm/contacts/${props.galleryId}`)
            const contactsWithSelectedEmail = response.data.map((contact: IContact) => {
                return { ...contact, selectedEmail: contact.business_email_address_c || contact.personal_email_address_c }
            })

            setContacts(contactsWithSelectedEmail)
        } catch (error) {
            toast.error("Failed to get contacts of the gallery")
        }
        setDataLoading(false)
    }

    async function checkInvitationEmail() {
        try {
            const response = await instance.get(`/settings`)
            if (response.data.invitationEmail) {
                setInvitationEmailExists(true)
            } else {
                setInvitationEmailExists(false)
            }

        } catch (error) {
            toast.error("Failed to check the invitation email of the settings")
        }
    }

    async function handleInvitation(contactId: string) {
        try {
            const contact: IContact | undefined = contacts.find((contact) => contact.id === contactId)
            const userData = {
                firstname: contact?.first_name,
                lastname: contact?.last_name,
                email: contact?.selectedEmail,
                contactId: contactId,
                galleryName: props.galleryName,
            }
            await instance.post(`/auth/register-account`, { userData })
            setContacts((prev) => prev.map((contact) => (contact.id === contactId ? { ...contact, isInFOP: true } : contact)))
            toast.success("Contact is invited")
        } catch (error) {
            toast.error("Failed to invite contact")
        }
    }

    function setSelectedEmail(contactId: string, email: string) {
        setContacts((prev) => prev.map((contact) => (contact.id === contactId ? { ...contact, selectedEmail: email } : contact)))
    }

    function closeModal() {
        props.setShow(false)
    }

    const columns = [
        {
            name: "Name",
            selector: (row: IContact) => row.name,
            sortable: true,
        },
        {
            name: "Invitation email",
            grow: 2,
            cell: (row: IContact) => {
                return (
                    <div className="selected-email">
                        <p>{row.selectedEmail}</p>
                        {row.business_email_address_c || row.personal_email_address_c ? (
                            <Form.Select value={row.selectedEmail} onChange={(e) => setSelectedEmail(row.id, e.currentTarget.value)}>
                                {row.business_email_address_c ? (
                                    <option value={row.business_email_address_c}>
                                        Business
                                    </option>
                                ) : (
                                    <></>
                                )}
                                {row.personal_email_address_c ? (
                                    <option value={row.personal_email_address_c}>
                                        Personal
                                    </option>
                                ) : (
                                    <></>
                                )}
                            </Form.Select>
                        ) : (
                            <span>This contact has no email address in the database</span>
                        )}
                    </div>
                )
            },
            sortable: true,
        },
        {
            name: "Last Login",
            selector: (row: IContact) => row.last_login ? moment(row.last_login).format("DD MMM YYYY") : "Never",
            sortable: true,
        },
        {
            name: "Ivitation",
            cell: (row: IContact) => {
                return (
                    <Button
                        disabled={!row.selectedEmail || !invitationEmailExists}
                        onClick={() => {
                            handleInvitation(row.id)
                        }}
                        variant={row.isInFOP ? "light" : "info"}
                        className="show-contacts-btn">
                        {row.isInFOP ? "Invite again" : "Invite"}
                    </Button>
                )
            },
            sortable: false,
            right: true,
        },
    ]

    return (
        <Modal size="xl" backdrop="static" dialogClassName="contacts-modal" show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header>
                <Modal.Title>{props.galleryName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={invitationEmailExists ? "info" : "warning"}>Set the invitation email in the <Link to="/settings">Settings</Link></p>
                <DataTable columns={columns} data={contacts} pagination progressPending={dataLoading} />
                <div className="btn-container">
                    <Button onClick={closeModal}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
