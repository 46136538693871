import React, { useState } from "react"
import { IFair, IMetabaseDashboard } from "../../../interfaces/fairs"
import DataTable from "react-data-table-component"
import { Icon } from "@ailibs/feather-react-ts"
import { toast } from "react-toastify"
import instance from "../../../api/api"
import { Form, Row, Col, Button } from "react-bootstrap"

interface IProps {
    moduleId: string
    metabaseDashboards: IMetabaseDashboard[]
    fairId: string
    crmId: string
    setFair: Function
    setSelectedModule: Function
}

function MetabaseDashboards(props: IProps) {
    const [newDashboard, setNewDashboard] = useState({
        dashboard_name: "",
        dashboard_number: 0,
    })

    const [selectedDashboard, setSelectedDashboard] = useState<IMetabaseDashboard | undefined>()

    /**
     * delete dashboard
     * @param dashboardId
     */
    async function deleteDashboard(dashboardId: string) {
        try {
            const response = await instance.delete(`/report`, {
                data: {
                    fairId: props.fairId,
                    moduleId: props.moduleId,
                    dashboardId: dashboardId,
                },
            })
            props.setFair(response.data)
            props.setSelectedModule((response.data as IFair).moduls.find((module) => module._id === props.moduleId))
            toast.success("The dashboard is deleted")
        } catch (error) {
            toast.error("Failed to delete dashboard")
        }
    }

    /**
     * create dashboard
     * @param e
     * @returns
     */
    async function createDashboard(e: React.FormEvent<HTMLFormElement>) {
        try {
            e.preventDefault()
            if (!newDashboard.dashboard_name) {
                toast.error("Name is required")
                return
            }

            if (newDashboard.dashboard_number < 1) {
                toast.error("Number is required")
                return
            }

            const body = {
                fairId: props.fairId,
                moduleId: props.moduleId,
                dashboardName: newDashboard.dashboard_name,
                dashboardNumber: newDashboard.dashboard_number,
            }

            const response = await instance.post(`/report`, body)
            props.setFair(response.data)
            props.setSelectedModule((response.data as IFair).moduls.find((module) => module._id === props.moduleId))
            toast.success("The dashboard is created")
            setNewDashboard({
                dashboard_name: "",
                dashboard_number: 0,
            })
        } catch (error) {
            toast.error("Failed to create dashboard")
        }
    }

    /**
     * update one dashboard
     * @param newDashboard
     * @returns
     */
    async function updateDashboard(newDashboard: IMetabaseDashboard) {
        try {
            if (!newDashboard.dashboard_name) {
                toast.error("Name is required")
                return
            }

            if (newDashboard.dashboard_number < 1) {
                toast.error("Number is required")
                return
            }

            const body = {
                fairId: props.fairId,
                moduleId: props.moduleId,
                dashboardId: newDashboard._id,
                updateData: {
                    dashboard_name: newDashboard.dashboard_name,
                    dashboard_number: newDashboard.dashboard_number,
                },
            }

            const response = await instance.patch(`/report`, body)
            props.setFair(response.data)
            props.setSelectedModule((response.data as IFair).moduls.find((module) => module._id === props.moduleId))
            toast.success("The dashboard is updated")
            setSelectedDashboard(undefined)
        } catch (error) {
            toast.error("Failed to update dashboard")
        }
    }

    /**
     * columns of the current dashboards
     */
    const columns = [
        {
            name: "Name",
            selector: (row: IMetabaseDashboard) => row.dashboard_name,
            cell: (row: IMetabaseDashboard) => {
                return selectedDashboard?._id === row._id ? (
                    <Form.Control
                        type="text"
                        value={selectedDashboard.dashboard_name}
                        placeholder="Dashboard name"
                        onChange={(evt: any) => setSelectedDashboard({ ...selectedDashboard, dashboard_name: evt.target.value })}
                    />
                ) : (
                    row.dashboard_name
                )
            },
            sortable: true,
        },
        {
            name: "Number",
            selector: (row: IMetabaseDashboard) => row.dashboard_number,
            cell: (row: IMetabaseDashboard) => {
                return selectedDashboard?._id === row._id ? (
                    <Form.Control
                        min={1}
                        type="number"
                        value={selectedDashboard.dashboard_number}
                        placeholder="Dashboard number"
                        onChange={(evt: any) => setSelectedDashboard({ ...selectedDashboard, dashboard_number: evt.target.value })}
                    />
                ) : (
                    row.dashboard_number
                )
            },
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row: IMetabaseDashboard) => {
                return selectedDashboard?._id === row._id ? (
                    <div className="row-actions">
                        <Icon className="action-icon save" onClick={() => updateDashboard(selectedDashboard)} name="save" size="14" />
                        <Icon className="action-icon close-icon" onClick={() => setSelectedDashboard(undefined)} name="x" size="14" />
                    </div>
                ) : (
                    <div className="row-actions">
                        <Icon className="action-icon delete" onClick={() => deleteDashboard(row._id)} name="trash-2" size="14" />
                        <Icon className="action-icon edit" onClick={() => setSelectedDashboard(row)} name="edit-2" size="14" />
                    </div>
                )
            },
            sortable: false,
            right: true,
        },
    ]
    return (
        <div className="metabase-dashboards">
            <div className="section">
                <h6>Add new dashboard</h6>
                <Form onSubmit={(e) => createDashboard(e)}>
                    <Row>
                        <Col xs={7} md={7}>
                            <Form.Group>
                                <Form.Label className="name">Dashboard name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newDashboard.dashboard_name}
                                    placeholder="Dashboard name"
                                    onChange={(evt: any) => setNewDashboard({ ...newDashboard, dashboard_name: evt.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={3} md={3}>
                            <Form.Group>
                                <Form.Label className="name">Number</Form.Label>
                                <Form.Control
                                    min={1}
                                    type="number"
                                    value={newDashboard.dashboard_number}
                                    placeholder="Dashboard number"
                                    onChange={(evt: any) => setNewDashboard({ ...newDashboard, dashboard_number: evt.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2} md={2} className="submit-btn-container">
                            <Button type="submit" className="create-button">
                                <Icon name="plus" size="14" color="white" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="section">
                <h6>Dashboards of the module</h6>
                <DataTable columns={columns} data={props.metabaseDashboards || ([] as IMetabaseDashboard[])} pagination />
            </div>
        </div>
    )
}

export default MetabaseDashboards
