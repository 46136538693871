import React, { useState, useEffect } from "react"
import { Row, Col, Container, Form, Input, Spinner } from "reactstrap"
import { Link, useLocation, withRouter } from "react-router-dom"
import instance from "../../api/api"
import LoginCarousel from "../../components/authentication/carousel.component"

interface IRegistrationUserData {
    name: string
    gallery: string
    token: string
    type: string
    isActivated: boolean
}

const Registration: React.FunctionComponent<any> = (props) => {
    const search = useLocation().search

    const [userData, setuserData] = useState<IRegistrationUserData>({
        name: "",
        gallery: "",
        token: "",
        type: "",
        isActivated: false,
    })

    const [errorMessage, setErrorMessage] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [isTokenFalse, setIsTokenFalse] = useState(false)
    const [updateSuccess, setupdateSuccess] = useState(false)

    // terms and conditions checkboxes
    const [tacCheckboxes, setTacCheckboxes] = useState({
        termsAndConditions: false,
        privacyPolicy: false,
        // cookiePolicy: false,
    })

    useEffect(() => {
        validateToken()
    }, [search])

    async function validateToken() {
        setIsLoading(true)
        try {
            const token = new URLSearchParams(search).get("action")
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            const response = await instance.get("/auth/validate-registration", config)
            setuserData(response.data)
        } catch (error) {
            setIsTokenFalse(true)
        }
        setIsLoading(false)
    }

    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")

    async function handleRegistration(evt: React.FormEvent) {
        evt.preventDefault()
        try {
            if (userData.type === "register") {
                let uncheckedTermsAndCond = false
                Object.values(tacCheckboxes).forEach((value) => {
                    if (!value) {
                        uncheckedTermsAndCond = true
                    }
                })
                if (uncheckedTermsAndCond) {
                    setErrorMessage("You have to agree to the Terms and Conditions, Privacy Policy and Cookie Policy.")
                    setTimeout(() => {
                        setErrorMessage("")
                    }, 5000)
                    return
                }
            }
            if (password === confirmPassword) {
                const config = {
                    headers: { Authorization: `Bearer ${userData.token}` },
                }
                await instance.patch("/auth/password-change", { password, confirmPassword }, config)
                setupdateSuccess(true)
            } else {
                setErrorMessage("The password and confirm password do not match.")
                setTimeout(() => {
                    setErrorMessage("")
                }, 5000)
            }
        } catch (error) {
            console.log("Failed to get userdata by token")
            setErrorMessage("Failed to update the password.")
            setTimeout(() => {
                setErrorMessage("")
            }, 5000)
        }
    }

    function renderFormSection() {
        if (isLoading) {
            return <Spinner animation="border" className="loader" />
        }

        if (isTokenFalse) {
            return <p className="error-message text-center">Something went wrong. You do not have access to the page or your invitation is expired.</p>
        }

        if (updateSuccess) {
            return (
                <div className="mt-5 text-center">
                    <p className="text-muted mb-0">{userData.type === "register" ? "You have successfully activated your account." : "You have successfully changed your password."}</p>
                    <Link to="/login" className="text-primary fw-semibold">
                        Login
                    </Link>
                </div>
            )
        }

        if (userData.token) {
            return (
                <Form className="custom-form mt-4 pt-2" onSubmit={handleRegistration}>
                    {userData.name ? (
                        <div className="mb-3">
                            <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                    <label className="form-label">Name</label>
                                </div>
                            </div>

                            <div className="mb-3">
                                <Input disabled name="name" type="text" className="form-control" value={userData.name} />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {userData.gallery && 0 ? (
                        <div className="mb-3">
                            <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                    <label className="form-label">Company</label>
                                </div>
                            </div>

                            <div className="mb-3">
                                <Input disabled name="gallery" type="text" className="form-control" value={userData.gallery} />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="mb-3">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <label className="form-label">Password</label>
                            </div>
                        </div>

                        <div className="mb-3">
                            <Input name="password" onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" required placeholder="Enter Password" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <label className="form-label">Password Again</label>
                            </div>
                        </div>

                        <div className="mb-3">
                            <Input name="passwordAgain" onChange={(e) => setConfirmPassword(e.target.value)} type="password" className="form-control" required placeholder="Confirm Password" />
                        </div>
                    </div>
                    {userData.type === "register" ? (
                        <div className="row mb-4">
                            <div className="col">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="remember-check"
                                        checked={tacCheckboxes.termsAndConditions}
                                        onChange={(e) => {
                                            setTacCheckboxes({ ...tacCheckboxes, termsAndConditions: e.target.checked })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="remember-check">
                                        I have read and agreed to the{" "}
                                        <Link target="_blank" to="/terms-and-policies/terms-and-conditions" className="text-primary fw-semibold">
                                            Terms and Conditions
                                        </Link>
                                    </label>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="row mb-4">
                        <div className="col">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                    checked={tacCheckboxes.privacyPolicy}
                                    onChange={(e) => {
                                        setTacCheckboxes({ ...tacCheckboxes, privacyPolicy: e.target.checked })
                                    }}
                                />
                                <label className="form-check-label" htmlFor="remember-check">
                                    I have read and agreed to the{" "}
                                    <Link target="_blank" to="/terms-and-policies/privacy-policy" className="text-primary fw-semibold">
                                        Privacy Policy
                                    </Link>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mb-4">
                        <div className="col">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                    checked={tacCheckboxes.cookiePolicy}
                                    onChange={(e) => {
                                        setTacCheckboxes({ ...tacCheckboxes, cookiePolicy: e.target.checked })
                                    }}
                                />
                                 <label className="form-check-label" htmlFor="remember-check">
                                    I have read and agreed to the{" "}
                                    <Link target="_blank" to="/terms-and-policies/cookie-policy" className="text-primary fw-semibold">
                                        Cookie Policy
                                    </Link>
                                </label>
                            </div>
                        </div>
                    </div> */}
                    <div className="mb-3">
                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">
                            {userData.type === " register" ? "Activate" : "Submit"}
                        </button>
                    </div>
                    <p className="error-message">{errorMessage}</p>
                </Form>
            )
        }
    }

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0" style={{overflowX:"hidden"}}>
                        <Col lg={4} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <span className="logo-txt">FRIEZE PORTAL</span>
                                            </Link>
                                        </div>
                                        <div className="auth-content">
                                            {isTokenFalse ? (
                                                <></>
                                            ) : (
                                                <div className="text-center">
                                                    <h5 className="mb-0">{userData.type === "register" ? "Activate Account" : "Password Change"}</h5>
                                                </div>
                                            )}

                                            {userData.type !== "register" && userData.isActivated ? <p className="error-message text-center">You already have activated account.</p> : <></>}

                                            {renderFormSection()}

                                            {updateSuccess || userData.type === "forgot-password" ? (
                                                <></>
                                            ) : (
                                                <div className="mt-5 text-center">
                                                    <p className="text-muted mb-0">
                                                        Already have an account?{" "}
                                                        <Link to="/login" className="text-primary fw-semibold">
                                                            {" "}
                                                            Sign In{" "}
                                                        </Link>{" "}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <LoginCarousel />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Registration)
