import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import instance from "../../api/api"
import { IGallerySubmission, IMainPage, IModule, ISubPage, submission_types } from "../../interfaces/fairs"
import parse from "html-react-parser"
import RenderSurvey from "../../components/display-survey.component"
import { useDispatch } from "react-redux"
import { ERoles } from "../../interfaces/authentication"
import { Button, Spinner, ListGroup, Modal } from "react-bootstrap"
import { Icon } from "@ailibs/feather-react-ts"
import UniqueComponentChecker from "../../components/unqiue-page-components/unique-component-checker.component"
import SubmissionTable from "../../components/fair/nomination-submissions.component";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { setPageData } from "../../store/pageSlice"
import { isAdminAccess } from "../../components/fair/_helper/module_helper"

function ModulDetails() {

    const dispatch = useDispatch();
    const history = useHistory();
    
    const { user } = useSelector((state) => (state as RootState).userReducer)
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)

    const { id, moduleId, mainpageid, subpageid } = useParams<{ id: string; moduleId: string; mainpageid?: string; subpageid?: string }>()

    const [ loading, setLoading ] = useState(true);
    const [ module, setModule ] = useState<IModule>()
    const [ activePage, setActivePage ] = useState<ISubPage | IMainPage | undefined>(undefined)
    const [ gallerySubmissions, setGallerySubmissions] = useState<IGallerySubmission[]>([]);
    const [ submissionsModalOpen, setSubmissionsModalOpen ] = useState<boolean>(false);
    const [ submissionsModalKey, setSubmissionsModalKey ] = useState<string>("");
    const [ alreadyRenderedSurveys, setAlreadyRenderedSurveys ] = useState<string[]>([]);

    useEffect(() => {
        fetchModule()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, moduleId])

    useEffect(() => {
        getActiveSubPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [module, moduleId, mainpageid, subpageid])

    useEffect(() => {
        getGallerySubmissions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, mainpageid, subpageid])
    
    async function fetchModule() {
        setLoading(true);
        try {
            const response = await instance.get(`/fair/modul/${moduleId}`)
            if(!response.data?.isEnabled && !isAdminAccess(user, selectedGallery)){
                history.push("/dashboard");
            }
            setModule(response.data)
            setLoading(false);
        } catch (error) {
            // TODO: handle error
            console.log(error)
            setLoading(false);
        }
    }

    async function getGallerySubmissions() {
        setLoading(true);
        try {
            const response = await instance.get(`/gallery-submission`, {
                params: {
                    data: {
                        fair:id,
                        gallery_id:selectedGallery.gallery_id,
                        modul_id:moduleId
                    }
                }
            })
            if (response.data.length) {
                let submissions = response.data;
                submissions = submissions.sort((a: IGallerySubmission, b: IGallerySubmission) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
                setGallerySubmissions(submissions)
            }
            setLoading(false);
        } catch (error) { 
            setLoading(false); 
        }
    }

    function getActiveSubPage() {
        setLoading(true);
        if (!module || !mainpageid) {
            setLoading(false);
            return
        }
        // find the main page and subpage by ids from the url
        const mainPage = module.main_pages.find((mainPage) => mainPage._id === mainpageid) || module.main_pages[0]
        const subpage = subpageid ? mainPage?.sub_pages.find((subpage) => subpage._id === subpageid) || mainPage?.sub_pages[0] : undefined

        if((!mainPage.isEnabled || (subpage && !subpage.isEnabled)) && !isAdminAccess(user, selectedGallery)){
            history.push("/dashboard");
        }
        // set activePage
        if (!subpage) {
            mainPage ? setActivePage(mainPage) : setActivePage(undefined)
        } else {
            setActivePage(subpage)
        }
        // set page title and breadcrumbs of the header
        dispatch(
            setPageData({
                name: generatePageTitle(mainPage, subpage),
                firstBreadCrumbName: module?.fairName || "",
                secondBreadCrumbName: module?.title || "",
                thirdBreadCrumbName: mainPage ? mainPage.title : ""
            })
        )
        setLoading(false);
    }

    function generatePageTitle(mainPage?: IMainPage, subpage?: ISubPage) {
        if (subpage)
            return subpage.title;
        else if (mainPage)
            return mainPage.title;
        else
            return "";
    }

    function handleEditClick() {
        if (!module?.main_pages.length)
            history.push(`/creator/${id}/${moduleId}`);
        else if (subpageid)
            history.push(`/creator/${id}/${moduleId}/${mainpageid}/${subpageid}`);
        else
            history.push(`/creator/${id}/${moduleId}/${mainpageid}`);
    }

    function getModuleType() {
        if(module) {
            if(module.modultype.name.toLowerCase().includes("accreditation")) {
                return submission_types.ACCREDITATION
            }
            else if(module.modultype.name.toLowerCase().includes("application")) {
                return submission_types.APPLICATION
            }
            else if(module.modultype.name.toLowerCase().includes("listing")) {
                return submission_types.LISTING
            }
            else {
                return submission_types.MANUAL

            }
        }
        else {
            //TODO: HORRIBLE TYPESCRIPT CODES
            return submission_types.ACCREDITATION
        }
    }

    /**
     * check the readonly status of the form for render survey
     */
    function checkReadonly(): boolean {
        // TODO: update this if the gallery of the user is selectable
        const extensioOfCurrentGallery = module?.deadline_extensions?.find(extension => extension.gallery_id === selectedGallery.gallery_id)
        // if the gallery has deadline extension: return false (the form is not readonly)
        if (extensioOfCurrentGallery && (extensioOfCurrentGallery.for_all_pages !==null || (activePage?._id && extensioOfCurrentGallery.pages.find(p=>p.pageId === activePage._id)!==undefined ))) {
            return false
        }else if(!activePage?.isOpen || !module?.isOpen) {
            // if the module is disabled (=closed) or the page is closed: return true (the form is readonly)
            return true
        }
        // default: the form is not readonly
        return false
    }

    let addedSurveys:string[] = [];

    return (
        <div className="content-wrapper">
            {!loading ? <>

                {isAdminAccess(user, selectedGallery) && (
                    <div>
                        <Button className="btn btn-primary" onClick={handleEditClick}>
                            <Icon name="edit-2" />
                            <span>{module && module.main_pages[0] ? "Edit Page" : "Edit Module"}</span>
                        </Button>
                    </div>
                )}

                {module && module.main_pages[0] && activePage ? <div className="module-details">
                    <div className="richtext">{parse(activePage?.markdown || "", {
                        replace: (domNode:any) => {
                            if(domNode.data){
                                domNode.data = domNode.data.replace("{{storage_url}}",process.env.REACT_APP_API_URL);
                            }
                            if (domNode.name === "img") {
                                if (domNode.attribs.width) {
                                    domNode.attribs.width = domNode.attribs.width + "%";
                                } else {
                                    domNode.attribs.width = "100%";
                                }
                            }
                            
                            if (new RegExp('{{(survey_.*?)}}').test(domNode.data)) {
                                const strippedCode = domNode.data.substring(2, domNode.data.length-2).replace("survey_", "");
                                if(strippedCode.length) {
                                    const survey = activePage.surveys.find(x => x._id === strippedCode);
                                    if(survey) {
                                        addedSurveys.push(survey._id)
                                        return(
                                            <div className="inline-survey">
                                                {isAdminAccess(user, selectedGallery) &&
                                                <div>
                                                    <div className="btn-container">
                                                        <Button onClick={() => {setSubmissionsModalOpen(true); setSubmissionsModalKey(survey._id) }}>View Submissions</Button>
                                                    </div>
                                                    <Modal className="form-submissions-modal" show={submissionsModalOpen && submissionsModalKey === survey._id} centered onHide={() => {setSubmissionsModalOpen(false); setSubmissionsModalKey("")}} key={survey._id}>
                                                        <Modal.Header closeButton>
                                                            <h5>Submissions for Form</h5>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <SubmissionTable fair_id={id} module_id={module._id} submission_type={getModuleType()} form_id={survey._id}/>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>}
                                                <RenderSurvey contractCategoryId={module?.contract_category_id as string} readonly={checkReadonly()} gallery_name={isAdminAccess(user, selectedGallery) ? "" : selectedGallery.gallery_name} gallery_id={isAdminAccess(user, selectedGallery) ? "" : selectedGallery.gallery_id} survey={survey} updateSubmissions={setGallerySubmissions} template={isAdminAccess(user, selectedGallery)} submissions={gallerySubmissions} getSubmissions={getGallerySubmissions}/>
                                            </div>
                                        )
                                    }

                                }
                            }
                            //Test text for unique components
                            if (new RegExp('{{(.*?)}}').test(domNode.data)) {
                                return (
                                    <UniqueComponentChecker fairId={id} module={module} code={domNode.data} submissions={gallerySubmissions} page={activePage} getSubmissions={getGallerySubmissions}/>
                                )
                            }
                        },
                })}</div>
                

                {(activePage && activePage.surveys?.length) ?
                    activePage.surveys.filter(x => !addedSurveys.includes(x._id)).map(survey => (
                            !survey.deleted ?
                            <div key={survey._id}>
                                {isAdminAccess(user, selectedGallery) &&
                                <div>
                                    <div className="btn-container">
                                        <Button onClick={() => {setSubmissionsModalOpen(true); setSubmissionsModalKey(survey._id) }}>View Submissions</Button>
                                    </div>
                                    <Modal className="form-submissions-modal" show={submissionsModalOpen && submissionsModalKey === survey._id} centered onHide={() => {setSubmissionsModalOpen(false); setSubmissionsModalKey("")}} key={survey._id}>
                                        <Modal.Header closeButton>
                                            <h5>Submissions for Form</h5>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <SubmissionTable fair_id={id} module_id={module._id} submission_type={getModuleType()} form_id={survey._id}/>
                                        </Modal.Body>
                                    </Modal>
                                    </div>}
                                <RenderSurvey  contractCategoryId={module?.contract_category_id as string} readonly={checkReadonly()} gallery_name={isAdminAccess(user, selectedGallery) ? "" : selectedGallery.gallery_name} gallery_id={isAdminAccess(user, selectedGallery) ? "" : selectedGallery.gallery_id} survey={survey} updateSubmissions={setGallerySubmissions} template={isAdminAccess(user, selectedGallery)} submissions={gallerySubmissions}getSubmissions={getGallerySubmissions}/>

                            </div> : null)
                    ) : null}

                {(activePage && activePage.files?.length) ?
                    <div className="page-file-list">
                        <h4>Files</h4>
                        <ListGroup>
                        {activePage.files.map(file => {
                        return (
                            <ListGroup.Item className="file" key={file._id}>                       
                                <a href={`${process.env.REACT_APP_API_URL}/${file.url}`} rel="noreferrer" target="_blank" download> <Icon name="file-text" size={"18px"}/>     <span>{file.name}</span></a>
                            </ListGroup.Item>
                        )})}</ListGroup>
                    </div> : null}
                </div>
                    : <div className="no-content-container">
                        <h4>No content yet!</h4>
                        {isAdminAccess(user, selectedGallery) && <p>Start adding pages to this Module and editing their content by clicking on the <span><Icon
                            name="edit-2" size="17px" /> Edit Module</span> button. </p>}
                    </div>}
            </> : <Spinner animation="border" className="loader" />}
        </div>
    )
}

export default ModulDetails
