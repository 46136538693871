import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import ProfileMenu from "./profile-menu.component"
import { Breadcrumb, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import logo from "../../assets/images/logo.png"
import { RootState } from "../../store/store"
import { Icon } from '@ailibs/feather-react-ts';
import { ERoles } from "../../interfaces/authentication"
import { isAdminAccess } from "../fair/_helper/module_helper"
import { default_gallery, setSelectedGallery } from "../../store/userSlice"

export default function Header(props: any) {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // mobile screen: the app starts with hidden sidedbar
        if (window.screen.width < 1024) {            
            document.body.classList.add("sidebar-enable")
            document.body.setAttribute("data-sidebar-size", "sm")
        }
    }, []);

    const { name, firstBreadCrumbName, secondBreadCrumbName, thirdBreadCrumbName } = useSelector((state) => (state as RootState).pageReducer)
    const { user, selectedGallery } = useSelector((state) => (state as RootState).userReducer)

    function tToggle() {
        var body = document.body
        if (!body.classList.contains("sidebar-enable")) {
            body.classList.add("sidebar-enable")
            document.body.setAttribute("data-sidebar-size", "sm")
        } else {
            body.classList.remove("sidebar-enable")
            document.body.setAttribute("data-sidebar-size", "lg")
        }
    }

    const logOutFromAccount = () => {
        dispatch(setSelectedGallery(default_gallery));
        history.push("/");
    }

    return (
        <React.Fragment>
            <div id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex navigation-wrapper">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/dashboard" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img alt="frieze-sm" src={logo} width="30" />
                                    </span>
                                    <span className="logo-lg">
                                        <span className="logo-txt">ARTNAV PORTAL</span>
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={() => {
                                    tToggle()
                                }}
                                type="button"
                                className="btn btn-sm px-3 font-size-16 header-item"
                                id="vertical-menu-btn">
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                        </div>
                        <div className="header-breadcrumb">
                            <h4>{name}</h4>
                            <Breadcrumb>
                                {firstBreadCrumbName ? (
                                    <Breadcrumb.Item href="#">
                                        {firstBreadCrumbName}
                                    </Breadcrumb.Item>
                                ) : (
                                    <></>
                                )}
                                {secondBreadCrumbName ? (
                                    <Breadcrumb.Item href="#">
                                       {secondBreadCrumbName}
                                    </Breadcrumb.Item>
                                ) : (
                                    <></>
                                )}
                                {thirdBreadCrumbName ? (
                                    <Breadcrumb.Item href="#">
                                       {thirdBreadCrumbName}
                                    </Breadcrumb.Item>
                                ) : (
                                    <></>
                                )}
                            </Breadcrumb>
                        </div>
                        {isAdminAccess(user, selectedGallery) ? <div className="settings-container" >
                            <Link
                                to="/settings"
                                className="settings-link btn header-item"
                            >
                                <Icon name="settings" className="icon-lg" />
                            </Link>
                        </div> : <></>}
                        {user.role === ERoles.SUPERADMIN && selectedGallery.gallery_id ? <div className="account-logout-container" >
                            <Button title="Log out from Account" onClick={logOutFromAccount}><Icon name="log-out" size="22px" /></Button>
                        </div> : <></>}
                    </div>
                    {user._id ? 
                        <div className="d-flex">
                            <ProfileMenu userData={props.userData} />
                        </div>
                    : null}
                </div>
            </div>
        </React.Fragment>
    )
}
