import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IGallerySubmission, IMainPage, IModule, ISubPage } from '../../interfaces/fairs';
import { RootState } from '../../store/store';
import {getGalleryStandNumber} from "../../_helper/_standPlans";

interface IProps {
    submissions: IGallerySubmission[] | null
    getSubmissions: Function | null
    fairId: string | null
    module: IModule | null
    page: IMainPage | ISubPage | null
}

function SignageForm(props: IProps) {
    //Signage data
    const [galleryName, setGalleryName] = useState<string>("");
    const [galleryCities, setGalleryCities] = useState<string>("");
    const [artistName, setArtistName] = useState<string>("");
    const [needArtistName, setNeedArtistName] = useState<boolean>(true);

    const [focused, setFocused] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)
    const standNumber = getGalleryStandNumber(selectedGallery, fairs, props.fairId, props.module?.contract_category_id);

    useEffect(() => {
        setUpEventHandlers();
    }, [props.module, props.page, selectedGallery.locations])

    const setUpEventHandlers = async() => {
        setLoading(true);
        setTimeout(async() => {
            setNeedArtistName(false);
            const galleryNameDiv = await waitForElm('[data-name="gallery_name"]');
            let galleryNameField:any = await waitForElm(".form-control", galleryNameDiv);

            if (galleryNameField) {
                setGalleryName(galleryNameField.value);
                galleryNameField.oninput = (event:any) => {
                    setGalleryName(galleryNameField.value);
                }
                galleryNameField.onfocus = () => {
                    setFocused("gallery-name");
                }
                galleryNameField.onblur = () => {
                    setFocused("");
                }
            }
    
            const galleryCitiesDiv = await waitForElm('[data-name="gallery_cities"]');
            let galleryCitiesField:any = await waitForElm(".form-control", galleryCitiesDiv);
            if (galleryCitiesField) {
                setGalleryCities(galleryCitiesField.value ? (Array.isArray(galleryCitiesField.value) ? galleryCitiesField.value.join(',') : galleryCitiesField.value) : '');
                galleryCitiesField.onchange = (event:any) => {
                    setGalleryCities(Array.isArray(galleryCitiesField.value) ? galleryCitiesField.value.join(',') : galleryCitiesField.value);
                }
                galleryCitiesField.onfocus = () => {
                    setFocused("gallery-cities");
                }
                galleryCitiesField.onblur = () => {
                    setFocused("");
                }
                galleryCitiesField.oninput = (event:any) => {
                    setGalleryCities(Array.isArray(galleryCitiesField.value) ? galleryCitiesField.value.join(',') : galleryCitiesField.value);
                }
            }
    
            setLoading(false);
            const artistNameDiv = await waitForElm('[data-name="artist_name"]');
            let artistNameField:any = await waitForElm(".form-control", artistNameDiv);
            if (artistNameField) {
                setArtistName(artistNameField.value ? artistNameField.value : '');
                artistNameField.oninput = (event:any) => {
                    setArtistName(artistNameField.value);
                }
                artistNameField.onfocus = () => {
                    setFocused("artist-name");
                }
                artistNameField.onblur = () => {
                    setFocused("");
                }
                setNeedArtistName(true);
            }

        }, 4000)
    }

    const waitForElm = (selector:string, parent?: Element):Promise<Element> => {
        return new Promise(resolve => {
            const element = parent ? parent.querySelector(selector) : document.querySelector(selector)
            if (element) {
                return resolve(element);
            }
    
            const observer = new MutationObserver(mutations => {
                const element = parent ? parent.querySelector(selector) : document.querySelector(selector)
                if (element) {
                    resolve(element);
                    observer.disconnect();
                }
            });
    
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    return (
        <div className='signage-form'>
            {props.module && props.page && !loading ? <>
                <Card>
                    <Card.Body>
                        <h2>{standNumber ? standNumber : "Stand Number" }</h2>
                        <h5 className={focused === "gallery-name" ? "focused" : ""}>{galleryName || "Gallery Name"}</h5>
                        <h5 className={focused === "gallery-cities" ? "focused" : ""}>{galleryCities || "Gallery Cities"}</h5>
                        {needArtistName && <h5 className={focused === "artist-name" ? "focused" : ""}>{artistName || "Artist Name"}</h5>}
                    </Card.Body>
                </Card>
               
            </> : <div style={{ textAlign: 'center' }}><Spinner animation='border' /></div>}
        </div>
    )
}
export default SignageForm;