import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './assets/scss/styles.scss';
import Application from './pages/application';
import { Provider } from "react-redux";
import store from "./store/store"


const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <Application />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();