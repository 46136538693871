import { Icon } from "@ailibs/feather-react-ts";
import React, { useEffect, useRef, useState } from "react";
import { ListGroup, OverlayTrigger, Tooltip, Modal, Row, Col, Container } from "react-bootstrap";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import FormCreatorModal from "./form-creator-modal.component";
import { FileUploader } from "react-drag-drop-files";
import DatePicker from "react-datepicker";
import instance from "../../api/api";
import { toast } from 'react-toastify';
import { AxiosError } from "axios"
import { IFair, IModule, IPageSurvey, IUploadedFile } from "../../interfaces/fairs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store"
import { setFairs } from "../../store/fairsSlice";
import ConfirmModal from "../confirm-modal.component";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import AddUniqueComponentModal from "./add-unique-component-modal.component";
import CodeEditor from '@uiw/react-textarea-code-editor';

interface IUploadFileData {
    file: File
    name: string
}

interface IProps {
    pages: any[]
    activeKey: string
    fair: IFair
    module: IModule
    setUpTreeData: Function
}

const SubpageContentCreator = (props: IProps) => {
    const dispatch = useDispatch()
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [formCreatorOpen, setFormCreatorOpen] = useState<boolean>(false);
    const [page, setPage] = useState<any>();
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

    //Selected survey
    const [selectedSurvey, selectSurvey] = useState<IPageSurvey | null>(null);
    const [surveyIndex, setSurveyIndex] = useState<number>(-1);
    
    const [showDeleteConfirm,setShowDeleteConfirm] = useState<boolean>(false);
    const [showSettingsModal,setShowSettingsModal] = useState<boolean>(false);
    const [successMessage,setSuccessMessage] = useState<string>("");
    const [submitBtnText,setSubmitBtnText] = useState<string>("");

    //Richtext Editor
    const [rtePreview, setRtePreview] = useState<boolean>(false);
    const [htmlEditor, setHtmlEditor] = useState<boolean>(false);

    //wyswyg editor
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
    const editorRef = useRef(null);
    const [htmlErrorModal, setHtmlErrorModal] = useState<boolean>(false);
    const [htmlErrorNode, setHtmlErrorNode] = useState<Element|null>(null);

    //Page Data
    const [title, setTitle] = useState<string>("");
    const [deadline, setDeadline] = useState<Date|null>(null);
    const [markdown, setMarkdown] = useState<string>("");
    const [surveys, setSurveys] = useState<any>([]);
    const [isEnabled, setIsEnabled] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isMandatory, setIsMandatory] = useState<boolean>(false);

    //Unique component
    const [ showUniqueComponentModal, setShowUniqueComponentModal ] = useState<boolean>(false);

    //Files
    const [selectedFiles, setSelectedFiles] = useState<IUploadFileData[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);

    const fileTypes = process.env.REACT_APP_FILE_TYPES ? process.env.REACT_APP_FILE_TYPES.split(",") : [];
    const options = ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']

    useEffect(() => {
        getPageData();
    }, [props.activeKey, props.pages]);

    const getPageData = () => {
        let pageData;
        for (let mainPage of props.pages) {
            if (mainPage.id === props.activeKey) {
                pageData = mainPage;
                break;
            }
            if (mainPage.children && mainPage.children.length) {
                for (let subPage of mainPage.children) {
                    if (subPage.id === props.activeKey) {
                        pageData = subPage;
                        break;
                    }
                }
            }
        }
        setPage(pageData);
        setUpPageData(pageData);
    }

    const setUpPageData = (pageData?: any) => {
        if (pageData) {
            setTitle(pageData.title);
            setDeadline(pageData.deadline ? new Date(pageData.deadline) : null);
            setMarkdown(pageData.markdown ? pageData.markdown : "");
            if (pageData.markdown) {
                try {
                    const contentBlock = htmlToDraft(pageData.markdown.replaceAll(/(<\/?)figure((?:\s+.*?)?>)/g, ''));
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorState(editorState)
                } catch (error) {
                    console.log(error)
                }
            } else {
                setEditorState(EditorState.createEmpty());
            }
            setSurveys(pageData.surveys);

            setUploadedFiles(pageData.files);

            setIsEnabled(pageData.isEnabled !== undefined ? pageData.isEnabled : false);
            setIsOpen(pageData.isOpen !== undefined ? pageData.isOpen : false);
            setIsMandatory(pageData.isMandatory !== undefined ? pageData.isMandatory : false);
        }
    }

    /*
    Input field change handling
    */
    const handleMarkdownChange = (state: EditorState) => {
        setEditorState(state)
        let isEmpty = !state.getCurrentContent().hasText();
        if (isEmpty) {
            setMarkdown("");
        }
        else {
            setMarkdown(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        }
    }

    const handleHTMLEditorChange = (html:string) => {
        setMarkdown(html)
        const contentBlock = htmlToDraft(html.replaceAll(/(<\/?)figure((?:\s+.*?)?>)/g, ''));
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
    }

    const handleContentStateChange = (state: RawDraftContentState) => {
        setMarkdown(draftToHtml(state));
    }

    const handleDeadlineChange = (date: any) => {
        setDeadline(date);
        setDatePickerOpen(false);
    }

    const handleTitleChange = (e: any) => {
        setTitle(e.currentTarget.value);
    }

    const validateHTML = (htmlString:string) => {
        let htmlToParse = htmlString.replaceAll("<br>","<br/>").replaceAll("&nbsp;","");
        let parser = new DOMParser();
        let doc = parser.parseFromString("<div>" + htmlToParse + "</div>", "text/html");
        let errorNode = doc.querySelector('parsererror');

        if (errorNode) {
            setHtmlErrorModal(true);
            setHtmlErrorNode(errorNode);
            return false;
        } else {
            setHtmlErrorModal(false);
            setHtmlErrorNode(null);
            return true;
        }
    }

    /**
     * Save page data
     */
    const updatePageData = async () => {
        const fairId = props.fair._id;
        const moduleId = props.module._id;
        try {
            if (markdown === "" || validateHTML(markdown)) {
                let response;
                const data = {
                    title: title,
                    deadline: deadline,
                    markdown: markdown,
                    isEnabled: isEnabled,
                    isOpen: isOpen,
                    isMandatory: isMandatory,
                    surveys: surveys,
                }
                if (page.type === "main_page") {
                    response = await instance.patch(`/fair/${fairId}/modul/${moduleId}/main-page/${page.id}`, data);
                } else {
                    response = await instance.patch(`/fair/${fairId}/modul/${moduleId}/main-page/${page.parentId}/subpage/${page.id}`, data);
                }
                const fairData: IFair = response.data;
                const module = fairData.moduls.find(modul => modul._id === moduleId);
                props.setUpTreeData(module);
                const updatedFairs = fairs.map(contextFair => {
                    return contextFair._id === fairId ? fairData : contextFair
                })
                dispatch(setFairs(updatedFairs))
                toast.success("Page content is updated")
            }
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    /*
     Survey handling
    */
    const addNewSurvey = async (surveyData: any) => {
        try {

            if(!surveyData.title){
                toast.error("Form title must be provided!");
                return;
            }

            setSurveys([...surveys, surveyData]);

            const fairId = props.fair._id;
            const moduleId = props.module._id;

            const surveyToSend = {
                survey_form: surveyData,
            }
            let response;
            if (page.type === "subpage") {
                response = await instance.post(`/fair/${fairId}/modul/${moduleId}/main-page/${page.parentId}/${page.id}/survey`, surveyToSend);
            } else {
                response = await instance.post(`/fair/${fairId}/modul/${moduleId}/main-page/${page.id}/survey`, surveyToSend);
            }
            const fairData: IFair = response.data;
            const module = fairData.moduls.find(modul => modul._id === moduleId);
            if (page.type === "subpage") {
                const mainPage = module?.main_pages.find(item => item._id === page.parentId);
                const subPage = mainPage?.sub_pages.find(item => item._id === page.id);
                selectSurvey(subPage?.surveys[subPage.surveys.length-1] || null);
                setSurveyIndex(subPage ? subPage.surveys.length : -1);
            } else {
                const mainPage = module?.main_pages.find(item => item._id === page.id);
                selectSurvey(mainPage?.surveys[mainPage.surveys.length-1] || null);
                setSurveyIndex(mainPage ? mainPage.surveys.length : -1);
            }
            props.setUpTreeData(module);
            const updatedFairs = fairs.map(contextFair => {
                return contextFair._id === fairId ? fairData : contextFair
            })
            dispatch(setFairs(updatedFairs))
            toast.success("Form is successfully added to the page")

        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const handleSurveyDelete = async(surveyId:string, surveyIndex:number)=>{
        try {
            const submissions = await instance.get(`gallery-submission?survey=${surveyId}`);
            setShowDeleteConfirm(true);
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }
    const confirmDelete = async(surveyId:string,surveyIndex:number)=>{
        try {
            setSurveys([
                ...surveys.slice(0, surveyIndex),
                ...surveys.slice(surveyIndex + 1)
                ]);
            await instance.patch(`/fair/survey/${surveyId}`,{deleted:true});
            await instance.patch(`/gallery-submission/deletebysurvey/${surveyId}`);

            selectSurvey(null);
            setSurveyIndex(-1);
            setShowDeleteConfirm(false);
            toast.success("Form is removed successfully")
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const editSurvey = async (surveyData: any, surveyId: string, index: number) => {
        if (surveyData.title) { 
            try {
                const surveyToSend = {
                    survey_form: surveyData,
                }
                const response = await instance.patch(`/fair/survey/${surveyId}`, surveyToSend);
                let editedSurveys = [...surveys];
                editedSurveys[index] = response.data;
                setSurveys(editedSurveys);
                toast.success("Form is updated successfully")
            }
            catch (error) {
                console.log(error);
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        } else {
            toast.error("Form title must be provided!")
        }
    }

    const handleApprovalNeededChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        try {
            let checked = e.target.checked;
            let editedSurveys:IPageSurvey[] = [...surveys];
            await instance.patch(`/fair/survey/${editedSurveys[index]._id}`, {approvalNeeded:checked});
            editedSurveys[index].approvalNeeded = checked;
            setSurveys(editedSurveys);
            toast.success("Form is updated successfully")
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const handleMultipleSubmissionsChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        try {
            let checked = e.target.checked;
            let editedSurveys:IPageSurvey[] = [...surveys];
            await instance.patch(`/fair/survey/${editedSurveys[index]._id}`, {multipleSubmissions:checked});
            editedSurveys[index].multipleSubmissions = checked;
            setSurveys(editedSurveys);
            toast.success("Form is updated successfully")
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }
  
    const handleSuccessMessageSave = async (index: number) => {
        try {
            let editedSurveys = [...surveys];
            if(editedSurveys[index].successMessage === successMessage){
                return;
            }
            await instance.patch(`/fair/survey/${editedSurveys[index]._id}`, {successMessage:successMessage});
            editedSurveys[index].successMessage = successMessage;
            setSurveys(editedSurveys);
            toast.success("Form is updated successfully");
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const handleSubmitBtnTextSave = async (index: number) => {
        try {
            let editedSurveys = [...surveys];
            if(editedSurveys[index].submitBtnText === submitBtnText){
                return;
            }
            await instance.patch(`/fair/survey/${editedSurveys[index]._id}`, {submitBtnText:submitBtnText});
            editedSurveys[index].submitBtnText = submitBtnText;
            setSurveys(editedSurveys);
            toast.success("Form is updated successfully");
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }
    

    /*
     File handling
    */
    const addNewFile = (file: any) => {
        setSelectedFiles(selectedFiles => [...selectedFiles, { file, name: "" }]);
    }

    const handleSelectedFileDelete = (index: number) => {
        setSelectedFiles([
            ...selectedFiles.slice(0, index),
            ...selectedFiles.slice(index + 1)
        ]);
    }

    const handleUploadedFileDelete = async (removedFileId: string) => {
        const fairId = props.fair._id;
        const moduleId = props.module._id;
        try {
            const updateData = {
                files: [...(page.files as IUploadedFile[]).filter(file => file._id !== removedFileId)]
            }
            let response;
            //remove file from page data
            if (page.type === "main_page") {
                response = await instance.patch(`/fair/${fairId}/modul/${moduleId}/main-page/${page.id}`, updateData);
            } else {
                response = await instance.patch(`/fair/${fairId}/modul/${moduleId}/main-page/${page.parentId}/subpage/${page.id}`, updateData);
            }
            //delete the file 
            await instance.delete(`file-upload/${removedFileId}`);
            //update tree data
            const fairData: IFair = response.data;
            const module = fairData.moduls.find(modul => modul._id === moduleId);
            props.setUpTreeData(module);
            const updatedFairs = fairs.map(contextFair => {
                return contextFair._id === fairId ? fairData : contextFair
            })
            dispatch(setFairs(updatedFairs))
            toast.success("File is removed from the page");
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let editedFiles = [...selectedFiles]
        editedFiles[index].name = e.target.value;
        setSelectedFiles(editedFiles)
    }

    const handleFileUpload = async (fileData: IUploadFileData, index: number) => {
        if (fileData.name && fileData.name !== "") {
            const fairId = props.fair._id;
            const moduleId = props.module._id;
            try {
                const formData = new FormData()
                formData.append("name", fileData.name)
                formData.append("category", "page")
                formData.append("file", fileData.file)

                const response = await instance.post(`file-upload`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                handleSelectedFileDelete(index)
                const updateData = {
                    files: [...page.files, response.data._id]
                }
                let fairResponse;
                if (page.type === "main_page") {
                    fairResponse = await instance.patch(`/fair/${fairId}/modul/${moduleId}/main-page/${page.id}`, updateData);
                } else {
                    fairResponse = await instance.patch(`/fair/${fairId}/modul/${moduleId}/main-page/${page.parentId}/subpage/${page.id}`, updateData);
                }
                const fairData: IFair = fairResponse.data;
                const module = fairData.moduls.find(modul => modul._id === moduleId);
                props.setUpTreeData(module);
                const updatedFairs = fairs.map(contextFair => {
                    return contextFair._id === fairId ? fairData : contextFair
                })
                dispatch(setFairs(updatedFairs))
                toast.success("File is uploaded")

            } catch (error) {
                console.log(error);
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        } else {
            toast.error("File name is required");
        }
    }

    const copyFileUrl = (url: string) => {
        const copyUrl = ("{{storage_url}}" + "/" +url).replace("./", "");
        navigator.clipboard.writeText(copyUrl);
        toast.success("Copied to clipboard!");
    }

    const _uploadImageCallBack = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const formData = new FormData()
                formData.append("name", file.name)
                formData.append("category", "page")
                formData.append("file", file)
                const response = await instance.post("/file-upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                resolve({ data: { link: (process.env.REACT_APP_API_URL + "/" + response.data.url).replace("./","") } });
            };
            reader.readAsDataURL(file);
        });
    }

    const reorderForms = (index:number, _direction:boolean) => {
        let tempSurveys = [...surveys];
        if (_direction) {
            let temp = tempSurveys[index-1];
            tempSurveys[index-1] = tempSurveys[index];
            tempSurveys[index] = temp;
        } else {
            let temp = tempSurveys[index+1];
            tempSurveys[index+1] = tempSurveys[index];
            tempSurveys[index] = temp;
        }
        setSurveys(tempSurveys);
    }

    return (
        <React.Fragment>
            <div className="builder-accordion-content">
                <div>
                    <h4>Page Details</h4>
                </div>
                
                {page ? <div className="details-wrapper">
                    <Form>
                        <Row className="form-row">
                            <Col className="form-col" sm={12} md={12} lg={6}>
                                <FormGroup>
                                    <Label for="title">Title</Label>
                                    <Input type="text" name="title" onChange={handleTitleChange} placeholder="Title" value={title}></Input>
                                </FormGroup>
                            </Col>

                            <Col className="form-col" sm={12} md={12} lg={6}>
                                <FormGroup>
                                    <Label for="deadline">Deadline</Label>
                                    <div className="datepicker-wrapper">
                                        <Icon name="calendar" onClick={() => setDatePickerOpen(!datePickerOpen)}/>
                                        <DatePicker className="form-control" readOnly open={datePickerOpen} name="deadline" onChange={handleDeadlineChange} selected={deadline} minDate={new Date()} value={(deadline && deadline!==null) ? deadline.toLocaleDateString() : "Deadline is not set"} />
                                        <Icon name="x" className="date-reset-icon" onClick={() => {setDeadline(null)}}/>
                                    </div>
                                    <div className="form-check form-switch">
                                        <label className={`form-check-label`} htmlFor="customSwitch1">
                                        <p style={{fontSize:"12px", fontWeight:"300"}}>{isOpen ? "Opened" : "Closed"}</p>
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isOpen}
                                            onChange={(e) => {setIsOpen(e.target.checked)}}
                                        />
                                    </div>
                                    <div className="form-check form-switch">
                                        <label className={`form-check-label`} htmlFor="customSwitch1">
                                        <p style={{fontSize:"12px", fontWeight:"300"}}>{isMandatory ? "Mandatory" : "Not Mandatory"}</p>
                                        </label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isMandatory}
                                            onChange={(e) => {setIsMandatory(e.target.checked)}}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>

                    <Row className="page-attachments">
                        <Col className="file-list" sm={12} md={12} lg={6}>
                            <h6>Files</h6>
                            {selectedFiles.length ? <ListGroup>
                                {selectedFiles.map((fileData: IUploadFileData, index: number) => {
                                    return (
                                        <ListGroup.Item className="file-upload-item" key={index}>
                                            {fileData.file.name ? <span>{fileData.file.name}</span> : <span>-</span>}
                                            <div className="file-name-input-group">
                                                <Input className="name-input" type="text" name={fileData.name} placeholder="File name" onChange={(e) => handleFileNameChange(e, index)} value={fileData.name} />
                                                <Button onClick={() => handleFileUpload(fileData, index)} className="btn btn-primary"><Icon name="save" /></Button>
                                            </div>

                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                                : <p>No files selected.</p>}
                            <FileUploader label="Upload or drop a file right here" fileOrFiles={selectedFiles} handleChange={addNewFile} name="file" types={fileTypes}
                                children={
                                    <div className="dragzone-content">
                                        <Icon name="upload-cloud" />
                                        <p>Select or drop files here!</p>
                                    </div>
                                } />

                            {uploadedFiles && uploadedFiles.length ? <ListGroup>
                                {uploadedFiles.map((file: IUploadedFile, index: number) => {
                                    return (
                                        <ListGroup.Item key={index}>
                                            {file.name ? <span><a href={`${process.env.REACT_APP_API_URL}/${file.url}`} target="_blank" download>{file.name}</a></span> : <span>-</span>}
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">Copy file URL</Tooltip>}
                                            >
                                                {({ ref, ...triggerHandler }) => (
                                                    <Button {...triggerHandler} onClick={() => copyFileUrl(file.url)} className="btn btn-primary" ref={ref}><Icon name="clipboard" /></Button>
                                                )}
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">Delete file</Tooltip>}
                                            >
                                                {({ ref, ...triggerHandler }) => (
                                                    <Button {...triggerHandler} onClick={() => handleUploadedFileDelete(file._id)} className="btn btn-primary" ref={ref}><Icon name="trash-2" /></Button>
                                                )}
                                            </OverlayTrigger>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                                : <p>No files uploaded.</p>}
                        </Col>
                        <Col className="survey-list" sm={12} md={12} lg={6}>
                            <h6>Forms</h6>
                            {surveys.length ? <ListGroup>
                                {surveys.filter((survey:IPageSurvey)=>!survey.deleted).map((survey: IPageSurvey, index: number) => {
                                    const surveyData = survey.survey_form ? survey.survey_form : null;     
                                    return (
                                            <Row key={survey._id} className="form-list-item">
                                                <Col sm={surveys.length > 1 ? 11 : 12}>
                                                <ListGroup.Item>
                                                    {(surveyData && surveyData.title) ? <span>{surveyData.title}</span> : <span>-</span>}
                                                    <FormGroup className="mr-2">
                                                        <Label for="approval-needed">Approval needed</Label>
                                                        <Input type="checkbox" name="approval-needed" checked={survey.approvalNeeded} onChange={(e)=>{handleApprovalNeededChange(e,index)}}/>
                                                    </FormGroup>
                                                    <FormGroup className="mr-2">
                                                        <Label for="multiple-submissions">Multiple Submissions</Label>
                                                        <Input type="checkbox" name="multiple-submissions" checked={survey.multipleSubmissions} onChange={(e)=>{handleMultipleSubmissionsChange(e,index)}}/>
                                                    </FormGroup>
                                                    <div className="survey-btn-container">
                                                        <Button title="Form Settings" onClick={() => { selectSurvey(survey); setSuccessMessage(survey.successMessage ? survey.successMessage : "Thank you for your submission!"); setSubmitBtnText(survey.submitBtnText ? survey.submitBtnText : ""); setShowSettingsModal(true); setSurveyIndex(index); }} className="btn btn-primary"><Icon name="settings" /></Button>
                                                        <Button title="Edit Form" onClick={() => { selectSurvey(survey); setFormCreatorOpen(true); setSurveyIndex(index); }} className="btn btn-primary"><Icon name="edit-2" /></Button>
                                                        <Button title="Copy Form Code" onClick={() => { navigator.clipboard.writeText("{{survey_" + survey._id + "}}"); toast.success("Copied to clipboard!") }} className="btn btn-primary"><Icon name="copy" /></Button>
                                                        <Button title="Delete Form" onClick={() => { selectSurvey(survey); setSurveyIndex(index); handleSurveyDelete(survey._id,index);}} className="btn btn-primary"><Icon name="trash-2" /></Button>
                                                    </div>
                                                </ListGroup.Item>
                                                </Col>
                                                {surveys.length > 1 &&<Col sm={1} className="ordering-btn-container">
                                                    {index > 0 &&<Icon name="arrow-up" size="16px" onClick={() => reorderForms(index, true)}/>}
                                                    {index < surveys.length-1 && <Icon name="arrow-down" size="16px" onClick={() => reorderForms(index, false)}/>}
                                                </Col> }
                                            </Row>
                                    )
                                })}

                            </ListGroup>
                                : <p>No forms added.</p>}

                            <div className="btn-container">

                                <Button className="btn btn-primary add-form-btn" onClick={() => setFormCreatorOpen(true)}><Icon name="plus" /><span>Add Form</span></Button>
                            </div>
                            <ConfirmModal title="Confirm Delete" body="Deleting this form will also delete all of its submissions. Are you sure?" show={showDeleteConfirm} onConfirm={()=>{if(selectedSurvey) confirmDelete(selectedSurvey._id, surveyIndex)}} onCancel={()=>{selectSurvey(null); setSurveyIndex(-1); setShowDeleteConfirm(false); }}/>
                            <Modal show={showSettingsModal} onHide={()=>{setShowSettingsModal(false)}} className="form-settings-modal">
                                <Modal.Header closeButton>
                                    <Modal.Title>Form Settings</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FormGroup className="mb-3">
                                        <Row><Label>Success Message</Label></Row>
                                        <Row>
                                            <Col sm={9}>
                                                <Input className="mb-2" value={successMessage} onChange={e=>setSuccessMessage(e.target.value)}></Input>
                                            </Col>
                                            <Col sm={1}>
                                                <Button className="btn-success" onClick={()=>{handleSuccessMessageSave(surveyIndex)}}>
                                                Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row><Label>Submit Button Text</Label></Row>
                                        <Row><p>Applies if multiple submissions are allowed</p></Row>
                                        <Row>
                                            <Col sm={9}>
                                                <Input className="mb-2" value={submitBtnText} onChange={e=>setSubmitBtnText(e.target.value)}></Input>
                                            </Col>
                                            <Col sm={1}>
                                                <Button className="btn-success" onClick={()=>{handleSubmitBtnTextSave(surveyIndex)}}>
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-secondary" onClick={()=>{setShowSettingsModal(false)}}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                    <Row className="unique-component-container">
                        <Col>
                            <div className="btn-container">
                                <Button className="btn btn-primary" onClick={() => setShowUniqueComponentModal(true)}><Icon name="plus" /><span>Add Unique Component</span></Button>
                            </div>
                        </Col>
                        
                    </Row>
                    <div className="status-switch">

                            <div className="form-check form-switch">
                                <h6>Status</h6>
                                <label className={`form-check-label`} htmlFor="customSwitch1">
                                <p style={{fontSize:"12px", fontWeight:"300"}}>{isEnabled ? "Enabled" : "Disabled"}</p>
                                </label>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isEnabled}
                                    onChange={(e) => {setIsEnabled(e.target.checked)}}
                                />
                            </div>
                    </div>

                    <div className="rte-wrapper">
                        <div className="rte-header">
                            <h5>Page Content</h5>
                            <div className="editor-btn-container">
                                <Button className="btn btn-primary" onClick={() => {setRtePreview(false); setHtmlEditor(!htmlEditor)}}>
                                    <Icon name="code" />
                                    <span>{!htmlEditor ? "Edit HTML" : "Stop Editing HTML"}</span>
                                </Button>
                                <Button className="btn btn-primary" onClick={() => {setRtePreview(!rtePreview); setHtmlEditor(false)}}>
                                    <Icon name={!rtePreview ? "eye" : "eye-off"} />
                                    <span>Preview</span>
                                </Button>
                            </div>
                        </div>

                        {!rtePreview && !htmlEditor &&
                        <div className="wyswyg_editor">
                            <Editor 
                                editorState={editorState}
                                onEditorStateChange={handleMarkdownChange}
                                onChange={handleContentStateChange}
                                ref={editorRef}
                                toolbar={{ 
                                    image: {
                                    uploadCallback: _uploadImageCallBack,
                                    previewImage: true,
                                    alt: { present: true, mandatory: false },
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',},
                                    options: options
                                }}
                                handlePastedText={() => false}
                            />
                        </div>}
                        {!htmlEditor && rtePreview && <div className="preview-rte" dangerouslySetInnerHTML={{ __html: markdown }}></div>}

                        {htmlEditor && !rtePreview && 
                            <CodeEditor
                                value={markdown}
                                placeholder=""
                                onChange={(e) => handleHTMLEditorChange(e.target.value)}
                                padding={15}
                                style={{
                                    fontSize: 14,
                                    backgroundColor: "#f2f2f2",
                                    color: "#000",
                                    minHeight: 250
                                }}
                            />
                        }
                    </div>
                    <div className="btn-container">
                        <Button className="btn btn-primary" onClick={updatePageData}>Save</Button>
                    </div>
                </div>
                    : <h5>No page selected.</h5>}
            </div>                 
            <FormCreatorModal show={formCreatorOpen} addNewSurvey={addNewSurvey} editSurvey={editSurvey} survey={selectedSurvey} handleClose={() => { setFormCreatorOpen(false); selectSurvey(null); }} surveyIndex={surveyIndex} templateCreator={false}/>
            <AddUniqueComponentModal show={showUniqueComponentModal} setShow={setShowUniqueComponentModal} />

            <Modal show={htmlErrorModal} onHide={() => {setHtmlErrorModal(false); setHtmlErrorNode(null)}}>
                <Modal.Header closeButton>
                        Invalid HTML
                </Modal.Header>
                <Modal.Body>
                    {htmlErrorNode && <div dangerouslySetInnerHTML={{__html: htmlErrorNode.outerHTML}}></div>}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default SubpageContentCreator;
