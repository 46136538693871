import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import instance from "../../api/api";
import { setPageData } from "../../store/pageSlice";
import EmailTable from "../../components/email/email-table.component";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { IUploadedFile } from "../../interfaces/fairs";

const Emails: React.FunctionComponent<any> = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => (state as RootState).userReducer)

    useEffect(() => {
        // set page title and breadcrumbs of the header
        dispatch(
        setPageData({
            name: "Email Templates",
            firstBreadCrumbName: "",
            secondBreadCrumbName: "",
            thirdBreadCrumbName: "",
        })
        );
    }, [dispatch]);

    async function sendTestEmail(templateName: string, subject: string, templateBody: string, attachments: IUploadedFile[]) {
        const email: string = user?.email;

        await instance.post("/email/send", {
            to: email,
            subject: subject,
            templateName: templateName,
            templateBody: templateBody,
            attachments: attachments,
        });
        return;
    }

    return (
        <div className="emails_page">
            <div className="emails-content">
                <div className="email-cards">
                    <EmailTable sendTestEmail={sendTestEmail}></EmailTable>
                </div>
            </div>
        </div>
    );
};

export default Emails;
