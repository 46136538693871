import "survey-react/modern.min.css"
import { Survey } from "survey-react"
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import instance from "../../api/api";
import { setPageData } from "../../store/pageSlice";
import placeholderImg from "../../assets/images/london.jpg";
import { useParams } from "react-router-dom";
import { IFair } from "../../interfaces/fairs";
import { Spinner } from "react-bootstrap";
import * as SurveyCore from "survey-core";
import { toast } from "react-toastify";
import parse from "html-react-parser"

SurveyCore.StylesManager.applyTheme("bootstrapmaterial");
const UserNomination: React.FunctionComponent<any> = (props) =>
{
    const dispatch = useDispatch();
    const params: any = useParams();

    const [fair, setFair] = useState<IFair>();
    const [survey, setSurvey] = useState<SurveyCore.SurveyModel | null>(null);
    const [loading, setLoading] = useState<boolean>();

    const getFairData = async () =>
    {
        try
        {
            const fairUrl = params.fairUrl;
            const response = await instance.get("/fair/fairByUrl/" + fairUrl);
            const fairData: IFair = response.data;
            setFair(fairData);
        } catch (error)
        {
            console.log(error);
        }
    }

    const setUpSurvey = ()=>{
        if(!fair?.user_nomination?.form){
            return;
        }
        let tempSurvey = new SurveyCore.Model(JSON.parse(fair.user_nomination.form));
        tempSurvey.onComplete.add(submit);
        setSurvey(tempSurvey);
    }

    useEffect(()=>{
        setUpSurvey();
    },[fair]);

    useEffect(() =>
    {
        getFairData();
        setLoading(false);
    }, []);

    useEffect(() =>
    {
        dispatch(setPageData({
            name: "User Nomination",
            firstBreadCrumbName: "",
            secondBreadCrumbName: "",
            thirdBreadCrumbName: "",
        }))
    }, [dispatch]);

    const submit = async(sender:any)=>{
        try{
            const submitData = {
                fair: fair?._id,
                gallery_id: null,
                modul_id: null,
                survey: null,
                survey_result: sender.data,
                survey_definition: fair?.user_nomination?.form,
            }

            await instance.post("/gallery-submission/usernomination", submitData);
           
            if(sender.data.email){
                await instance.post("/email/send", {
                    to:sender.data.email,
                    subject:"Submission was successful", 
                    templateBody:fair?.user_nomination?.emailTemplate?.body || "<h1>Submission was successful!</h1><p>Thank you for your submission!</p>",
                    attachments: fair?.user_nomination?.emailTemplate?.attachments || [],
                });
            }

            if(fair?.user_nomination?.adminEmail){
                await instance.post("/email/send", {
                    to:fair.user_nomination.adminEmail,
                    subject:`User nomination`, 
                    templateBody:`There was a successful user nomination for the fair: ${fair.name}`
                });
            }
        
            toast.success("Submitted successfully!");
        }
        catch(error)
        {
            console.log(error);
        }
    } 

    return (
        <div className="user-nomination_page">
            {!loading && fair? <>
                <div className="image-wrapper" style={{ backgroundImage: fair?.image?.url ? `url(${process.env.REACT_APP_API_URL}/${fair.image.url})` : `url(${placeholderImg}` }} />
                <div className="nomination_wrapper">
                    <h1>{fair.name}</h1>
                    <div>
                        <p>{fair.description && parse(fair.description)}</p>
                    </div>
                    {fair.user_nomination && <>
                    <hr style={{ width: '50%', marginLeft: "auto", marginRight: "auto" }}></hr>
                    <div>
                        <p>{fair.user_nomination?.content && parse(fair.user_nomination?.content)}</p>
                    </div>
                    {survey!== null && fair && <div className="survey_container center_container">
                        <div className="display-survey" >
                            <Survey model={survey} />
                        </div>
                    </div>}
                    </>}
                </div>

            </> : <Spinner animation="border" className="loader" />}
        </div>
    );
};

export default UserNomination;
