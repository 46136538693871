import { Icon } from '@ailibs/feather-react-ts';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FormHelper } from '../../../_helper/formHelper';
import TextFilter from '../../DataTableFilters/text-filter.component';
import StatusBadge from '../../fair/status-badge.component';

export function buildDynamicPanelTableColumns(field: any, panelData: any, startedit:Function, startdelete:Function, survId?: string) {

    let columns:any[] = [];

    //Get paneldata headers
    const firstElement = panelData[0] || {};

    let row:any = [];

    field.templateElements.forEach((element:any)=>{
        const key = element.name;
        if(!firstElement[key]) return;

        if (element) {
            row.push(element);

            columns.push({
                    name: <b>{element.title ? `${element.title}:` : `${element.name}:`}</b>,
                    id:key,
                    unique_selector:key,
                    field_type:field.type,
                    selector: (row:any) => row[key],
                    cell: ( row:any ) => {
                        if (field.type === "paneldynamic" && field.usage && field.usage === FormHelper.usage.PASS_ALLOCATION && key === "status") {
                            return <StatusBadge currentStatus={getSelectorValue(element, row[key], startedit, startdelete)}
                            statuses={[
                                {name:"Not Yet Submitted", value:"Submitted", variant:"warning"},
                                {name:"Under Review", value:"Under Review", variant:"info"},
                                {name:"Approved", value:"Approved",variant:"success"},
                                {name:"Completed", value:"Completed",variant:"success"},
                                {name:"Already Nominated", value:"Already Nominated",variant:"danger"},
                            ]}
                        />
                        }
                        else {
                            return getSelectorValue(element, row[key], startedit, startdelete)
                        }
                    },
                    sortable: true,
                    style: {whiteSpace: "normal"},
                    wrap: true,
                }
            )
        }
    })

    if (field.usage && field.usage === FormHelper.usage.PASS_ALLOCATION) {
        columns.push({
                name: "",
                unique_selector:"actions",
                cell: ( row:any ) => {

                    if(row.status && (row.status !== "Accepted" && row.status !== "Approved" && row.status !== "Rejected" && row.status !== "Pending" && row.status !== "Under Review" && row.status !== "Already Nominated" && row.status !== "Completed")) {
                        return(
                            <div className='pass_action'>
                                <Icon name="edit-2" size="22px" onClick={() =>{ startedit(row, row.index, survId) }} />
                                <Icon name="trash" size="22px" onClick={() =>  startdelete(row, row.index, survId) } />
                            </div>
                        )
                    }
                },
                right: true,
            }
        )
    }

    return columns;
}

export function getSelectorValue(field: any, value: any, startedit:Function, startdelete:Function, survey_id?: string) {
    switch (field.type) {
        case "signaturepad":
            const sFile = dataURLtoFile(value, "signature");
            const sUrl = URL.createObjectURL(sFile);
            return (<a href={sUrl} target="_blank" rel="noreferrer" download>Signature</a>);

        case "file":
            const file = dataURLtoFile(value[0].content, value[0].name);
            const url = URL.createObjectURL(file);
            return (<a href={url} target="_blank" rel="noreferrer" download>{value[0].name}</a>);

        case "dropdown":
        case "radiogroup":
            if (field.choices) {
                let choice = field.choices.find((c:any)=>c.value && c.value === value);
                if(choice){
                    return choice.text ? choice.text : (choice.value ? choice.value : choice);
                }
            }
            return value;

        case "checkbox":
            let labels:string[] = [];
            value.forEach((v:any)=>{
                if (field.choices) {
                    let choice = field.choices.find((c:any)=>c.value && c.value === v);
                    if (choice) {
                        labels.push(choice.text ? choice.text : (choice.value ? choice.value : choice));
                    } else {
                        labels.push(v);
                    }
                }
            });
            return labels.join(", ");

        case "paneldynamic":
            const columns = buildDynamicPanelTableColumns(field, value, startedit, startdelete, survey_id);
            const dynamicPanelData = value.map((row:any, index:number) => { return {...row, index:index}});

            const customStyles = {
                cells: {
                    style: {
                        fontSize: '12px'
                    },
                },
            };

            return (
                <DynamicPanelDataTableContainer columns={columns} data={dynamicPanelData} customStyles={customStyles} />
            );

        default:
            return value;
    }
}

export function dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

function DynamicPanelDataTableContainer(props:any) {

    //Data and filtered data
    const [ data, setData ] = useState<any[]>([]);
    const [ filtered, setFiltered] = useState<any[]>([]);

    //Create filter
    const [ filter, setFilter ] = useState<any>({});

    //Save data to be able to manipulate
    useEffect(() => {
        setData(props.data);
        setFiltered(props.data);
    }, [props.data])

    //Filter data on every filter change
    useEffect(() => {

        if(!Object.keys(filter).length) {
            return;
        }

        let filtered = data.filter( (item) => {
            for (var key in filter) {
                if (item[key] === undefined || !item[key].toLowerCase().includes(filter[key]))
                    return false;
            }
            return true;
        });
        
        setFiltered(filtered)
    }, [filter])

    //filter change
    function setGalleryNameFilter(value:any, key:string) {
        setFilter((prev:any) => { return {...prev, [key]:value.toLowerCase() }})
    }

    //Refactor columns to be able to add text filter
    const columns = props.columns.map((column:any) => { 
        if(column.unique_selector !== "actions" && (column.field_type !== "signaturepad" || column.field_type !== "file" || column.field_type !== "paneldynamic") ) {
            return {
                ...column, 
                name: <TextFilter 
                        label={column.name.props.children} 
                        value={filter[column.unique_selector] ? filter[column.unique_selector] : ""} 
                        onChange={(value:string) => setGalleryNameFilter(value, column.unique_selector)}/>
                }
            }
        else {
            return column
        }
    })



    return(
        <div>            
            <DataTable defaultSortFieldId={"status"} defaultSortAsc={false} columns={columns} data={filtered} persistTableHead={true} customStyles={props.customStyles || {}}/>
        </div>
    )
}