import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import instance from "../../api/api"
import parse from "html-react-parser"
import { Col, Container, Row } from "react-bootstrap"

function TermsAndConditions() {
    const params: { type: string } = useParams()
    const documentType = params.type

    const [content, setContent] = useState("")
    const [title, setTitle] = useState("")

    useEffect(() => {
        getContent()
    }, [params, documentType])

    async function getContent() {
        try {
            const response = await instance.get("/settings")

            switch (documentType) {
                case "terms-and-conditions":
                    setContent(response.data.termsAndConditions || "")
                    setTitle("Terms and Conditions")
                    break
                case "privacy-policy":
                    setContent(response.data.privacyPolicy || "")
                    setTitle("Privacy Policy")
                    break
                case "cookie-policy":
                    setContent(response.data.cookiePolicy || "")
                    setTitle("Cookie Policy")
                    break
            }
        } catch (error) {
            console.log("Failed to get content")
        }
    }

    return (
        <div className="terms-and-conditions-page">
            <Container className="content-container">
                <h2 className="title">{title}</h2>
                <div className="content">
                    {parse(content)}
                </div>
            </Container>
            <div className="right-bg"></div>
        </div>
    )
}

export default TermsAndConditions
