import { IconName } from "@ailibs/feather-react-ts";

export function getIconName(moduleTitle: string): IconName {
    if( moduleTitle.includes("manual"))
        return "flag";
    if( moduleTitle.includes("application"))
        return "edit";
    if( moduleTitle.includes("listing"))
        return "list";
    if( moduleTitle.includes("accreditation"))
        return "file-text";
    return "box";
}

/*in case we add more statuses that allow gallery to submit their forms, 
we only have to change this function instead of every instance*/
export function isSubmissionStatusOpen(status:string){
    const openStatuses = ["open","incomplete", "inprogress"];
    return openStatuses.includes(status);
}

export function getOpportunityId(selectedGallery:any, moduleContractCategoryId:any) {
    const revenueItem = (selectedGallery as any)._extra.RevenueLineItems.find((item:any) => {
        return item.category_id === moduleContractCategoryId;
    });

    if (revenueItem)
        return revenueItem.opportunity_id;
    return "";
}
