import { Icon } from '@ailibs/feather-react-ts';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, ListGroup, Row, Spinner, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import instance from '../../api/api';
import { ERoles } from '../../interfaces/authentication';
import { IGallerySubmission, IMainPage, IModule, ISubPage, IUploadedFile } from '../../interfaces/fairs';
import { RootState } from '../../store/store';
import {categories, getGalleryStandNumber} from '../../_helper/_standPlans';
import { isAdminAccess } from '../fair/_helper/module_helper';

interface IProps {
    submissions: IGallerySubmission[] | null
    getSubmissions: Function | null
    fairId: string | null
    module: IModule | null
    page: IMainPage | ISubPage | null
}

interface IUploadedStandPlan extends IUploadedFile {
    name: string,
    standCode: string,
    uploaderName: string
}

function StandPlans(props: IProps) {
    const { user } = useSelector((state) => (state as RootState).userReducer)
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const { id, moduleId } = useParams<{id:string, moduleId:string}>();

    const [loading, setLoading] = useState<boolean>(true);
    const [standPlans, setStandPlans] = useState<IUploadedStandPlan[]>([]);
    const [feedbacks, setFeedbacks] = useState<IUploadedStandPlan[]>([]);

    useEffect(() => {
        if (user._id && selectedGallery) {
            getStandPlans();
        }
    }, [user, selectedGallery, props.module, props.page, id, moduleId])

    const getStandPlans = async() => {
        setLoading(true);
        try {
            const categoryValues = Object.keys(categories).map((key:any) => categories[key].value);
            const response = await instance.get('/file-upload', {
                params: {
                    category: categoryValues,
                    fair_id: id,
                    module_id: moduleId
                }
            });
            const uploadedFilesData:IUploadedFile[] = response.data;
            let files = [];
            for (let fileData of uploadedFilesData) {
                const fileNameArr = fileData.name.split("-");
                let obj: IUploadedStandPlan = {
                    ...fileData,
                    name: fileNameArr[fileNameArr.length-1],
                    standCode: fileData.extra_info && fileData.extra_info.stand_code ? fileData.extra_info.stand_code : "-",
                    uploaderName: fileData.uploader && fileData.uploader._id ? `${fileData.uploader.firstname} ${fileData.uploader.lastname}` : "-"
                }
                files.push(obj);
            }

            if (!isAdminAccess(user, selectedGallery)) {
                const standNumber = getGalleryStandNumber(selectedGallery, fairs, props.fairId, props.module?.contract_category_id) || '';
                files = files.filter(plan => plan.standCode === standNumber.toUpperCase());
            }
            setStandPlans(files.filter(plan => plan.category === "stand-plan"));
            setFeedbacks(files.filter(plan => plan.category === "stand-plan-feedback"));
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error("Error occured while loading Stand Plans!");
            setLoading(false);
        }
    }

    const handleFileDownload = (fileUrl: string) => {
        const link = document.createElement("a");
        link.href = process.env.REACT_APP_API_URL + "/" + fileUrl;
        link.target = "_blank";
        link.rel = "noreferrer";
        link.download = process.env.REACT_APP_API_URL + "/" + fileUrl;
        link.click();
    }

    return (
        <div className="stand-plans-wrapper">
        {!loading ?
            <Card>
                <Card.Header>
                    <h5>Stand Plan</h5>
                </Card.Header>
                <Card.Body>
                    <ListGroup>
                        {standPlans.length ? standPlans.map(plan => {
                            return (
                                <ListGroup.Item>
                                    <Row>
                                        <Col className="col plan-name" sm={4}><b>{plan.name}</b></Col>
                                        <Col className="col" sm={4}><b>{categories.find(cat => cat.value === plan.category)?.display || "-"}</b></Col>
                                        <Col className="col" sm={3}><b>{moment(new Date(plan.createdAt)).format("YYYY-MM-DD (h:mm:ss A)")}</b></Col>
                                        <Col sm={1} className="download-btn col"><Icon name="download" onClick={() => handleFileDownload(plan.url)}/></Col>
                                    </Row>
                                </ListGroup.Item>
                            )
                        }) : <p>Your Stand Plan has not been uploaded yet.</p>}
                    </ListGroup>
                    <ListGroup>
                        {feedbacks.length ? feedbacks.map(plan => {
                            return (
                                <ListGroup.Item>
                                    <Row>
                                        <Col className="col plan-name" sm={4}><b>{plan.name}</b></Col>
                                        <Col className="col" sm={4}><b>{categories.find(cat => cat.value === plan.category)?.display || "-"}</b></Col>
                                        <Col className="col" sm={3}><b>{moment(new Date(plan.createdAt)).format("YYYY-MM-DD (h:mm:ss A)")}</b></Col>
                                        <Col sm={1} className="download-btn col"><Icon name="download" onClick={() => handleFileDownload(plan.url)}/></Col>
                                    </Row>
                                </ListGroup.Item>
                            )
                        }) : <p>Your Stand Plan has no feedbacks yet.</p>}
                    </ListGroup>
                </Card.Body>
            </Card>
        :
        <div style={{textAlign: "center"}}><Spinner animation="border" /></div> }
        </div>
    )
}
export default StandPlans;