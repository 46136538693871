import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IFair } from "../interfaces/fairs"

interface IFairsState {
    fairs: IFair[]
}

const initialState: IFairsState = {
    fairs: [],
}

const fairsSlice = createSlice({
    name: "fairs",
    initialState,
    reducers: {
        setFairs: (state: IFairsState, action: PayloadAction<IFair[]>) => {
            return { ...state, fairs: action.payload }
        },
        resetFairs: (state: any) => {
            return { ...initialState }
        },
    },
})

export const { setFairs, resetFairs } = fairsSlice.actions
export default fairsSlice.reducer
