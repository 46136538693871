import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import { toast } from "react-toastify";
import { IUserDetails } from "../../interfaces/authentication"

interface IProps {
    show:boolean
    user:IUserDetails | null
    onConfirm:Function
    onCancel:Function
}

export default function EditUserModal(props: IProps) {

    const [firstName,setFirstName] = useState<string>(""); 
    const [lastName,setLastName] = useState<string>(""); 
    const [email,setEmail] = useState<string>(""); 
    const [contactId,setContactId] = useState<string>(""); 

    const handleSave = ()=>{
        if(props.user === null){
            return;
        }
        let errors = []; 
        if(!firstName){
            errors.push("First Name field can not be blank");
        }
        if(!lastName){
            errors.push("Last Name field can not be blank");
        }
        if(!email){
            errors.push("E-mail field can not be blank");
        }
        if(errors.length){
            errors.forEach(error => {
                toast.error(error);
            });
            return;
        }
        props.onConfirm(props.user._id,
            {
                firstname:firstName,
                lastname:lastName,
                email:email,
                contactId:contactId
            });
    }
    
    useEffect(()=>{
        if(props.user === null){
            return;
        }
        setFirstName(props.user.firstname);
        setLastName(props.user.lastname);
        setEmail(props.user.email);
        setContactId(props.user.contactId);
    },[props.user])
    
    return (
        <Modal show={props.show} onHide={()=>{props.onCancel()}}>
            <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>First Name</Form.Label>
                <Form.Control className="mb-3" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}}></Form.Control>
                <Form.Label>Last Name</Form.Label>
                <Form.Control className="mb-3" value={lastName} onChange={(e)=>{setLastName(e.target.value)}}></Form.Control>
                <Form.Label>Email</Form.Label>
                <Form.Control className="mb-3" value={email} onChange={(e)=>{setEmail(e.target.value)}}></Form.Control>
                <Form.Label>Contact Id</Form.Label>
                <Form.Control className="mb-3" value={contactId} onChange={(e)=>{setContactId(e.target.value)}}></Form.Control>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={()=>{props.onCancel()}}>
                Close
            </Button>
            <Button variant="primary" onClick={()=>{handleSave()}}>
                Save
            </Button>
            </Modal.Footer>
        </Modal>
    )
}
