import { Icon } from "@ailibs/feather-react-ts/dist/Icon";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import instance from "../../api/api";
import DateFilter from "../../components/DataTableFilters/date-filter.component";
import SelectFilter from "../../components/DataTableFilters/select-filter.component";
import TextFilter from "../../components/DataTableFilters/text-filter.component";
import OrderItemModal from "../../components/fair/order-item-modal.component";
import StatusButton from "../../components/fair/status-button.component";
import { IOrder, IOrderItem, EOrderStatus, EOrderItemStatus, EPaymentStatus, EOrderCategory } from "../../interfaces/fairs";
import { setPageData } from "../../store/pageSlice";
import { RootState } from "../../store/store";
import CurrencyList from "currency-list";
import { isAdminAccess } from "../../components/fair/_helper/module_helper";
import { Col, Modal, Row } from "react-bootstrap";
import {round} from "lodash";

interface ISelectedRow {
    selectedOrder: string
    selectedOrderCurrency: string
    selectedOrderItem: IOrderItem | null
    eventId: string
}

const FairOrderHistory: React.FunctionComponent<any> = () => {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ orders, setOrders ] = useState<IOrder[]>([]);

    const [showOrderItemModal, setShowOrderItemModal] = useState<boolean>(false)
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<ISelectedRow>()

    //pagination
    const [ limit, setLimit ] = useState<number>(20);
    const [ pageNo, setPageNo ] = useState<number>(0);
    const [ total, setTotal ] = useState<number>(0);

    //filters
    const [ filters, setFilters ] = useState<any>({
        orderCategory:"",
        "payment.status":"",
        "payment.type":"",
        createdAt:null
    })

    const [textFilters, setTextFilters ] = useState<any>({
        gallery_name:"",
        fair:"",
        module:"",
    })

    const dispatch = useDispatch();

    //redux data
    const { user, selectedGallery } = useSelector((state) => (state as RootState).userReducer);
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer);

    //load galleries
    useEffect(() => {
        if(isAdminAccess(user, selectedGallery)) {
            getOrders()
        }
    }, [fairs, filters, pageNo])

    useEffect(() => {
        dispatch(setPageData({
            name: "Order History",
            firstBreadCrumbName:"",
            secondBreadCrumbName: "",
            thirdBreadCrumbName: "" 
        }));
    }, []);

    async function getOrders() {
        setLoading(true);

        let queryParams = {...filters};

        console.log('filters', filters)

        if(filters.createdAt) {
            const start = new Date(queryParams.createdAt)
            const end = new Date(queryParams.createdAt)

            start.setHours(0,0,0,0);
            end.setHours(24,0,0,0);

            queryParams.createdAt = {
                $gte: start, 
                $lt: end
            }
        }   
        else {
            delete queryParams["createdAt"]
        }
        
        Object.keys(queryParams).forEach(key => {
            if (!queryParams[key].length && key !== "createdAt") {
                delete queryParams[key];
            }
        });

        if(textFilters.gallery_name.length) {
            queryParams.gallery_name = { $regex: textFilters.gallery_name, $options: "i" }
        }

        if(textFilters.fair.length) {
            const fairIds = fairs.filter((fair) => fair.name.match(new RegExp(textFilters.fair, "i"))).map(x => x._id)
            queryParams.fair = { $in: fairIds }
        }

        if(textFilters.module.length) {
            const moduleIds = fairs?.map((x:any) => x.moduls).flat().filter((module:any) => module.match(new RegExp(textFilters.module, "i"))).map(x => x._id)
            queryParams.module = { $in: moduleIds }
        }

        const response = await instance.get("/order", { params: { data: JSON.stringify(queryParams), limit:limit, pageNo:pageNo }});
        if(response.data) {
            setOrders(response.data.result);
            setTotal(response.data.totalRecords)
        }

        setLoading(false);
    }

    async function updateOrder(id: string, updateData: any) {
        try {
            const body = {
                id,
                data: updateData,
            }
            const response = await instance.put("/order", body)
            setOrders((prev) => {
                return prev.map(order => order._id === response.data._id ? response.data : order)
            })
            toast.success("The order is updated")
        } catch (error) {
            toast.error("Failed to update the order")
        }
    }

    async function updateOrderItem(id: string, itemId: string, updateData: any) {
        try {
            const body = {
                id: id,
                orderItemId: itemId,
                data: updateData,
            }
            const response = await instance.put("/order", body)
            setOrders((prev) => {
                return prev.map((order) => (order._id === response.data._id ? response.data : order))
            })
            toast.success("Order item is updated")
        } catch (error) {
            toast("Failed to update the order item")
        }
    }

    async function deleteOrderItem() {
        try {
            let order = orders.find(o => o._id === selectedRow?.selectedOrder);
            if (order) {
                const index = order.order_items.findIndex(item => item._id === selectedRow?.selectedOrderItem?._id);
                if (index >= 0) {
                    order.order_items.splice(index, 1);
                    await updateOrder(order._id, order);
                    setShowConfirmDeleteModal(false);
                    toast.warning("Item deleted from order");
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function addNewItem(orderItem: IOrderItem) {
        try {
            let order = orders.find(o => o._id === selectedRow?.selectedOrder);
            if (order) {
                order.order_items.push(orderItem);
                await updateOrder(order._id, order);
                setShowOrderItemModal(false);
                toast.warning("Item added to order");
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function downloadPDF(order: IOrder) {
        try {
            const response = await instance.get(`/order/${order._id}?pdf=true`, {
                responseType: 'blob' //Force to receive data in a Blob Format
            })
            fileDownload(response.data, `${order._id}.pdf`);
            
        } catch (error) {
            toast.error("Failed to download PDF")
        }
    }

    const getOrderTotalPrice = (currency: string, orderItems: IOrderItem[]) => {
        const totalPrice:number = orderItems.reduce((a:number,b:IOrderItem)=>{ return a+round((round(parseFloat(b.price),2)*b.value),2) },0);
        return CurrencyList.get(currency || "GBP").symbol + totalPrice;
    }

    function handleNameFilterKeyDown(e:any) {
        //apply filter on enter press

        if(e.keyCode === 13){
            e.preventDefault(); //otherwise it will change the sorting of the column too
            getOrders();
        }
    }

    const orderColumns = [
        {
            name: (<TextFilter label="Gallery Name" value={textFilters.gallery_name} onChange={(data:string) => setTextFilters((prev:any) => { return {...prev, gallery_name:data} })} onKeyDown={handleNameFilterKeyDown}/>),
            selector: (row: IOrder) => {
                return row.gallery_name
            },
        },
        {
            name: (<TextFilter label="Fair" value={textFilters.fair} onChange={(data:string) => setTextFilters((prev:any) => { return {...prev, fair:data} })} onKeyDown={handleNameFilterKeyDown}/>),
            selector: (row: IOrder) => {
                const fair = fairs.find((fair) => fair._id === row.fair)
                return fair?.name || ""
            },
        },
        {
            name: (<TextFilter label="Module" value={textFilters.module} onChange={(data:string) => setTextFilters((prev:any) => { return {...prev, module:data} })} onKeyDown={handleNameFilterKeyDown}/>),
            selector: (row: IOrder) => {
                const fair = fairs.find((fair) => fair._id === row.fair)
                const module = fair?.moduls.find((module) => module._id === row.module)
                return module?.title || ""
            },
        },
        {
            name: (<SelectFilter label="Order Category" value={filters.orderCategory} onChange={(data:string) => {setFilters((prev:any) => { return {...prev, orderCategory:data} });} } options={
                [
                    { value: '', display: 'Any'},
                    ...Object.values(EOrderCategory).map( val => ({ value: val, display: val }) )
                ]
            } />),
            selector: (row: IOrder) => row.orderCategory,
        },
        {
            name: (<SelectFilter label="Status" value={filters.status} onChange={(data:string) => { setFilters((prev:any) => { return {...prev, status:data} })}} options={
                [
                    { value: '', display: 'Any'},
                    ...Object.values(EOrderStatus).map( val => ({ value: val, display: val }) )
                ]
            } />),
            minWidth:"150px",
            cell: (row: any) => {
                return (
                    <>
                        <StatusButton
                            currentStatus={row.status}
                            onChange={(e: string) => {
                                let updateData:any = {status:e}
                                if(e === "Approved"){
                                    let updatedItems = row.order_items.map((item:IOrderItem)=>{return {...item,status:e}});
                                    updateData = {...updateData,order_items:updatedItems};
                                }
                                updateOrder(row._id, updateData);
                            }}
                            statuses={[
                                {
                                    name: EOrderStatus.APPROVED,
                                    value: EOrderStatus.APPROVED,
                                    variant: "success",
                                },
                                {
                                    name: EOrderStatus.PENDING,
                                    value: EOrderStatus.PENDING,
                                    variant: "warning",
                                },
                                {
                                    name: EOrderStatus.UNDER_REVIEW,
                                    value: EOrderStatus.UNDER_REVIEW,
                                    variant: "info",
                                },
                                {
                                    name: EOrderStatus.CANCELED,
                                    value: EOrderStatus.CANCELED,
                                    variant: "danger",
                                },
                            ]}
                        />
                    </>
                )
            },
        },
        {
            name:  (<SelectFilter label="Payment Status" value={filters["payment.status"]} onChange={(data:string) => {setFilters((prev:any) => { return {...prev, "payment.status":data} })}} options={
                [
                    { value: '', display: 'Any'},
                    ...Object.values(EPaymentStatus).map( val => ({ value: val, display: val }) )
                ]
            } />),
            minWidth:"150px",
            cell: (row: any) => {
                return (
                    <StatusButton
                        onChange={(e:string)=>{ updateOrder(row._id, { payment: {data:row.payment.data, type:row.payment.type, status:e} })}}
                        currentStatus={row.payment ? row.payment.status : EPaymentStatus.UNPAID}
                        statuses={[
                            {
                                name: EPaymentStatus.PAID,
                                value: EPaymentStatus.PAID,
                                variant: "success",
                            },
                            {
                                name: EPaymentStatus.UNPAID,
                                value: EPaymentStatus.UNPAID,
                                variant: "warning",
                            },
                        ]}
                    />
                )
            },
        },
        {
            name: (<SelectFilter label="Payment Method" value={filters["payment.type"]} onChange={(data:string) => {setFilters((prev:any) => { return {...prev, "payment.type":data} })}} options={[
                {value:"", display:"Any"},
                {value:"Invoice", display:"Invoice"},
                {value:"Stripe", display:"Stripe"},
            ]} />),
            selector: (row: any) => row.payment?.type,
        },
        {
            name: <b>Total price</b>,
            selector: (row: any) => getOrderTotalPrice(row.payment.currency, row.order_items),
        },
        {
            name: (<DateFilter label="Created At" value={filters.createdAt} onChange={(data:string) => {setFilters((prev:any) => { return {...prev, createdAt:data} })}}/>),
            selector: (row: any) => moment(row.createdAt).format("YYYY MMM DD HH:mm:ss"),
            minWidth:"200px"
        },
        {
            name: "Action",
            cell: (row: any) => {
                return (
                    <Button
                        onClick={() => {
                            downloadPDF(row)
                        }}
                        className="download-btn">
                        {" "}
                        <Icon size={16} className="download-icon" name="download" />
                    </Button>
                )
            },
            allowOverflow: true,
        },
    ]

    const orderItemColumns = [
        {
            name: 'Name',
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: 'Price (Excluding VAT)',
            selector: (row: any) => `${CurrencyList.get(row.currency || "GBP").symbol}${round(parseFloat(row.price_ex_vat || row.price),2)}`,
        },
        {
            name: 'VAT Rate',
            selector: (row: any) => `${row.tax_rate_c ? round(parseFloat(row.tax_rate_c)*100,2) : 0}%`,
        },
        {
            name: 'Price (Including VAT)',
            selector: (row: any) => `${CurrencyList.get(row.currency || "GBP").symbol}${round(parseFloat(row.price),2)}`,
        },
        {
            name:'Quantity',
            selector: (row: any) => row.value,
            sortable: true,
        },
        {
            name:'Status',
            cell: (row:any) => {
                return(
                    <>
                        <StatusButton currentStatus={row.status} onChange={(e:string)=>{updateOrderItem(row.orderId, row._id,{status:e})}} statuses={[
                            {
                                name:EOrderItemStatus.APPROVED,
                                value:EOrderItemStatus.APPROVED,
                                variant:"success"
                            },
                            {
                                name:EOrderItemStatus.PENDING,
                                value:EOrderItemStatus.PENDING,
                                variant:"warning"
                            },
                            {
                                name:EOrderItemStatus.REFUNDED,
                                value:EOrderItemStatus.REFUNDED,
                                variant:"info"
                            },
                        ]}/>
                    </>
                )
            },
            sortable: true,
        },
        {
            name:'Actions',
            cell: (row: any) => 
            <Row>
                {row.status !== EOrderItemStatus.APPROVED && row.status !== EOrderItemStatus.CANCELED && 
                <><Col><Icon size={16} className="edit-icon" name="edit" onClick={() => {
                    setSelectedRow({
                        selectedOrderItem: row as IOrderItem,
                        selectedOrder: row.orderId,
                        selectedOrderCurrency: row.currency,
                        eventId: row.fair
                    })
                    setShowOrderItemModal(true)
                }}/></Col>
                <Col><Icon size={16} className="edit-icon" name="trash-2" onClick={() => {
                    setSelectedRow({
                        selectedOrderItem: row as IOrderItem,
                        selectedOrder: row.orderId,
                        selectedOrderCurrency: row.currency,
                        eventId: row.fair
                    })
                    setShowConfirmDeleteModal(true);
                }}/></Col></>}
            </Row>,
            sortable: true,
        },
    ];
    const ExpandedRowComponent: React.FC<ExpanderComponentProps<any>> = ({ data }) => {
        return (
            <div className="expanded">
                <DataTable className="expanded-table" columns={orderItemColumns} data={data.order_items.map((item: any) => { return {...item, orderId: data._id, currency:data.payment.currency, fair:data.fair}})} />
                {data.status !== EOrderItemStatus.APPROVED && data.status !== EOrderItemStatus.CANCELED && 
                <Button onClick={() => {
                    setSelectedRow({
                        selectedOrderItem: null,
                        selectedOrder: data._id,
                        selectedOrderCurrency: data.payment.currency,
                        eventId: data.fair
                    })
                    setShowOrderItemModal(true);
                }}>Add new Item</Button>}
            </div>
        )
    }
 
    return (
        <div className="order-history-page">
            <div>
                <div className="order-results">
                    <div>
                        <DataTable
                            columns={orderColumns}
                            data={orders}
                            expandableRows
                            expandableRowsComponent={ExpandedRowComponent}

                            persistTableHead={true}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={total}
                            onChangeRowsPerPage={(newPerPage, page) => { setPageNo(0); setLimit(newPerPage)}}
                            onChangePage={(page) => setPageNo(page-1)}
                            paginationRowsPerPageOptions={[ 10, 20, 30, 40, total ]}
                            paginationPerPage={limit}

                            />
                    </div>
                </div>
            </div>
            {selectedRow && <OrderItemModal show={showOrderItemModal} setShow={setShowOrderItemModal} orderId={selectedRow?.selectedOrder} orderItem={selectedRow?.selectedOrderItem} setOrders={setOrders} currency={selectedRow?.selectedOrderCurrency} addNewItem={addNewItem} eventId={selectedRow?.eventId}/>}
            {selectedRow && 
                <Modal backdrop="static" show={showConfirmDeleteModal}>
                    <Modal.Header>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item from the order?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={deleteOrderItem}>Accept</Button>
                        <Button onClick={() => {setShowConfirmDeleteModal(false); setSelectedRow(undefined)}}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    );
};

export default FairOrderHistory;

