import React, { useEffect, useState } from "react";
import { Icon } from "@ailibs/feather-react-ts";
import { Button } from "reactstrap";
import SortableTree, { addNodeUnderParent, removeNodeAtPath } from 'react-sortable-tree';
import { Dropdown, Modal } from "react-bootstrap";
import { IFair, IMainPage, IModule, ISubPage } from "../../interfaces/fairs";
import instance from "../../api/api";
import { toast } from "react-toastify";
import { AxiosError } from "axios"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store"
import { setFairs } from "../../store/fairsSlice";
import { useHistory } from "react-router-dom";

const SubpageList = (props: any) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [treeData, setTreeData] = useState(props.treeData);

    const [showPagesSection, setShowPagesSection] = useState(true)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [deletableNode, setDeletableNode] = useState<any>(null);

    const fairId = props.fair._id;
    const moduleId = props.module._id;

    useEffect(() => {
        setTreeData(treeData);
    }, [props.treeData]);

    //***Main Pages */
    const addMainPageToModule = async (pageData: IMainPage) => {
        try {
            const response = await instance.post(`/fair/${fairId}/modul/${moduleId}`, pageData);

            const fairData: IFair = response.data;
            const module = fairData.moduls.find(modul => modul._id === moduleId);
            props.setUpTreeData(module);
            const id = module?.main_pages[module?.main_pages.length-1]._id;
            
            props.handleActiveKeyChange(id);
            const updatedFairs = fairs.map(contextFair => {
                return contextFair._id === fairId ? fairData : contextFair
            })
            dispatch(setFairs(updatedFairs))
            toast.success("Page created successfully")
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const deleteMainPageFromModule = async (pageId: string) => {
        try {
            const response = await instance.delete(`/fair/${fairId}/modul/${moduleId}/main-page/${pageId}`);
            const fairData: IFair = response.data;
            const module = fairData.moduls.find(modul => modul._id === moduleId);
            props.setUpTreeData(module);
            const updatedFairs = fairs.map(contextFair => {
                return contextFair._id === fairId ? fairData : contextFair
            })
            dispatch(setFairs(updatedFairs))
            toast.warning("Page deleted")
            setDeletableNode(null)
            setShowDeleteConfirmation(false);
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    /***Subpages */
    const addNewSubPage = async (mainPageId: string, pageData: ISubPage) => {
        try {

            const response = await instance.post(`/fair/${fairId}/modul/${moduleId}/main-page/${mainPageId}`, pageData);

            const fairData: IFair = response.data;
            const module = fairData.moduls.find(modul => modul._id === moduleId);
            const treeData = props.setUpTreeData(module);
            const mainPage = treeData.find((node: any) => node.id === mainPageId);
            const id = mainPage.children[mainPage.children.length-1].id;
            props.handleActiveKeyChange(id);
            const updatedFairs = fairs.map(contextFair => {
                return contextFair._id === fairId ? fairData : contextFair
            })
            dispatch(setFairs(updatedFairs))
            toast.success("Page created successfully")
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const deleteSubpage = async (mainPageId: string, subPageId: string) => {
        try {
            const response = await instance.delete(`/fair/${fairId}/modul/${moduleId}/main-page/${mainPageId}/subpage/${subPageId}`);

            const fairData: IFair = response.data;
            const module = fairData.moduls.find(modul => modul._id === moduleId);
            props.setUpTreeData(module);
            const updatedFairs = fairs.map(contextFair => {
                return contextFair._id === fairId ? fairData : contextFair
            })
            dispatch(setFairs(updatedFairs))
            toast.warning("Page deleted")
            setDeletableNode(null)
            setShowDeleteConfirmation(false);
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const handleDeleteNode = async (nodeInfo: any) => {
        try {
            let { node, treeIndex, path } = nodeInfo;
            console.log(nodeInfo);
            props.setTreeData(removeNodeAtPath({
                treeData: props.treeData,
                path: path,
                getNodeKey: ({ node }: { node: any }) => node.id,
                ignoreCollapsed: false,
            }));
    
            //delete files of the page
            await instance.post("file-upload/delete-multiple",{
                idArray: node.files.map((file:any)=>file._id)
            });
    
            if (!nodeInfo.parentNode) {
                deleteMainPageFromModule(nodeInfo.node.id);
            } else {
                deleteSubpage(nodeInfo.parentNode.id, nodeInfo.node.id);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddNode = (event: any, nodeInfo: any) => {
        event.stopPropagation();
        if (nodeInfo)
            nodeInfo.node.expanded = true;
        const newNode = {
            title: nodeInfo ? "New Subpage" : "New Main Page",
            expanded: true,
            id: Date.now(),
            className: nodeInfo ? "subpage" : "mainpage"
        };
        const newTree = addNodeUnderParent({
            treeData: props.treeData,
            newNode: newNode,
            expandParent: true,
            parentKey: nodeInfo ? nodeInfo.node.id : undefined,
            getNodeKey: ({ node }) => node.id,
        });
    
        props.setTreeData(newTree.treeData);

        if (!nodeInfo) {
            const mainPage: IMainPage = {
                title: newNode.title,
                isEnabled: true,
                isOpen: true,
                isMandatory: false,
                deadline: null,
                sub_pages: [],
                surveys: [],
                files: []
            }
            addMainPageToModule(mainPage);
        } else {
            const subPage: ISubPage = {
                title: newNode.title,
                isEnabled: true,
                isOpen: true,
                isMandatory: false,
                deadline: null,
                markdown: " ",
                surveys: [],
                files: []
            }
            addNewSubPage(nodeInfo.node.id, subPage);
        }
    }

    const handleNodeClick = (event: any, nodeInfo: any) => {
        event.stopPropagation();
        let node = nodeInfo.node;
        const clickedItemClassName = event.target.className;
        if (
            clickedItemClassName !== 'rst__expandButton' &&
            clickedItemClassName !== 'rst__collapseButton'
        ) {
            if (node.parentId)
                history.push(`/creator/${fairId}/${moduleId}/${node.parentId}/${node.id}`);
            else
                history.push(`/creator/${fairId}/${moduleId}/${node.id}`);
        }
    }

    const handleNodeMove = async(data: any) => {
        const treeData = data.treeData;
        try {
            let updatedModule: IModule = {
                ...props.module,
                main_pages: []
            }
            // Iterate through tree data and update pages' details
            for (let node of treeData) {
                let mainPage: IMainPage = {
                    title: node.title,
                    isEnabled: node.enabled,
                    isOpen: node.isOpen,
                    isMandatory: node.isMandatory,
                    deadline: node.deadline ? node.deadline : null,
                    sub_pages: [],
                    markdown: node.markdown,
                    surveys: node.surveys ? node.surveys : null,
                    files: node.files || [],
                }
                if (node.children) {
                    for (let child of node.children) {
                        const subPage: ISubPage = {
                            title: child.title,
                            isEnabled: child.enabled,
                            isOpen: child.isOpen,
                            isMandatory: child.isMandatory,
                            deadline: child.deadline ? child.deadline : null,
                            markdown: child.markdown ? child.markdown : " ",
                            surveys: child.surveys ? child.surveys : null,
                            files: child.files || [],
                        }
                        mainPage.sub_pages.push(subPage);
                    }
                }
                updatedModule.main_pages.push(mainPage);
            }
            const fairId = props.fair._id;
            const moduleId = props.module._id;

            const response = await instance.patch(`/fair/${fairId}/modul/${moduleId}`, updatedModule);
            const fairData: IFair = response.data;
            const updatedFairs = fairs.map(contextFair => {
                return contextFair._id === fairId ? fairData : contextFair
            })
            dispatch(setFairs(updatedFairs))
            toast.success("Updated successfully")
            props.getFairData();
        } catch (error) {
            console.log(error);
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const handleChange = async (treeData: any) => {
        props.setTreeData(treeData);
    }

    return (
            <div className="subpage-list-content">
            <div className="pages-header">
                <h4>Pages</h4>
                <Icon onClick={() => setShowPagesSection(!showPagesSection)} name={showPagesSection ? "chevron-up" : "chevron-down"} className="chevron-icon"/>
            </div>
            
            {showPagesSection ? <>
                <div className="tree-wrapper">
                    <SortableTree
                        treeData={props.treeData}
                        onChange={treeData => handleChange(treeData)}
                        onMoveNode={handleNodeMove}
                        isVirtualized={false}
                        generateNodeProps={(node: any) => ({
                            buttons: [
                                <>{!node.node.isEnabled ? <Icon name="lock" className="lock-icon"></Icon> : null}</>,
                                <Dropdown >
                                    <Dropdown.Toggle className="subpage-node-btn"><Icon name="more-vertical" /></Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {!node.parentNode ? <Dropdown.Item onClick={(event) => handleAddNode(event, node)}>Add</Dropdown.Item> : null}
                                        <Dropdown.Item onClick={(e) => {e.stopPropagation(); setShowDeleteConfirmation(true); setDeletableNode(node)}}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            ],
                            onClick: (event: any) =>{console.log(node); handleNodeClick(event, node)},
                            className: node.node.id === props.activeKey ? `${node.node.className} active` : node.node.className
                        })}
                        placeholderRenderer={({ isOver, canDrop }) => (
                            null
                        )}
                        maxDepth={2}
                        getNodeKey={({ node }: { node: any }) => node.id}
                    />
                </div>
                <div className="btn-container">
                    <Button className="btn btn-primary" onClick={(event) => handleAddNode(event, null)}><Icon name="plus" /><span>Add Page</span></Button>
                </div>
                <Modal show={showDeleteConfirmation} onHide={()=>{setShowDeleteConfirmation(false)}}>
                    <Modal.Header>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this page?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-secondary" onClick={()=>{setShowDeleteConfirmation(false)}}>
                            Cancel
                        </Button>
                        <Button className="btn-warning" onClick={()=>{handleDeleteNode(deletableNode)}}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </> : <></>}
        </div>
    )
}

export default SubpageList;