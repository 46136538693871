import React from "react"
import { Button } from "react-bootstrap"
import { Icon } from "@ailibs/feather-react-ts/dist/Icon"
import instance from "../../api/api"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { ERoles } from "../../interfaces/authentication"
import { isAdminAccess } from "../fair/_helper/module_helper"

function StandOrderDownloader() {
    const { id } = useParams<{ id: string }>()

    //Logged in user
    const { user } = useSelector((state) => (state as RootState).userReducer)
    //redux data
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)

    async function downloadZIP() {
        try {
            if (!isAdminAccess(user, selectedGallery)) {
                const url = `/order/stand-form/${id}?gallery=${selectedGallery.gallery_id}&gallery_name=${selectedGallery.gallery_name}`
                const response = await instance.get(url, {
                responseType: "blob", //Force to receive data in a Blob Format
                })
                fileDownload(response.data, `Stand_Plan_and_Order_Form.zip`)
            }
            
        } catch (error) {
            toast.error("Failed to download PDF")
        }
    }

    return (
        <div>
            <Button
                onClick={() => {
                    downloadZIP()
                }}
                className="download-btn">
                Download Stand Plan and Order Form <Icon size={16} className="download-icon" name="download" />
            </Button>
        </div>
    )
}

export default StandOrderDownloader
