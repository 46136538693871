import {IGallerySubmission} from "../interfaces/fairs";

const getXLSValue = (question:any, value:any)=>{
    let type = question.type;
    switch (type) {
        case "text":
        case "comment":
            return value;
        case "dropdown":
        case "radiogroup":
            let choice = question.choices ? question.choices.find((c:any)=>c.value===value) : false;
            if(choice){
                return choice.text? choice.text : choice.value;
            }
            return value;
        case "file":
            return `file: ${value[0].name}`;
        case "signaturepad":
            return "file: signature.png";
        case "paneldynamic":
            let panels:string[] = [];
            value.forEach((elem:any)=>{
                let panelElems:string[] = [];
                Object.keys(elem).forEach(k=>{
                    const panelQuestion = question.templateElements.find((element:any)=>element.name === k);
                    if(panelQuestion){
                        const title = panelQuestion.title? panelQuestion.title : panelQuestion.name;
                        panelElems.push(`${title}:${getXLSValue(panelQuestion,elem[k])}`);
                    }
                })
                panels.push(panelElems.join(', '));
            });
            return panels.join('|');
        case "checkbox":
            let labels:string[] = [];
            value.forEach((v:any)=>{
                let choice = question.choices.find((c:any)=>c.value === v);
                if(choice){
                    labels.push(choice.text? choice.text : choice.value);
                }else{
                    labels.push(v);
                }
            });
            return labels.join(', ');
        default:
            return value;
        }
}

export const XLSXFormatSurveyResult = (questions:any[],surveyResult:any)=>{
    let formattedResults:any[] = [];

    questions.forEach((question:any) => {
        if(surveyResult[question.name]){
                formattedResults.push(getXLSValue(question,surveyResult[question.name]));
            }else{
                formattedResults.push("-");
            }
        });

        return formattedResults;
}

export const getUniqueSubmissions = function (submissions:IGallerySubmission[]) {
    submissions = submissions.sort(function(subA,subB) {
        if (subA.survey._id === subB.survey._id) {
            return (subA.createdAt > subB.createdAt) ? 1 : ((subB.createdAt < subA.createdAt) ? -1 : 0)
        }
        return subA.survey._id > subB.survey._id ? 1 : -1;
    });

    //group submissions by gallery name and get their latest submissions
    let groupSubmissions = submissions.reduce((hash:any, obj:IGallerySubmission) => ({...hash, [obj["gallery_id"]]:( hash[obj["gallery_id"]] || [] ).concat(obj)}), {});
    Object.keys(groupSubmissions).forEach(key=>{
        groupSubmissions[key] = [...Array.from(new Map(groupSubmissions[key].map((item:any) => [item.survey._id, item])).values())];
    });

    const uniqueSubmissions = Object.values(groupSubmissions).flat();

    return uniqueSubmissions as IGallerySubmission[];
}