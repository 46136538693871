import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Table } from "react-bootstrap";
import { Icon } from "@ailibs/feather-react-ts/dist/Icon";
import instance from "../../api/api";

import EmailTableRow from "./email-table-row.component";
import EmailAddForm from "./email-add-form.component";

const EmailTable = (props: any) => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [isTemplateAdded, setIsTemplateAdded] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const handleEditTemplate = () => {
    setIsEdited(!isEdited);
  };

  const handleAddTemplate = () => {
    if (isTemplateAdded) {
      ReactDOM.render(<EmailAddForm data={props.data} handleAddTemplate={handleAddTemplate}></EmailAddForm>, document.getElementById("email-modaladd"));
    }
    setIsTemplateAdded(!isTemplateAdded);
  };

  useEffect(() => {
    if (isTemplateAdded) {
      ReactDOM.render(<EmailAddForm data={props.data} handleAddTemplate={handleAddTemplate}></EmailAddForm>, document.getElementById("email-modaladd"));
    }
  }, [isTemplateAdded]);

  async function getAllTemplate() {
    try {
      const result = await instance.get("/email");
      const templateData: any[] = result.data;

      const newTemplates = [...templateData];
      setTemplates([...newTemplates]);
      console.log(templateData);
    } catch (error) {
      // TODO: handle error
      console.log("Failed to load email templates", error);
    }
  }
  useEffect(() => {
    getAllTemplate();
  }, [isTemplateAdded, isEdited]);
  useEffect(() => {
    getAllTemplate();
  }, []);

  return (
    <div className="container-fluid">
      <Row>
        <Col xl={12}>
          <div className="position-relative">
            <div id="email-alert"></div>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table responsive bordered hover className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th>Name</th>
                        <th>Subject</th>
                        <th>Body</th>
                        <th>Attach.</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {templates.length ? (
                        templates.map((item) => (
                          <EmailTableRow key={item._id} handleEditTemplate={handleEditTemplate} sendTestEmail={props.sendTestEmail} data={item}></EmailTableRow>
                        ))
                      ) : (
                        <tr className="bg-light">
                          <td colSpan={6}>
                            <div className="d-height-130 d-flex flex-column justify-content-center align-items-center">
                              <p>No email template exists yet.</p>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  handleAddTemplate();
                                }}>
                                <Icon name="plus" size={14} />
                                Add new template
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                      {templates.length !== 0 && (
                        <tr>
                          <td colSpan={6}>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddTemplate();
                              }}>
                              <Icon name="plus" size={14} />
                              Add new template
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <div id="email-modaledit"></div>
            <div id="email-modaladd"></div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmailTable;
