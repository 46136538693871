import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../api/api";
import { ERoles, IUserDetails } from "../../interfaces/authentication";
import { setPageData } from "../../store/pageSlice";
import DataTable from "react-data-table-component";
import { Spinner, Button, Modal } from "react-bootstrap";
import { RootState } from "../../store/store";
import ContactsModal from "../../components/fair/contacts-modal.component";
import { Icon } from "@ailibs/feather-react-ts/dist/Icon";
import TextFilter from "../../components/DataTableFilters/text-filter.component";
import ActivityLogs from "../../components/activity-logs.component";

interface ISelectedGallery {
    id: string
    galleryName: string
}

interface ISort {
    column:string
    sortDirection:string
}
const Galleries: React.FunctionComponent<any> = (props) => {

    const [ loading, setLoading ] = useState(true);
    const [ users, setUsers ] = useState<IUserDetails[]>([]);

    const [showContactsModal, setShowContactsModal] = useState<boolean>(false)
    const [showRecentActivityModal, setShowRecentActivityModal] = useState<boolean>(false);
    const [selectedGallery, setSelectedGallery] = useState<ISelectedGallery>({
        id: "",
        galleryName: "",
    })

    const [galleryIdFilter, setGalleryIdFilter] = useState<string>("");
    const [galleryNameFilter, setGalleryNameFilter] = useState<string>("");

    //pagination
    const [ totalData, setTotalData ] = useState<number>(0);
    const [ perPage, setPerPage] = useState<number>(10);
    const [ page, setPage ] = useState<number>(1);

    //sort
    const [ sortOrder, setSortOrder] = useState<ISort>({column:"name", sortDirection:"ASC"});

    //redux data
    const { user } = useSelector((state) => (state as RootState).userReducer)

    const dispatch = useDispatch();

    //timeouts for search
    useEffect(() => {

        const delayDebounceFn = setTimeout(() => {
            getUsers()
        }, 1000)

        return () => clearTimeout(delayDebounceFn)

    }, [galleryIdFilter, galleryNameFilter])


    useEffect(() => {

        if(user.role === ERoles.SUPERADMIN) {
            getUsers();
        }

    }, [page, sortOrder ])

    useEffect(() => {
        dispatch(setPageData({
            name: "Accounts",
            firstBreadCrumbName: "",
            secondBreadCrumbName: "",
            thirdBreadCrumbName: "",
        }))
    }, [dispatch])

    const getUsers = async () => {
        try {
            setLoading(true);

            let filter:any = {};

            if(galleryIdFilter.length) {
                filter["id"] = galleryIdFilter;
            }

            if(galleryNameFilter.length) {
                filter["name"] = { '$contains': galleryNameFilter }
            }

            const response = await instance.get("/crm/all-accounts", {
                params: {
                    offset: (page-1)*perPage,
                    max_num:perPage,
                    sort:sortOrder.column,
                    order:sortOrder.sortDirection,
                    filter:filter
                }
            });
            
            setTotalData(response.data.total_records);

            const usersData = response.data.records;
            setUsers(usersData);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: (
                <TextFilter disabled={loading} label="Account Id" value={galleryIdFilter} onChange={setGalleryIdFilter}/>
            ),
            sort_id:"id",
            selector: (row: any) => row.id,
            sortable: true,
        },
        {
            name: (
                <TextFilter disabled={loading} label="Account Name" value={galleryNameFilter} onChange={setGalleryNameFilter}/> 
            ),
            sort_id:"name",
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: <b>Actions</b>,
            cell: (row: any) => {
                return (
                    <div className="actions">
                    <Button
                        title="Show Contacts"
                        onClick={() => {
                            setSelectedGallery({ id: row.id, galleryName: row.name })
                            setShowContactsModal(true)
                        }}
                        className="action-btn">
                        <Icon size={16} className="contacts-icon" name="users" />
                    </Button>
                    <Button
                        title="Recent Activity"
                        onClick={() => {
                            setSelectedGallery({ id: row.id, galleryName: row.name })
                            setShowRecentActivityModal(true)
                        }}
                        className="action-btn">
                        <Icon size={16} className="contacts-icon" name="rotate-ccw" />
                    </Button>
                    </div>
                )
            },
            sortable: false,
            right: true
        },
    ]

    function handleSort(column:any, sortDirection:any) {
        setSortOrder({column:column.sort_id, sortDirection:sortDirection});
    };

    return (
        <div className="galleries_page">
            {/* {!loading ?  */}
            <div className="content_wrapper">
                <DataTable 
                    columns={columns} 
                    data={users}
                    persistTableHead
                    
                    // pagination 
                    pagination
                    paginationServer
                    paginationTotalRows={totalData}
                    onChangeRowsPerPage={(newPerPage:number, page) => {setPerPage(newPerPage); setPage(1);} }
                    onChangePage={(page:number) => setPage(page)}
                    paginationRowsPerPageOptions={[ 10, 20, 50 ]}
                    paginationPerPage={perPage}

                    //progress
                    progressComponent={<Spinner animation="border" color="primary" className="loader"/>}
                    progressPending={loading}
                    //sort
                    onSort={handleSort}
                    sortServer
                    defaultSortFieldId={2}
                        />
            </div> 
            <ContactsModal 
                show={showContactsModal} 
                setShow={() => setShowContactsModal(!showContactsModal)} 
                galleryId={selectedGallery.id} 
                galleryName={selectedGallery.galleryName} />
            <Modal fullscreen show={showRecentActivityModal} onHide={() => setShowRecentActivityModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Recent Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ActivityLogs filters={{galleryId: selectedGallery.id, galleryName: selectedGallery.galleryName}}/>
                </Modal.Body>
            </Modal>
        </div>
    )
};

export default Galleries;