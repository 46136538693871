import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Label } from "reactstrap";
import instance from "../../api/api";
import userImage from "../../assets/images/dummy_user.png";
import { ERoles } from "../../interfaces/authentication";
import { setPageData } from "../../store/pageSlice";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { isAdminAccess } from "../../components/fair/_helper/module_helper";

const Profile: React.FunctionComponent<any> = (props) => {

    const [isChanging, setIsChanging] = useState(false);
    const [oldPassword,setOldPassword] = useState("");
    const [newPassword,setNewPassword] = useState("");
    const [newPasswordConfirm,setNewPasswordConfirm] = useState("");

    const dispatch = useDispatch();
    const { user } = useSelector((state) => (state as RootState).userReducer)
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)

    const callChangePassword = async()=>{
        await instance.post(`/user/${user._id}/passwordchange`,{oldPassword:oldPassword,newPassword:newPassword,newPasswordConfirm:newPasswordConfirm}).then(()=>{
            setIsChanging(false); 
            toast.success("Password change was successful");
        }).catch(error=>{
            toast.error(error.response?.data);
        });
    }

    useEffect(() => {
        // set page title and breadcrumbs of the header
        dispatch(
            setPageData({
                name: "Profile",
                firstBreadCrumbName: "",
                secondBreadCrumbName: "",
                thirdBreadCrumbName: "",
            })
        );

    }, [dispatch]);


    return (
        <div className="profile-page">
            <div className="profile-info">
                <img alt="profile-avatar" className="img-fluid rounded-circle d-block" src={userImage}></img>
                <div className="user-details">
                    <h4>{user.firstname} {user.lastname}</h4>
                    <div className="text-muted"><i className="bx bx-envelope align-middle" /> {user.email}</div>
                    <div className="text-muted"><i className="bx bx-user align-middle" /> {user.role}</div>
                    {!isAdminAccess(user, selectedGallery) ? <div className="text-muted"><i className="bx bx-grid align-middle" /> {selectedGallery.gallery_name}</div> : null }
                </div>
                <div className="actions">
                    <Button disabled={isChanging} className="btn btn-primary" onClick={() => { setIsChanging(!isChanging) }}><span><i className="bx bx-pencil align-middle" />Change Password</span></Button>
                </div>
            </div>

            {isChanging && <div className="card change-password-card">
                <div className="card-body">
                    <h5>Change Password</h5>
                    <Label for="oldpassword">Old Password</Label>
                    <input type="password" name="oldpassword" placeholder="" value={oldPassword} onChange={(e) => { setOldPassword(e.target.value); }} className="form-control"/>
                    <Label for="newpassword">New Password</Label>
                    <input type="password" name="newpassword" placeholder="" value={newPassword} onChange={(e) => { setNewPassword(e.target.value); }} className="form-control"/>
                    <Label for="newpasswordconfirm">New Password Confirm</Label>
                    <input type="password" name="newpasswordconfirm" placeholder="" value={newPasswordConfirm} onChange={(e) => { setNewPasswordConfirm(e.target.value); }} className="form-control"/>
                    <div className="btn-wrap">
                        <Button className="btn btn-primary" onClick={() => { setIsChanging(false); setOldPassword(""); setNewPassword(""); setNewPasswordConfirm(""); }}><span>Cancel</span></Button>
                        <Button className="btn btn-primary" onClick={() => { callChangePassword(); }}><span>Save</span></Button>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default Profile;
