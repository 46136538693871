import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { IFair, IModule } from '../../interfaces/fairs';

interface IProps {
    galleries:any
    module: IModule
    fair: IFair
}

interface IWallAllocationData {
    gallery_name: string
    total_area: number
    stand_area: number
    already_existing: number
    perimeter_area: number
    corner_stands: number
}

function WallAllocationsByModule(props: IProps) {
    const [wallAllocationData, setWallAllocationData] = useState<IWallAllocationData[]>([]);

    useEffect(() => {
        getAllocationDataForGalleries()
    }, [props.galleries, props.fair, props.module])

    const getAllocationDataForGalleries = () => {
        let tempAllocationData = [];
        for (let gallery of props.galleries) {
            const revenueItems = gallery._extra.RevenueLineItems;
            for (let item of revenueItems) {
                if (item.sales_stage === "Closed Won" && item.eve_events_id === props.fair.crm_id && item.category_id === props.module.contract_category_id) {
                    const wallAllocDataObj = {
                        gallery_name: gallery.name,
                        total_area: item.actual_size_not_rounded_c,
                        stand_area: item.thirty_percent_c,
                        already_existing: item.total_walls_c,
                        perimeter_area: item.d_value_c,
                        corner_stands: item.e_third_wall_c
                    }
                    tempAllocationData.push(wallAllocDataObj);
                }
            }
        }
        setWallAllocationData(tempAllocationData);
    }

    const columns = [
        {
            name: "Gallery",
            selector: (row:IWallAllocationData) => row.gallery_name,
            sortable: true, 
        }, 
        {
            name: "Total Stand Area",
            selector: (row:IWallAllocationData) => row.total_area,
            sortable: false,
        },
        {
            name: "Length of walls included in allocation (% of stand area)",
            selector: (row:IWallAllocationData) => row.stand_area,
            sortable: false,
        },
        {
            name: "Length of walls already existing on stand (see layout)",
            selector: (row:IWallAllocationData) => row.already_existing,
            sortable: false,
        },
        {
            name: "Perimeter wall allocation left for your use",
            selector: (row:IWallAllocationData) => row.stand_area,
            sortable: false,
        },
        {
            name: "Corner stands only - Length of missing wall(s)",
            selector: (row:IWallAllocationData) => row.stand_area,
            sortable: false,
        },
    ]

    return (
        <DataTable persistTableHead responsive columns={columns} data={wallAllocationData} />
    )
}
export default WallAllocationsByModule;