type statusVariants = 'danger' | 'warning' | 'success' | 'primary' | 'secondary' | 'info'
interface IStatus{
    name:string
    value:string
    variant:statusVariants
}

interface IProps {
    currentStatus: string
    statuses:IStatus[]
}

export default function StatusBadge(props: IProps) {

    const getClass = ()=>{
        let foundStatus = props.statuses.find(status=>status.value === props.currentStatus);
        if(!foundStatus){
             return "status-badge rounded-pill badge-soft-primary";
        }
        return `status-badge rounded-pill badge-soft-${foundStatus.variant}`;
    }

    const getTitle = ()=>{
        let foundStatus = props.statuses.find(status=>status.value === props.currentStatus);
        if(!foundStatus){
             return "Invalid Status";
        }
        return foundStatus.name;
    }

    return (
        <div className={getClass()}>{getTitle()}</div>
    )
}