import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import Cookies from "universal-cookie"
import { ERoles, IPassAllocation, IUserDetails } from "../interfaces/authentication"

const cookies = new Cookies()

interface IGallery {
    gallery_id:string
    gallery_name:string
    pass_allocation:IPassAllocation[]
    locations:any[]
    section:string
    email1:string
    email2:string
}

export const defaultUser: IUserDetails = {
    _id: "",
    firstname: "",
    lastname: "",
    email: "",
    contactId:"",
    role: ERoles.GALLERY,
    gallery_access: [],
    fairs: []
}

export const default_gallery = {
    gallery_id:"",
    gallery_name:"",
    pass_allocation:[],
    locations: [],
    section:"",
    email1:"",
    email2:""
}

interface IUserState {
    isAuthenticated: boolean
    user: IUserDetails
    selectedGallery:IGallery
}

const initialState: IUserState = {
    isAuthenticated: cookies.get("token") ? true : false,
    user: defaultUser,
    selectedGallery:default_gallery,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsAuthenticated: (state: IUserState, action: PayloadAction<boolean>) => {
            return { ...state, isAuthenticated: action.payload }
        },
        setUser: (state: IUserState, action: PayloadAction<IUserDetails>) => {            
            return { ...state, user: action.payload }
        },
        resetUser: () => {
            return { ...initialState }
        },
        setSelectedGallery: (state:IUserState, action: PayloadAction<IGallery>) => {
            return {...state, selectedGallery:action.payload}
        }
    },
})

export const { setIsAuthenticated, setUser, resetUser, setSelectedGallery } = userSlice.actions
export default userSlice.reducer
