import React from "react";
import { IGallerySubmission, IMainPage, IModule, ISubPage } from "../../interfaces/fairs";
import AccreditationPassTypes from "./accreditation-pass-types.component";
import OrderForm from "./order-form.component";
import OrderHistory from "./gallery-order-history.component";
import ProgressTable from "./progress-table.component";
import SignageForm from "./signage-form.component";
import SubmissionResultTable from "./submission-result-table.component";
import StandPlans from "./stand-plans.component";
import WallAllocationTable from "./wall-allocation-table.component";
import StandOrderDownloader from "./stand-order-downloader.component";

interface IProps {
    code:string
    submissions: IGallerySubmission[] | null
    getSubmissions: Function | null
    fairId: string | null
    module: IModule | null
    page: IMainPage | ISubPage | null
}

export interface iUnqieComponent {
    id:string
    name:string
    description:string
    code:string
}

export const avalibleUniqueComponents : iUnqieComponent[] = [
    {id:"1", name:"Progress Table", description:"Shows the Gallery progress on submitted data", code:"{{progress_table}}"},    
    {id:"2", name:"Pass Allocation Table", description:"Show the avalible Passes for the Gallery", code:"{{pass_table}}"},
    {id:"3", name:"Order Form", description:"Displays an Order form for the administrator to edit and Gallery to fill out", code:"{{order_form}}"},
    {id:"4", name:"Order History", description:"Displays the order history (in the current module) for the gallery", code:"{{order_history}}"},
    {id:"5", name:"Submission Result Table", description:"Displays a table with submitted data for forms in a page", code:"{{submission_table}}"},
    {id:"6", name:"Signage Form", description:"Displays a signage form for the Gallery to fill out", code:"{{signage_form}}"},
    {id:"7", name:"Stand Plans", description:"Displays a list of uploaded Stand Plans for a Gallery", code:"{{stand_plans}}"},
    {id:"8", name:"Stand Orders and Submissions", description:"Download button for the PDF of stand forms and orders", code:"{{stand_orders}}"},
    {id:"9", name:"Wall Allocation Table", description:"Displays a table with Wall Allocation data for a Gallery", code:"{{wall_allocation}}"},
];

//Have to update this and the object as we work on unique components
interface iComponents {
    [key: string]: any // any used to be able to get every key from variable
    progress_table:Function
    pass_table:Function
    order_form:Function
    order_history:Function
    submission_table:Function
    signage_form:Function
    stand_plans:Function
    stand_orders:Function
    wall_allocation:Function
}

const componentCodes: iComponents = {
    progress_table: ProgressTable,
    pass_table: AccreditationPassTypes,
    order_form: OrderForm,
    order_history: OrderHistory,
    submission_table: SubmissionResultTable,
    signage_form:SignageForm,
    stand_plans:StandPlans,
    stand_orders:StandOrderDownloader,
    wall_allocation:WallAllocationTable,
}

const parseSettings = (data: string) => {
    let settings = {};
    try {
        settings = JSON.parse(data)
    } catch (e) {
        settings = {};
    }
    return settings;
}

export default function UniqueComponentChecker(props: IProps) {

    function decideComponent(data:any) {
        //remove wrapping curly braces
        const strippedCode = props.code.substring(2, props.code.length-2);
        // get the settings from it if there is any
        const settings = strippedCode.substring(strippedCode.indexOf("[") + 1, strippedCode.lastIndexOf("]"));

        //get the core component code
        const componentCode = strippedCode.split(' ')[0].trim();

        //if we have a component - render it
        if (componentCodes[componentCode]) {
            return <div>{componentCodes[componentCode]({...data, settings: parseSettings(settings)})}</div>
        } else {
            // else highlight the component code with error
            return (
                <p>
                    Failed to load unique component. Key: <span>{props.code}</span> does not exists in our system.
                </p>
            )
        }
    }
    return(
        <div className="unique-component-container">
            {decideComponent(props)}
        </div>
    );
}