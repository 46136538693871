import { IFair, IModuleType } from "./fairs"

export enum ERoles {
    SUPERADMIN = "superadmin",
    GALLERY = "gallery",
}
export interface IUserDetails {
    _id: string
    firstname: string
    lastname: string
    email: string
    role: ERoles
    gallery_access: IGalleryAccess[]
    fairs: IFairExtended[]
    last_login?: Date
    contactId:string
}

export interface IGalleryAccess {
    gallery_name: string
    gallery_id: string
    fair_access: IFairAccess[]
    pass_allocation: IPassAllocation[]
}

export interface IGallery {
    name:string
    id:string
    email1:string
    email2:string
}

export interface IFairAccess {
    fair_id: string,
    fair_name: string
}

export interface IPassAllocation {
    id: string
    pass_name: string
    quantity: number
    fair_id: string,
    pt_pass_types_id: string

}

export interface IActivityLog {
    _id: string
    user: string
    userId: string
    userEmail: String
    galleryId: string
    galleryName: string
    entity: any
    data: any
    entity_type: "Fair" | "EmailTemplate" | "Modultype" | "GallerySubmission" | "Survey" | "UploadedFile" | "User" | "-"
    action: "post" | "create" | "update" | "delete" | "login"
    fair?: IFair
    module?: IModuleType
    action_description?: string
    createdAt: Date
    updatedAt: Date
}

export interface IProspectList {
    id:string
    name:string
    fairId:string
}

export interface IFairExtended extends IFair {
    prospectLists: IProspectList[]
}
