import axios from "axios";
import * as Survey from "survey-core"
import { countryCodes } from "./phones";

export const telephoneWidget = {
    name: "telephone",
    title: "Telephone",
    iconName: "telephone",
    widgetIsLoaded: function () {
        return true;
    },
    isFit: function (question: any) {
        return question.getType() === 'telephone';
    },
    activatedByChanged: function (activatedBy: any) {
        //@ts-ignore
        Survey.JsonObject.metaData.addClass("telephone", [], null, "text");
    },
    isDefaultRender: false,
    htmlTemplate: ` <div class="d-flex justify-content-between">
                        <select class="form-select form-select-sm" value="+44" style="width: 35%">
                            ${countryCodes.map((country) => {
                                return ("<option value=" + "'" + country.code + "'" +`${country.code === "+44" ? "selected" : ""}` + ">" + country.name + " " + country.code + "</option>")
                            })}
                        </select>
                        <input class="form-control" type="tel" minlength="9" maxlength="10" style="width: 60%"/>
                    </div>`,
    afterRender: function (question: any, el: any) {
        let telNum = el.getElementsByTagName("input")[0];
        let code = el.getElementsByTagName("select")[0];

        telNum.onchange = function () {
            question.value = code.value + "-" + telNum.value;
        }
        code.onchange = function () {
            question.value = code.value + "-" + telNum.value;
        }
        const onValueChangedCallback = function () {
            if (question.value) {
                code.value = question.value.split("-")[0] ? question.value.split("-")[0] : "";
                telNum.value = question.value.split("-")[1] ? question.value.split("-")[1] : "";
            }
        }
        const onReadOnlyChangedCallback = function () {
            if (question.isReadOnly) {
                telNum.setAttribute('disabled', 'disabled');
                code.setAttribute('disabled', 'disabled');
            } else {
                telNum.removeAttribute("disabled");
                code.removeAttribute("disabled");
            }
        };
        question.readOnlyChangedCallback = onReadOnlyChangedCallback;
        question.valueChangedCallback = onValueChangedCallback;
        onValueChangedCallback();
        onReadOnlyChangedCallback();
    },
    willUnmount: function (question: any, el: any) {
    }
}