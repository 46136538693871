import { Form } from "react-bootstrap"

interface IOption{
    value:string,
    display:string
}

interface Iprops{
    label:string
    value:string,
    onChange:any,
    options:IOption[]
}

function SelectFilter(props:Iprops) {

    const renderOptions = props.options.map((option:IOption,i)=>(
        <option key={i} value={option.value}>{option.display}</option>
    ))

    return (
        <div className="datatable-filter">
            <div className="column-title ">{props.label}</div>
            <Form.Select onClick={(e)=>{e.stopPropagation()}} value={props.value} onChange={(e) => { props.onChange(e.target.value) }}>
                {renderOptions}
            </Form.Select>
        </div>
    )
}

export default SelectFilter
