import React, { useEffect, useState } from "react";
import { setPageData } from "../../store/pageSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import instance from "../../api/api";
import { IFair, IModule, submission_types } from "../../interfaces/fairs";
import { toast } from "react-toastify";
import Submissions from "../../components/fair/nomination-submissions.component";
import IndividualNominationSubmissions from "../../components/fair/individual-nomination-submissions.component";
import { Button, Card, ListGroup, OverlayTrigger, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap";
import WallAllocationsByModule from "../../components/fair/wall-allocations-by-module.component";
import { Icon } from "@ailibs/feather-react-ts";

interface IProps {

}

const FairSubmissionDetailsByModule: React.FunctionComponent<any> = (props:IProps) => {

    const [ fair, setFair ] = useState<IFair>();
    const [ module, setModule ] = useState<IModule>();
    const [galleries, setGalleries] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [activeKey, setActiveKey] = useState<string>("gallery_subs");

    const dispatch = useDispatch();
    const params: any = useParams();

    useEffect(() => {
        getFairData();
    }, [params.id, params.moduleId])

    async function getFairData() {
        try {
            const id = params.id;
            const response = await instance.get("/fair/" + id);
            setFair(response.data);

            const module = response.data.moduls.find((x:any) => x._id === params.moduleId);
            if(module) {
                setModule(module);
            }

        } catch (error) {
            toast.error("Failed to load Form results for " + module?.title)
        }
    }

    useEffect(() => {
        if(fair && module) {
            dispatch(setPageData({
                name: "Fair Submissions",
                firstBreadCrumbName: fair?.name || "",
                secondBreadCrumbName: module?.title || "",
                thirdBreadCrumbName: "" ,
            }))
        }
    }, [ dispatch, fair, module, params.moduleId ])



    function getModuleType() {
        if(module) {
            if(module.modultype.name.toLowerCase().includes("accreditation")) {
                return submission_types.ACCREDITATION
            }
            else if(module.modultype.name.toLowerCase().includes("application")) {
                return submission_types.APPLICATION
            }
            else if(module.modultype.name.toLowerCase().includes("listing")) {
                return submission_types.LISTING
            }
            else {
                return submission_types.MANUAL

            }
        }
        else {
            console.log('else accreditation')
            //TODO: HORRIBLE TYPESCRIPT CODES
            return submission_types.NONE
        }
    }

    const copyUrl = () => {
        if(!fair?.user_nomination?.url){
            return;
        }
        const copyurl = `${process.env.REACT_APP_FRONTEND_URL}/usernomination/${fair.user_nomination.url}`;
        navigator.clipboard.writeText(copyurl);
        toast.success("Copied to clipboard!");
    }

    return (
        <div className="fair-submission-details-by-module-container">
            <Tabs activeKey={!loading ? activeKey : "gallery_subs"} onSelect={(e:any) => setActiveKey(e)} id="uncontrolled-tab-example" className="mb-3">

                {module && fair && getModuleType() !== submission_types.NONE ?
                <Tab eventKey="gallery_subs" title="Gallery Submissions"> 
                    <Card className="nomination_subs">
                        <Card.Body>
                            <Submissions setGalleries={setGalleries} fair_id={fair._id} module_id={module._id} submission_type={getModuleType()} setLoading={setLoading}/>
                        </Card.Body>
                    </Card> 
                </Tab> : null}

                {module && fair && !loading && getModuleType() !== submission_types.NONE ?
                <Tab eventKey="abcde_summary" title="ABCDE Data Summary">
                     <Card className="">
                        <Card.Body>
                            <WallAllocationsByModule module={module} fair={fair} galleries={galleries}/>
                        </Card.Body>
                    </Card> 
                </Tab> : null}
            
                {getModuleType() === submission_types.ACCREDITATION && fair && !loading ?
                <Tab eventKey="individual_subs" title="Individual User Nominations">
                    <Card>
                        <Card.Body>
                            <Card className="url-card">
                                <Card.Body>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="me-3 text-muted">{fair.user_nomination?.url ? process.env.REACT_APP_FRONTEND_URL+"/usernomination/"+fair.user_nomination?.url : "No url is set."}</div>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">Copy file URL</Tooltip>}
                                    >
                                    {({ ref, ...triggerHandler }) => (
                                        <Button disabled={!fair.user_nomination?.url} {...triggerHandler} onClick={() =>{copyUrl()}} className="btn btn-primary" ref={ref}><Icon size={20} name="clipboard" /></Button>
                                    )}
                                    </OverlayTrigger>
                                </div>
                                </Card.Body>
                            </Card>
                            <IndividualNominationSubmissions fair_id={fair._id}  />
                        </Card.Body>
                    </Card> 
                </Tab> : null }
            </Tabs>
        </div>
    );
};

export default FairSubmissionDetailsByModule;
