import axios from "axios"
import React, { useState } from "react"
import { Form, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Input } from "reactstrap"
import instance from "../../api/api"

interface IProps {
    setShowForgotPassword: Function
}

function ForgotPasswordRquest(props: IProps) {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [success, setSuccess] = useState(false)

    async function handleRequest(evt: React.FormEvent) {
        setLoading(true)
        evt.preventDefault()
        try {
            await instance.post("/auth/forgot-password", { email })
            setSuccess(true)
            setLoading(false)
        } catch (error) {
            console.log("error", error);
            
            setErrorMessage("Failed to send email")
            setLoading(false)
            setTimeout(() => {
                setErrorMessage("")
            }, 5000)
        }
    }

    return (
        <div className="forgot-password-container">
            {success ? (
                <p className="success-message">Email sent successfully</p>
            ) : (
                <Form className="custom-form mt-4 pt-2" onSubmit={handleRequest}>
                    <div className="mb-3">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <label className="form-label">Email</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Input name="email" label="Email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter email" type="email" required />
                        </div>
                    </div>

                    <div className="mb-3">
                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">
                            Send Request
                            {loading && <Spinner className="login-loader" animation="border" size="sm" />}
                        </button>
                    </div>
                    <div>
                        <p className="error-message">{errorMessage}</p>
                    </div>
                </Form>
            )}
            <div className="mt-5 text-center">
                <p onClick={() => props.setShowForgotPassword(false)} className="text-muted mb-0 back-login">
                    Back to the Login Page
                </p>
            </div>
        </div>
    )
}

export default ForgotPasswordRquest
