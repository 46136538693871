import React, { useState, useEffect } from "react"
import instance from "../../api/api"
import { ITemplateSurvey } from "../../interfaces/fairs"
import DataTable from "react-data-table-component"
import moment from "moment"
import { setPageData } from "../../store/pageSlice"
import { useDispatch } from "react-redux"
import { Spinner } from "react-bootstrap"
import { Button } from "reactstrap"
import { Icon } from "@ailibs/feather-react-ts"
import FormCreatorModal from "../../components/content-creator/form-creator-modal.component"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import ConfirmModal from "../../components/confirm-modal.component"

function TemplateSurveys() {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)

    const [surveys, setSurveys] = useState<ITemplateSurvey[]>([])

    const [selectedSurvey, selectSurvey] = useState<ITemplateSurvey | null>(null)
    const [formCreatorOpen, setFormCreatorOpen] = useState<boolean>(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

    useEffect(() => {
        fetchTemplateSurveys()
    }, [])

    useEffect(() => {
        // set page title and breadcrumbs of the header
        dispatch(
            setPageData({
                name: "Form Templates",
                firstBreadCrumbName: "",
                secondBreadCrumbName: "",
                thirdBreadCrumbName: "",
            })
        )
    }, [dispatch])

    async function fetchTemplateSurveys() {
        try {
            const response = await instance.get("/template-survey")
            setSurveys(response.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    /*
     Survey handling
    */
    const addNewSurvey = async (surveyData: any) => {
        if (surveyData.title) {
            try {
                const surveyToSend = {
                    survey_form: surveyData,
                    name: surveyData.title,
                }
                const response = await instance.post(`/template-survey`, surveyToSend)
                setSurveys((prev) => [...prev, response.data])

                toast.success("Form Template created successfully")
            } catch (error) {
                console.log(error)
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        } else {
            toast.error("Form title is required!");
        }
    }

    const editSurvey = async (surveyData: any, surveyId: string) => {
        try {
            const updateData = {survey_form: surveyData};
            const response = await instance.patch(`/template-survey/${surveyId}`, updateData)
            const updatedIndex = surveys.findIndex((survey) => survey._id === response.data._id)
            setSurveys((prev) => {
                prev[updatedIndex] = response.data
                return [...prev]
            })
            toast.success("Form Template is updated successfully")
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const deleteSurvey = async (surveyId: string) => {
        try {
            await instance.delete(`/template-survey/${surveyId}`)
            setSurveys((prev) => [...prev.filter((survey) => survey._id !== surveyId)])
            toast.warning("Form is deleted")
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            toast.error(err.response?.data)
        }
    }

    const columns = [
        {
            name: "Title",
            selector: (row: ITemplateSurvey) => row.survey_form.title,
            sortable: true,
        },
        {
            name: "Created At",
            selector: (row: ITemplateSurvey) => moment(row.createdAt).fromNow(),
            sortable: false,
        },
        {
            name: "Actions",
            selector: (row: ITemplateSurvey) => "",
            cell: (row: ITemplateSurvey) => {
                return (
                    <div>
                        <Icon
                            className="action-icon"
                            onClick={() => {
                                selectSurvey(row)
                                setFormCreatorOpen(true)
                            }}
                            name="edit-2"
                        />
                        <Icon className="action-icon delete-icon" onClick={() => {
                            selectSurvey(row);
                            setDeleteModalOpen(true);
                        }} name="trash-2" />
                    </div>
                )
            },
            right: true,
        },
    ]

    const defaultTableHeader = (
        <div>
            <h4>Default Forms</h4>
            <p>These Forms are used for special submissions</p>
        </div>
    )

    const customTableHeader = (
        <div>
            <h4>Custom Forms</h4>
        </div>
    )

    return (
        <div>
            {!loading ? (
                <div className="content_wrapper template-surveys">
                    <div className="card">
                        <div className="card-body">
                            <DataTable title={defaultTableHeader} columns={columns} data={surveys.filter((survey) => survey.default)} pagination />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <DataTable title={customTableHeader} columns={columns} data={surveys.filter((survey) => !survey.default)} pagination />
                        </div>
                    </div>
                    <div className="btn-container">
                        <Button className="btn btn-primary" onClick={() => setFormCreatorOpen(true)}>
                            <Icon name="plus" />
                            <span>Add Form</span>
                        </Button>
                    </div>
                    <FormCreatorModal
                        show={formCreatorOpen}
                        editSurvey={editSurvey}
                        survey={selectedSurvey}
                        addNewSurvey={addNewSurvey}
                        handleClose={() => {
                            setFormCreatorOpen(false)
                            selectSurvey(null)
                        }}
                        surveyIndex={0}
                        templateCreator={true}
                    />
                    <ConfirmModal title="Confirm Delete" body={`Are you sure you want to delete ${selectedSurvey && selectedSurvey.survey_form.title}`} show={deleteModalOpen} onConfirm={()=>{if(selectedSurvey) deleteSurvey(selectedSurvey._id); selectSurvey(null); setDeleteModalOpen(false);}} onCancel={()=>{setDeleteModalOpen(false)}}/>
                </div>
            ) : (
                <Spinner animation="border" className="loader" />
            )}
        </div>
    )
}

export default TemplateSurveys
