import { Icon } from "@ailibs/feather-react-ts"
import { useState } from "react"
import { Button, Col, Dropdown, DropdownButton, Form, Modal, Row, FormControl } from "react-bootstrap"
import { avalibleUniqueComponents, iUnqieComponent } from "../unqiue-page-components/unique-component-checker.component"
import {EOrderCategory} from "../../interfaces/fairs";
import OrderFormSettings from "./order-form-settings.component";
import ProgressTableSettings from "./progress-table-settings.component";

interface IProps {
    show: boolean
    setShow: Function
}

const categoryNameOptions = ["Production Add-on", "Tickets"]
const typeNameOptions = ["Storage", "Cleaning", "Order Form", "Worker","Exhibitor", "Build - Electrics", "Build - Lights", "Build - Walls", "Build - Other"]
const defaultSuccessMessage = 'Order placed! You will receive an email confirmation.';
const paymentMethodOptions = ["Stripe", "Invoice"]
const orderCategoryOptions = Object.values(EOrderCategory)

function AddUniqueComponentModal(props: IProps) {
    const [copied, setCopied] = useState<number>(-1)
    //Order Form Settings
    const [categoryNames, setCategoryNames] = useState<string[]>([])
    const [orderCategory, setOrderCategory] = useState<string>(EOrderCategory.STAND_ORDER)
    const [hasDownloadButton, setHasDownloadButton] = useState<string>('0')
    const [successMessage, setSuccessMessage] = useState<string>(defaultSuccessMessage)
    const [typeNames, setTypeNames] = useState<string[]>([]);
    const [paymentMethod, setPaymentMethod] = useState(paymentMethodOptions[0])

    //Progress Table Settings
    const [defaultTableSorting, setDefaultTableSorting] = useState<string>("Page Order");
    const sortingOptions = ["Page Order", "Date"];
    const [groupByPages, setGroupByPages] = useState('1');

    function getOrderCode(code: string) {
        const trimmedCode = code.replace(/[{}]/g, "")
        const props = {
            categoryNames,
            typeNames,
            paymentMethod,
            orderCategory,
            hasDownloadButton,
            successMessage
        }
        return `{{${trimmedCode} [${JSON.stringify(props)}]}}`
    }

    function getProgressTableCode(code: string) {
        const trimmedCode = code.replace(/[{}]/g, "")
        const props = {
           defaultTableSorting,
        //    groupByPages
        }
        return `{{${trimmedCode} [${JSON.stringify(props)}]}}`
    }

    const selectType = (typeName:string)=>{
        let typeIndex = typeNames.findIndex(t=>t === typeName);
        if(typeIndex === -1){
            setTypeNames([...typeNames,typeName]);
        }
        else{
            let newTypeNames = [...typeNames];
            newTypeNames.splice(typeIndex,1);
            setTypeNames(newTypeNames);
        }
    }

    const selectCategory = (categoryName: string) => {
        let categoryIndex = categoryNames.findIndex((t) => t === categoryName)
        if (categoryIndex === -1) {
            setCategoryNames([...categoryNames, categoryName])
        } else {
            let newCategoryNames = categoryNames.filter((category) => category === categoryName)
            setTypeNames(newCategoryNames)
        }
    }

    const getComponentCode = (component:iUnqieComponent) => {
        if (component.name === "Order Form") {
            return getOrderCode(component.code);
        }
        if (component.name === "Progress Table") {
            return getProgressTableCode(component.code);
        }
        else {
            return component.code;
        }
    }

    return (
        <Modal backdrop="static" dialogClassName="add-unique-component-modal" show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header>
                <Modal.Title>Available Unique Components</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {avalibleUniqueComponents.map((component: iUnqieComponent, index: number) => {
                    return ( 
                        <div className="unique-component" key={index}>
                            <p className="title">{component.name}</p>
                            {component.name === "Order Form" &&
                                <OrderFormSettings 
                                    paymentMethod={paymentMethod}
                                    paymentMethodOptions={paymentMethodOptions}
                                    orderCategory={orderCategory}
                                    orderCategoryOptions={orderCategoryOptions}
                                    hasDownloadButton={hasDownloadButton}
                                    successMessage={successMessage}
                                    categoryNames={categoryNames}
                                    categoryNameOptions={categoryNameOptions}
                                    typeNames={typeNames}
                                    typeNameOptions={typeNameOptions}
                                    setPaymentMethod={setPaymentMethod}
                                    setOrderCategory={setOrderCategory}
                                    setHasDownloadButton={setHasDownloadButton}
                                    setSuccessMessage={setSuccessMessage}
                                    selectCategory={selectCategory}
                                    selectType={selectType}
                                />
                            }
                            {component.name === "Progress Table" &&
                                <ProgressTableSettings
                                    defaultTableSorting={defaultTableSorting}
                                    sortingOptions={sortingOptions}
                                    groupByPages={groupByPages}
                                    setDefaultTableSorting={setDefaultTableSorting}
                                    setGroupByPages={setGroupByPages}
                                />
                            }
                            <div className="unique-component-footer">
                                <p className="description">{component.description}</p>
                                <p
                                    className="copied"
                                    onClick={() => {
                                        navigator.clipboard.writeText(getComponentCode(component))
                                        setCopied(index)
                                        setTimeout(() => setCopied(-1), 2000)
                                    }}>
                                    {copied === index ? "Copied !" : "Copy"}
                                </p>
                            </div>
                        </div> 
                )
                })}
                
            </Modal.Body>
            <Modal.Footer>
                <p className="help-info">To add a unique component, copy one from above, and insert the copied text to the Page Content.</p>
                <Button onClick={() => props.setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddUniqueComponentModal
