import { useState, useEffect } from "react";
import instance from "../../api/api";
import DataTable from "react-data-table-component";

interface IProps {
    fair_id:string
}

function IndividualSubmissionTable(props:IProps) {

    const [submissions, setSubmissions] = useState<Object[]>([]);

    useEffect(() => {
        getGallerySubmissions()
    }, [props.fair_id])

    async function getGallerySubmissions() {
        try {
            const response = await instance.get(`/gallery-submission/usernominations/?fair=${props.fair_id}`);
            const survey_results = response.data.map((r:any) => r.survey_result);
            setSubmissions(survey_results);
            
        } catch (error) {
            console.log('error', error)
        }
    }

    const getColumns = submissions.length > 0 ? Object.keys(submissions[0]).map(key=>{
        return {
            name : key,
            selector: (row:any) => row[key]
        }
    }) : [];

    return (
        <div className="user-submission-container">
            <DataTable 
                columns={getColumns} 
                data={Object.values(submissions)} 
                />
        </div>
    )
}

export default IndividualSubmissionTable
