import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import instance from "../../api/api";
import { IUserDetails } from "../../interfaces/authentication";
import { setPageData } from "../../store/pageSlice";
import DataTable from "react-data-table-component";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Icon } from "@ailibs/feather-react-ts/dist/Icon";
import EditUserModal from "./edit-user-modal";
import { toast } from "react-toastify";
import TextFilter from "../../components/DataTableFilters/text-filter.component";
import SelectFilter from "../../components/DataTableFilters/select-filter.component";
import DateFilter from "../../components/DataTableFilters/date-filter.component";
import StatusBadge from "../../components/fair/status-badge.component";
import moment from "moment";
import * as XLSX from "xlsx-js-style";
import ActivityLogs from "../../components/activity-logs.component";

const Users: React.FunctionComponent<any> = (props) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()

    //filters
    const [firstName,setFirstName] = useState<string>("");
    const [lastName,setLastName] = useState<string>("");
    const [email,setEmail] = useState<string>("");
    const [contactId,setContactId] = useState<string>("");
    const [lastLogin,setLastLogin] = useState<Date | null>(null);
    const [role,setRole] = useState<string>("any");
    const [invitationStatus,setInvitationStatus] = useState<string>("any");

    const [selectedRows,setSelectedRows] = useState<any[]>([]);

    const [editModalShow, setEditModalShow] = useState<boolean>(false);
    const [showRecentActivityModal, setShowRecentActivityModal] = useState<boolean>(false);
    const [selectedUser, selectUser] = useState<IUserDetails | null> (null);

    useEffect(() => {
        dispatch(setPageData({
            name: "Users",
            firstBreadCrumbName: "",
            secondBreadCrumbName: "",
            thirdBreadCrumbName: "",
        }))
    }, [dispatch])

    const [users, setUsers] = useState<IUserDetails[]>();

    const getFilteredUsers = ()=>{
        if(!users){
            return [];
        }
        let filteredUsers = [...users];
        filteredUsers = filteredUsers.filter(user=>user.firstname.toLowerCase().includes(firstName.toLowerCase()));
        filteredUsers = filteredUsers.filter(user=>user.lastname.toLowerCase().includes(lastName.toLowerCase()));
        filteredUsers = filteredUsers.filter(user=>user.email.toLowerCase().includes(email.toLowerCase()));
        filteredUsers = filteredUsers.filter(user=>user.contactId.toLowerCase().includes(contactId.toLowerCase()));
        if(lastLogin){
            filteredUsers = filteredUsers.filter(user=>user.last_login && new Date(user.last_login).setHours(0,0,0,0) === new Date(lastLogin).setHours(0,0,0,0));
        }
        if(role!=="any"){
            filteredUsers = filteredUsers.filter(user=>user.role === role);
        }
        if(invitationStatus=="pending"){
            filteredUsers = filteredUsers.filter(user=>!user.last_login);
        }
        if(invitationStatus=="accepted"){
            filteredUsers = filteredUsers.filter(user=>user.last_login);
        }
        return filteredUsers;          
    }

    const getUsers = async () => {
        try {
            const response = await instance.get("/user/");
            const usersData = response.data;
            setUsers(usersData);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const updateUser = async (id:string, data:any) => {
        try {
            const response = await instance.post(`/user/${id}/`,data);
            if(users){
                let newUsers = [...users];
                newUsers = newUsers.map(user=>user._id === response.data._id ? response.data : user);
                setUsers(newUsers);
            }
            toast.success("User updated successfully");
        } catch (error) {
            console.log(error);
            toast.error("User update failed");
        }
    }

    const columns = [
        {
            name:(
                <TextFilter disabled={false} value={firstName} onChange={setFirstName} label="First Name"/>
            ),
            selector: (row: any) => row.firstname,
            sortable: true,
            minWidth:"150px"
        },
        {
            name:(
                    <TextFilter disabled={false} value={lastName} onChange={setLastName} label="Last Name"/>
                ),
            selector: (row: any) => row.lastname,
            sortable: true,
            minWidth:"150px"
        },
        {
            name:(
                    <TextFilter disabled={false} value={email} onChange={setEmail} label="E-mail"/>
                ),
            selector: (row: any) => row.email,
            sortable: true,
            minWidth:"150px"
        },
        {
            name:(
                    <TextFilter disabled={false} value={contactId} onChange={setContactId} label="Contact Id"/>
                ),
            selector: (row: any) => row.contactId || "-",
            sortable: true,
            minWidth:"150px"
        },
        {
            name:(
                    <SelectFilter label="Role" value={role} onChange={setRole} options={[{value:"any",display:"Any"},{value:"superadmin",display:"Superadmin"},{value:"gallery",display:"Gallery"}]}/>
                ),
            selector: (row: any) => row.role,
            sortable: true,
            minWidth:"150px"
        },
        {
            name:(
                 <DateFilter label="Last Login" value={lastLogin} onChange={setLastLogin} />
                ),
            selector: (row: any) => row.last_login ? moment(new Date(row.last_login)).format("YYYY-MM-DD (h:mm:ss A)") : "Never",
            sortable: false,
            minWidth:"220px"
        },
        {
            name:(
                 <SelectFilter label="Invitation Status" value={invitationStatus} onChange={setInvitationStatus} options={[
                    {value:"any",display:"Any"},
                    {value:"accepted",display:"Accepted"},
                    {value:"pending",display:"Pending"},
                 ]}/>
                ),
            selector: (row: any) => {
                let currentStatus = row.last_login ? "accepted" : "pending";
                return (<StatusBadge currentStatus={currentStatus} statuses={[{value:"accepted",name:"Accepted",variant:"success"},{value:"pending",name:"Pending",variant:"warning"}]}></StatusBadge>)
            },
            sortable: false,
            minWidth:"220px"
        },
        {
            name: "Actions",
            cell: (row:IUserDetails)=>
                <div className="actions">
                    <Icon className="action-icon" name="edit" onClick={()=>{selectUser(row); setEditModalShow(true) }} />
                    <Icon className="action-icon" name="rotate-ccw" onClick={()=>{ selectUser(row); setShowRecentActivityModal(true)}} />
                </div>
        }
    ];

    useEffect(() => {
        getUsers();
    }, [])

    const handleXLSDownload = ()=>{
        var workbook = XLSX.utils.book_new();
        let data:any[][] = [];
        data.push(["First Name","Last Name","E-mail","Contact Id","Role","Last Login","Invitation Status"]);
        selectedRows.forEach(row=>{
            let lastlogin = row.last_login? new Date(row.last_login).toLocaleDateString() : "-";
            let invitationstatus = row.last_login? "Accepted" : "Pending";
            data.push([row.firstname,row.lastname,row.email,row.contactId,row.role,lastlogin,invitationstatus]);
        });
        var worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook,worksheet);
        XLSX.writeFile(workbook,"users_export.xlsx");
    }

    return (
        <div className="users_page">
            {!loading ? <div className="content_wrapper">
                {users && <>
                <div className="action-btn-row">
                    {selectedRows.length > 0 && <div className="btn-wrapper">
                    <Button onClick={()=>{ handleXLSDownload();}} className="download-btn"> <Icon className="download-icon" name="download" /> <span>XLSX</span></Button>
                    </div>}
                </div>
                <DataTable columns={columns} data={getFilteredUsers()} pagination persistTableHead={true} selectableRows onSelectedRowsChange={(e)=>{setSelectedRows(e.selectedRows)}}/>
                <EditUserModal user={selectedUser} show={editModalShow} onCancel={()=>{setEditModalShow(false); selectUser(null)}} onConfirm={(id:string,data:any)=>{updateUser(id,data); setEditModalShow(false); selectUser(null);}}></EditUserModal>
                <Modal fullscreen show={showRecentActivityModal} onHide={() => setShowRecentActivityModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Recent Activity</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ActivityLogs filters={selectedUser ? {user: `${selectedUser.firstname} ${selectedUser.lastname}`, userId: selectedUser.contactId || null, userEmail: selectedUser.email} : undefined}/>
                    </Modal.Body>
                </Modal>
                </>
                }
            </div> : <Spinner animation="border" className="loader" /> }
        </div>
    );
};

export default Users;