import React, { useState, Dispatch, useEffect } from "react"
import { Row, Col, Container, Form, Input, Alert } from "reactstrap"
import { useDispatch } from "react-redux"
import { Link, useHistory, withRouter } from "react-router-dom"
import instance, { setAxiosHeader } from "../../api/api"
import { ILoginResponse } from "../../interfaces/responses"
import LoginCarousel from "../../components/authentication/carousel.component"
import Cookies from "universal-cookie"
import { AxiosError } from "axios"
import { Spinner } from "react-bootstrap"
import logo from "../../assets/images/logo.png";
import { setIsAuthenticated } from "../../store/userSlice"
import ForgotPasswordRquest from "../../components/authentication/forgot-password-request.component"

const Login: React.FunctionComponent<any> = (props) => {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const [loading, setLoading] = useState<boolean>(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [regSuccess, setRegSuccess] = useState(false)

    const [showForgotPassword, setShowForgotPassword] = useState(false)

    //Histry for redirect after login
    const history = useHistory()

    const cookies = new Cookies()

    useEffect(() => {
        const state: any = history.location.state
        if (state) {
            if (state.regSuccess) {
                setRegSuccess(true)
                setTimeout(() => {
                    setRegSuccess(false)
                }, 5000)
            }
        }
    }, [])

    /**
     * Redux functions to save if authentication was complete or not.
     */
    const dispatch: Dispatch<any> = useDispatch()

    //Set the email equal to the data provided from the user
    function handleEmailChange(evt: React.FormEvent<HTMLInputElement>) {
        setEmail(evt.currentTarget.value)
    }

    //Set the password equal to the data provided from the user
    function handlePasswordChange(evt: React.FormEvent<HTMLInputElement>) {
        setPassword(evt.currentTarget.value)
    }

    async function handleLogin(evt: React.FormEvent) {
        evt.preventDefault()
        setLoading(true);
        //Remove whitespaces from password
        let pwWithoutWhitSpaces = password.replace(/\s/g, "")

        try {
            let response = await instance.post<ILoginResponse>("/auth/login", { email: email, password: pwWithoutWhitSpaces })
            cookies.set("token", response.data.token, { path: "/" })
            setAxiosHeader()
            dispatch(setIsAuthenticated(true))
            setLoading(false);
            history.push("/")
        } catch (err) {
            console.log(err)
            const error = err as AxiosError
            setErrorMessage(error.response?.data || "Failed to login")
            setLoading(false);
            setTimeout(() => {
                setErrorMessage("")
            }, 5000)
        }
    }

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0" style={{overflowX:"hidden"}}>
                        <Col lg={4} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <span className="logo-txt">ARTNAV PORTAL</span>
                                            </Link>
                                        </div>
                                        <div className="auth-content">
                                            <div className="text-center">
                                                <h5 className="mb-0">{showForgotPassword ? "Forgot Password" : "Welcome"}</h5>
                                                <p className="text-muted mt-2">{showForgotPassword ? "Enter your email and we will send you a reset" : "Please sign in to your ARTNAV Portal account below"}</p>
                                            </div>
                                            {showForgotPassword ? <ForgotPasswordRquest setShowForgotPassword={setShowForgotPassword} /> : <Form className="custom-form mt-4 pt-2" onSubmit={handleLogin}>
                                                <div className="mb-3">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <label className="form-label">Email</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <Input name="email" label="Email" onChange={handleEmailChange} className="form-control" placeholder="Enter email" type="email" required />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <label className="form-label">Password</label>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="">
                                                                <p onClick={() => setShowForgotPassword(!showForgotPassword)} className="forgot-password-link text-muted">
                                                                    Forgot password?
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Input name="password" onChange={handlePasswordChange} type="password" className="form-control" required placeholder="Enter Password" />
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="remember-check" />
                                                            <label className="form-check-label" htmlFor="remember-check">
                                                                Remember me
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">
                                                        Sign in
                                                        {loading && <Spinner className="login-loader" animation="border" size="sm" />}
                                                    </button>
                                                </div>
                                                <div>
                                                    <p className="message">If you do not have an ARTNAV Portal account, please be in touch with your ARTNAV contact.</p>
                                                </div>
                                                <div>
                                                    <p className="error-message">{errorMessage}</p>
                                                </div>
                                            </Form>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <LoginCarousel />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Login)
