import React, { useState } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { withRouter, Link, useHistory } from "react-router-dom"
import userImage from "../../assets/images/dummy_user.png"
import logout from "../../_helper/_logout"
import { ERoles } from "../../interfaces/authentication"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { isAdminAccess } from "../fair/_helper/module_helper"

function ProfileMenu(props: any) {
    //Logged in user
    const { user } = useSelector((state) => (state as RootState).userReducer)
    //redux data
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer)

    const [menu, setMenu] = useState<boolean>(false)
    const history = useHistory();

    function handleLogout(e: any) {
        e.stopPropagation()
        logout()
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block profile-menu">
                <DropdownToggle className="btn header-item bg-soft-light border-start border-end" id="page-header-user-dropdown" tag="button">
                    <img className="rounded-circle header-profile-user" src={userImage} alt="Header Avatar" />
                    <div>
                        <span className="d-none d-xl-inline-block ms-2 me-1">{user.firstname} {user.lastname}</span>
                        {isAdminAccess(user, selectedGallery) ?
                        <div>
                            <span className="d-none d-xl-inline-block ms-2 me-1 gallery_name">Administrator</span>
                        </div>
                        :
                        <div>
                            {selectedGallery.gallery_id && <span className="d-none d-xl-inline-block ms-2 me-1 gallery_name">{selectedGallery.gallery_name}</span>}
                        </div>
                        }
                    </div>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem className="user-info" header>
                        <span className="username">{user.firstname} {user.lastname}</span>
                        {isAdminAccess(user, selectedGallery) ?
                            <span className="role">Administrator</span>
                        :
                        <span>
                            {user.gallery_access.length && <span className="role">{selectedGallery.gallery_name}</span>}
                        </span>
                        }
                    </DropdownItem>
                    <div className="dropdown-divider user-info-divider" />
                    <DropdownItem onClick={() => history.push("/profile")}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        Profile
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <Link to="#" className="dropdown-item" onClick={handleLogout}>
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                        <span>Logout</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

ProfileMenu.propTypes = {
    success: PropTypes.any,
}
export default withRouter(ProfileMenu)
