import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react"
import { useHistory, withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { Icon } from "@ailibs/feather-react-ts/dist/Icon"
import { IFair, IMainPage, IModule } from "../../../interfaces/fairs"
import { ERoles } from "../../../interfaces/authentication"
import { ISubPage } from "../../../interfaces/fairs"
import {SidebarLinks, SidebarAdminLinks} from "./sidebar-links.component"
import moment from "moment"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { closeSidebarMobile } from "../../../_helper/_setSidebar"
import { getIconName } from "../../../_helper/_custom"
import { checkModuleAccess, isAdminAccess } from "../../fair/_helper/module_helper";

interface ISelectedModule
{
    parent: IFair
    module: IModule
}

const SidebarContent = (props: any) =>
{
    const ref = useRef<any>()
    const history = useHistory()

    const { user, selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const [selectedModule, selectModule] = useState<ISelectedModule | undefined>()
    const [showModuleMenu, setShowModuleMenu] = useState(false)

    const [activeFairItem, selectFairItem] = useState<string>("");
    const [activeMainPageItem, selectMainPageItem] = useState<string>("");

    const setSelected = async () => {
        try {
            const path = history.location.pathname;
            for (let fair of fairs) {
                for (let module of fair.moduls) {
                    if ((path.includes(fair._id) && module._id && (path.includes('fair') || path.includes('creator'))) && path.includes(module._id)) {
                        const selectedModule: ISelectedModule = {
                            parent: fair,
                            module: module,
                        }
                        selectModule(selectedModule);
                        setShowModuleMenu(true);
                        selectFairItem(fair._id);
                    } else if (!path.includes("fair") && !path.includes("creator")) {
                        selectModule(undefined);
                        setShowModuleMenu(false);
                    }
                    if (path.includes("fairs") && path.includes(fair._id)) {
                        selectFairItem(fair._id);
                    }
                }
            }
        } catch (error)
        {
            console.log(error)
        }
    }

    useEffect(() => {
        setSelected();
        if (history.location.pathname.includes("creator"))
        {
            document.body.classList.add("sidebar-enable");
            document.body.dataset.sidebarSize = "sm";
        } else if (window.screen.width > 1024)
        {
            document.body.classList.remove("sidebar-enable");
            document.body.dataset.sidebarSize = "lg";
        }

    }, [history.location.pathname, fairs])

    useEffect(() => {
        ref.current.recalculate()
    })

    function handleFairClick(id: string) {
        closeSidebarMobile()
        selectFairItem(id);
        history.push(`/fairs/${id}`);
    }

    function handleModuleClick(parent: IFair, module: IModule) {
        closeSidebarMobile()
        const selected: ISelectedModule = { parent: parent, module: module }
        selectModule(selected)
        setShowModuleMenu(true)
    }

    function handleMainPageItemClick(id: string)
    {
        if (id === activeMainPageItem)
            selectMainPageItem("");
        else
            selectMainPageItem(id);
    }

    function setSubPageClass(subPage: ISubPage)
    {
        if (subPage._id)
        {
            const activeClass = history.location.pathname.includes(subPage._id) ? "active" : "";
            return `subpage-item ${activeClass}`
        }
    }

    function getModuleLinkPath(fair: IFair, selectedModule: IModule)
    {
        let path = `/fair/${fair._id}/${selectedModule._id}`

        if (selectedModule.main_pages[0] && (selectedModule.main_pages[0].markdown || selectedModule.main_pages[0].surveys?.length))
        {
            path += `/${selectedModule.main_pages[0]?._id}`
        } else if (selectedModule.main_pages[0]?.sub_pages.length)
        {
            path += `/${selectedModule.main_pages[0]?._id}/${selectedModule.main_pages[0]?.sub_pages[0]?._id}`
        }
        return path
    }

    function getBackItemLinkPath() {
        let path = `/fair/${selectedModule?.parent._id}/${selectedModule?.module._id}`;
        if (selectedModule?.module.main_pages.length) {
            selectedModule?.module.main_pages.forEach(mainPage => {
                if (mainPage.sub_pages.length) {
                    mainPage.sub_pages.forEach(subPage => {
                        if (subPage._id && history.location.pathname.includes(subPage._id)) {
                            path += `/${mainPage._id}/${subPage._id}`;
                            return path;
                        }
                    })
                }
                if (mainPage._id && history.location.pathname.includes(mainPage._id)) {
                    path += `/${mainPage._id}`;
                    return path;
                }
            })
        }
        return path;
    }

    function getMainPageLinkPath(mainPage: IMainPage) {
        let path = `/fair/${selectedModule?.parent._id}/${selectedModule?.module._id}/${mainPage._id}`;
        if (mainPage.surveys.length || mainPage.markdown) {
            return path;
        } else if (mainPage.sub_pages.length) {
            path += `/${mainPage.sub_pages[0]._id}`;
            return path;
        }
        return path;
    }

    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
                <div id="sidebar-menu">
                    {user._id && <ul className="metismenu list-unstyled" id="side-menu">
                        {!showModuleMenu ? (
                            <React.Fragment>
                                <div className="main-menu">
                                    <li className="menu-title">Main Menu</li>
                                        <SidebarLinks user={user} selectFairItem={selectFairItem}></SidebarLinks>
                                    <li>
                                        <div className={history.location.pathname === "/fairs" ? "menu-item-wrapper active" : "menu-item-wrapper"} onClick={() => { selectFairItem(""); closeSidebarMobile() }}>
                                            <Link className="main-item" to="/fairs">
                                                <Icon name="zap" />
                                                <span>My Fairs</span>
                                            </Link>
                                        </div>
                                        <ul>
                                            {fairs.filter((fair:IFair)=>fair.moduls.some(module=>(((module.isEnabled && checkModuleAccess(fair,module,user,selectedGallery)) || isAdminAccess(user, selectedGallery) )))).map((fair: IFair) =>{
                                                return (
                                                    <li className="fair-menu" key={fair._id}>
                                                        <div className={`menu-item-wrapper submenu-item ${(history.location.pathname.includes(fair._id) && history.location.pathname.includes("fair")) && activeFairItem === fair._id ? "active mm-active" : ""}`} onClick={() => handleFairClick(fair._id)}>
                                                            <Link to="#" className="has-arrow">
                                                                {fair.name}
                                                            </Link>
                                                        </div>
                                                        <ul className={(history.location.pathname.includes(fair._id) && history.location.pathname.includes("fair")) && activeFairItem === fair._id ? "show" : ""}>
                                                            {fair.moduls.map(module => {
                                                                if(checkModuleAccess(fair,module,user,selectedGallery)) {
                                                                    return(
                                                                        <li key={module._id}>
                                                                            <div className={`menu-item-wrapper module-item`}>
                                                                            {(module.isEnabled || isAdminAccess(user, selectedGallery)) && 
                                                                            <Link
                                                                                    to={module.isEnabled || isAdminAccess(user, selectedGallery) ? getModuleLinkPath(fair, module) : "#"}
                                                                                    className={module.isOpen || isAdminAccess(user, selectedGallery) ? "has-arrow" : ""}
                                                                                    onClick={() => (module.isEnabled || isAdminAccess(user, selectedGallery)) && handleModuleClick(fair, module)}>
                                                                                    <Icon name={(module.isEnabled && module.isOpen) || !isAdminAccess(user, selectedGallery) ? getIconName(module.modultype.name) : "lock"}></Icon>
                                                                                    {module.title}
                                                                                    {!module.isOpen && !isAdminAccess(user, selectedGallery) ? <Icon className={"lock-icon"} name={"lock"} size="10"></Icon> : <></>}
                                                                                </Link>}
                                                                            </div>
                                                                    </li>
                                                                    )
                                                                }
                                                            })}
                                                        </ul>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                </div>
                                {isAdminAccess(user, selectedGallery) ? <div className="admin-menu">
                                    <li className="menu-title">Admin Menu</li>
                                    <SidebarAdminLinks user={user} selectFairItem={selectFairItem}/>
                                    <li>
                                        <div className={history.location.pathname === "/submissions" ? "menu-item-wrapper active" : "menu-item-wrapper"} >
                                            <Link className="main-item" to="/submissions">
                                                <Icon name="zap" />
                                                <span>Submissions</span>
                                            </Link>
                                        </div>
                                        <ul>
                                            {fairs.map((fair: IFair) =>{
                                                return (
                                                    <li className="fair-menu" key={fair._id}>
                                                        <div className={`menu-item-wrapper submenu-item ${(history.location.pathname.includes(fair._id) && history.location.pathname.includes("submissions")) && activeFairItem === fair._id ? "active mm-active" : ""}`} onClick={() => {selectFairItem(fair._id); history.push("/submissions/" + fair._id)}}>
                                                            <Link to="#" className="has-arrow">
                                                                {fair.name}
                                                            </Link>
                                                        </div>
                                                        <ul className={(history.location.pathname.includes(fair._id) && history.location.pathname.includes("submissions")) && activeFairItem === fair._id ? "show" : ""}>
                                                            {fair.moduls.map(module => {
                                                                return (
                                                                    <li key={module._id}>
                                                                        <div className={`menu-item-wrapper module-item ${(history.location.pathname.includes(fair._id) && history.location.pathname.includes("submissions") && history.location.pathname.includes(module._id as string)) ? "active mm-active" : ""}`}>
                                                                            <Link to={"/submissions/" + fair._id + "/" + module._id} className="has-arrow" >
                                                                                <Icon name={getIconName(module.modultype?.name)}></Icon>
                                                                                {module.title}
                                                                            </Link>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                    
                                    <li>
                                        <div className={history.location.pathname === "/files" ? "menu-item-wrapper active" : "menu-item-wrapper"} >
                                            <Link className="main-item" to="/files">
                                                <Icon name="folder" />
                                                <span>Files</span>
                                            </Link>
                                        </div>
                                        <ul>
                                            {fairs.map((fair: IFair) =>{
                                                return (
                                                    <li className="fair-menu" key={fair._id}>
                                                        <div className={`menu-item-wrapper submenu-item ${(history.location.pathname.includes(fair._id) && history.location.pathname.includes("files")) && activeFairItem === fair._id ? "active mm-active" : ""}`} onClick={() => {selectFairItem(fair._id); history.push("/files/" + fair._id)}}>
                                                            <Link to="#" className="has-arrow">
                                                                {fair.name}
                                                            </Link>
                                                        </div>
                                                        <ul className={(history.location.pathname.includes(fair._id) && history.location.pathname.includes("files")) && activeFairItem === fair._id ? "show" : ""}>
                                                            {fair.moduls.map(module => {
                                                                return (
                                                                    <li key={module._id}>
                                                                        <div className={`menu-item-wrapper module-item ${(history.location.pathname.includes(fair._id) && history.location.pathname.includes("files") && history.location.pathname.includes(module._id as string)) ? "active mm-active" : ""}`}>
                                                                            <Link to={"/files/" + fair._id + "/" + module._id} className="has-arrow" >
                                                                                <Icon name={getIconName(module.modultype?.name)}></Icon>
                                                                                {module.title}
                                                                            </Link>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>

                                    <li>
                                        <div className={`${history.location.pathname.includes("/reports") ? "menu-item-wrapper active" : "menu-item-wrapper"}`} >
                                            <Link className="main-item" to="/reports">
                                                <Icon name="pie-chart" />
                                                <span>Reports</span>
                                            </Link>
                                        </div>
                                        <ul>
                                            {history.location.pathname.includes("/reports") ? fairs.map((fair: IFair) =>{
                                                return (
                                                    <li className="fair-menu" key={fair._id}>
                                                        <div className={`menu-item-wrapper submenu-item ${(history.location.pathname.includes(fair._id) && history.location.pathname.includes("/reports")) && activeFairItem === fair._id ? "active mm-active" : ""}`}>
                                                            <Link to={"/reports/" + fair._id} onClick={() => {selectFairItem(fair._id)}} className="has-arrow">
                                                                {fair.name}
                                                            </Link>
                                                        </div>
                                                        <ul className={(history.location.pathname.includes(fair._id) && history.location.pathname.includes("/reports")) && activeFairItem === fair._id ? "show" : ""}>
                                                            {fair.moduls.map(module => {
                                                                return (
                                                                    <li key={module._id}>
                                                                        <div className={`menu-item-wrapper module-item ${(history.location.pathname.includes(fair._id) && history.location.pathname.includes("/reports") && history.location.pathname.includes(module._id as string)) ? "active mm-active" : ""}`}>
                                                                            <Link to={"/reports/" + fair._id + "/" + module._id} >
                                                                                <Icon name={getIconName(module.modultype?.name)}></Icon>
                                                                                {module.title}
                                                                            </Link>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </li>
                                                )
                                            }): <></>}
                                        </ul>
                                    </li>
                                </div> : null }
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div>
                                <li>
                                    {!history.location.pathname.includes("creator") ?
                                        <>
                                        <div className="menu-item-wrapper">
                                            <Link
                                                className="fair-name"
                                                to={`/fairs/${activeFairItem}`}
                                                onClick={(e) => { history.push(`/fairs/${activeFairItem}`); setShowModuleMenu(false); selectModule(undefined); }}>
                                                {`${selectedModule?.parent.name}`}
                                            </Link>
                                            
                                        </div>
                                        <Link className="toggle-sidebar" to="#" onClick={() => {document.body.classList.remove("sidebar-enable"); document.body.dataset.sidebarSize = "lg";}}><i className="fa fa-angle-right"></i></Link>
                                        </>
                                        : <div className="back-item-wrapper">
                                            <Link className="creator-back-item" to={/*`/fair/${activeFairItem}/${selectedModule?.module._id}/${selectedModule?.module.main_pages[0] ? selectedModule.module.main_pages[0]._id : ""}`*/ getBackItemLinkPath()}>
                                                <i className="fa fa-angle-left"></i>
                                            </Link>
                                        </div>}
                                        
                                </li>
                                </div>
                                {!history.location.pathname.includes("creator") && selectedModule?.module.main_pages.map((mainPage) => {
                                    return (
                                        <li key ={mainPage._id} className="main-page-wrapper">
                                            {(mainPage.isEnabled || isAdminAccess(user, selectedGallery)) && <div className={`menu-item-wrapper main-page-item ${mainPage._id && history.location.pathname.includes(mainPage._id) ? " mm-active" : ""
                                                } ${mainPage._id && history.location.pathname.includes(mainPage._id) ? "active" : ""
                                                }`}>
                                                <Link
                                                    to={/*`/fair/${selectedModule.parent._id}/${selectedModule.module._id}/${mainPage._id}${mainPage.surveys?.length || mainPage.markdown ? "" : mainPage.sub_pages.length ?  "/" + mainPage.sub_pages[0]?._id : ""}
                                                        }`*/getMainPageLinkPath(mainPage)
                                                    }
                                                    onClick={() => mainPage._id ? handleMainPageItemClick(mainPage._id) : null}
                                                    className={(
                                                        ((!isAdminAccess(user, selectedGallery)) && mainPage.sub_pages.filter(p=>p.isEnabled).length) ||
                                                        (isAdminAccess(user, selectedGallery) && mainPage.sub_pages.length))
                                                    ? "has-arrow" : ""}>
                                                        <div className="page-item-wrapper">
                                                            <div>
                                                                <span className="page-title">
                                                                {mainPage.title}
                                                                {mainPage.isMandatory && <span style={{color:"red"}}> *</span>}
                                                                </span>
                                                                
                                                                {mainPage.deadline && <span className="page-deadline">{"Deadline: " + moment(mainPage.deadline).format("Do MMMM, YYYY")}</span>}
                                                            </div>
                                                            {!mainPage.isEnabled && <div className="icon"><Icon name="lock"></Icon></div>}
                                                        </div>
                                                </Link>
                                            </div>}
                                            <ul className={mainPage._id && history.location.pathname.includes(mainPage._id) ? "subpages show" : "subpages"}>
                                                {mainPage.sub_pages.map((subPage) =>
                                                {
                                                    return (
                                                        <li key={subPage._id}>
                                                            {(subPage.isEnabled || isAdminAccess(user, selectedGallery)) && <div className={`menu-item-wrapper subpage-item ${setSubPageClass(subPage)}`}>
                                                                <Link
                                                                    to={`/fair/${selectedModule.parent._id}/${selectedModule.module._id}/${mainPage._id}/${subPage._id}`
                                                                    }>
                                                                    <div className="page-item-wrapper">
                                                                        <div>
                                                                            <span className="page-title">
                                                                            {subPage.title}
                                                                            {subPage.isMandatory && <span style={{color:"red"}}> *</span>}
                                                                            </span>
                                                                            {subPage.deadline && <span className="page-deadline">{"Deadline: " + new Date(subPage.deadline).toLocaleDateString()}</span>}
                                                                        </div>
                                                                        {!subPage.isEnabled && <div className="icon"><Icon name="lock"></Icon></div>}
                                                                    </div>
                                                                </Link>
                                                            </div>}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                    )
                                })}
                            </React.Fragment>
                        )}
                    </ul> }
                </div>
            </SimpleBar>
        </React.Fragment>
    )
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(SidebarContent)
