import { Icon } from '@ailibs/feather-react-ts';
import React from 'react';
import { Col, Dropdown, DropdownButton, Form, FormControl, Row } from 'react-bootstrap';

interface IProps {
    paymentMethod: string,
    paymentMethodOptions: string[],
    orderCategory: string,
    orderCategoryOptions: string[],
    hasDownloadButton: string,
    successMessage: string,
    categoryNames: string[],
    categoryNameOptions: string[],
    typeNames: string[],
    typeNameOptions: string[],
    setPaymentMethod: Function,
    setOrderCategory: Function,
    setHasDownloadButton: Function,
    setSuccessMessage: Function,
    selectCategory: Function,
    selectType: Function
}

function OrderFormSettings(props: IProps) {

    return (
        <div>
            <Row className="filter-selector">
                <Col>
                    <p className="label">Payment Method</p>
                    <Form.Select value={props.paymentMethod} onChange={(e) => props.setPaymentMethod(e.target.value)}>
                        {props.paymentMethodOptions.map((option, i) => {
                            return (
                                <option value={option} key={i}>
                                    <span>{option}</span>
                                </option>
                            )
                        })}
                    </Form.Select>
                </Col>
                <Col>
                    <p className="label">Order Category</p>
                    <Form.Select value={props.orderCategory} onChange={(e) => props.setOrderCategory(e.target.value)}>
                        {props.orderCategoryOptions.map((option, i) => {
                            return (
                                <option value={option} key={i}>
                                    <span>{option}</span>
                                </option>
                            )
                        })}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="filter-selector">
                <Col>
                    <p className="label">Can Download PDF</p>
                    <Form.Select value={props.hasDownloadButton} onChange={(e) => props.setHasDownloadButton(e.target.value)}>
                        <option value={'0'} key={0}>
                            <span>{'No'}</span>
                        </option>
                        <option value={'1'} key={1}>
                            <span>{'Yes'}</span>
                        </option>
                    </Form.Select>
                </Col>
                <Col>
                    <p className="label">Success Message</p>
                    <FormControl
                        type={'text'}
                        defaultValue={props.successMessage}
                        onChange={(e) => props.setSuccessMessage(e.target.value)} />
                </Col>
            </Row>
            <Row className="filter-selector">
                <Col>
                    <p className="label">Item Category</p>
                    <DropdownButton id="dropdown-item-button" title={`selected: ${props.categoryNames.length}`} variant="secondary" className="type-select-button" autoClose={"outside"}>
                        {props.categoryNameOptions.map((option, i) => {
                            return (
                                <Dropdown.Item key={i} as="button" onClick={() => { props.selectCategory(option) }}>{option} {props.categoryNames.includes(option) && <Icon name="check" size={20}></Icon>}</Dropdown.Item>
                            );
                        })}
                    </DropdownButton>
                </Col>

                <Col>
                    <p className="label">Item Type</p>
                    <DropdownButton id="dropdown-item-button" title={`selected: ${props.typeNames.length}`} variant="secondary" className="type-select-button" autoClose={"outside"}>
                        {props.typeNameOptions.map((option, i) => {
                            return (
                                <Dropdown.Item key={i} as="button" onClick={() => { props.selectType(option) }}>{option} {props.typeNames.includes(option) && <Icon name="check" size={20}></Icon>}</Dropdown.Item>
                            );
                        })}
                    </DropdownButton>
                </Col>
            </Row>

        </div>
    )
}
export default OrderFormSettings;