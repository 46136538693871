import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IPageState {
    name: string
    firstBreadCrumbName: string
    secondBreadCrumbName: string
    thirdBreadCrumbName: string
}

const initialState: IPageState = {
    name: "",
    firstBreadCrumbName: "",
    secondBreadCrumbName: "",
    thirdBreadCrumbName: "",
}

const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        setPageData: (state: IPageState, action: PayloadAction<IPageState>) => {
            return { ...state, ...action.payload }
        },
        resetPage: (state: any) => {
            return { ...initialState }
        },
    },
})

export const { setPageData, resetPage } = pageSlice.actions
export default pageSlice.reducer
