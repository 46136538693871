import axios from 'axios';
import Cookies from "universal-cookie"

const cookies = new Cookies();

/**
 * Default instance for axios
 */
export const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL, //TODO: Move to config if possible
	timeout: parseInt(process.env.REACT_APP_TIMEOUT || "600000"),
});

export function setAxiosHeader() {
    instance.defaults.headers.common["Authorization"] = `Bearer ${cookies.get("token")}`
}

setAxiosHeader()

export default instance
