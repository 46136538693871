import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./userSlice"
import pageReducer from "./pageSlice"
import fairsReducer from "./fairsSlice"

const store = configureStore({
    reducer: {
        userReducer: userReducer,
        pageReducer: pageReducer,
        fairsReducer: fairsReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export default store
