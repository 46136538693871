import { Form } from "react-bootstrap"

interface Iprops{
    label:string
    value:string,
    onChange:any,
    disabled?:boolean,
    onKeyDown?:any
}

function TextFilter(props:Iprops) {

    return (
        <div className="datatable-filter">
            <div className="column-title">{props.label}</div>
            <Form.Control disabled={props.disabled} onClick={(e)=>{e.stopPropagation()}} value={props.value} onChange={(e)=>{props.onChange(e.target.value)}} onKeyDown={props.onKeyDown}></Form.Control>
        </div>
    )
}

export default TextFilter
