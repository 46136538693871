import { Icon } from "@ailibs/feather-react-ts";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import instance from "../../api/api";
import { EOrderCategory, EOrderItemStatus, EOrderStatus, EPaymentStatus, EPaymentType, IFair, IOrderItem, IPayment } from "../../interfaces/fairs";
import { setPageData } from "../../store/pageSlice";
import { RootState } from "../../store/store";
import { getOpportunityId } from "../../_helper/_custom";
import CheckoutForm from "./checkout-form";
import { round } from "lodash";
interface IOrderData {
    fair:string
    module:string
    page: string
    gallery_id:string
    gallery_name:string
    order_items:any[]
    status:EOrderStatus
    payment:IPayment
    orderCategory:EOrderCategory
    legal_entity: string
    opportunity_id: string
}

interface ICart {
    items:IOrderItem[],
    fairId:string,
    moduleId:string,
    pageId:string,
    orderCategory:EOrderCategory
    currency_name:string
    currency_symbol:string
    moduleContractCategoryId:string
}

interface IProps{

}

export default function Checkout(props:IProps) {

    const dispatch = useDispatch();
    const history = useHistory();
    const cookies = new Cookies();

    const {fairs} = useSelector((state) => (state as RootState).fairsReducer);

    
    // const cart = useSelector((state) => (state as RootState).cartReducer);
    const [cart,setCart] = useState<ICart|null>(null);
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer);
    const [success,setSuccess] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [fair, setFair] = useState<IFair|undefined>(undefined);

    const getCartSum = cart?.items.reduce((accumulator,object)=>{return accumulator + round(round(parseFloat(object.price),2)*object.value,2)},0) || 0;
    const renderCart = cart?.items.map((item:IOrderItem,index)=>(<div className="itemRow" key={index}> <div className="itemName"> {item.name} {item.value>1 ? "X "+item.value : ""} </div> <div className="itemPrice">{cart.currency_symbol}{round(round(parseFloat(item.price),2)*item.value,2)}</div> </div>));

    //stripe
    const stripePromise = process.env.REACT_APP_STRIPE_PK ? loadStripe(process.env.REACT_APP_STRIPE_PK) : null;
    const [clientSecret, setClientSecret] = useState<string>("");

    const createPaymentIntent = async()=>{
        try {
            const response = await instance.post("stripe/create-payment-intent", {
                items:cart?.items,
                currency:cart?.currency_name
            });
            setClientSecret(response.data.clientSecret)
        } catch (error) {
            console.log(error);
        }
    }

    const createOrder = async(paymentData:string)=>{
        try {
            if(!cart){
                return;
            }
            const orderData : IOrderData = {
                fair:cart.fairId,
                module:cart.moduleId,
                gallery_id:selectedGallery.gallery_id,
                gallery_name:selectedGallery.gallery_name,
                page: cart.pageId,
                order_items:cart.items.map((item)=>{return {...item,status:EOrderItemStatus.APPROVED}}),
                status: EOrderStatus.APPROVED,
                payment:{
                    type:EPaymentType.STRIPE,
                    data:paymentData,
                    status:EPaymentStatus.PAID,
                    currency:fair?.currency || "GBP",
                },
                orderCategory:cart.orderCategory,
                legal_entity: fair?.legalEntity || "",
                opportunity_id: getOpportunityId(selectedGallery, cart.moduleContractCategoryId)
            };
            await instance.post("/order", {data : orderData});
        } catch (error) {
            console.log(error);
        }
    }

    const onSuccess = (paymentData:string)=>{
        createOrder(paymentData);
        cookies.remove('cart',{path:'/'});
        setSuccess(true);
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get("successMessage")) {
            setSuccessMessage(query.get("successMessage") || '');
        }
        if (query.get("success")) {
          setSuccess(true);
          return;
        }

        let cartCookie = cookies.get("cart");

        if(!cartCookie){
            return;
        }
        setCart(cartCookie);
        const fair = fairs.find(item => item._id === cartCookie.fairId);
        setFair(fair);
      
    }, []);

    useEffect(()=>{
        if(!cart || success || clientSecret){
            return;
        }
        if(cart.items.length>0){
            createPaymentIntent();
        }
    },[cart])


    useEffect(() =>
    {
        // set page title and breadcrumbs of the header
        dispatch(
            setPageData({
                name: "Checkout",
                firstBreadCrumbName: "",
                secondBreadCrumbName: "",
                thirdBreadCrumbName: "",
            })
        );

    }, [dispatch]);

    return (
        <div className="checkout-page">
            {success && <div className="successMsg">
                <Icon size={48} name="check" color="green"></Icon>
                <h5>{successMessage}</h5>
                <Button variant="light" onClick={()=>{history.goBack()}}>Go Back</Button>
            </div>}
            

            {!success && <div>
            <Card>
                <Card.Header>
                    <Icon name="shopping-cart"></Icon>
                    <h5>Summary</h5>
                </Card.Header>
                <Card.Body>
                    {cart && cart.items.length>0 ? <>
                        {renderCart}
                            <>
                                {/* <div className="vatRow"><span>20% VAT included</span></div> */}
                                <div className="totalRow"><span>Total: </span><span>{cart.currency_symbol} {getCartSum}</span></div>
                            </>
                        </> :
                    <p className="empty"><i>Your order is empty</i></p>}
                </Card.Body>
            </Card>
            {clientSecret && stripePromise && fairs.length &&
                <Elements options={{clientSecret:clientSecret,appearance:{theme:"stripe"}}} stripe={stripePromise}>
                  <CheckoutForm onSuccess={(paymentData:string)=>{onSuccess(paymentData)}}/>
              </Elements>
            }

           
            </div>}
        </div>
    );
};
