import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
// import { createRoot } from "react-dom/client";
import { Icon } from "@ailibs/feather-react-ts/dist/Icon";
import instance from "../../api/api";
import { truncate } from "lodash";

import EmailEditForm from "./email-edit-form.components";
import AlertDismissible from "../alert/alert-dismissible.component";
import { toast } from "react-toastify";
import { IUploadedFile } from "../../interfaces/fairs";

const EmailTableRow = (props: any) => {
    const { _id, name, subject, body, attachments } = props.data;

    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isEdited, setIsEdited] = useState<boolean>(false);

    const handleSendEmail = (name: string, subject: string, body: string, attachments:IUploadedFile[]) => {
        props.sendTestEmail(name, subject, body, attachments);
        ReactDOM.render(<AlertDismissible variant={"success"} message={`"${name}" test email sent.`} />, document.getElementById("email-alert"));
    };

    async function handleDelete(_id: string) {
        if (window.confirm(`Are you sure you want to delete "${name}"`)) {
        try {
            const response = await instance.delete(`/email/${_id}`);
            console.log(response);
            setIsDeleted(true);
            props.handleEditTemplate(!isEdited);
            ReactDOM.render(<AlertDismissible variant={"success"} message={`"${name}" is deleted.`} />, document.getElementById("email-alert"));
        } catch (error) {
            // TODO: handle error
            console.log("Failed to delete email template", error);
            ReactDOM.render(<AlertDismissible variant={"error"} message={`Failed to delete "${name}" template.`} />, document.getElementById("email-alert"));
        }
        }
    };

    const handleEdit = () => {
        if (isEdited) {
            ReactDOM.render(<EmailEditForm data={props.data} handleEdit={handleEdit}></EmailEditForm>, document.getElementById("email-modaledit"));
        }
        props.handleEditTemplate(!isEdited);
        setIsEdited(!isEdited);
    };

    useEffect(() => {
        if (isEdited) {
            ReactDOM.render(<EmailEditForm data={props.data} handleEdit={handleEdit}></EmailEditForm>, document.getElementById("email-modaledit"));
        }
    }, [isEdited]);

    return !isDeleted ? (
        <tr>
        <td>{name}</td>
        <td>{subject}</td>
        <td>{truncate(body, {length: 20})}</td>
        <td>{attachments.length ? <>{attachments.length}&times;<Icon name="file" size={14} /></> : ""}</td>
        <td>
            <div className="button-group email-actions">
            <button
                onClick={() => {
                handleDelete(_id);
                }}
                type="button"
                title="Delete email template"
                className="btn btn-danger btn-sm">
                <Icon name="trash" size={14} /> Delete
            </button>

            <button
                onClick={() => {
                handleSendEmail(name, subject, body, attachments);
                }}
                type="button"
                title="Send test email"
                className="btn btn-secondary btn-sm">
                <Icon name="send" size={14} /> Test
            </button>

            <button onClick={handleEdit} type="button" title="Edit email content" className="btn btn-success btn-sm">
                <Icon name="edit" size={14} /> Edit
            </button>
            </div>
        </td>
        </tr>
    ) : (
        <></>
    );
};

export default EmailTableRow;
