import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import instance from "../../api/api";
import SubpageContentCreator from "../../components/content-creator/subpage-content-creator.component";
import SubpageList from "../../components/content-creator/subpage-list.component";
import { IFair, IMainPage, IModule, ISubPage } from "../../interfaces/fairs";
import { setPageData } from "../../store/pageSlice";

const ContentCreator: React.FunctionComponent = (props: any) => {
    const params: any = useParams();
    const {id, moduleId, mainPageId, subPageId} = params;

    const history: any = useHistory();

    const [loading, setLoading] = useState(true);

    const [fair, setFair] = useState<IFair>();
    const [module, setModule] = useState<IModule>();
    const [activeKey, setActiveKey] = useState<string>("");

    const [treeData, setTreeData] = useState<any>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        // set page title and breadcrumbs of the header
        dispatch(setPageData({
            name: "Content Creator",
            firstBreadCrumbName: fair?.name || "",
            secondBreadCrumbName: module?.title || "" ,
            thirdBreadCrumbName: "" 
        }))
    }, [dispatch, fair, module])

    useEffect(() => {
        if (subPageId)
            setActiveKey(subPageId);
        else if (mainPageId)
            setActiveKey(mainPageId);
            
        getFairData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, moduleId, mainPageId, subPageId]);

    const getFairData = async () => {
        try {
            const response = await instance.get("/fair/" + id);
            const fairData: IFair = response.data;

            const module = fairData.moduls.find(module => module._id === moduleId);
            const updatedTreeData = setUpTreeData(module);

            setFair(fairData);
            setModule(module);
            setLoading(false);
            return updatedTreeData;
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const setUpTreeData = (module?: IModule) => {
        let tempTreeData = [];
        let index = 0;
        if (module) {
            for (let page of module.main_pages) {
                let treeNode = {
                    id: page._id,
                    title: page.title,
                    subtitle: page.deadline? `Deadline: ${moment(page.deadline).format("Do MMMM, YYYY")}` : "",
                    type: "main_page",
                    deadline: page.deadline,
                    expanded: treeData[index] ? treeData[index].expanded : true,
                    children: [{}],
                    className: "mainpage",
                    markdown: page.markdown,
                    surveys: page.surveys.filter(survey => !survey.deleted),
                    files: page.files,
                    isEnabled: page.isEnabled,
                    isOpen:page.isOpen,
                    isMandatory:page.isMandatory
                }
                treeNode.children.pop();
                for (let subPage of page.sub_pages) {
                    const childNode: any = {
                        id: subPage._id,
                        parentId: page._id,
                        title: subPage.title,
                        className: "subpage",
                        type: "subpage",
                        subtitle: subPage.deadline ? `Deadline: ${moment(page.deadline).format("Do MMMM, YYYY")}` : "",
                        deadline: subPage.deadline,
                        markdown: subPage.markdown,
                        surveys: subPage.surveys.filter(survey => !survey.deleted),
                        files: subPage.files,
                        isEnabled: subPage.isEnabled,
                        isOpen: subPage.isOpen,
                        isMandatory: subPage.isMandatory
                    }
                    treeNode.children.push(childNode);
                }
                tempTreeData.push(treeNode);
                index++;
            }
        }
        setTreeData(tempTreeData);
        return tempTreeData;
    }

    const handleActiveKeyChange = (id: string) => {
        setActiveKey(id);
    }

    return (
        <Row className="builder-wrapper" >
            {!loading ? <>
                <Col className="subpage-list" sm={12} md={6} lg={4}>
                    <SubpageList treeData={treeData} handleActiveKeyChange={handleActiveKeyChange} activeKey={activeKey} setTreeData={setTreeData} fair={fair} module={module} getFairData={getFairData} setUpTreeData={setUpTreeData} />
                </Col>
                <Col sm={12} md={6} lg={8}>
                    {fair && module &&<SubpageContentCreator pages={treeData} activeKey={activeKey} fair={fair} module={module} setUpTreeData={setUpTreeData} />}
                </Col>
            </> : <Spinner animation="border" className="loader" />}
        </Row>
    )
}

export default ContentCreator;