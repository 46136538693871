import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import instance from '../../api/api';
import { ERoles } from '../../interfaces/authentication';
import { IGallerySubmission, IModule, IPageSurvey, IDynamicObject } from '../../interfaces/fairs';
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import {FormHelper} from "../../_helper/formHelper";
import moment from "moment";
import { isAdminAccess } from '../fair/_helper/module_helper';

interface IProps {
    fairId: string
    module: IModule
    settings: IDynamicObject
}

interface IRowForm {
    pageTitle: string
    mainPageId: string
    subPageId: string | null
    surveys: IPageSurvey[]
    deadline: Date | null
}


function ProgressTable(props: IProps) {
    const [forms, setForms] = useState<IRowForm[]>([]);
    const [submissions, setSubmissions] = useState<IGallerySubmission[]>([]);
    const [submissionsLoaded, setSubmissionsLoaded] = useState<boolean>(false);

    //Check if all data provided to be able to load module
    const [ avalible, setAvalible ] = useState<boolean>(false);

    //redux
    const { user } = useSelector((state) => (state as RootState).userReducer);
    const { selectedGallery } = useSelector((state) => (state as RootState).userReducer);

    useEffect(() => {
        if(props.module !== null && props.fairId !== null) {
            setAvalible(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(avalible) {
            getGallerySubmissions();
            setUpForms();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.module, props.fairId, user, submissionsLoaded, avalible])

    const setUpForms = () => {
        const module = props.module;
        let tempForms: IRowForm[] = [];

        if (module) {
            for (let mainPage of module.main_pages) {
                if (mainPage.surveys && mainPage.surveys.length && mainPage.isMandatory && mainPage.isEnabled) {
                    for (let survey of mainPage.surveys) {
						if (!survey.deleted) {
                        	const includes = tempForms.find(item => item.mainPageId === mainPage._id);
                        	if (includes) {
                            	includes.surveys.push(survey);
                        	} else {
                            	const rowForm: IRowForm = {
                                	pageTitle: mainPage.title,
                                	mainPageId: mainPage._id || "",
                                	subPageId: null,
                                	surveys: [survey],
                                	deadline: mainPage.deadline
                            	}
								tempForms.push(rowForm);
							}
                        }
                    }
                }
                if (mainPage.sub_pages.length) {
                    for (let subPage of mainPage.sub_pages) {
                        if (subPage.surveys && subPage.surveys.length && subPage.isMandatory && subPage.isEnabled) {
                            for (let survey of subPage.surveys) {
								if (!survey.deleted) {
                                	const includes = tempForms.find(item => item.subPageId && item.subPageId === subPage._id);
                                	if (includes ){
                                    	includes.surveys.push(survey);
                                	} else {
                                    	const rowForm: IRowForm = {
                                        	pageTitle: subPage.title,
                                        	mainPageId: mainPage._id || "",
                                        	subPageId: subPage._id || "",
                                        	surveys: [survey],
                                        	deadline: subPage.deadline
                                    	}
                                    	tempForms.push(rowForm);
									}
                                }
                            }
                        }
                    }
                }
            }
            setForms(tempForms);
        }
    }

    const getGallerySubmissions = async () => {
        try {
            if (user.gallery_access.length && props.fairId && !submissionsLoaded) {
                const response = await instance.get(`/gallery-submission`, {
                    params: {
                        data: {
                            gallery_id:selectedGallery.gallery_id,
                            fair:props.fairId
                        }
                    }
                })
                setSubmissions(response.data);
                setSubmissionsLoaded(true);
            }
        } catch (error) { }
    }

    const checkSubmissionStatus = (survey: IPageSurvey) => {

        //TODO: do not use return in one line, harder to read
        if (isAdminAccess(user, selectedGallery)) return "-";

        //TODO: the submissions should be only for this page not all, we should not filter here
        let filteredSubmissions = submissions.filter(sub => sub.survey._id === survey._id);

        //TODO: this have to be done in a the backend side
        filteredSubmissions = filteredSubmissions.sort(function(subA,subB) {
            if (subA.survey._id === subB.survey._id) {
                return (subA.createdAt < subB.createdAt) ? 1 : ((subB.createdAt > subA.createdAt) ? -1 : 0)
            }
            return subA.survey._id > subB.survey._id ? 1 : -1;
        });

        //TODO: submitted shoudl not be the last one, we should refator the always return the submitted, and get history if needed.
        const submitted = filteredSubmissions[filteredSubmissions.length-1];
        
        const status = FormHelper.status[submitted ? submitted.submission_status : 'incomplete'];

        return <b><span style={{color: status.color}}>{status.name}</span></b>

    }

    const checkRowSubStatus = (surveys:IPageSurvey[]) => {
        if (isAdminAccess(user, selectedGallery)) return "-";
        let rowStatus = '';
        let itemStatus = '';
        for (let survey of surveys) {
            let filteredSubmissions = submissions.filter(sub => sub.survey._id === survey._id);
            filteredSubmissions = filteredSubmissions.sort(function(subA,subB) {
                if (subA.survey._id === subB.survey._id) {
                    return (subA.createdAt < subB.createdAt) ? 1 : ((subB.createdAt > subA.createdAt) ? -1 : 0)
                }
                return subA.survey._id > subB.survey._id ? 1 : -1;
            });

            if(filteredSubmissions.length){
                itemStatus = filteredSubmissions[filteredSubmissions.length-1].submission_status;
            }else{
                itemStatus = FormHelper.status.incomplete.code;
            }

            if(rowStatus === ''){
                rowStatus = itemStatus;
            }else if(rowStatus !== itemStatus){
                rowStatus = FormHelper.status.inprogress.code
            }
            
        }
        const status = FormHelper.status[rowStatus] || {};
        return <b><span style={{color: status.color}}>{status.name}</span></b>
    }

    const caseInsensitiveSort = (rowA:IRowForm, rowB:IRowForm) => {
        return (moment(rowA.deadline) as any) - (moment(rowB.deadline) as any);
    };

    const columns = [
        {
            name: <b>Page</b>,
            cell: (row:IRowForm) => 
                (<Link to={`/fair/${props.fairId}/${props.module._id}/${row.mainPageId}/${row.subPageId ? row.subPageId : ""}`}>
                    <b><u>{row.pageTitle}</u></b>
                </Link>),
            sortable: true,
            sort_id: "page",
        },
        {
            name: <b>Status</b>,
            cell: (row:IRowForm) => checkRowSubStatus(row.surveys),
            sortable: false
        },
        {
            name: <b>Deadline</b>,
            // selector: (row:IRowForm) => row.deadline,
            cell: (row:IRowForm) => (
                <p>{moment(row.deadline).format("Do MMMM, YYYY")} - {moment(row.deadline) < moment() ?
                    <span style={{color:'#eb4034'}}>Overdue</span> :
                    <span style={{fontSize:"12px", color:"#a3a3a3"}}>
                        {(moment(row.deadline).diff(moment(), 'days') ?
                            moment(row.deadline).diff(moment(), 'days') :
                            1) + " days left"}
                    </span>
                }</p>
            ),
            sortFunction: caseInsensitiveSort,
            sortable: true,
            sort_id: "date"
        }
    ]

    const expandableColumns = [
        {
            name: <b>Form</b>,
            selector: (row:IPageSurvey) => row.survey_form.title || "-",
            sortable: false,
        },
        {
            name: <b>Status</b>,
            selector: (row:IPageSurvey) => checkSubmissionStatus(row),
            sortable: false
        }
    ]

    const ExpandableComponent: React.FC<ExpanderComponentProps<any>> = ({ data }) => {
        return <DataTable columns={expandableColumns} data={data.surveys} responsive persistTableHead/>
    }

    const getDefaultSortField = () => {
        const settings = props.settings;

        if (settings.hasOwnProperty("defaultTableSorting")) {
            const defaultSorting = settings.defaultTableSorting;
            if (defaultSorting === "Date") {
                return 3;
            }
        }
        return null;
    }

    return (
        <div>
            {avalible ?
            <DataTable columns={columns} data={forms} persistTableHead responsive expandableRows expandableRowsComponent={ExpandableComponent} defaultSortFieldId={getDefaultSortField()}/>
                :
                <p>Component not avalible with the current page data.</p>}
        </div>
    )
}
export default ProgressTable;
