import React, { useEffect, useState } from "react";
import { Button, Card, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import instance from "../../api/api";
import palceholderImg from "../../assets/images/london.jpg";
import { Icon } from "@ailibs/feather-react-ts";
import { IFair, IModule } from "../../interfaces/fairs";
import { setPageData } from "../../store/pageSlice";
import { getIconName } from "../../_helper/_custom";
import parse from "html-react-parser"
import { toast } from "react-toastify";
import { ERoles } from "../../interfaces/authentication";
import FairModuleEdit from "../../components/fair/fair-module-edit.component";
import ReactDatePicker from "react-datepicker";
import { IEmailTemplate } from "../../interfaces/email";
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { setFairs } from "../../store/fairsSlice";
import { after } from "lodash";
import { checkModuleAccess, isAdminAccess } from "../../components/fair/_helper/module_helper";

interface IStatus
{
    isEnabled?:boolean
    isOpen?:boolean
}
const ModuleSelection: React.FunctionComponent<any> = (props) =>{

    const [loading, setLoading] = useState<boolean>(true);
    const [fair, setFair] = useState<IFair>();
    const [deadline, setDeadline] = useState<Date|null>(null);
    const [deadlineExtending, setDeadlineExtending] = useState<boolean>(false);
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

    //module settings
    const [ showSettingsModal, setShowSettingsModal ] = useState<boolean>(false);
    const [ selectedModule, setSelectedModule ] = useState<IModule | null>(null);

    const { id } = useParams<{ id: string }>()
    const { user, selectedGallery } = useSelector((state) => (state as RootState).userReducer)
    const { fairs } = useSelector((state) => (state as RootState).fairsReducer)

    const history = useHistory();
    const dispatch = useDispatch();

    const [emailTemplates,setEmailTemplates] = useState<IEmailTemplate[]>([]);


    const getFairData = async () => {
        setLoading(true);
        try {
            const response = await instance.get("/fair/" + id);
            const fairData: IFair = response.data;
            setFair(fairData);
            setDeadline(fairData.deadline ? new Date(fairData.deadline) : null);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const getEmailTemplates = async ()=>{
        try {
            const response = await instance.get("email");
            setEmailTemplates(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getFairData();
        getEmailTemplates();
    }, [id]);

    // set page title and breadcrumbs of the header
    useEffect(() => {
        dispatch(setPageData({
            name: "Fair",
            firstBreadCrumbName: fair?.name || "",
            secondBreadCrumbName: "Modules",
            thirdBreadCrumbName: ""
        }))
    }, [dispatch, fair])

    const handleCardClick = (moduleId: string) =>
    {
        if (fair)
        {
            const selectedModule: any = fair.moduls.find(module => module._id === moduleId);
            if (selectedModule.isEnabled || isAdminAccess(user, selectedGallery)) {
                let path = `/fair/${fair._id}/${moduleId}`
                if (selectedModule.main_pages[0] && (selectedModule.main_pages[0].markdown || selectedModule.main_pages[0].surveys?.length))
                {
                    path += `/${selectedModule.main_pages[0]?._id}`
                } else if (selectedModule.main_pages[0]?.sub_pages.length)
                {
                    path += `/${selectedModule.main_pages[0]?._id}/${selectedModule.main_pages[0]?.sub_pages[0]?._id}`
                }
                history.push(path)
            }
        }
    }

    async function extendDeadline() {
        try {
            const updateData = { deadline: deadline };
            const response = await instance.patch("/fair/" + fair?._id, updateData);
            const fairData: IFair = response.data;
            setFair(fairData);
            setDeadline(fairData.deadline ? new Date(fairData.deadline) : null);
            toast.success("Fair deadline updated!");
            setDeadlineExtending(false);
            const updatedFairs = fairs.map((contextFair:IFair) => {
                return contextFair._id === id ? fairData : contextFair
            })
            dispatch(setFairs(updatedFairs))
        } catch (error) {
            console.log(error);
            toast.error("Fair deadline could not be updated.");
            setDeadlineExtending(false);
        }
    }

    async function updateStatus(status: IStatus, moduleId: string) {
        try {
            const response = await instance.patch(`/fair/${fair?._id}/modul/${moduleId}`,status)
            setFair(response.data)

            const updatedFairs = fairs.map((contextFair:IFair) => {
                return contextFair._id === id ? response.data : contextFair
            })
            dispatch(setFairs(updatedFairs))
            toast.success("The module is updated")
        } catch (error) {
            toast.error("Failed to update the module")
        }
    }

    return (
        <div className="module_selection_page">
            {!loading && fair ? <> 
            <div className="content-header">
                <div className="image-wrapper" style={{ backgroundImage: fair.image ? `url(${process.env.REACT_APP_API_URL}/${fair.image.url}` : `url(${palceholderImg}` }}>
                    {isAdminAccess(user, selectedGallery) ? <Button onClick={() => { history.push(`${fair._id}/edit`); }}><Icon name="edit"></Icon><span>Edit Fair</span></Button> : null}
                    <h1>{fair.name}</h1>
                </div>
                <div>{fair.description && parse(fair.description)}</div>
            </div>
            <div className="content-wrapper">
                <div className="card-wrapper">
                    {fair && fair.moduls.map(module => (
                            checkModuleAccess(fair, module, user, selectedGallery) && (module.isEnabled || isAdminAccess(user, selectedGallery) ) ? <Card key={module._id} >
                                <Card.Body className="module-card" onClick={() => handleCardClick(module._id ? module._id : "")}>
                                    <Col sm={12} md={8} lg={10} className="module-info-side" >
                                        <div className="module-icon">
                                            <Icon name={getIconName(module.modultype.name)} size="30px" />
                                        </div>
                                        <div className="module-details">
                                            <h5>{module.title}</h5> {module.description &&<span>{module.description}</span>}
                                        </div>
                                    </Col>
                                    <Col sm={12} md={4} lg={2} className="module-settings-side">
                                        {isAdminAccess(user, selectedGallery) ? <div className="settings">
                                            <Icon name="settings" onClick={(event) => {event.stopPropagation(); setShowSettingsModal(true); setSelectedModule(module)}} />
                                        </div> : null }
                                        <div>
                                            <div className="active-status">
                                            {isAdminAccess(user, selectedGallery) ?<div className="form-check form-switch ">
                                                    <label className={`form-check-label`} htmlFor="customSwitch1">
                                                        <p style={{fontSize:"12px", fontWeight:"300"}}>{`${module.isEnabled ? "Enabled" : "Disabled"}`}</p>
                                                    </label>
                
                                                        <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={module.isEnabled}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={(e) => {
                                                            updateStatus({isEnabled:e.target.checked}, module._id);

                                                        }}
                                                    />
                                                </div> : null}
                                            </div>
                                            <div className="opened-status">
                                                <div className="form-check form-switch ">
                                                    <label className={`form-check-label`} htmlFor="customSwitch1">
                                                        <p style={{fontSize:"12px", fontWeight:"300"}}>{`${module.isOpen ? "Opened" : "Closed"}`}</p>
                                                    </label>
                                                    {isAdminAccess(user, selectedGallery) ?
                                                        <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={module.isOpen}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={(e) => {
                                                            e.stopPropagation()
                                                            updateStatus({isOpen:e.target.checked}, module._id);

                                                        }}
                                                    /> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    
                                </Card.Body>
                            </Card>:null)
                        )
                    }
                </div>
            </div> </> : <Spinner animation="border" className="loader" />}
            {selectedModule && fair ?
                <FairModuleEdit
                    emailTemplates={emailTemplates}
                    show={showSettingsModal}
                    onHide={() => {setShowSettingsModal(false); setSelectedModule(null)}}
                    module={selectedModule}
                    fairId={fair._id}
                    crmId={fair.crm_id}
                    setFair={setFair}
                    setSelectedModule={setSelectedModule}
                />
                : null
            }

        </div>
    );
};

export default ModuleSelection;
